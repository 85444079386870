
<template>
<div>


    <div class="container-fluid fixcontent "  :class="saving?'disable-page ':''">

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
<!--            <div v-if="deferredPrompt" class="col-md-1 alert alert-info text-center" role="alert">-->

<!--                <div class="">-->
<!--                    <div class="d-flex justify-content-center">-->
<!--                        <button class="btn btn-outline-primary mr-2" @click="dismiss">Dismiss</button>-->
<!--                        <button class="btn btn-primary" @click="install">Install</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-md-5  text-center offset-1 col-7 mt-4 mtmb text-light  font-weight-semi-bold" style="font-size: 17px">
            <welcomenote/>

            </div>


            <div class="col-md-4 text-right col-4 text-light mt-1 mtmb" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut"> Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>



        <div class="row mt-2">



           <sidebar :generatePdf="generatePdf"></sidebar>

            <div class="col-md-11"  >
                <div class="row "  >

                    <div class="col-md-12 col-12 mb-1" >

                        <div class="bgsecond fixheight"  >
                            <table class="table   table-responsive-sm">
                                <thead  class="thead-dark table-bordered " >
                                <tr class="">
                                    <th scope="col">Code </th>
                                    <th scope="col" class="col-md-1">Stock </th>
                                    <th scope="col">Description</th>
<!--                                    <th scope="col" >A | F</th>-->

                                    <th scope="col" >Qty</th>
                                    <th scope="col" v-if="discount_applied || a_dis">Original Price</th>
                                    <th scope="col" v-if="discount_applied || a_dis">Discount</th>
                                    <th scope="col">Unit Price</th>

                                    <th scope="col">Total</th>

                                </tr>
                                </thead>
                                <tbody class="" >
                                <datalist id="item-list-id" v-if="showitemdatalist">
                                    <option v-for="item in items" :key="item.mapid" :value="item.itemCode">
<!--                                        <span v-if="loggedUserLocation">-->
<!--                                            {{-->
<!--                                                (-->
<!--                                                    Number(item.FarnboroughWarehouseAfterOpen) +-->
<!--                                                    Number(item.IsleworthWarehouseAfterOpen) +-->
<!--                                                    Number(item.BoundsGreenAfterOpen) +-->
<!--                                                    Number(item.GatwickAfterOpen) +-->
<!--                                                    Number(item.HanworthAfterOpen)+-->
<!--                                                    Number(item.WembleyAfterOpen)+-->
<!--                                                    Number(item.HayesAfterOpen)+-->
<!--                                                    Number(item.WatfordAfterOpen)+-->
<!--                                                    Number(item.CroydonAfterOpen)+-->
<!--                                                    Number(item.WholesaleAfterOpen)+-->
<!--                                                    Number(item.OnlineAfterOpen)-->
<!--                                                ).toFixed(2)-->
<!--                                            }}-->
<!--                                            ({{(item[`${loggedUserLocation}AfterOpen`]).toFixed(2)}})-->
<!--                                        </span>-->
                                    {{ item.itemName }}  | {{item.itemDescription}}  </option>
                                </datalist>
                                <template v-if="searched_items">
                                    <tr class="text-light"  v-for="item in searched_items" :key="item.mapid">
                                        <th class="col-md-3 ">
                                            <div class="input-container w-100">
                                                <span v-if="!adminorderrefund ">
                                                   <i v-if="!paid || deposit || adminorderrefund || ( invoice.status!='Completed' && invoice.status!='Refund Collected' && invoice.status!='Partial Refund'  && invoice.status!='Full Refund')" class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem(item.mapid)" style="margin-right: 0.5rem;"></i>
                                                </span>

                                                <input class="bg-light col-md-11 form-control" v-model="item.itemCode" :disabled="items.length==0 ||((paid || itemsreceived) && !deposit || invoice.refund==1) && ( invoice.status=='Completed' || invoice.status=='Refund Collected' || invoice.status=='Partial Refund' || invoice.status=='Full Refund') " list="item-list-id" @change="addsearchitem('',item.itemCode)"  >
                                            </div>
                                        </th>

                                        <td class="col-md-1">
                                                <span v-if="loggedUserLocation && item.item_stock">



                                                        <span v-b-tooltip.hover title="Forecast Stock in all branches">      {{
                                                                (
                                                                    Number(item.item_stock?.FarnboroughWarehouseAfterOpen) +
                                                                    Number(item.item_stock?.IsleworthWarehouseAfterOpen) +
                                                                    Number(item.item_stock?.BoundsGreenAfterOpen) +
                                                                    Number(item.item_stock?.GatwickAfterOpen) +
                                                                    Number(item.item_stock?.HanworthAfterOpen)+
                                                                    Number(item.item_stock?.WembleyAfterOpen)+
                                                                    Number(item.item_stock?.HayesAfterOpen)+
                                                                    Number(item.item_stock?.WatfordAfterOpen)+
                                                                    Number(item.item_stock?.CroydonAfterOpen)+
                                                                    Number(item.item_stock?.WholesaleAfterOpen)+
                                                                    Number(item.item_stock?.OnlineAfterOpen)
                                                                ).toFixed(2)
                                                            }}
                                                        </span>
                                                                <span v-b-tooltip.hover title="Forecast Stock in current branch">
                                                                ({{ (item.item_stock?.[`${loggedUserLocation}AfterOpen`] || 0).toFixed(2) }})

                                                            </span>

                                                        </span>
                                        </td>
                                        <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="((paid || itemsreceived) && !deposit || invoice.refund==1) && ( invoice.status=='Completed' || invoice.status=='Refund Collected' || invoice.status=='Partial Refund' || invoice.status=='Full Refund')" @input="validate(item.itemCode, item.mapid)" :state="hasDuplicateDescription.length? hasDuplicateDescription[0]!=(item.itemCode+item.itemDescription).trim():null"> </b-input></td>
<!--                                        <td class="col-md-1 ">-->
<!--                                       <span v-if="loggedUserLocation"> {{item[`${loggedUserLocation}Stock`]}} | {{item[`${loggedUserLocation}AfterOpen`]}}</span>-->
<!--                                        </td>-->
                                        <td class="col-md-1 "><input type="number" v-model="item.qty"  @keyup="update_invoice('subTotal', subTotal), update_invoice('grandTotal', Number(subTotal)+Number((subTotal*0.2).toFixed(2))), update_invoice('vat', (subTotal*0.2).toFixed(2))" @change="handlechangeqty(item.mapid),update_invoice('subTotal', subTotal), update_invoice('grandTotal', Number(subTotal)+Number((subTotal*0.2).toFixed(2))), update_invoice('vat', (subTotal*0.2).toFixed(2))"  class="bg-light form-control" :disabled="(paid || itemsreceived) && !deposit && ( invoice.status=='Completed' || invoice.status=='Refund Collected' || invoice.status=='Partial Refund' || invoice.status=='Full Refund')" @input="validate(item.itemCode, item.mapid)"></td>


                                        <td class="col-md-1 " v-if="discount_applied || a_dis"> £{{item.original_price}} </td>
                                        <td class="col-md-1 " v-if="discount_applied || a_dis"> <input class="bg-light form-control" type="number" step="any" v-model="item.discount" @keyup="setdiscount_dp(item.mapid),update_invoice('subTotal', subTotal), update_invoice('grandTotal', Number(subTotal)+Number((subTotal*0.2).toFixed(2))), update_invoice('vat', (subTotal*0.2).toFixed(2))" :disabled="(paid || itemsreceived) && !deposit"/>      </td>

                                        <td class="col-md-1 "><input type="number" class="bg-light form-control" v-model="item.defaultPrice"  @keyup="apply_discount(item.mapid),update_invoice('subTotal', subTotal), update_invoice('grandTotal', Number(subTotal)+Number((subTotal*0.2).toFixed(2))), update_invoice('vat', (subTotal*0.2).toFixed(2))" @change="update_invoice('subTotal', subTotal), update_invoice('grandTotal', Number(subTotal)+Number((subTotal*0.2).toFixed(2))), update_invoice('vat', (subTotal*0.2).toFixed(2))" :disabled="((paid || itemsreceived) && !deposit ) && ( invoice.status=='Completed' || invoice.status=='Refund Collected' || invoice.status=='Partial Refund' || invoice.status=='Full Refund')" @input="validate(item.itemCode, item.mapid)"/></td>


                                        <td >£{{(item.qty*item.defaultPrice).toFixed(2)}}
                                        <span class="sample_print_icon">
                                           <a :href="`/sample_print.php?itemCode=${item.itemCode}&description=${item.itemDescription}&defaultPrice=${item.defaultPrice}&qty=${item.qty}`" target="_blank">
                                                <img src="@/assets/sample_print_icon2.png" width="30px">
                                             </a>
                                        </span>
                                        </td>

                                    </tr>
                                </template>

                                <tr>
                                    <th scope="row" >
                                <span class="text-light" v-if="items.length==0">
                                    Please wait, items are loading...  <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </span>
                                 <span class="text-light" v-if="fetching_item_stock">
                                    Fetching item stock... <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </span>
                                        <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; visibility: hidden" @click="addsearchitem('yes')" v-if="(showplus && !paid) || deposit " ></i>
                                    </th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td v-if="discount_applied || a_dis"></td>
                                    <td v-if="discount_applied || a_dis"></td>
                                    <td  class="col-md-1">
                                        <img class=" cursor-pointer addbtn"  src="@/assets/images/additem.png" @click="addsearchitem('yes')" v-if="((showplus && !paid) || deposit) && invoice.refund!=1 || ( invoice.status!='Completed' && invoice.status!='Refund Collected' && invoice.status!='Partial Refund'  && invoice.status!='Full Refund') && items.length>0"  >
                                    </td>
                                    <td class="col-md-1">
<!--                                        <button class="btn btn-info" @click="apply_discount" v-if="showplus" :disabled="(paid || itemsreceived) && !deposit">Apply Discount</button>-->
                                    </td>



                                </tr>

                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                      <div class="col-md-12 " style="margin:1px">
                          <div class="row mt-1   mtmb Border ">
                              <datalist id="customer-list-id"  >

                                  <option v-for="customer in customers" :key="customer.id" :value="customer.customerCode" > {{customer.lastName}} | {{customer.companyName}}    </option>
                              </datalist>
                              <div class="col-md-6 col-6 text-light" >
                                  <h4 class="heading mt-1 mtmb mb-1">Customer Details:
                                            <span  style="margin-left: 25px" v-if="latestinvoices">
                                           <span v-if="!newposclicked">
                                                 <span @click="moveLeft"  :class="loaded_order.order_index<1?'not-allowed':'cursor-pointer'">&lt;</span>
                                              <span style="font-size: small">

                                               <span v-if="loaded_order.order_index+1==1">
                                                  {{loaded_order.order_index+1}}<sup>st</sup>
                                               </span>
                                                <span v-else-if="loaded_order.order_index+1==2">
                                                  {{loaded_order.order_index+1}}<sup>nd</sup>
                                               </span>
                                                <span v-else-if="loaded_order.order_index+1==3">
                                                  {{loaded_order.order_index+1}}<sup>rd</sup>
                                               </span>
                                                 <span v-else-if="loaded_order.order_index+1>3">
                                                  {{loaded_order.order_index+1}}<sup>th</sup>
                                               </span>

                                               Out of {{latestinvoices.data.length}}
                                           </span>
                                              <span @click="moveRight" :class="loaded_order.order_index<latestinvoices.data.length-1?'cursor-pointer':'not-allowed'">&gt;</span>

                                           </span>
                                            </span>
                                  </h4>


                                  <div class="row mt-2">
                                      <div class="col-md-3">
                                          <h6 > Name: </h6>
                                      </div>
                                      <div class="col-md-8">

                                          <b-form-input  class="bg-light  form-control text-capitalize mx-3"  v-model="selected_customer.lastName"  :state="selected_customer.lastName!=''"   placeholder="Customer Name:"   > </b-form-input>

                                      </div>
                                  </div>
                                  <div class="row mt-2">
                                      <div class="col-md-3">
                                          <!--                                <span v-if="d2" class="text-danger" style="position: absolute ; margin-right: -200px ; margin-top: -15px">*</span>-->
                                          <h6 > Telephone:  </h6>
                                      </div>
                                      <div class="col-md-8">

                                          <b-form-input type="text" class="form-control mx-3" placeholder="Mobile Number" v-model="selected_customer.mobile" :state="d2" > </b-form-input>

                                      </div>
                                  </div>
                                  <div class="row mt-2">
                                      <div class="col-md-3">
                                          <!--                                <span v-if="d2" class="text-danger" style="position: absolute ; margin-right: -200px ; margin-top: -15px">*</span>-->
                                          <h6 > T. Card:  </h6>
                                      </div>
                                      <div class="col-md-8">

                                          <b-form-input v-model="cardNo" type="text" class="form-control mx-3"  placeholder="Trade Card" required list="tradecard-list-id" autocomplete="off" :disabled="invoice.status!='Quote'" @change="handleTradeCardSelection"> </b-form-input>
                                          <datalist id="tradecard-list-id" v-if="tradecards.length">
                                              <option v-for="card in tradecards" :key="card.id" :value="card.trade_card_no"> {{ card.trade_card_no }} | {{ card.customer? card.customer.companyName:"" }}   </option>
                                          </datalist>

                                      </div>
                                  </div>
                                  <!--                        <div class="row mb-1" v-if="selected_customer.customerType=='Trade'">-->
                                  <!--                            <div class="col-md-6">-->
                                  <!--                                <h6>Customer Type:</h6>-->
                                  <!--                            </div>-->
                                  <!--                            <div class="col-md-6">-->

                                  <!--                                <input class="bg-light col-md-11 form-control text-capitalize" style="background-color: rgb(53 53 71) !important; color: white !important;" v-model="selected_customer.customerType" :disabled="paid">-->
                                  <!--                            </div>-->
                                  <!--                        </div>-->
                                  <!--                        <div class="row mb-1 d-none" v-if="selected_customer.contactPerson">-->
                                  <!--                            <div class="col-md-6">-->
                                  <!--                                <h6>Contact Person:</h6>-->
                                  <!--                            </div>-->
                                  <!--                            <div class="col-md-6">-->

                                  <!--                                <input class="bg-light col-md-11 form-control text-capitalize" style="background-color: rgb(53 53 71) !important; color: white !important;" v-model="selected_customer.contactPerson" :disabled="paid">-->
                                  <!--                            </div>-->
                                  <!--                        </div>-->
                              </div>
                              <div class="col-md-6 col-6 text-light">


                                  <div class="row mt-2 mtmb ">
                                      <div class="col-md-5 mt-2 mtmb">
                                          <h6  >Address 1:</h6>
                                      </div>
                                      <div class="col-md-7">

                                          <!--                                <input v-if="customersreceived" class="bg-light form-control text-capitalize  col-md-12 offset-xl-2 col-xl-10 " style="background-color: rgb(53 53 71) !important; color: white !important;" v-model="selected_customer.lastName" :disabled="paid">-->

                                          <b-form-input type="text" class="form-control  "  v-model="selected_customer.addressLine1" :state="d3a1"> </b-form-input>
                                      </div>
                                  </div>
                                  <div class="row mt-3 mtmb">
                                      <div class="col-md-5 mt-2 mtmb">
                                          <h6  >Address 2:</h6>
                                      </div>
                                      <div class="col-md-7">

                                          <b-form-input type="text" class="form-control mb-2 mtmb"  v-model="selected_customer.addressLine2" :state="d3a2"  > </b-form-input>
                                      </div>
                                  </div>
                                  <div class="row mt-3 mtmb mb-2 ">
                                      <div class="col-md-5 mt-2 mtmb">
                                          <h6  >Postcode:</h6>
                                      </div>
                                      <div class="col-md-7 ">

                                          <b-form-input type="text" class="form-control mb-2 "  v-model="selected_customer.postcode"  :state="d3pc" > </b-form-input>
                                      </div>
                                  </div>
                                  <!--                        <div class="row mb-1 d-none" v-if="selected_customer.walletBalance">-->
                                  <!--                            <div class="col-md-6">-->
                                  <!--                                <h6>Wallet Balance:</h6>-->
                                  <!--                            </div>-->
                                  <!--                            <div class="col-md-6">-->

                                  <!--                                <input class="bg-light col-md-11 form-control text-capitalize" style="background-color: rgb(53 53 71) !important; color: white !important;" v-model="selected_customer.walletBalance" :disabled="paid">-->
                                  <!--                            </div>-->
                                  <!--                        </div>-->
                                  <!--                        <div class="row mb-1 d-none">-->
                                  <!--                            <div class="col-md-6">-->
                                  <!--                                <h6>Discount Code:</h6>-->
                                  <!--                            </div>-->
                                  <!--                            <div class="col-md-6">-->

                                  <!--                                <input class="bg-light col-md-11 form-control text-capitalize" style="background-color: rgb(53 53 71) !important; color: white !important;" v-model="selected_customer.discountType" :disabled="paid">-->
                                  <!--                            </div>-->
                                  <!--                        </div>-->
                                  <!--                        <div class="row mt-4 mb-3">-->
                                  <!--                            <div class="col-md-4 col-6  col-xl-6 mt-2"></div>-->
                                  <!--                            <div class="col-md-8 col-6 col-xl-6 mt-2" v-if="customersreceived">-->
                                  <!--                                <button v-if="savingnewcus" type="button" class="btn custom-btn1 text-light  col-md-12 offset-xl-2 col-xl-10 "   :disabled="paid" @click="savenew()">Save New</button>-->
                                  <!--                                <button v-else="" type="button" class="btn custom-btn1 text-light  col-md-12 offset-xl-2 col-xl-10 "  @click="updateCustomer()" :disabled="paid">Update</button>-->

                                  <!--                            </div>-->

                                  <!--                        </div>-->
                              </div>
                          </div>
                          <div class="row Border mt-3 mtmb text-light">
                              <h4 class="heading col-md-12  mt-2 mtmb">Enter New Payment: </h4>
                              <div class="col-md-4">
                                  <p>Payment Type  </p>

                                  <v-select
                                      v-model="payment.payment_type"
                                      class="bgsecond"
                                      placeholder="Choose Type"
                                      :options="paymenttypes"
                                      @input="payment.payment_amount=0"
                                  >

                                  </v-select>



                              </div>


                              <div class="col-md-2" >
                                  <p>Amount  </p>
                                  <input class="form-control " type="number"  v-model="payment.payment_amount" @input="checknegative"    placeholder="Amount">
                              </div>
                              <div class="col-md-3" >
                                  <p>Ref Number</p>
                                  <input type="text"  class="text form-control mb-2 text-dark" placeholder="Reference No." v-model="payment.reference" >
                              </div>
                              <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                  <p>Date</p>
                                  <b-form-datepicker

                                      :reset-button=true
                                      reset-button-variant="btn btn-warning"
                                      id="datepicsuper"
                                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                      v-model="payment.date"
                                      placeholder="Date"

                                      dropup  calendar-width="100%">

                                  </b-form-datepicker>
                              </div>
                              <div class="col-md-3" v-else="">
                                  <p>Date</p>
                                  <b-form-datepicker

                                      :reset-button=true
                                      reset-button-variant="btn btn-warning"
                                      id="example-datepicker"
                                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                       :disabled="true"
                                      placeholder="Date"

                                      dropup  calendar-width="100%">

                                  </b-form-datepicker>
                              </div>


                              <!--                     row2 start                   -->

                              <div class="col-md-1 mt-2 mtmb">
                                  <p>Total: </p>
                              </div>
                              <div class="col-md-1 mt-2 mtmb">
                                  <p> {{(invoice.grandTotal).toFixed(2)}}</p>
                              </div>
                              <div class="col-md-1 mt-2 mtmb offset-2">
                                  <p>Paid: </p>
                              </div>
                              <div class="col-md-1 mt-2 mtmb ">
                                  <p> {{invoice.totalPaid}}</p>
                              </div>
                              <div class="col-md-2 mt-2 mtmb col-xxl-1 offset-xxl-2 offset-2" style="padding: 0px !important;">
                                  <p>Due Balance: </p>
                              </div>
                              <div class="col-md-2 mt-2 mtmb">
                                  <p> {{due.toFixed(2)}}</p>
                              </div>
                              <!--                     row2 end                   -->
                              <!--                     row3 start                   -->
                              <h4 class="heading col-md-12 mb-3 mt-2 mtmb mt-minus ">Payment History: </h4>
                              <div class="col-md-8 payment-height2" :class="invoice.payments==null || invoice.payments=='' ? '':'payment-height'">
                                  <table class="table table-bordered text-light" v-if="invoice.payments">
                                      <thead>
                                      <tr>
                                          <th>Date
                                              <b-icon
                                              v-if="authenticatedUser.permissions.includes('super') && invoice.invoice_ref!='Not Created'"
                                              :icon="paymentdateedit?'check-circle':'pencil-square'"
                                              variant="success"
                                              @click="handleedit('date')"
                                              role="button"
                                          ></b-icon>

                                          </th>
                                          <th>Type
                                              <b-icon
                                                  v-if="authenticatedUser.permissions.includes('super') && invoice.invoice_ref!='Not Created'"
                                                  :icon="paymenttypeedit?'check-circle':'pencil-square'"
                                                  variant="success"
                                                  @click="handleedit('type')"
                                                  role="button"
                                              ></b-icon>
                                          </th>
                                          <th>Amount </th>
                                          <th>Reference #
                                              <b-icon
                                                  v-if="authenticatedUser.permissions.includes('super') && invoice.invoice_ref!='Not Created'"
                                                  :icon="paymentreferenceedit?'check-circle':'pencil-square'"
                                                  variant="success"
                                                  @click="handleedit('reference')"
                                                  role="button"
                                              ></b-icon>
                                          </th>

                                      </tr>
                                      </thead>
                                      <tbody   >
                                      <tr v-for="pmt in invoice.payments" :key="pmt.id" >
                                          <td >
                                            <span v-if="authenticatedUser.permissions.includes('super')" >
                                               <span v-if="paymentdateedit">
<!--                                                   <input type="text" v-model="pmt.date" @change="updatepayment(pmt.id, {date:pmt.date})" pattern="\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}">-->


                                                    <b-input
                                                        type="text"
                                                        v-model="pmt.date"
                                                        @change="updatepayment(pmt.id, {date:pmt.date})"
                                                        :pattern="datePattern"
                                                        title="Enter a date in the format yyyy-MM-dd HH:mm:ss"
                                                    ></b-input>

                                                   <!--                                              <datepicker-->
<!--                                                  v-model="pmt.date"-->
<!--                                                  format="yyyy-MM-dd"-->

<!--                                                  placeholder="y-m-d"-->
<!--                                                  class="dark-datepicker"-->
<!--                                              ></datepicker>-->


                                               </span>
                                                <span v-else="">
                                                     {{formatDate(pmt.date)}}
                                                </span>

                                            </span>
                                              <span v-else="">
                                                          {{formatDate(pmt.date)}}
                                              </span>


                                          </td>
                                          <td>

                                              <span v-if="authenticatedUser.permissions.includes('super')" @dblclick="handleedit('type')">
                                               <span v-if="paymenttypeedit && invoice.refund==0">
                                                      <v-select
                                                          v-model="pmt.payment_type"

                                                          class="bgsecond"
                                                          placeholder="Choose Type"
                                                          :options="paymenttypes.filter((pt)=>{
                                                              if((Number(selected_customer.walletBalance)<Number(pmt.payment_amount) && pt.includes('Credit'))){
                                                                  return false

                                                              }
                                                              else {
                                                                  return true
                                                              }
                                                          })"
                                                          @input="updatepayment(pmt.id, {payment_type:pmt.payment_type})"
                                                      />


                                               </span>
                                                        <span v-else-if="paymenttypeedit && invoice.refund==1">
                                                      <v-select
                                                          v-model="pmt.payment_type"

                                                          class="bgsecond"
                                                          placeholder="Choose Type"
                                                          :options="paymenttypes.filter((pt)=>{
                                                              if((Number(selected_customer.walletBalance)<(-Number(pmt.payment_amount)) && !pt.includes('Credit'))){
                                                                  return false

                                                              }
                                                              else {
                                                                  return true
                                                              }
                                                          })"
                                                          @input="updatepayment(pmt.id, {payment_type:pmt.payment_type})"
                                                      />


                                                  </span>

                                                <span v-else="">
                                                    {{pmt.payment_type.includes('Credit') && invoice.refund==0?'Credit':pmt.payment_type}}
                                                </span>

                                            </span>
                                              <span v-else="">
                                                  {{pmt.payment_type}}
                                              </span>
                                          </td>
                                          <td> {{pmt.payment_amount}}</td>
                                          <td>
                                              <span v-if="authenticatedUser.permissions.includes('super')" @dblclick="handleedit('reference')">
                                               <span v-if="paymentreferenceedit">
                                                   <input type="text" v-model="pmt.reference" @change="updatepayment(pmt.id, {reference:pmt.reference})">

                                               </span>
                                                <span v-else="">
                                                  {{pmt.reference}}
                                                </span>

                                            </span>
                                              <span v-else="">
                                                    {{pmt.reference}}
                                              </span>
                                          </td>

                                      </tr>


                                      <tr v-if="invoice.payments==null || invoice.payments=='' "><td></td><td></td><td></td><td></td></tr>
                                      <tr v-if="invoice.payments==null || invoice.payments=='' "><td></td><td></td><td></td><td></td></tr>
                                      <tr v-if="invoice.payments==null || invoice.payments=='' "><td></td><td></td><td></td><td></td></tr>
                                      <tr v-if="invoice.payments==null || invoice.payments=='' "><td></td><td></td><td></td><td></td></tr>
                                      <tr v-if="invoice.payments==null || invoice.payments=='' "><td></td><td></td><td></td><td></td></tr>
                                      <tr v-if="invoice.payments==null || invoice.payments=='' "><td></td><td></td><td></td><td></td></tr>
<!--                                      <tr v-else="" v-for="pmt in emptypaymentrows" :key="Math.random()+pmt" ><td></td><td></td><td></td><td></td></tr>-->



                                      </tbody>
                                  </table>
                              </div>
                              <div class="col-md-4 mb-2 mtmb" v-if="authenticatedUser.permissions.includes('can-manage-pos')">
                                  <h4 class="heading mb-3 mtmb mt-minus">Cashier </h4>
                                  <div class="dropdown text-white mb-3 mtmb">

                                      <b-form-select
                                          v-model="selected_customer.cashier_id"
                                          :options="cashiers"
                                          :disabled="(paid && !authenticatedUser.permissions.includes('super'))"
                                          :state="selected_customer.cashier_id!=''"
                                          class="bgsecond bform text-white mb-2 "

                                          value-field="id"
                                          text-field="fullName"
                                      >

                                          <template #first>
                                              <b-form-select-option value="">Select Cashier</b-form-select-option>
                                          </template>
                                          <template #option="{ option }">
                                              <div>{{ option.fullName }}</div>
                                          </template>

                                      </b-form-select>
                                  </div>
                                  <div class="position-relative d-inline-block w-100">
                                      <button type="button" class="btn custom-btn2 w-100 btn-lg text-light mt-4 mtmb"
                                              @click="save_invoice"
                                              :disabled="makedis || saving">
                                          Save
                                      </button>
                                      <span v-if="have_changes" class="unsaved-dot"></span>
                                  </div>
                                  <div class="text-center"  id="spinner" v-if="showspinner">
                                      <div class="spinner-border text-warning "></div>
                                  </div>

                              </div>
                              <div class="col-md-4 mb-2 mtmb" v-else="">
                                  <span>You dont have access to Create Or Update the Orders</span>
                              </div>
                              <!--                     row3 end                   -->
                              <!--                    <div class="col-md-9 col-12 text-light mt-2">-->

                              <!--                    <div class="row">-->

                              <!--                        <div class="col-md-2"></div>-->
                              <!--                        <div class="col-md-6"></div>-->
                              <!--                        <div class="col-md-6">-->
                              <!--                            <div class="row">-->

                              <!--                                <div class="col-md-5 col-3 mt-2">-->


                              <!--                                </div>-->
                              <!--                                <div class="col-md-7 col-4 mt-2">-->


                              <!--                                </div>-->

                              <!--                            </div>-->
                              <!--                            <div class="row">-->
                              <!--                                <div class="col-md-12 col-1 mt-2 " >-->


                              <!--                                </div>-->


                              <!--                            </div>-->
                              <!--                            <div class="row">-->
                              <!--                                <div class="col-md-5 col-1 mt-2" >-->


                              <!--                                </div>-->
                              <!--                                <div class="col-md-7 col-3 mt-2 text-center">-->
                              <!--                                    <button v-if="!(!makedis && !paid)" class="btn btn-success"  @click="save_payment" :disabled="disableAddPayment">Add Payment</button>-->
                              <!--                                </div>-->

                              <!--                            </div>-->
                              <!--                        </div>-->
                              <!--                        <div class="col-md-6">-->

                              <!--                        </div>-->
                              <!--                    </div>-->


                              <!--&lt;!&ndash;                        <div class="row">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-2 col-1" style="margin-top: 0px;">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <i class="bi bi-credit-card-2-back bi-icon "></i>&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <p style="margin-top: -10px; margin-bottom: 0px; padding: 0px">Card</p>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-3 col-3 mt-2">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <input class="  form-control " type="number" v-model="invoice.cardPayment"  @keyup="update_invoice('totalPaid',totalentered)" @change="update_invoice('totalPaid',totalentered)" :disabled="paid || (invoice.cardPayment>0 && invoice.cardDate!=null)">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-5 col-4 mt-2">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <input type="text" class="text form-control text-dark" placeholder="Reference No." v-model="invoice.cardReference" :readonly="paid || ( invoice.cardDate!=null)" >&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                        <div class="row">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-2 col-1" style="margin-top: 0px">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <i class="bi bi-cash-stack bi-icon "></i>&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <p style="margin-top: -10px; margin-bottom: 0px; padding: 0px">Cash</p>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-3 col-3 mt-2">&ndash;&gt;-->

                              <!--&lt;!&ndash;                                <input class="form-control " type="number"  v-model="invoice.cashPayment" @keyup="update_invoice('totalPaid',totalentered)" @change="update_invoice('totalPaid',totalentered)" :disabled="paid || (invoice.cashPayment>0 && invoice.cashDate!=null)">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-5 col-4 mt-2">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <input type="text"  class="text form-control mb-2 text-dark" placeholder="Reference No." v-model="invoice.cashReference" :readonly="paid || (invoice.cashDate!=null)">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                        <div class="row">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-2 col-1" style="margin-top: 0px">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <i class="bi bi-bank bi-icon me-2 "></i>&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <p style="margin-top: -10px; margin-bottom: 0px; padding: 0px">Bank</p>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-3 col-3 mt-2">&ndash;&gt;-->

                              <!--&lt;!&ndash;                                <input class="form-control mb-2" type="number" v-model="invoice.bankPayment"  @keyup="update_invoice('totalPaid',totalentered)" @change="update_invoice('totalPaid',totalentered)" :disabled="paid || (invoice.bankPayment>0 && invoice.bankDate!=null)">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                            <div class="col-md-5 col-4 mt-2">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <input type="text" class="text form-control mb-2 text-dark" placeholder="Reference No." v-model="invoice.bankReference" :readonly="paid || (invoice.bankDate!=null)">&ndash;&gt;-->
                              <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                              <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                              <!--                        <div class="row mt-4">-->
                              <!--                            <div class="col-md-2"></div>-->
                              <!--                            <div class="col-md-10">-->
                              <!--                            <p class="w-100" style="font-size: 17px">-->
                              <!--                                Entered: £{{payment.payment_amount}} &nbsp;&nbsp;-->
                              <!--                                Due: £{{(invoice.grandTotal).toFixed(2)}} &nbsp;&nbsp;-->
                              <!--                                Remaining: £{{((invoice.grandTotal)-invoice.totalPaid).toFixed(2)}} &nbsp;&nbsp;-->
                              <!--                            </p>-->


                              <!--                            </div>-->

                              <!--                        </div>-->
                              <!--                    </div>-->
                              <!--                    <div class="col-md-3 col-12 text-light mt-3">-->

                              <!--                        <button v-if="!paid" type="button" class="btn custom-btn w-100 text-light" @click="save_invoice('Quote')" :disabled="makereq">Save for later-->
                              <!--                        </button>-->
                              <!--                        <button v-else="" type="button" class="btn custom-btn w-100 text-light" :disabled="true" >Save for later</button>-->
                              <!--                        <br><br>-->

                              <!--&lt;!&ndash;                        <button v-else="" type="button" class="btn custom-btn2 w-100 btn-lg text-light  mb-2"  :disabled="true" >Finish</button>&ndash;&gt;-->
                              <!--                    </div>-->
                          </div>
                      </div>

                    </div>
                    <div class="col-md-6 ">

                          <div class="row mt-1 mtmb Border1 text-light font-weight-semi-bold bgsecond lg1" style="margin: 1px; padding: 0.9rem; font-size: 18px">
                              <div class="col-md-6 mx-5 mt-3 mtmb">
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p class="" > Sub Total:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p > £{{Number(invoice.subTotal).toFixed(2)}}</p>
                                      </div>
                                  </div>
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p  class="" >  VAT:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p> £{{Number(invoice.vat).toFixed(2)}}</p>
                                      </div>
                                  </div>
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p  class="" >  Grand Total:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p> £{{(invoice.grandTotal).toFixed(2)}}</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-4  mt-3 mtmb">
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p class="font-color" > Total:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p > £{{(invoice.grandTotal).toFixed(2)}}</p>
                                      </div>
                                  </div>
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p  class="HDcolor" >  Paid:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p> £{{invoice.totalPaid}}</p>
                                      </div>
                                  </div>
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p  class="font1-color" >  Due:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p> £{{due.toFixed(2)}}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        <div class="row Border p-3  mt-3 mtmb" style="margin: 2px; font-size: 18px" >
                            <div class="col-md-12 col-12 ">
                                <div class="row mb-2 text-light">
                                    <div class="col-md-4 col-6 mt-2 mtmb ">
                                        <h4 class="heading mt-1 mtmb mb-3"> Dispatch Details</h4>
                                        <div class="form-check">
                                            <b-form-radio class="form-check-input" name="dd1" id="dd1" v-model="invoice.deliveryOption" size="lg" value="1" :disabled="disradio" @change="change_occur" >Pickup from store now </b-form-radio>

                                        </div>
                                        <div class="form-check">
                                            <b-form-radio class="form-check-input" name="dd2" id="dd2" v-model="invoice.deliveryOption" size="lg" value="2" :disabled="disradio"
                                                          @change="change_occur"
                                            > Pickup from store later</b-form-radio>

                                        </div>
                                        <div class="form-check">
                                            <b-form-radio class="form-check-input" name="dd3" id="dd3" v-model="invoice.deliveryOption" size="lg" value="3" :disabled="disradio" @change="change_occur">Delivery to Address </b-form-radio>

                                        </div>


                                    </div>
                                    <div class="col-md-3 col-6 mt-3 ">
                                        <label class="mt-1">Pick Up Location </label>

                                        <b-form-select
                                            v-model="invoice.pickup_location_id"
                                            :options="locations.filter((l)=>[7,13,locations.find((il)=>il.branch_id==authenticatedUser.branch_id).id].includes(l.id))"


                                            value-field="id"
                                            text-field="location_name"
                                            :state="checkpickup"
                                            :disabled="invoice.invoice_ref.startsWith('Onl')"
                                        >

                                            <template #first>
                                                <b-form-select-option value="">Select One</b-form-select-option>
                                            </template>
                                            <template #option="{ option }">
                                                <div>{{ option.fullName }}</div>
                                            </template>

                                        </b-form-select>


<!--                                        <label class="mt-1">Delivery Location</label>-->

<!--                                        <b-form-select-->
<!--                                            v-model="invoice.delivery_location_id"-->
<!--                                            :options="locations.filter((l)=>[7,13,locations.find((il)=>il.branch_id==authenticatedUser.branch_id).id].includes(l.id))"-->
<!--                                            value-field="id"-->
<!--                                            text-field="location_name"-->
<!--                                            :state="checkdelivery"-->
<!--                                            :disabled="invoice.invoice_ref.startsWith('Onl')"-->
<!--                                        >-->

<!--                                            <template #first>-->
<!--                                                <b-form-select-option value="">Select One</b-form-select-option>-->
<!--                                            </template>-->
<!--                                            <template #option="{ option }">-->
<!--                                                <div>{{ option.fullName }}</div>-->
<!--                                            </template>-->

<!--                                        </b-form-select>-->
<!--                                  -->
                                    </div>

                                    <div class="col-md-5 mt-5 mtmb">
<!--                                    <div class="col-md-12">-->
<!--                                -->
<!--                               -->
<!--                                    </div>-->

                                        <div class="form-check  mt-2 ">
                                            <!--                                    <input class="form-control" type="date" id="date" name="date" v-model="invoice.deliveryDate" :readonly="paid">-->

                                            <b-form-datepicker

                                                :reset-button=true
                                                reset-button-variant="btn btn-warning"
                                                id="example-datepicker"
                                                v-model="invoice.deliveryDate"
                                                placeholder="Select Date"
                                                class="mb-2" :state="dd2"
                                                dropup  calendar-width="100%">

                                            </b-form-datepicker>

                                            <!--                                    <p v-if="makereq===1"  class="error-message text-danger">This field is required.</p>-->
                                        </div>
                                        <div class="form-check">
                                            <b-form-checkbox
                                                id="checkbox-1"
                                                v-model="invoice.deliveryEstimated"
                                                name="checkbox-1"
                                                value="1"
                                                unchecked-value="0"
                                                @change="change_occur"
                                            >
                                                Delivery Date is Estimated
                                            </b-form-checkbox>

                                        </div>
<!--                                        <div class="form-check mt-3">-->
<!--                                            <b-form-input v-model="cardNo" type="text" class="form-control"  placeholder="Trade Card" required list="tradecard-list-id" autocomplete="off" :disabled="invoice.status!='Quote'" @change="handleTradeCardSelection"> </b-form-input>-->
<!--                                            <datalist id="tradecard-list-id" v-if="tradecards.length">-->
<!--                                                <option v-for="card in tradecards" :key="card.id" :value="card.trade_card_no"> {{ card.trade_card_no }} | {{ card.customer? card.customer.companyName:"" }}   </option>-->
<!--                                            </datalist>-->

<!--                                        </div>-->

                                    </div>



                                    <!--                            <div class="col-md-6 col-6" v-if="requiremobe" >-->

                                    <!--                                <form v-if="!selected_customer.mobile">-->
                                    <!--                                    <div class="row ">-->
                                    <!--                                        <div class="col-md-7 col-12 mb-2 mb-lg-0">-->
                                    <!--                                            <h4 class="heading HDcolor mt-2 " >Mobile Number:-->

                                    <!--                                            </h4>-->
                                    <!--                                            <input type="text" class="form-control" placeholder="Mobile Number" v-model="invoice.cusMobile" :disabled="paid">-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                </form>-->
                                    <!--                            </div>-->
                                    <!--                            <div class="col-md-6 col-6" v-if="requireAddress" >-->
                                    <!--                                <h4 class="heading HDcolor mt-2 " v-show="selected_customer.addressLine1==null" >Delivery Address:-->

                                    <!--                                </h4>-->

                                    <!--                                <form  v-show="selected_customer.addressLine1==null">-->
                                    <!--                                    <input type="text" class="form-control mb-2 " placeholder="20 Carlton Drive" v-model="invoice.deliveryAddress1"  :disabled="paid">-->
                                    <!--                                    <input type="text" class="form-control mb-2" placeholder="Benfleet, Essex" v-model="invoice.deliveryAddress2" :disabled="paid" >-->
                                    <!--                                    <div class="row">-->
                                    <!--                                        <div class="col-md-6 col-12">-->
                                    <!--                                            <input type="text" class="form-control" placeholder="SS7 3YQ" v-model="invoice.deliveryPostcode" :disabled="paid">-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class="col-md-6 col-12 mb-2 mb-lg-0" v-if="!selected_customer.mobile">-->
                                    <!--                                            <input type="text" class="form-control" placeholder="Mobile Number" v-model="invoice.cusMobile" :disabled="paid">-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                </form>-->
                                    <!--                            </div>-->
                                </div>
                            </div>
                        </div>
                          <div class="row text-light  mt-2 p-3    text-light font-weight-semi-bold  lg1" style="margin: 1px; margin-bottom: 1.20rem; font-size: 18px; margin-top: 32px !important;">
                              <div class="col-md-6 mx-3">
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p class="HDcolor" > Invoice Ref:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p > {{invoice.invoice_ref}}</p>
                                      </div>
                                  </div>
                                  <div class="row g-0">
                                      <div class="col-md-6 ">
                                          <p  class="HDcolor" > Date:</p>
                                      </div>
                                      <div class="col-md-6 ">
                                          <p> {{formattedDate}}</p>
                                      </div>
                                  </div>
                              </div>


                              <div class="col-md-5  ">
                                  <div class="row g-1">
                                      <div class="col-md-4 ">
                                          <p class="HDcolor" >Cashier: </p>
                                      </div>
                                      <div class="col-md-8 ">
                                          <p class="mx-1" v-if="selectedCashier"> {{selectedCashier ? selectedCashier.fullName:''}}</p>
                                          <p class="mx-1" v-else-if="invoice.invoice_ref!=='Not Created'"> {{invoice.cashier.fullName}}</p>



                                      </div>
                                  </div>
                                  <div class="row g-0">
                                      <div class="col-md-4 ">
                                          <p class="HDcolor font-color font-weight" > Status: </p>
                                      </div>
                                      <div class="col-md-8 ">
                                          <p class="text-warning">{{invoiceStatus}}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="row mx-1 mt-3 mtmb mt-minus2">
                              <!--                    <div class="col-md-12  p-0 col-12 ">-->
                              <!--                        <b-button type="button" class="btn custom-btn5  text-light"  @click="toggleordermodal" ><i class="bi bi-search"></i>-->

                              <!--                                Find Order-->

                              <!--                        </b-button>-->
                              <!--                        <button type="button" class="btn custom-btn5 mx-2 text-light"><i class="bi bi-box-seam"></i> Return</button>-->
                              <!--                        <button type="button" class="btn custom-btn5  text-light"><i class="bi bi-stickies"></i> Notes</button>-->

                              <!--                        <button type="button" class="btn custom-btn5 mx-2  text-light" @click="newpos()"><i class="bi bi-receipt"></i> New Pos</button>-->
                              <!--                        <button type="button" class="btn custom-btn5 text-light" @click="generatePdf" :disabled="allowprint"><i class="bi bi-journal-text"></i> Send Invoice</button>-->
                              <!--                        <b-link class="" :to="{ path: '/pos/print-invoice' }" :disabled="allowprint">-->
                              <!--                                <button type="button" class="btn custom-btn5 mx-2 text-light" :disabled="allowprint">-->
                              <!--                                    <i class="bi bi-printer"></i>-->
                              <!--                                </button>-->
                              <!--                        </b-link>-->


                              <!--                    </div>-->

                              <img class="  mrdb small-btns  cursor-pointer" style="margin-left: -3px" src="@/assets/images/downbaricons/newpos.png" @click="newpos()"  >
                              <button  type="button" class="btn dispatch small-btns btn-lg text-light mrdb "  @click="dispatchmodalShow = ! dispatchmodalShow"  :disabled="(invoice.invoice_ref=='Not Created' || invoice.status=='Quote' ) " >Booking</button>

                              <!--                    <img class=" mrdb  cursor-pointer" src="@/assets/images/downbaricons/findorder.png" @click="toggleordermodal" >-->
                              <img class=" mrdb small-btns " src="@/assets/images/downbaricons/return.png" @click="refund" :class="(invoice.refund == 1 || (invoice.status!='Completed' && invoice.status!='Partial Refund') )?'not-allowed':'cursor-pointer'">
                              <div>

                                  <img class=" mrdb small-btns cursor-pointer" style="height: 100%" src="@/assets/images/downbaricons/notes.png" v-b-modal.modal-xl v-b-modal.modal-center @click="notesmodalShow = !notesmodalShow" >
                                  <span class="notemsg" v-if="added_notes.length">{{ added_notes.length }}</span>
                              </div>

                              <button  type="button" class="btn email small-btns btn-lg text-light mrdb "  @click="generatePdf" :disabled="invoice.invoice_ref=='Not Created' || !branches.length" > Email</button>
                              <button  type="button" class="btn email small-btns btn-lg text-light mrdb "  @click="generateDownload" :disabled="invoice.invoice_ref=='Not Created' || !branches.length" > <i class="fas fa-download"></i> </button>

                              <div class="text-center"  id="spinner-mail" v-if="showspinnermail">
                                  <div class="spinner-border text-warning "></div>
                              </div>
                              <b-link class="" :to="{ path: '/pos/print-invoice' }" :disabled="allowprint || !branches.length">
                                  <img class=" mrdb small-btns " src="@/assets/images/downbaricons/print.png"  >
                              </b-link>




                          </div>

                    </div>
                </div>


            </div>
<!--            <div class="col-md-11 text-light text-center" v-else="">-->
<!--                <h1>You Dont have Access to manage Invoices</h1>-->
<!--            </div>-->
            <div class="col-md-5">

                <div class="row mt-1 mtmb">
                    <div class="col-md-12 col-12 " >



                    </div>

                </div>
            </div>
            <div class="col-md-6 ">
<!--                <div class="row ">-->
<!--                    <div class=" col-md-6 col-5">-->
<!--                        <input type="text" class="form-control mt-2" v-model="searchcustomer" @change="selectedCus(searchcustomer,'yes')"   placeholder="Customer Code:" list="customer-list-id" :disabled="!customersreceived" :readonly="paid">-->
<!--                        <datalist id="customer-list-id" >-->

<!--                            <option v-for="customer in customers" :key="customer.id" :value="customer.customerCode" > {{ customer.firstName }} {{customer.lastName}} | {{customer.companyName}}    </option>-->
<!--                        </datalist>-->
<!--                    </div>-->
<!--                    <div class="col-md-2 col-2"> <button class="btn  custom-btn search" type="button" @click="selectedCus(searchcustomer,'yes')" :disabled="paid">-->
<!--                        <i class="bi bi-search text-light"></i>-->
<!--                    </button></div>-->
<!--                    <div class=" col-md-4 col-5 ">-->
<!--                        <button class="btn  custom-btn text-light w-100 text-bold" type="button" @click="savecustomer()" :disabled="paid">-->
<!--                            <i class="bi bi-plus text-light  "></i> <span>New Customer</span>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->




            </div>
        </div>
<!--        modals -->
<!--        <b-modal v-model="showmodal"  centered size="xl">-->
<!--          <Orders :togglorderemodal="toggleordermodal"/>-->
<!--        </b-modal>-->
    </div>
    <VueHtml2pdf
         shrink-to-fit=yes
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"

        filename="tiles_invoice"
        :pdf-quality="1"
        :manual-pagination="true"
        pdf-content-width="1100px"
         @beforeDownload="beforeDownload($event)"

        ref="html2Pdf"
        :html-to-pdf-options="{
            margin:[0,0,0,0],
          filename:`TilesInvoice-${this.invoice.invoice_ref}`,
          jsPDF: {
            format: [1300, 1100],
            unit: 'px'
          },
          // html2canvas: {
          //   useCORS: true,
          //   dpi: 192,
          //   letterRendering: true,
          //   scale: 1, // resolution
          // },
             pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' }


       }"


    >
        <section slot="pdf-content">
            <!-- PDF Content Here -->
            <PrintInvoice mode="send"/>
        </section>
    </VueHtml2pdf>


    <VueHtml2pdf
        shrink-to-fit=yes
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"

        filename="tiles_invoice"
        :pdf-quality="1"
        :manual-pagination="true"
        pdf-content-width="1100px"

        ref="html2Pdfdown"
        :html-to-pdf-options="{
            margin:[0,0,0,0],
          filename:`TilesInvoice-${this.invoice.invoice_ref}`,
          jsPDF: {
            format: [1300, 1100],
            unit: 'px'
          },
          // html2canvas: {
          //   useCORS: true,
          //   dpi: 192,
          //   letterRendering: true,
          //   scale: 1, // resolution
          // },
             pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' }


       }"


    >
        <section slot="pdf-content">
            <!-- PDF Content Here -->
            <PrintInvoice mode="send"/>
        </section>
    </VueHtml2pdf>

    <b-modal  v-model="notesmodalShow"  centered  >
        <div class="facebook-comments">
            <h2 class="mb-2">Notes</h2>
            <ul v-if="added_notes!=[]">
                <li v-for="(note, index) in added_notes" :key="index" class="comment">
                    <div class="comment-avatar">

                        <i class="bi bi-person"></i>
                    </div>
                    <div class="comment-content p-2" style="border:1px solid rgb(246 246 246); border-radius: 18px; background-color: rgb(246 246 246)">
                      <div class="row">
                          <div class="comment-author col-md-6"> {{note.cashier_name}} </div>
                          <div class="comment-date col-md-6 text-right">{{formatDate(note.date)}} </div>
                      </div>
                        <div class="comment-text">{{ note.text }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="card-footer py-3 border-0" style="background-color: #f8f9fa;">
            <div class="d-flex flex-start w-100">

                <div class="form-outline w-100">
                  <h4>{{selectedCashier ? selectedCashier.fullName:''}}</h4>
                    <b-form-textarea
                        id="textarea"
                        v-model="newnote.text"
                        placeholder="Enter notes ..."
                        :state="selected_customer.cashier_id!='' && selected_customer.lastName!='' && invoice.deliveryOption!=''"
                        :disabled="selected_customer.cashier_id=='' || selected_customer.lastName=='' || invoice.deliveryOption==''"
                        rows="4"

                        max-rows="6"
                    ></b-form-textarea>
                </div>
            </div>
            <div class="text-right mt-2 mtmb pt-1">
                <button type="button" class="btn btn-default btn-sm " :class="(selected_customer.cashier_id=='' || newnote.text=='' || selected_customer.lastName=='' || invoice.deliveryOption=='')?'not-allowed':''" @click="addnewnote"  :disabled="selected_customer.cashier_id=='' || newnote.text=='' || selected_customer.lastName=='' || invoice.deliveryOption==''" > <img class=" " src="@/assets/images/notesend.png" style="width: 20px !important;" ></button>


            </div>
        </div>
    </b-modal>
    <b-modal  v-model="dispatchmodalShow" id="dispatchmodal" size="xl"    class="dispatchmodal "  >
        <div class="row" >
        <div class="col-md-12 text-light bgsecond ">
            <h1 class="text-center" v-if="invoice.refund!=1" >Dispatch Status

            </h1>
            <h1 class="text-center" v-else="" >Return Status</h1>
            <table class="table table-bordered text-light " >
                <thead>
                <tr>
                    <th>Item Code</th>
                    <th>Description</th>
                    <th class="col-md-1">Ordered </th>
                    <th class="col-md-1">Picked </th>
                    <th class="col-md-1">Remaining </th>
                    <th class="col-md-1"> Stock </th>

                </tr>
                </thead>
                <tbody class="">
                <tr v-for="item in searched_items" :key="item.mapid" >
                    <td>{{item.itemCode}}</td>
                    <td> {{item.itemDescription}}</td>
                    <td v-if="invoice.refund!=1"> {{item.qty}} </td>
                    <td v-else=""> {{-item.qty}} </td>
                    <td> {{item.picked}} </td>
                    <td v-if="invoice.refund!=1">{{(Number(item.qty) - Number(item.picked)).toFixed(2)}}</td>
                    <td v-if="invoice.refund==1 && invoice.orginal_items">{{ Number(item.qty)+Number((invoice.orginal_items.filter(oi=>oi.itemCode===item.itemCode && oi.itemDescription ===item.itemDescription))[0].picked)-Number(item.picked) }}</td>
<!--                    <td>-->
<!--                        {{-->
<!--                            () => {-->
<!--                                let sum = 0;-->
<!--                                for (let branch of branchlocations) {-->
<!--                                    // Use 'branch' directly to access the current element-->
<!--                                    let item = parseFloat(branch); // Convert to number if branches are strings-->
<!--                                    sum = sum + item;-->
<!--                                }-->
<!--                                return sum;-->
<!--                            }-->
<!--                        }}-->
<!--                    </td>-->
                    <td><span v-if="item.stock">{{Number(item.stock).toFixed(2)}}</span></td>

                </tr>


                </tbody>
            </table>
        </div>
        <div class="col-md-12 bgsecond text-light mt-3 mtmb">
            <h1 class="text-center" v-if="invoice.refund!=1">Dispatch History </h1>
            <h1 class="text-center" v-else="">Return History</h1>
            <div class="row" v-for="dispatch in  transactionshistory" :key="dispatch.id">
               <div class="col-md-12">
                   <table class="table table-bordered text-light" >
                       <thead>
                       <tr>
<!--                           <th>ID</th>-->
                           <th>Date</th>
                           <th>Item Code</th>
                           <th>Item Description </th>
                           <th>Qty </th>
                           <th>Mode </th>
                           <th>Location </th>
                           <th>Dispatched By </th>
                           <th >Picked By</th>
                           <th v-if="invoice.refund!=1">Collected By</th>
                           <th v-else="">Returned By</th>


                       </tr>
                       </thead>
                       <tbody >

                       <tr  v-for="item in dispatch.dispatchitems"  :key="item.id">

<!--                           <td>-->
<!--                               <b-link class="" :to="{ path: `/delivery-note/${dispatch.id}`, params: { id: dispatch.id } }">-->
<!--                                   {{dispatch.id}}-->
<!--                               </b-link>-->

<!--                           </td>-->
                           <td>{{formatDate(dispatch.date)}} </td>
                           <td>{{item.itemCode}}</td>
                           <td>{{item.itemDescription}}</td>
                           <td>{{item.qty}}</td>
                           <td>{{dispatch.mode}}</td>
                           <td>
                               <span v-if="(locations.find(c=>c.id==dispatch.location_id))">
                                   {{(locations.find(c=>c.id==dispatch.location_id)).location_name }}
                               </span>
                           </td>
                           <td v-if="(cashiers.find(c=>c.id==dispatch.cashier_id))">{{(cashiers.find(c=>c.id==dispatch.cashier_id)).fullName }}</td>

                           <td>
                               <b-form-input v-model="dispatch.picked_by"  :disabled="true"/>
                           </td>
                           <td>
                               <b-form-input v-model="dispatch.collected_by" :disabled="true" />
                           </td>

                       </tr>

                       </tbody>
                   </table>
                   <div class="col-md-3 offset-9 mb-3 d-flex justify-content-end">
                       <button class="btn btn-danger" v-if="dispatch.status=='Draft'" @click="cancel_transaction(dispatch.id)" :disabled="deleting_transaction">
                          <span v-if="deleting_transaction">
                             Deleting
                          </span>
                           <span v-else="">
                             Delete
                          </span>

                       </button>
<!--                       <button class="btn btn-success mx-2" v-if="dispatch.status=='Draft'" :disabled="locations.find((location)=>location.branch_id==authenticatedUser.branch_id).id!=dispatch.location_id" @click="handle_picked(dispatch.id,dispatch.picked_by)">Picked </button>-->

<!--                       <button class="btn btn-success mx-2"  @click="dispatchundo(dispatch.id)">Undo</button>-->

                       <b-link class="" :to="{ path: `/delivery-note/${dispatch.id}`, params: { id: dispatch.id } }">
                           <button class="btn btn-info">Print</button>
                       </b-link>

                   </div>
               </div>
            </div>

        </div>
        </div>

        <div class="row bgsecond text-light pb-2 mt-3 mtmb">
       <div class="col-md-6">
           <h1 class="text-center">New Transaction</h1>
           <table class="table table-bordered table-sm text-light" >
               <thead>
               <tr>
                   <th>Date</th>
                   <th>Mode </th>
<!--                   <th>Dispatched By </th>-->
                   <th>Location </th>
<!--                   <th v-if="invoice.refund!=1">Picked By</th>-->
<!--                   <th v-else="">Returned By</th>-->


               </tr>
               </thead>
               <tbody>
               <tr>
                   <td>
                       <b-form-datepicker

                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           id="transaction-datepicker" v-model="newtransaction.date" placeholder="Select Date" class="mb-2"   dropup  calendar-width="100%">

                       </b-form-datepicker>
                   </td>
                   <td class="">
                           <b-form-select
                               v-model="newtransaction.mode"
                               :options="['Collection','Delivery']"


                               class="w-100"

                           >

                               <template #first>
                                   <b-form-select-option value="">Select Mode</b-form-select-option>
                               </template>
                               <template #option="{ option }">
                                   <div>{{ option }}</div>
                               </template>

                           </b-form-select>
                   </td>
<!--                   <td>-->
<!--                       <b-form-select-->
<!--                           v-model="newtransaction.cashier_id"-->
<!--                           :options="cashiers"-->


<!--                           value-field="id"-->
<!--                           text-field="fullName"-->
<!--                       >-->

<!--                           <template #first>-->
<!--                               <b-form-select-option value="">Select One</b-form-select-option>-->
<!--                           </template>-->
<!--                           <template #option="{ option }">-->
<!--                               <div>{{ option.fullName }}</div>-->
<!--                           </template>-->

<!--                       </b-form-select>-->
<!--                   </td>-->
                   <td>
                       <b-form-select
                           v-model="location_id"
                           :options="locations.filter((l)=>[7,13,locations.find((il)=>il.branch_id==authenticatedUser.branch_id).id].includes(l.id))"


                           value-field="id"
                           text-field="location_name"
                           :disabled="invoice.invoice_ref.startsWith('Onl')"
                       >

                           <template #first>
                               <b-form-select-option value="">Select One</b-form-select-option>
                           </template>
                           <template #option="{ option }">
                               <div>{{ option.fullName }}</div>
                           </template>

                       </b-form-select>
                   </td>
<!--                   <td>-->
<!--                       <b-input class="form-control" placeholder="name" v-model="newtransaction.picked_by"></b-input>-->
<!--                   </td>-->
               </tr>




               </tbody>
           </table>
          <div class="row ">
              <div class="col-md-12 text-right">
                  <button  type="button" class="btn save-tran text-center btn-sm text-light  " @click="save_transaction" :disabled="disablesavetransaction || saving_transaction" >

                      <span v-if="saving_transaction">
                          Saving Transaction
                      </span>
                      <span v-else="">
                          Save Transaction
                      </span>
                  </button>

              </div>
          </div>
       </div>
            <div class="col-md-6">
                <h1 class="text-center" v-if="invoice.refund!=1">
                    Dispatch Items   <span style="font-size: 1.2rem !important;" :class="(Number(invoice.totalPaid)-100)<Granddispatchtotal?'make_red':'make_green'" v-if="Number(dispatch_total)>0 && invoice.status=='Deposit'"> Amount={{Granddispatchamount}}, Vat={{GrandVat}}, Total={{Granddispatchtotal}} </span>
                </h1>
                <h1 class="text-center" v-else="">Return Items </h1>
                <table class="table table-bordered table-sm text-light" >
                    <thead>
                    <tr>
                        <th>Item Code</th>
                        <th>Item Description</th>
                        <th>Qty </th>


                    </tr>
                    </thead>
                    <tbody>
                     <tr v-for="item in newtransaction.dis_items" :key="item.id">
                         <td>{{item.itemCode}}</td>
                         <td>{{item.itemDescription}} </td>
                         <td class="col-md-2" >
                             <b-input type="number" v-model="item.qty"  @keyup="checkqty(item)"></b-input>
                         </td>
<!--                         <td class="col-md-2" >-->

<!--                             <b-input type="number" v-model="item.qty"  @keyup="checkqty(item)" :value="Number(item.qty) - Number(item.picked)"></b-input>-->
<!--                         </td>-->

                     </tr>




                    </tbody>
                </table>
            </div>

        </div>


    </b-modal>
</div>


</template>



<script>
import {confirmDeleteAlert, errorAlert, successAlert} from "@/components/Alerts";

//import apiService from "../../service/apiService";
// import Header from "@/components/layout/Header";
// import PageHeader from "@/components/layout/PageHeader";
import showrealtime from '@/components/showrealtime'
import $ from "jquery";
import moment from 'moment';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {mapActions} from "vuex";
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
 import axios from 'axios';
import apiService from "@/service/apiService";
// import orders from "@/pages/admin/mypos/orders";

import VueHtml2pdf from 'vue-html2pdf'
import PrintInvoice from '@/pages/admin/mypos/printinvoice';
 import { cloneDeep } from 'lodash';
import sidebar from "@/components/layout/sidebar";
import URL from "@/constants/baseurl";
import newinvoice from "@/constants/newinvoice"
import newcustomer from "@/constants/newcustomer";
import newpayment from "@/constants/newpayment";
import newitem from "@/constants/newitem";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import Datepicker from "vuejs-datepicker";


export default {
    name: "pos",
    mixins: [],
  //  components: {Header, PageHeader},
    components:{
        Welcomenote,
        'v-select': vSelect, 'ShowRealTime':showrealtime, 'PrintInvoice':PrintInvoice, 'VueHtml2pdf': VueHtml2pdf,'sidebar':sidebar},
    computed:{

        ...mapState('items', ['items']),
        ...mapState('invoice', ['invoice','cashiers','branches','latestinvoices','loaded_order']),
        ...mapState('items',['searched_items']),
        ...mapState('notes',['newnote','added_notes']),
        ...mapState('dispatch',['newtransaction','transactionshistory']),
        ...mapState('customers', ['customers', 'selected_customer']),
        ...mapGetters('items', ['subTotal']),
        ...mapGetters('invoice', ['paid','deposit']),
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('payment', ['payment']),

        adminorderrefund(){
          if(
              ((this.invoice.status=='Pending Delivery'
              ||this.invoice.status=='Pending Collection')
              && (!this.authenticatedUser.permissions.includes('super'))
              )
          )  {
             return true
             } else {
              return false
          }
        },
     allowsuper(){
          if(this.authenticatedUser.permissions.includes('super') && this.invoice.status!='Completed' && this.invoice.status!='Partial Refund' && this.invoice.status!='Full Refund'){
           return true
          } else {
              return false
          }
     },
        GrandVat() {
            // Calculate VAT for dispatch_total and historytotal
            const dispatch_total = Number(this.dispatch_total);
            const historytotal = Number(this.historytotal);
            const vat = ((0.2 * dispatch_total) + (0.2 * historytotal)).toFixed(2);
            return Number(vat);
        },
        Granddispatchtotal() {
            // Calculate total including VAT for dispatch_total and historytotal
            const dispatch_total_with_vat = Number(this.dispatch_total_with_vat);
            const historytotal_with_vat = Number(this.historytotal_with_vat);
            console.log(dispatch_total_with_vat,'dispatch_with_vat')
            console.log(historytotal_with_vat,'historytotal_with_vat')
            const total = (dispatch_total_with_vat + historytotal_with_vat).toFixed(2);
            return Number(total);
        },
        Granddispatchamount() {
            // Calculate the sum of dispatch_total and historytotal
            const dispatch_total = Number(this.dispatch_total);
            const historytotal = Number(this.historytotal);
            const grandAmount = (dispatch_total + historytotal).toFixed(2);
            return Number(grandAmount);
        },
        // dispatch_total() {
        //
        //
        //
        //     if (this.newtransaction.dis_items.length > 0) {
        //
        //
        //
        //         // Calculate total based on each item's quantity multiplied by unit price
        //         return this.newtransaction.dis_items.reduce((total, dis_item) => {
        //
        //           let totalqty_in_history = 0;
        //             if(dis_item.totalqty_in_history>0){
        //                 totalqty_in_history = dis_item.totalqty_in_history;
        //             }
        //
        //             let totalqty=Number(dis_item.qty) + Number(totalqty_in_history)
        //
        //             return total + ((totalqty) * Number(dis_item.pivot.unitPrice));
        //         }, 0);
        //     } else {
        //         return 0; // If there are no items, return 0
        //     }
        // },
        dispatch_total_with_vat() {
            // Check if dis_items is not empty
          let dv=   this.dispatch_total+(0.2*this.dispatch_total)
            return Number(dv).toFixed(2)
        },
        loggedUserLocation(){
            if(this.locations.length){
            let locations=this.locations.filter((location)=>location.branch_id==this.authenticatedUser.branch_id)
          let name=locations[0].location_name
          let formattedName = name.replace(/\s/g, '');
            return formattedName
            }
            else {
                return false
            }
        },
       checkpickup(){
        if(this.invoice.deliveryOption==2 || this.invoice.deliveryOption==3){
            if(this.invoice.pickup_location_id){
                return true
            } else {
                return false
            }
        } else {
            return null
        }
       },
        // checkdelivery(){
        //     if(this.invoice.deliveryOption==3){
        //         if(this.invoice.delivery_location_id){
        //             return true
        //         } else {
        //             return false
        //         }
        //     } else {
        //         return null
        //     }
        // },
        discount_applied(){
        let items= this.searched_items.filter((item)=>item.discount_applied==true||item.discount_applied==1)
            if(items.length){
                return true
            } else {
                return false
            }
        },
        branchlocations(){
            let loc=this.locations.filter(lc=>lc.id==this.authenticatedUser.branch_id).map((bl)=>bl.location_name.replace(/\s/g, '')+'Stock')
            return  loc
        },

        hasDuplicateDescription() {
            const duplicates = [];
            if (this.searched_items.length) {
                const uniqueItems = new Set();


                for (const obj of this.searched_items) {
                    const itemKey = (obj.itemCode + obj.itemDescription).trim();

                    if (uniqueItems.has(itemKey) && !duplicates.includes(itemKey)) {
                        duplicates.push(itemKey);
                    } else {
                        uniqueItems.add(itemKey);
                    }
                }

               return  duplicates
            }

            return duplicates;
        },

        // gotcustomers(){
        //     return cloneDeep(this.customers);
        // },



        cnotes(){
            let customerswithnotes=cloneDeep(this.customers).filter(c=>c.notes !=null)

            return customerswithnotes
        },
        cus_balance(){
            if(this.selected_customer.walletBalance){
                return Number(this.selected_customer.walletBalance).toFixed(2)
            }
            else return 0
        },
        // credit_payment_sum() {
        //     if (this.invoice.payments) {
        //         const creditPayments = this.invoice.payments.filter(p => p.payment_type === 'Credit');
        //
        //         // Use reduce to calculate the sum of payment_amount
        //         const sum = creditPayments.reduce((total, payment) => total + Number(payment.payment_amount), 0);
        //
        //         return sum;
        //     } else {
        //         return 0; // If there are no payments or no 'Credit' payments
        //     }
        // },
        noremaining(){

            let allzero=true
        if(this.invoice.refund !=1){
            this.searched_items.forEach(d => {

                if(Number(d.qty) - Number(d.picked)){
                    allzero=false
                }
            });
        }
        else {
            this.searched_items.forEach(d => {
                if((Number(d.qty) + Number((this.invoice.orginal_items.filter(oi => oi.itemCode === d.itemCode && oi.itemDescription === d.itemDescription))[0].picked) - Number(d.picked)) != 0){
                    allzero=false
                }
            });
        }
         return allzero
        },

        disablesavetransaction(){
            let x=0;
            let allzero=true

            const allDispatchItems = cloneDeep(this.transactionshistory)
                .map(obj => obj.dispatchitems) // Extract dispatchitems from each object
                .reduce((acc, items) => acc.concat(items), []); // Concatenate all dispatchitems into a single array

           // console.log(allDispatchItems,'disable');


            this.newtransaction.dis_items.forEach(d => {
                   if(d.qty!=0){
                       allzero=false
                   }
                this.searched_items = this.searched_items.map(sd => {
                    if (sd.itemCode === d.itemCode && sd.itemDescription === d.itemDescription) {

                       let totalqty= (allDispatchItems.filter((ad)=>sd.itemCode === ad.itemCode && sd.itemDescription === ad.itemDescription )).reduce((acc, item) => {
                            // Parse the qty as a number and add it to the accumulator
                            return acc + Number(item.qty);
                        }, 0);

                     if(this.invoice.refund!=1){
                       //  if(Number(d.qty)>((Number(sd.qty)-Number(sd.picked)-Number(totalqty))) || Number(d.qty)<0){

                         // console.log('check',(Number(sd.qty).toFixed(2)-Number(totalqty).toFixed(2)))
                         if(Number(d.qty).toFixed(2)>((Number(sd.qty).toFixed(2)-Number(totalqty).toFixed(2))).toFixed(2) || Number(d.qty)<0){
                             x=x+1
                         }
                     }
                     else {
                         if(this.invoice.orginal_items) {


                             // if (Number(d.qty) < (Number(sd.qty) + Number((this.invoice.orginal_items.filter(oi => oi.itemCode === sd.itemCode && oi.itemDescription === sd.itemDescription))[0].picked) - Number(sd.picked)) || Number(d.qty) > 0)
                             if (Number(d.qty) < ((Number(sd.qty)-Number(totalqty)))  || Number(d.qty) > 0)
                             {

                                 x = x + 1
                             }
                         }
                     }
                    }
                    return sd;
                });

            });
            if(x || ! this.newtransaction.mode  || allzero || ! this.location_id || ((Number(this.invoice.totalPaid)-100)<this.Granddispatchtotal) && this.invoice.status=='Deposit' ){
               return  true
            }
            else {
                return  false
            }

        },
        notallowsave(){
            // if((this.selected_customer.firstName==null || this.selected_customer.cashier_id==null) || ( (this.payment.payment_amount>0 && (this.payment.reference==null || this.payment.payment_type==null )) || (this.invoice.deliveryOption!=1 && this.selected_customer.mobile==null) || (this.invoice.deliveryOption==3 && (this.selected_customer.addressLine1==null || this.selected_customer.addressLine2==null || this.selected_customer.postcode==null)))){
            //    return true
            // }
            // else {
            //     return false
            // }


            if(this.selected_customer.lastName){
                return false
            }
            else{
                return true
            }
        },

        due(){
            return (this.invoice.grandTotal).toFixed(2)-(this.invoice.totalPaid)
        },
        tp(){
           if( this.invoice.totalPaid){
               let tp= this.invoice.totalPaid
              return  tp
           }
           else{
               return 0
           }

        },
        emptypaymentrows() {
            if ((this.invoice.payments).length==1){

                return 4
            }
            else if ((this.invoice.payments).length==2){
                return 2
            }


            else return 0
        },
        totalPaymentAmount() {
         if(this.invoice.refund==0 || this.invoice.refund==null){
             // return this.invoice.payments.reduce(
             //     (total, payment) => {
             //         if(Number(payment.payment_amount) > 0 ){
             //           return  total + Number(payment.payment_amount)
             //         } else if (Number(payment.payment_amount) < 0){
             //             return  total + Number(payment.payment_amount)
             //         }
             //         else return  total
             //     },
             //     0
             // );
             let total = 0;
             let payments = cloneDeep(this.invoice.payments);
             payments.forEach(payment => {
                 total =Number(total)+Number(payment.payment_amount);
             });
             return Number(total).toFixed(2);

         }
         else {
             return this.invoice.payments.reduce(
                 (total, payment) => {
                     if(Number(payment.payment_amount) < 0 ){
                         return  total + Number(payment.payment_amount)
                     }
                     else return  total
                 },
                 0
             );
         }

        },
        showplus(){
            let newitems = this.searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0){
                return true
            }
            else {
                return false
            }
        },
        d2(){
              if(this.invoice.deliveryOption==2 || this.invoice.deliveryOption==3){
                  if(this.selected_customer.mobile=='' || this.selected_customer.mobile==null){
                      return false
                  }
                  else {
                      return true
                  }

              }
              else{
                  return null
                   }
             },
        dd2(){
            if(this.invoice.deliveryOption==2 || this.invoice.deliveryOption==3){
                if(this.invoice.deliveryDate=='' || this.invoice.deliveryDate==null){
                    return false
                }
                else {
                    return null
                }

            }
            else{
                return null
            }
        },
        d3a1(){
            if( this.invoice.deliveryOption==3){
                if(this.selected_customer.addressLine1 =='' || this.selected_customer.addressLine1 ==null){
                    return false
                }
                else {
                    return true
                }

            }
            else{
                return null
            }
        },
        d3a2(){
            if( this.invoice.deliveryOption==3){
                if(this.selected_customer.addressLine2 =='' || this.selected_customer.addressLine2 ==null){
                    return false
                }
                else {
                    return true
                }

            }
            else{
                return null
            }
        },
        d3pc(){
            if( this.invoice.deliveryOption==3){
                if(this.selected_customer.postcode =='' || this.selected_customer.postcode ==null){
                    return false
                }
                else {
                    return true
                }

            }
            else{
                return null
            }
        },

        totalentered(){
            let entered= Number(this.payment.payment_amount)
           return  entered.toFixed(2)
        },
        showitemdatalist(){
            if(this.searchitem.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },



        itemsreceived(){
            if(this.items.length>0){

                return false
            }
            else {
                return true
            }
        },
        requireAddress(){
            if(this.invoice.deliveryOption=='3' ){

                return true
            }
            else {
                return false
            }
        },
        requiremobe(){
            if(this.invoice.deliveryOption=='2'
                // &&
                // (
                //
                //     this.invoice.cusMobile==null
                //     || this.invoice.cusMobile==''
                //     || this.invoice.cusMobile=='null'
                // )
            ){

                return true
            }
            else {
                return false
            }
        },
        customersreceived(){
            if(this.customers.length>0){

                return true
            }
            else {
                return false
            }
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
           user=  JSON.parse(user)
            return user
        },
        selectedCashier(){
          return  this.cashiers.filter((c)=>c.id==this.selected_customer.cashier_id)[0]
        },
        invoiceStatus(){
            if(this.invoice.invoice_ref=='Not Created' ){
                return 'Quote'
            }
            else {
                return this.invoice.status
            }


        },

        formattedDate() {
            let currentDate = new Date().toLocaleString('en-US', {timeZone: 'Europe/London'});
            if (this.invoice.issueDate !== "") {
                currentDate = new Date(this.invoice.issueDate).toLocaleString('en-US', {timeZone: 'Europe/London'});
            }

            const formattedDate = moment(currentDate).format('YYYY-MM-DD');

            return formattedDate;
        },

        makereq(){
            if(this.invoice.deliveryOption>1 && ! this.invoice.deliveryDate && ! this.invoice.cusMobile ){
                return 1
            }
            if(this.invoice.deliveryOption>1 && ! this.invoice.deliveryDate && ! this.invoice.cusMobile || !this.invoice.cashier_id || (!this.makedis && !this.paid)){
                return true
            }

            else{
                return false
            }
        },
        disableAddPayment(){

            if(

                 (this.invoice.deliveryOption==2 && (! this.invoice.deliveryDate || (! this.selected_customer.mobile && ! this.invoice.cusMobile)))
                || (this.invoice.deliveryOption==3 && (! this.invoice.deliveryDate || (! this.selected_customer.mobile && ! this.invoice.cusMobile) ||
                    (
                        this.invoice.deliveryAddress1==null
                        || this.invoice.deliveryAddress1=='null'
                        || this.invoice.deliveryAddress1==''
                        || this.invoice.deliveryAddress2==null
                        || this.invoice.deliveryAddress2==''
                        || this.invoice.deliveryAddress2=='null'
                        || this.invoice.deliveryPostcode==null
                        || this.invoice.deliveryPostcode==''
                        || this.invoice.deliveryPostcode=='null'

                    )


                ))
                ||  this.invoice.cashier_id==null
                ||  this.invoice.cashier_id==''
                ||  this.invoice.grandTotal==0
                ||  Number(this.payment.payment_amount)==0
                ||  this.payment.payment_type==null
            ){
                return true
            }


            else{
                return false
            }

        },

        makedis() {

            if(
                   (
                    ((Number(this.payment.payment_amount)>0 || Number(this.payment.payment_amount)<0 )
                     && (!this.payment.payment_type
                         || !this.payment.reference
                         || ( (this.due).toFixed(2)<Number(this.totalentered) && (this.due).toFixed(2)>=0)
                         || ( (this.due).toFixed(2)>Number(this.totalentered) && (this.due).toFixed(2)<0)
                         || (this.payment.payment_type.includes('Credit') && this.cus_balance<Number(this.totalentered))
                         ||  this.invoice.deliveryOption==null
                         ||  this.invoice.deliveryOption==''
                     ))
                     && (this.invoice.refund==0 || this.invoice.refund==null)
                  )
                ||
                (
                    (Number(this.payment.payment_amount)<0
                        && (!this.payment.payment_type
                            || !this.payment.reference
                            || (this.due).toFixed(2)>Number(this.totalentered)

                            ||  this.invoice.deliveryOption==null
                            ||  this.invoice.deliveryOption==''
                        ))
                    && this.invoice.refund==1
                )
                ||   this.payment.payment_amount===''
               ||   (this.payment.payment_amount>0 && this.invoice.refund==1)
               // ||   (this.payment.payment_amount<0 && (this.invoice.refund==0 || this.invoice.refund==null))
                || (this.invoice.deliveryOption==2 && (! this.invoice.deliveryDate || (! this.selected_customer.mobile)))
                || (this.invoice.deliveryOption==3 && (! this.invoice.deliveryDate || (! this.selected_customer.mobile ) ||
                (
                    this.selected_customer.addressLine1==null
                    || this.selected_customer.addressLine1=='null'
                    || this.selected_customer.addressLine1==''
                    || this.selected_customer.addressLine2==null
                    || this.selected_customer.addressLine2==''
                    || this.selected_customer.addressLine2=='null'
                    || this.selected_customer.postcode==null
                    || this.selected_customer.postcode==''
                    || this.selected_customer.postcode=='null'





                )


            ))
                ||  this.selected_customer.cashier_id==null
                ||  this.selected_customer.cashier_id==''
                ||  this.selected_customer.lastName==null
                ||  this.selected_customer.lastName==''
                || (Number(this.invoice.totalPaid) < Number(this.invoice.grandTotal) && this.invoice.refund==1)
                // || (Number(this.invoice.totalPaid) > Number(this.invoice.grandTotal) && this.invoice.refund==0)
                || this.hasDuplicateDescription.length > 0
                || this.checkpickup==false
                // || this.checkdelivery==false


                // || (this.authenticatedUser.permissions.includes('super') && this.invoice.status!='Draft' && this.invoice.status!='Deposit'  && this.invoice.status!='Completed' && this.due!=0 )



            ){
              return true
            }


            else{
                return false
            }

        },

        allowprint(){
            if(this.invoice.invoice_ref=='Not Created'){
                return true
            }
            else{
                return false
            }

        },

        fields() {
            return [
                {
                    label:'Sr#',
                    key: 'id'
                },
                {
                    label:'First Name',
                    key: 'first_name'
                },
                {
                    label:'Last Name',
                    key: 'last_name'
                },
                {
                    label:'Email',
                    key: 'email'
                },
                {
                    label:'User Name',
                    key: 'user_name'
                },
                // {
                //     label:'CRR ID',
                //     key: 'customer_id_number'
                // },
                // {
                //     label:'Customer No',
                //     key: 'customer_number'
                // },
                // {
                //     label:'Payer No',
                //     key: 'payer_number'
                // },
                {
                    label:'Role',
                    key: 'role'
                },
                {
                    label:'Actions',
                    key: 'actions'
                },

            ]
        }
    },
    data() {
        return {
            fetching_item_stock:false,
            have_changes:false,
            newposclicked:false,
            deleting_transaction:false,
            saving_transaction:false,
            fetching:true,
            deferredPrompt: null,
            historytotal:0,
            historytotal_with_vat:0,
            dispatch_total:0,

            collected_by:'',
            pickedby:'',
            tradecards:[],
            cardNo:'',
            a_dis:false,
            total_discount:0,
            saving:false,
            locations:[],
            location_id:'',
            datePattern: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
            paymenttypeedit:false,
            paymentreferenceedit:false,
            paymentdateedit:false,
            paymenttypes:[],
            showspinnermail:false,
            showspinner:false,
            disradio:false,
            notesmodalShow: false,
            dispatchmodalShow:false,
            showmodal:false,
            savingnewcus:false,
            roles: [],
            user: {},


            searchitem:'',
            searchcustomer:'',
            itemName: '',
            itemDescription: '',
            itemCode: '',
            defaultPrice: 0,
            status: true,


            branchId: '',
            cashierId: '',
            title: '',
            firstName: '',
            lastName: '',
            customerCode: '',
            mobile: '',
            companyName: '',
            customerType: '',
            discountType: '',
            addressLine1: '',
            addressLine2: '',
            postcode: '',
            email:'',
            contactPerson:'',
            notes: '',
            status2: false,
            duplicateditemcode:null





        }
    },
   async  created() {
       this.fixpaymenttype();
       this.disableradio();
       window.addEventListener("beforeinstallprompt", e => {
           e.preventDefault();
           // Stash the event so it can be triggered later.
           this.deferredPrompt = e;
       });
       window.addEventListener("appinstalled", () => {
           this.deferredPrompt = null;
       });

     //   await  this.getUsers();
     //   await this.getRoles();
     //   await this.getItems();
     //   await this.getCustomers();
     //  // this.selectedCus('0123456789','no');
     //   await this.getbranches()
     // // await  this.latestinvoice();
     //   await this.getcashiers();
     // //  await this.getcountry()

       // reduce water fall
       const promises =[

        this.getItems(),
        // this.getCustomers(),

        this.getbranches(),

        this.getcashiers(),
        this.getlocations(),
        this.draft_collection(),


       ]
       // Wait for all promises to complete
       await Promise.all(promises);
       if (this.invoice.invoice_ref!='Not Created'){
           this.invoice.refunded_orders=await this.get_refunded_orders(this.invoice.id)

           console.log('this.invoice',this.invoice)
       }
       this.fetching=false
      // this.search_orders()

       this.fillcard();





   },
    mounted() {
      //  this.adjustContentHeight()
        this.addsearchitem()



    },

    watch: {
        searchitem(newValue) {
            this.searchitem = newValue.toUpperCase();
        },
        'invoice.invoice_ref': {
            handler(newVal) {
                if (newVal.startsWith('Onl')) {
                    this.location_id = this.invoice.delivery_location_id;
                }
            },
            immediate: true,
        },
        due(newValue) {
         // console.log('due watched',newValue)
        if(this.authenticatedUser.permissions.includes('super')){
            if (newValue<0){
                this.paymenttypes=['Refund Cash','Refund Card','Refund Bank' ,'Refund Credit']
            } else {
                this.paymenttypes=[
                    'Cash',
                    'Card',
                    'Bank',
                    'Pay By Link',
                    `Credit(${this.cus_balance})`
                ]
            }
        }
        },
        searchcustomer(newValue){
            this.searchcustomer=newValue.toUpperCase();
        },
        async cardNo(newVal) {
            this.have_changes = true;

            //  alert('cardcustomername changed. New value: ' + newVal);

            // You can also call your gettradecustomers method here if needed
            await this.getlinkedtradecards(newVal);
        },
        // invoice: {
        //     handler(newInvoice) {
        //        // console.log(newInvoice.deliveryOption);
        //         if (newInvoice.deliveryOption==1){
        //             this.searched_items.map((item)=>{
        //                 item.picked=item.qty
        //             })
        //         } else {
        //          // this.attachinvoiceitems(this.invoice.items)
        //         }
        //     },
        //     deep: true,
        // },
        searched_items: {
            handler(newItems, oldItems) {
                // if(this.invoice.invoice_ref!='Not Created'){
                //     this.have_changes = true;
                // }
                this.have_changes = true;

            },
            deep: true,
        },
        // invoice: {
        //     handler(newInvoice, oldInvoice) {
        //         this.have_changes = true;
        //     },
        //     deep: true,
        // },



        // 'invoice.deliveryEstimated': function (newVal, oldVal) {
        //     this.have_changes = true;
        // },
        // 'invoice.deliveryOption': function (newVal, oldVal) {
        //     this.have_changes = true;
        // },
        'searched_items.itemCode': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'invoice.deliveryDate': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'selected_customer.addressLine1': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'selected_customer.addressLine2': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'selected_customer.postcode': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'selected_customer.mobile': function (newVal, oldVal) {
            this.have_changes = true;
        },

        'selected_customer.lastName': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'selected_customer.cashier_id': function (newVal, oldVal) {
            this.have_changes = true;
        },


        'invoice.pickup_location_id': function (newVal, oldVal) {
            this.have_changes = true;
        },

        'payment.payment_amount': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'payment.payment_type': function (newVal, oldVal) {
            this.have_changes = true;
        },

        'payment.date': function (newVal, oldVal) {
            this.have_changes = true;
        },
        'payment.reference': function (newVal, oldVal) {
            this.have_changes = true;
        },


    },
    methods: {
         ...mapActions(['login','processLogoutManual']),
        ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removeSearchedItem']),
        ...mapActions('items',['add_searched_items','attachinvoiceitems','refillInvoiceSearcheditems']),
        ...mapActions('notes',['add_new_note','attachinvoicenotes']),
        ...mapActions('dispatch',['add_dis_items','add_transaction','remove_transaction','attach_invoice_transactions','reset_transactionshistory','dispatchPicked']),
        ...mapActions('customers', ['getCustomers','set_selectedcustomer_balance','set_selectedcustomer']),
        ...mapActions('invoice', ['updateFullInvoice','getcashiers' ,'getbranches']),
        ...mapActions('payment', ['setNewpayment']),

        // search_orders(){
        //      console.log(this.latestinvoices.data,'searched orders=====================')
        // },
        async get_refunded_orders(invoice_id){
            const {data} = await apiService.get(`/get-refunded-orders/${invoice_id}`);

         // this.invoice.refunded_orders=data;
         // console.log('this.invoice',this.invoice)
            return data;

        },

        change_occur(){
           this.have_changes=true
        },

        updatenewTransactionDisItems() {
            const allDispatchItems = cloneDeep(this.transactionshistory).filter((th)=>th.status!='Approved')
                .map(obj => obj.dispatchitems) // Extract dispatchitems from each object
                .reduce((acc, items) => acc.concat(items), []); // Concatenate all dispatchitems into a single array

            // console.log(allDispatchItems,'allDispatchItems');
            this.newtransaction.dis_items = this.newtransaction.dis_items.map((nt) => {

                const matchedItem = this.searched_items.find((i) =>
                    i.itemCode === nt.itemCode && i.itemDescription === nt.itemDescription
                );

                if (matchedItem) {
                    let pendingqty=0;
                    if(allDispatchItems.length)
                    {
                        pendingqty=allDispatchItems
                            .filter(ad => ad.itemCode === matchedItem.itemCode && ad.itemDescription === matchedItem.itemDescription)
                            .reduce((total, ad) => total + Number(ad.qty), 0);
                    }



                    if(this.invoice.refund!=1){
                        const picked = Number(matchedItem.picked);
                        const qty = Number(matchedItem.qty);
                        const remaining_to_add = qty - picked - pendingqty;

                        return {
                            ...nt,
                            qty: Number(remaining_to_add).toFixed(2),
                        };
                    } else {

                        const qty = Number(matchedItem.qty)+Number((this.invoice.orginal_items.filter(oi=>oi.itemCode===matchedItem.itemCode && oi.itemDescription ===matchedItem.itemDescription))[0].picked)-Number(matchedItem.picked);

                        const remaining_to_add = qty  - pendingqty;
                        return {
                            ...nt,
                            qty: Number(remaining_to_add).toFixed(2),
                        };
                    }

                }


                return nt;
            });




            // allDispatchItems.forEach(d => {
            //     this.newtransaction.dis_items.forEach(sd => {
            //         if (sd.itemCode === d.itemCode && sd.itemDescription === d.itemDescription) {
            //             sd.qty = Number(sd.qty) + Number(d.qty);
            //         }
            //     });
            // });
            console.log(this.newtransaction.dis_items,'this.newtransaction.dis_items')
            console.log(this.searched_items,'this.searched_items')
        },

        async dismiss() {
            this.deferredPrompt = null;
        },
        async install() {
            this.deferredPrompt.prompt();
        },
        calculateHistoryTotal( ) {
            const allDispatchItems = cloneDeep(this.transactionshistory)
                .map(obj => obj.dispatchitems) // Extract dispatchitems from each object
                .reduce((acc, items) => acc.concat(items), []); // Concatenate all dispatchitems into a single array

           const searchedItems=cloneDeep(this.searched_items)
      // console.log(this.searched_items,'this.searched_items')
            let totalPrice = 0;

            // Loop through allDispatchItems
            allDispatchItems.forEach(dispatchItem => {
                // Find the corresponding item from searchedItems
                const searchedItem = searchedItems.find(item => item.itemCode === dispatchItem.itemCode && item.itemDescription === dispatchItem.itemDescription);

                // If the item is found, calculate the subtotal and add it to the total price
                if (searchedItem) {
                    const subtotal = searchedItem.defaultPrice * dispatchItem.qty;
                    totalPrice += subtotal;
                }
            });

            // Update totalPrice in the component's data
            this.historytotal = totalPrice;
            this.historytotal_with_vat = (totalPrice+(totalPrice*0.2)).toFixed(2);

        },

        autoselectpickuplocation(){
            if(this.invoice.deliveryOption==2) {
                this.invoice.pickup_location_id = this.locations.find((location) => location.branch_id == this.authenticatedUser.branch_id).id
            }
        },
        // autoselectdeliverylocation(){
        //      if(this.invoice.deliveryOption==3){
        //          this.invoice.delivery_location_id=this.locations.find((location)=>location.branch_id==this.authenticatedUser.branch_id).id
        //      }
        //
        // },
        moveLeft() {
             this.loaded_order.order_index-=1
              this.loadorder(this.latestinvoices.data[this.loaded_order.order_index])

        },
        moveRight() {
            this.loaded_order.order_index+=1
            this.loadorder(this.latestinvoices.data[this.loaded_order.order_index])

        },
       async loadorder(order, attach_itemes=true) {
           order.refunded_orders=await this.get_refunded_orders(order.id)
            this.updateFullInvoice(order)

            if(attach_itemes){
                this.attachinvoiceitems(order.items)
            }

            console.log(order.items,'order.items')
            let dispatchingitems=cloneDeep(order.items)
            let disitems=dispatchingitems.map((d)=>{

                return { ...d, qty: 0, itemDescription:d.pivot.itemDescription};
            })
            this.add_dis_items(disitems)

            this.attachinvoicenotes(order.notes)
            this.attach_invoice_transactions(order.transactions)
            //  console.log(order)
            this.$store.commit('customers/setSelectedCustomer', order.customer)
            this.setNewpayment({
                "invoice_id":null,
                "invoice_ref":null,
                "cashier_id":null,
                "payment_type": null,
                "reference":null,
                "payment_amount":0,
                "date":null
            })
            this.updatenewTransactionDisItems()
            // this.$router.push({ path: '/' })
            //  this.togglorderemodal()

        },
        handlechangeqty(mapid) {
            //  alert(mapid);
            let item = this.searched_items.find((item) => item.mapid == mapid);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(qty==0){
                if(item.id!=0 && item.qty!=''){
                    item.qty=Number(item.box_qty).toFixed(2)
                }
            }
            let validqty = qty / box_qty
            if (box_qty) {
                if(this.invoice.refund!=1) {
                    if (validqty < 1) {
                        item.qty = Number(item.box_qty).toFixed(2)
                    }
                        // else if (qty % box_qty == 0) {
                        //     //
                    // }
                    else {
                        item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                    }
                }
            }

            this.apply_discount(item.mapid)


        },
        fillcard(){
        // console.log(this.invoice.customer.trade_card.trade_card_no,'===================')
            if(this.invoice.customer.trade_card){
               this.cardNo=this.invoice.customer.trade_card.trade_card_no
                this.a_dis=true
            }
        },
        handleTradeCardSelection() {
         let card=this.tradecards.find((c)=>c.trade_card_no==this.cardNo)

            this.set_selectedcustomer(card.customer)

            this.apply_card_discount()
           //  console.log(this.selected_customer,'========')


        },
        async dispatchundo(dispatch_id){
            const {data} = await apiService.get(`/undo-dispatch/${dispatch_id}`);
            if(data.status=='Draft'){
                let transactionshistory=this.transactionshistory.map((dispatch)=>{
                    if(dispatch.id==dispatch_id){
                        return data
                    }
                    else {
                        return dispatch
                    }
                })
                this.dispatchPicked(transactionshistory)


                Swal.fire('Success!', 'Un Do is Completed Successfully!', 'success');

            }

        },
        async getlinkedtradecards(card_no){
            //  alert(name)
            //  console.log(name)
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            try {
                const response = await axios.post(`${URL.BASEURL}/api/getlinkedtradecards`,  {card_no:card_no});
                this.tradecards=response.data

                //   alert(this.found)
                //   console.log(this.cardcustomers,'===================')



            } catch (error) {
                console.error("Error getting customer:", error);
            }

        },
        setdiscount_dp(mapid) {
            if (this.selected_customer.customerType == 'Trade') {
                this.searched_items.map((item) => {
                    if (item.mapid == mapid) {

                            item.defaultPrice = Number(cloneDeep(item.original_price) - Number(item.discount)/Number(item.qty)).toFixed(2)
                             item.discount_applied = true

                    }

                })

            }
        },
        setdiscount_mx(mapid){
            this.searched_items.map((item)=>{
                if(item.mapid==mapid){

                  //  let defaultPrice= cloneDeep(item.defaultPrice)

                  let reversedMaxDiscount;

                    if(this.selected_customer.customerType=='Trade'){
                        item.original_price=cloneDeep(item.defaultPrice)
                        item.defaultPrice=cloneDeep(item.trade_card_price)
                        item.discount=((Number(item.original_price)-Number(item.trade_card_price))*(Number(item.qty))).toFixed(2)
                    }



                    item.max_discount = reversedMaxDiscount.toFixed(2);
                }

            })

        },
        apply_discount(mapid){
          //  alert(this.selected_customer.customerType)
   if(this.selected_customer.customerType=='Trade'){


    this.a_dis=true
    let totalDiscount = 0;
    this.searched_items=
        // let items=cloneDeep(this.searched_items)
        this.searched_items.map((item)=>{
            console.log(item)
            if(item.mapid==mapid){
                let discount=item.discount
                if(discount==item.discount){


                    item.discount=((Number(item.original_price)-Number(item.defaultPrice))*(Number(item.qty))).toFixed(2)
                } else {
                    discount=0
                }

                    item.discount_applied=true
                  //  item.defaultPrice=(item.defaultPrice-discount).toFixed(2)
                    totalDiscount+=discount


                return item
            }
        })
    // alert(totalDiscount)
    this.total_discount=totalDiscount.toFixed(2)
    this.update_invoice('grandTotal',this.invoice.grandTotal-this.total_discount)
    this.update_invoice('subTotal',this.invoice.subTotal-this.total_discount)
}
            // this.update_invoice('custDiscount',this.total_discount)

        },
        apply_card_discount(){
            //
            this.a_dis=true
            let totalDiscount = 0;
            this.searched_items=
                // let items=cloneDeep(this.searched_items)
                this.searched_items.map((item)=>{
                   // console.log(item)
                    let discount=Number(item.defaultPrice)-Number(item.trade_card_price)
                    if(discount){

                        // discount = Number(discount.toString().substring(0, 2)) || 0;
                         discount=Number(item.qty)*Number(discount)
                    }
                    else {
                        discount=0
                    }
                    if(!item.discount_applied){
                        item.discount_applied=true
                        item.discount=Number(discount).toFixed(2)

                        item.defaultPrice=Number(item.trade_card_price).toFixed(2)

                        totalDiscount+=discount

                    }

                    return item
                })
            // alert(totalDiscount)
            this.total_discount=totalDiscount.toFixed(2)
            this.update_invoice('grandTotal',this.invoice.grandTotal-this.total_discount)
            this.update_invoice('subTotal',this.invoice.subTotal-this.total_discount)
            // this.update_invoice('custDiscount',this.total_discount)

        },
       async handle_picked(dispatch_id,picked_by){
            // alert(picked_by)
             if(! picked_by){
               await  Swal.fire('Info!', 'Picked By is Required!', 'info');
                 return
             }
           // const swalResult = await Swal.fire({
           //     title: 'Mark as Picked',
           //
           //     icon: 'warning',
           //     showCancelButton: true,
           //     confirmButtonColor: '#3085d6',
           //     cancelButtonColor: '#d33',
           //     confirmButtonText: 'Yes, mark it!',
           //
           // });
         //  if (swalResult.isConfirmed) {
           const {data} = await apiService.get(`/mark-dispatch-picked/${dispatch_id}/${picked_by}`);
          // console.log(data.status)
           if(data.status=='Picked'){
         let transactionshistory=this.transactionshistory.map((dispatch)=>{
              if(dispatch.id==dispatch_id){
                  return {...dispatch, status:'Picked'}
              }
              else {
                  return dispatch
              }
          })
          this.dispatchPicked(transactionshistory)


          Swal.fire('Success!', 'Transaction Saved Successfully!', 'success');

      }
        //   }

       },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },

        handleedit(type){
             if(type=='date'){
                 this.paymentdateedit=!this.paymentdateedit
             }
            if(type=='type'){
                this.paymenttypeedit=!this.paymenttypeedit
            }
            if(type=='reference'){
                this.paymentreferenceedit=!this.paymentreferenceedit
            }

        },
        async update_payment(payment_id,data){
           //  data={...data, customer_id:this.selected_customer.id}

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/update-payment/${payment_id}`, data);

                if (res) {
                    //  Swal.fire('Success!', 'Payment Update Successfully!', 'success');
                    if(data.payment_type){
                        if(data.payment_type.includes('Credit') && res.data.payment_before.payment_type!='Credit' ){

                            this.set_selectedcustomer_balance(-Number((this.invoice.payments).find((p)=>p.id==payment_id).payment_amount))
                            this.paymenttypes=[
                                'Cash',
                                'Card',
                                'Bank',
                                'Pay By Link',
                                `Credit(${this.cus_balance})`
                            ]
                        }
                      //  alert((this.invoice.payments).find((p)=>p.id==payment_id).payment_type)
                         if((!data.payment_type.includes('Credit') && this.invoice.refund==0) && res.data.payment_before.payment_type=='Credit' ){

                            this.set_selectedcustomer_balance(+Number((this.invoice.payments).find((p)=>p.id==payment_id).payment_amount))
                            this.paymenttypes=[
                                'Cash',
                                'Card',
                                'Bank',
                                'Pay By Link',
                                `Credit(${this.cus_balance})`
                            ]
                        }
                    }

                   // console.log(res.data)
                   //




                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to update invoice.', 'info');
            }
        },
       async updatepayment( payment_id,data){
             if(data.date){

                 if (this.datePattern.test(data.date)) {
                //  alert('valid format')
                  await   this.update_payment(payment_id,data)
                     this.paymentdateedit=false

                 }
                 else {
                     Swal.fire('Error', 'Invalid Date Format', 'error');
                 }
             }

           if(data.payment_type){
               await   this.update_payment(payment_id,data)
               this.paymenttypeedit=false
           }
           if(data.reference){
               await   this.update_payment(payment_id,data)
               this.paymentreferenceedit=false
           }

        },

        checknegative(){


         if(this.invoice.refund==1){

         if(this.payment.payment_amount>0){
             this.payment.payment_amount=0
         }
         }
            if(this.invoice.refund==0){
                if(this.payment.payment_type=='Refund Cash' || this.payment.payment_type=='Refund Card' || this.payment.payment_type=='Refund Bank' || this.payment.payment_type=='Refund Credit')
                {
                    if(this.payment.payment_amount>0){
                        this.payment.payment_amount=0
                    }

                    if((Number(this.invoice.totalPaid)+Number(this.payment.payment_amount))<0){

                        this.payment.payment_amount=-Number(this.invoice.totalPaid).toFixed(2)

                    }
                }
                else {
                    if(this.payment.payment_amount<0){
                        this.payment.payment_amount=0
                    }
                }

            }
        },
        checkqty(item){


          let targetitem= this.newtransaction.dis_items.find((ditem)=>ditem.pivot.itemCode==item.pivot.itemCode&&ditem.pivot.itemDescription==item.pivot.itemDescription)

          //   console.log(targetitem,'targetitem========================')

          if(this.invoice.refund==1)
          {
              if(targetitem.qty>0){

                  targetitem.qty=0
              }

          }
          if(this.invoice.refund==0 ){
              this.calculateHistoryTotal()

              if (this.newtransaction.dis_items.length > 0) {
                  this.dispatch_total = this.newtransaction.dis_items.reduce((total, dis_item) => {

                      return total + (Number(dis_item.qty) * Number(dis_item.pivot.unitPrice));
                  }, 0);
              } else {
                  this.dispatch_total = 0;
              }

              if(targetitem.qty<0){
                  targetitem.qty=0
              }
          }


        },

        refund(){

            this.disradio=false
             this.reset_transactionshistory()
         //   console.log( this.transactionshistory ,'ok')
            this.paymenttypes=['Refund Cash','Refund Card','Refund Bank' ,'Refund Credit']
            this.update_invoice('status', 'Quote')
            this.update_invoice('invoice_ref', 'Not Created')
            this.update_invoice('grandTotal', 0)
            this.update_invoice('subTotal', 0)
            this.update_invoice('vat', 0)
            this.update_invoice('totalPaid', 0)
            this.update_invoice('refund', 1)
            this.update_invoice('payments', [])
            let currentDate = new Date().toLocaleString('en-US', {timeZone: 'Europe/London'});
            const fcurrentDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.update_invoice('issueDate', fcurrentDate)
            this.searched_items = this.searched_items.map(sd => {
                sd.qty=-sd.qty
                return sd;
            });
            this.update_invoice('subTotal', this.subTotal)
            this.update_invoice('grandTotal', Number(this.subTotal)+Number((this.subTotal*0.2).toFixed(2)))
            this.update_invoice('vat', (this.subTotal*0.2).toFixed(2))
            this.update_invoice('parent_inv_id',this.invoice.id)

         //   console.log(this.invoice)


        },

         generatePdf() {

             let enterorconfirm=''
             let cusemail=''
             if (this.selected_customer.email){
                 enterorconfirm='Confirm'
                 cusemail=this.selected_customer.email
             }
             else {
                 enterorconfirm='Enter'
             }

                 Swal.fire({
                     title: `${enterorconfirm} Email`,
                     html: `
                   <div class="row">
                    <input id="swal-input-1"  class="swal2-input col-md-10" placeholder="Please Enter Email of Customer" value=${cusemail}>

                       </div>

                               `,
                     focusConfirm: false,
                     showCancelButton: true,
                     confirmButtonText: 'Send',
                     cancelButtonText: 'Cancel',
                     preConfirm: () => {
                         const email = Swal.getPopup().querySelector('#swal-input-1').value;

                         if (!email) {
                             Swal.showValidationMessage('Email is Required!');
                         }
                         this.selected_customer.email=email
                     },

                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.showspinnermail=true
                         this.$refs.html2Pdf.generatePdf()
                     }
                 });


        },

        generateDownload() {
            this.$refs.html2Pdfdown.generatePdf()
        },

        fixpaymenttype(){

            if(this.invoice.refund==1 ) {
                this.paymenttypes=['Refund Cash','Refund Card','Refund Bank' ,'Refund Credit']
            }
            if(this.invoice.refund==0) {

                if(this.authenticatedUser.permissions.includes('super') && this.invoice.status!='Completed'  && this.invoice.status!='Partial Refund' && this.invoice.status!='Full Refund'){
                    this.paymenttypes=[
                        'Cash',
                        'Card',
                        'Bank',
                        'Pay By Link',
                        `Credit(${this.cus_balance})`,
                        'Refund Cash',
                        'Refund Card',
                        'Refund Bank' ,
                        'Refund Credit'
                    ]
                } else {
                    this.paymenttypes=[
                        'Cash',
                        'Card',
                        'Bank',
                        'Pay By Link',
                        `Credit(${this.cus_balance})`
                    ]
                }



            }
            if(this.due<0){
                if(this.authenticatedUser.permissions.includes('super')) {
                    this.paymenttypes = ['Refund Cash', 'Refund Card', 'Refund Bank', 'Refund Credit']
                }
            }

        },
        disableradio(){
            if(this.invoice.invoice_ref!="Not Created" && this.invoice.status=='Completed' && this.noremaining && this.invoice.deliveryOption=='1') {
               this.disradio=true
            }
            else {
                this.disradio=false
            }
        },
        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('DD MMM, YYYY');

            return date2;
        },
        // async hasDownloaded($event) {
        //     const formData = new FormData();
        //     formData.append('pdfFile', $event, `TilesInvoice-${this.invoice.invoice_ref}.pdf`);
        //     formData.append('cus_id', this.selected_customer.id);
        //     formData.append('cus_email', this.selected_customer.email);
        //    // console.log(formData, 'pdf');
        //
        //     const apiToken = localStorage.getItem('token');
        //     if (apiToken) {
        //         // Set the Authorization header with the API token
        //         axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
        //     }
        //
        //     try {
        //         const res = await axios.post(`${this.posapi}/api/sendinvoice`, formData, {
        //             // No need to manually set 'Content-Type' header; it will be set automatically to 'multipart/form-data'
        //         });
        //
        //        console.log(res);
        //        if (res.data){
        //            Swal.fire('Success', 'Invoice has been sent', 'success');
        //        }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // },
     async sendpdf(pdfBlob){

            const formData = new FormData();
            formData.append('pdfFile', pdfBlob, `TilesInvoice-${this.invoice.invoice_ref}.pdf`);
            formData.append('cus_id', this.selected_customer.id);
            formData.append('cus_email', this.selected_customer.email);
            // console.log(formData, 'pdf');

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/sendinvoice`, formData, {
                    // No need to manually set 'Content-Type' header; it will be set automatically to 'multipart/form-data'
                });

                console.log(res);
                if (res.data.message=='File uploaded successfully'){
                   await this.getCustomers()
                    this.showspinnermail=false
                    Swal.fire('Success', 'Invoice has been sent', 'success');
                }
            } catch (error) {
                console.error(error);
            }
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {

            await html2pdf().set(options).from(pdfContent).output("blob").then(async (pdfBlob) => {
                // Use an arrow function instead of a regular function to preserve 'this' context
                await this.sendpdf(pdfBlob);
            });
        },
        adjustContentHeight() {



            const contentContainer = document.querySelector('.fixcontent');
            const containerHeight = contentContainer.clientHeight;
            const windowHeight = window.innerHeight;
            let scalingFactor = windowHeight / containerHeight;

            if (scalingFactor < 1) {
                console.log(scalingFactor,'scale')

                console.log(scalingFactor,'scale')
                console.log(scalingFactor,'scale2')
                contentContainer.style.transform = `scaleY(${scalingFactor})`;
                contentContainer.style.transformOrigin = 'top';
                // contentContainer.style.transition = 'transform 0.3s';

            } else {
                contentContainer.style.transform = '';
            }



        },
        async signOut() {
            await this.processLogoutManual();

           // await this.$router.push({name: "Login"});
          //  window.location.reload();
            window.location.href = this.$router.resolve({ name: "Login" }).href;

        },

        toggleordermodal(){
           this.showmodal=!this.showmodal
        },



        update_invoice(k, v){
            this.$store.dispatch("invoice/updateInvoice", { key: k, value: v });
        },
        // watch: {
        //     searched_items: {
        //         handler(newItems) {
        //
        //             const subTotal = newItems.reduce((total, item) => total + item.defaultPrice * item.qty, 0);
        //              console.log(searched_items,newItems,'watcher')
        //             this.update_invoice('subTotal', subTotal);
        //         },
        //         deep: true // Enable deep watching of nested properties
        //     }
        // },
        selectedCus(code, run) {
               if(this.selected_customer=='' || run=='yes') {

                   const data = cloneDeep(this.customers).filter((c) => c.customerCode == code)
                   console.log(this.customers,data, 'here 1')

                   if (data.length) {
                       this.$store.commit('customers/setSelectedCustomer', data[0])
                       this.update_invoice('deliveryAddress1', data[0].addressLine1)
                       this.update_invoice('deliveryAddress2', data[0].addressLine2)
                       this.update_invoice('deliveryPostcode', data[0].postcode)
                       this.update_invoice('customer_id', data[0].id)
                       this.savingnewcus=false
                      // this.adjustContentHeight();
                   } else {
                       if (code != '0123456789') {

                           Swal.fire('Customer Not Found', 'Save new if you want', 'info');
                           this.$store.commit('customers/setSelectedCustomer', '')
                           this.$store.commit('customers/setSelectedCustomer', {customerCode:code, lastName:''})
                           this.savingnewcus=true

                       }

                   }

               }
        },
        async save_transaction(disall='',approve=false,id=null,collected_by=null) {

         if(! this.authenticatedUser.permissions.includes('super')){
             if(this.invoice.refunded_orders){
                 if(this.invoice.refunded_orders.length){
                     Swal.fire('Info!', 'You Cannot make dispatch, Order has Refund! Ask Super admin to make Dispatch', 'info');
                     return
                 }

             }
         }
             this.newtransaction.invoice_id=this.invoice.id
             this.newtransaction.customer_id=this.invoice.customer_id
            if(this.selectedCashier){
                this.newtransaction.cashier_id=this.selectedCashier.id
            } else {
                this.newtransaction.cashier_id=this.invoice.cashier_id
            }

            if(disall=='Dispatch_All'){



                 this.newtransaction.mode='auto'
                if(this.selectedCashier) {
                    this.newtransaction.cashier_id = this.selectedCashier.id
                } else {
                    this.newtransaction.cashier_id = this.invoice.cashier_id
                }
                this.newtransaction.status='Approved'
                this.newtransaction.location_id=this.location_id

                // this.searched_items=this.searched_items.map((i)=>i.picked=i.qty)

            }

            let data=cloneDeep(this.newtransaction)
                 data.location_id=this.location_id
            const apiToken = localStorage.getItem('token');
             if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
              }
            if(approve){
                //
                // if(! collected_by){
                //     await  Swal.fire('Info!', 'Collected By is Required!', 'info');
                //     return
                // }
                data={}
                data.id=id
                data.location_id=this.location_id
                data.invoice_id=this.invoice.id
                data.customer_id=this.invoice.customer_id
                data.dis_items=(this.transactionshistory.find((item)=>item.id==id)).dispatchitems
                data.status='Approved'
                // data.collected_by=collected_by
            }

            this.saving_transaction=true;
              try {
                const res = await axios.post(`${URL.BASEURL}/api/create-transaction`, data);

                 if (res) {

                     // this.loadorder(res.data.invoice)

                     this.add_transaction(res.data)
                     this.dispatch_total=0
                     this.dispatch_total_with_vat=0
                     if(res.data.transaction_25){

                         this.add_transaction(res.data.transaction_25)
                     }

                     // console.log(this.transactionshistory,'this.transactionshistory')


                     this.calculateHistoryTotal();

                  //   console.log(this.transactionshistory,'here')


                     if(res.data.status=='Approved') {



                         (this.transactionshistory.find((item) => item.id == res.data.id)).dispatchitems.forEach(d => {

                             this.searched_items = this.searched_items.map(sd => {
                                 if (sd.itemCode === d.itemCode && sd.itemDescription === d.itemDescription) {

                                     sd.picked = Number(sd.picked) + Number(d.qty);
                                 }
                                 // console.log(sd, 'sd.picked')
                                 // console.log(d, 'd.picked')

                                 return sd;
                             });

                         });
                         // console.log('this.searched_items',this.searched_items)



                     }
                     // this.newtransaction.dis_items = this.newtransaction.dis_items.map((nt) => {
                     //     let picked=this.searched_items.find((i)=>i.itemCode==nt.itemCode && i.itemDescription==nt.itemDescription).picked
                     //     let qty=this.searched_items.find((i)=>i.itemCode==nt.itemCode && i.itemDescription==nt.itemDescription).qty
                     //     let remaining=Number(qty)-Number(picked)
                     //     return {  ...nt,
                     //             qty: remaining
                     //           }
                     // });
                     this.updatenewTransactionDisItems()



                    if(disall !='Dispatch_All') {
                        Swal.fire('Success!', 'Transaction Saved Successfully!', 'success');
                    }





                    this.disableradio()
                    if(res.data.completed){
                        if(this.invoice.refund!=1){
                            if(this.noremaining){
                                this.update_invoice('status', 'Completed')
                            }


                        }
                        else {

                            this.update_invoice('status', 'Refund Collected')
                        }

                    }
                   // console.log(this.transactionshistory,'transaction history')
                   //   this.attachinvoiceitems(res.data.invoice_items)
                   //   console.log(this.searched_items,'this.searched_items here')

                     // await this.getItems()



                     // this.newtransaction.id=''
                     // this.newtransaction.invoice_id=''
                     // this.newtransaction.dis_items=[]
                     // this.newtransaction.picked_by=''
                     // this.newtransaction.cashier_id =''
                     this.newtransaction.mode=''
                     this.newtransaction.status='Draft'



                    }
                    else {
                     //
                    }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to add Transaction.', 'info');
            }
            this.saving_transaction=false;
        },

        async cancel_transaction(id){
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            const result = await Swal.fire({
                title: 'Are you sure you want to delete this transaction?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                this.deleting_transaction=true
                try {
                    const res = await axios.delete(`${URL.BASEURL}/api/cancel-transaction/${id}`);

                    if (res.data == 'done') {
                        this.remove_transaction(id)
                        Swal.fire('success', 'Transaction  Deleted', 'success');
                        this.dispatch_total=0;
                        this.dispatch_total_with_vat=0;
                        this.calculateHistoryTotal();
                        this.updatenewTransactionDisItems()

                    } else {
                        Swal.fire('info', 'Transaction Cant be Deleted', 'info');
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Error', 'Failed to add Transaction.', 'info');
                }
                this.deleting_transaction=false
            }

        },

        async getcountry() {
            const {data} = await apiService.get(`/getcountry`);
           console.log(data)
        },

        async getUsers(role = '') {
            const {data} = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },

        async deleteUser(id, e) {
            e.preventDefault()
            const {isConfirmed} = await confirmDeleteAlert('user');
            if (isConfirmed) {
                try {
                    await this.$axios.delete(`user/${id}`);
                    await successAlert('User', 'deleted')
                    await this.getUsers();
                } catch (e) {
                    await errorAlert('User', 'deleting');
                }
            }
        },
        async getRoles() {
            const {data} = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },

    async   validate(code,mapid){

         //  let obj = $("#item-list-id").find("option[value='" + code + "']");
            if(this.invoice.refund==0){
                let rmitem= this.items.filter((item)=>item.itemCode==code)
                if(rmitem[0])

                    console.log(code,'valid')
                else {
                    console.log(code,mapid,'rmcode')
                    // let rmitem= this.searched_items.filter((item)=>item.mapid==mapid)
                    await   Swal.fire('Invalid Code', 'Please enter a valid item code', 'info');
                    this.removeitem(mapid)
                }
            }
       },
      validatepicked(qty,picked,mapid){
         if(Number(picked)>Number(qty)){

             this.searched_items.map((item)=>{
                 if(item.mapid==mapid){

                   item.picked=qty
                     return item
                 }
                 else {
                     return item
                 }
             })

         }

        },

        async get_item_stock(id){
            const {data} = await apiService.get(`/item-stock/${id}`);
            return data

        },


       async addsearchitem(dummy='', itemcode=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.searched_items).length==0) {
                this.add_searched_items(newitem)
            }
            else if(itemcode) {
                const   item=this.items.find((i) => i.itemCode==itemcode)
                //console.log(item,'item')

                if(item){
                    this.fetching_item_stock=true
                  item.item_stock = await this.get_item_stock(item.id)
                    this.fetching_item_stock=false
                    //  console.log(item[0])
                    if(this.invoice.refund!=1){

                        if(Number(item.box_qty )>0){
                            item.qty=item.box_qty
                        }
                    }




                    // if(this.selected_customer.cusDiscount){
                    //   // console.log(item,"==============default==================")
                    //
                    //     item.original_price=cloneDeep(item.defaultPrice)
                    //     item.defaultPrice= (cloneDeep(item.defaultPrice) - Number(item.defaultPrice)*(Number(this.selected_customer.cusDiscount)/100)).toFixed(2)
                    //    // item.cusDiscount=this.selected_customer.cusDiscount
                    //    // console.log(item,"==============default==================")
                    // }
                    if(this.selected_customer.customerType=='Trade'){
                        item.original_price=cloneDeep(item.defaultPrice)
                        item.defaultPrice=cloneDeep(item.trade_card_price)
                        item.discount=((Number(item.original_price)-Number(item.trade_card_price))*(Number(item.qty))).toFixed(2)
                    }
                    this.add_searched_items(item)
                    this.update_invoice('subTotal', this.subTotal)
                    this.update_invoice('grandTotal', Number(this.subTotal)+Number((this.subTotal*0.2).toFixed(2)))
                    this.update_invoice('vat', (this.subTotal*0.2).toFixed(2))
                    const   dummyitem=this.searched_items.filter((i) => i.qty=='')
                   this.removeitem(dummyitem[0].mapid)
                    // console.log(dummyitem,'dummyitem')

                    // console.log('searched_item', this.searched_items)
                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                }
            }
          //  console.log(this.searched_items)

        },
        removeitem(id){
             // alert('ok')
        //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removeSearchedItem(id)
            this.update_invoice('subTotal', this.subTotal)
            this.update_invoice('grandTotal', Number(this.subTotal)+Number((this.subTotal*0.2).toFixed(2)))

            this.update_invoice('vat', (this.subTotal*0.2).toFixed(2))
        },

      async  update_cus_address(){
          const apiToken = localStorage.getItem('token');
          if (apiToken) {
              // Set the Authorization header with the API token
              axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
          }
          let address = {
              addressLine1: this.invoice.deliveryAddress1,
              addressLine2: this.invoice.deliveryAddress2,
              postcode: this.invoice.deliveryPostcode
          };

          try {
              const response = await axios.put(`${URL.BASEURL}/api/update-customer/${this.selected_customer.id}`, address);
              console.log(response.data); // Handle the response as needed
              if (response.data){
                  await this.getCustomers();
                  this.selectedCus(response.data.customerCode,'yes');
                  Swal.fire('Success', 'Customer address updated successfully!', 'success');

              }
          } catch (error) {
              console.error(error);
          }
        },
      async  addnewnote(){
            const fcurrentDate = moment().format('YYYY-MM-DD HH:mm:ss');
            this.newnote.cashier_id=this.selectedCashier.id,
            this.newnote.cashier_name=this.selectedCashier.fullName,
            this.newnote.date=fcurrentDate
             this.add_new_note(this.newnote)
          await this.save_invoice()
        },

     async save_invoice(status=''){


             // if(this.invoice.refunded_orders){
             //     if(this.invoice.refunded_orders.length ){
             //         Swal.fire('Info!', 'Cannot Update, Order is locked because it has Refund!', 'info');
             //         return
             //     }
             // }

     let fixed_items= this.searched_items.map((i)=>{
             return {
                 ...i, itemDescription:i.itemDescription.trim()
             }
         })

         this.refillInvoiceSearcheditems(fixed_items);








             this.saving=true
           //  console.log(this.invoice.orginal_items,'org')
             this.showspinner=true;
         const apiToken = localStorage.getItem('token');

            let customer= await this.savenew()

         this.update_invoice('customer_id', customer.id)
         if(this.authenticatedUser.permissions.includes('super')){
          //
         }
         else {
             this.update_invoice('branch_id', customer.branch_id)
         }

         this.update_invoice('cashier_id', customer.cashier_id)
         this.update_invoice('deliveryAddress1', customer.addressLine1)
         this.update_invoice('deliveryAddress2', customer.addressLine2)
         this.update_invoice('deliveryPostcode', customer.postcode)
         this.update_invoice('cusMobile', customer.mobile)


   // status management start

         if(this.invoice.refund !=1){


            // alert(this.noremaining)
             if(status !='Quote' && this.invoice.status !='Deposit' &&  ! this.noremaining){

                 if(this.invoice.deliveryOption==3){

                     status='Pending Delivery'
                 }
                 else if(this.invoice.deliveryOption==2){

                     status='Pending Collection'
                 }
                 else if(this.invoice.deliveryOption==1){

                     status='Pending Collection'
                 }

                 this.update_invoice('status', status)
             }
             else{

                 status=this.invoice.status
             }


             if (! (Number(this.payment.payment_amount) > 0) && ! this.invoice.payments.length) {


                 this.update_invoice('status', 'Quote')


             } else {


                 if(((Number(this.due)).toFixed(2)-Number(this.totalentered).toFixed(2))!=0)
                 {

                     this.update_invoice('status', 'Deposit')
                 }
                 else {


                     ///start === adding for if some how noremaining in miscalculated
                     if(this.invoice.deliveryOption==3){

                         status='Pending Delivery'
                     }
                     else if(this.invoice.deliveryOption==2){

                         status='Pending Collection'
                     }
                     else if(this.invoice.deliveryOption==1){

                         status='Pending Collection'
                     }

                     ///end === adding for if some how noremaining in miscalculated


                     this.update_invoice('status', status)
                 }


             }

             if(this.invoice.status!='Quote' && this.invoice.status!='Deposit' && this.noremaining){
              if(Number(this.invoice.grandTotal).toFixed(2)==Number(this.invoice.totalPaid).toFixed(2)){
                  status='Completed'
              }

                 this.update_invoice('status', status)
             }
         }
         else {

           // status for refund
             if ( Number(this.payment.payment_amount) == 0) {

                 if( ! this.invoice.payments.length){
                     this.update_invoice('status', 'Quote')
                 }


             } else if( this.invoice.status!='Refund Collected') {

                 if(((Number(this.due)).toFixed(2)-Number(this.totalentered).toFixed(2))!=0){


                     this.update_invoice('status', 'Deposit Refund')
                 }
                 else {
                         this.update_invoice('status', 'Uncollected Collection')
                 }


             }
         }
   // status management end





         let currentDate = new Date().toLocaleString('en-US', {timeZone: 'Europe/London'});
         if (this.invoice.issueDate !== "") {
             currentDate = new Date(this.invoice.issueDate).toLocaleString('en-US', {timeZone: 'Europe/London'});
         }
         const fcurrentDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.update_invoice('issueDate', fcurrentDate)
            this.update_invoice('dueDate', fcurrentDate)
           //  this.saveinvoice()
       //  await this.getItems();
         //   console.log(this.searched_items)
         //   console.log(this.invoice)
         // let sinvoice={...this.invoice}
         //
         // let sitems= [...this.searched_items]
         // console.log(sinvoice,'s')
         // console.log(sitems,'s')

         let invoice=cloneDeep(this.invoice)
          // console.log(this.totalPaymentAmount,'this.totalPaymentAmount')
         invoice={...invoice,"totalPaid":Number(Number(this.totalentered)+Number(this.totalPaymentAmount)).toFixed(2)}

         let auto_location;
            if(this.invoice.deliveryOption==2 || this.invoice.deliveryOption==3){
                auto_location=this.invoice.pickup_location_id
            }
         // if(this.invoice.deliveryOption==3){
         //     auto_location=this.invoice.delivery_location_id
         // }
         const invoice_data={
                'invoice':invoice,
                'items':this.searched_items,
                'notes':this.added_notes,
                 'auto_location':auto_location
            }
          //   console.log(invoice_data,'invoicedata')
       //  const apiToken = localStorage.getItem('token');
         if (apiToken) {
             // Set the Authorization header with the API token
             axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
         }

         try {

             const res = await axios.post(`${URL.BASEURL}/api/create-invoice`, invoice_data);
           //  console.log(res);
             // await this.latestinvoice();
                   let invoice=res.data
             let load_order=res.data
                   let dispatchingitems=cloneDeep(res.data.items)

                let disitems =dispatchingitems.map((d)=>{

                 return { ...d, qty: 0, itemDescription:d.pivot.itemDescription};
                  })
                 this.add_dis_items(disitems)
             if(invoice.transactions){

                 this.attach_invoice_transactions(invoice.transactions)
             }





             if (! Number(this.payment.payment_amount)> 0) {
               //  alert(this.payment.payment_amount)
                 invoice.payments=this.invoice.payments

             }

             this.updateFullInvoice(invoice);

             this.update_invoice('invoice_ref', res.data.invoice_ref);
           //  await this.getCustomers();

             if (res.data.status !== 'Quote') {
              //   await Swal.fire('Invoice Created Successfully!', `Invoice Reference: ${res.data.invoice_ref}`, 'success');
             } else if (res.data.status === 'Quote' ) {
              //   await Swal.fire('Invoice Saved for later Successfully!', `Invoice Reference: ${res.data.invoice_ref}`, 'success');
             }

             if ((Number(this.payment.payment_amount) > 0 || Number(this.payment.payment_amount) < 0) && this.invoice.refund!=1) {

               await  this.save_payment()

             }
             if (Number(this.payment.payment_amount) < 0 && this.invoice.refund==1) {

                 await  this.save_payment()

             }

            // dispatch management start
             if(this.invoice.refund!=1 && this.invoice.deliveryOption==1){
               //  alert(this.noremaining)
               //  console.log(this.invoice,'========================invoice')
               //   if(! this.noremaining ){
               //       if(this.invoice.grandTotal==this.invoice.totalPaid){
               //           this.update_invoice('status', 'Pending Collection')
               //       }
               //
               //       axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
               //       await axios.post(`${URL.BASEURL}/api/update-invoice-status`, {id:this.invoice.id, 'status':'Pending Collection'});
                // }
                if(this.invoice.refunded_orders){

                   if(this.invoice.refunded_orders.length){
                    //   alert('here')
                   } else {
                       if(this.invoice.status !='Deposit' && this.invoice.status !='Quote' &&  this.transactionshistory.length==0){
                           const dispactch_all = await Swal.fire({
                               title: 'Do you want to Dispatch All Auto?',
                               showDenyButton: true,
                               showCancelButton: false,
                               confirmButtonText: 'Yes',
                               denyButtonText: 'No',
                           });

                           if (dispactch_all.isConfirmed) {

        //                        const { value: location_id } = await Swal.fire({
        //                            title: 'Select a Location',
        //                            html: `
        //   <div>
        //     <select id="locationSelect" class="swal2-input">
        //       <option value="">Select One</option>
        //       ${this.locations
        //                                .map(
        //                                    (location) =>
        //                                        `<option value="${location.id}">${location.location_name}</option>`
        //                                )
        //                                .join('')}
        //     </select>
        //   </div>
        // `,
        //                            showCancelButton: true,
        //                            confirmButtonText: 'OK',
        //                            cancelButtonText: 'Cancel',
        //                            preConfirm: () => {
        //                                // Retrieve the selected location_id
        //                                const selectedLocationId = document.getElementById('locationSelect').value;
        //                                return selectedLocationId;
        //                            },
        //                        });
                               //   console.log('Selected Location ID:', location_id);
                               if(this.authenticatedUser.permissions.includes('super')){
                                   this.location_id=this.locations.find((location)=>location.branch_id==this.invoice.branch_id).id
                               }
                               else {
                                   this.location_id=this.locations.find((location)=>location.branch_id==this.authenticatedUser.branch_id).id
                               }


                               if(this.location_id){
                                   let dispatchingitems_auto=cloneDeep(res.data.items)
                                   let disitems_auto =dispatchingitems_auto.map((d)=>{

                                       return { ...d, qty: Number(d.pivot.quantity) - Number(d.pivot.picked), itemDescription:d.pivot.itemDescription};
                                   })
                                   this.add_dis_items(disitems_auto)
                                   await  this.save_transaction('Dispatch_All')
                               }
                           } else if (dispactch_all.isDenied) {
                               // this.update_invoice('status', 'Pending Collection')
                               // axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                               // await axios.post(`${URL.BASEURL}/api/update-invoice-status`, {id:this.invoice.id, 'status':'Pending Collection'});


                               // await this.save_invoice()
                           }
                       }
                   }
                } else {
                    // alert(this.invoice.refunded_orders)

                    if(this.invoice.status !='Deposit' && this.invoice.status !='Quote' &&  this.transactionshistory.length==0){
                        const dispactch_all = await Swal.fire({
                            title: 'Do you want to Dispatch All Auto?',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Yes',
                            denyButtonText: 'No',
                        });

                        if (dispactch_all.isConfirmed) {

        //                     const { value: location_id } = await Swal.fire({
        //                         title: 'Select a Location',
        //                         html: `
        //   <div>
        //     <select id="locationSelect" class="swal2-input">
        //       <option value="">Select One</option>
        //       ${this.locations
        //                             .map(
        //                                 (location) =>
        //                                     `<option value="${location.id}">${location.location_name}</option>`
        //                             )
        //                             .join('')}
        //     </select>
        //   </div>
        // `,
        //                         showCancelButton: true,
        //                         confirmButtonText: 'OK',
        //                         cancelButtonText: 'Cancel',
        //                         preConfirm: () => {
        //                             // Retrieve the selected location_id
        //                             const selectedLocationId = document.getElementById('locationSelect').value;
        //                             return selectedLocationId;
        //                         },
        //                     });
                            //   console.log('Selected Location ID:', location_id);
                            if(this.authenticatedUser.permissions.includes('super')){
                                this.location_id=this.locations.find((location)=>location.branch_id==this.invoice.branch_id).id
                            }
                            else {
                                this.location_id=this.locations.find((location)=>location.branch_id==this.authenticatedUser.branch_id).id
                            }

                            if(this.location_id){
                                let dispatchingitems_auto=cloneDeep(res.data.items)
                                let disitems_auto =dispatchingitems_auto.map((d)=>{

                                    return { ...d, qty: Number(d.pivot.quantity) - Number(d.pivot.picked), itemDescription:d.pivot.itemDescription};
                                })
                                this.add_dis_items(disitems_auto)
                                await  this.save_transaction('Dispatch_All')
                            }
                        } else if (dispactch_all.isDenied) {
                            this.update_invoice('status', 'Pending Collection')
                            axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                            await axios.post(`${URL.BASEURL}/api/update-invoice-status`, {id:this.invoice.id, 'status':'Pending Collection'});


                            // await this.save_invoice()
                        }
                    }
                }
             }
             else if(this.invoice.refund==1 && this.invoice.deliveryOption==1) {
                 //console.log(this.invoice.original_items, 'org_items')
                // dispatch management for refund
                 if(this.invoice.status !='Deposit Refund' && this.invoice.status !='Quote' && ! this.noremaining){
                     const dispactch_all = await Swal.fire({
                         title: 'Do you want to Collect All Auto?',
                         showDenyButton: true,
                         showCancelButton: true,
                         confirmButtonText: 'Yes',
                         denyButtonText: 'No',
                     });

                     if (dispactch_all.isConfirmed) {

                         const { value: location_id } = await Swal.fire({
                             title: 'Select a Location',
                             html: `
          <div>
            <select id="locationSelect" class="swal2-input">
              <option value="">Select One</option>
              ${this.locations
                                 .map(
                                     (location) =>
                                         `<option value="${location.id}">${location.location_name}</option>`
                                 )
                                 .join('')}
            </select>
          </div>
        `,
                             showCancelButton: true,
                             confirmButtonText: 'OK',
                             cancelButtonText: 'Cancel',
                             preConfirm: () => {
                                 // Retrieve the selected location_id
                                 const selectedLocationId = document.getElementById('locationSelect').value;
                                 return selectedLocationId;
                             },
                         });
                         //   console.log('Selected Location ID:', location_id);
                         this.location_id=location_id

                         if(this.location_id){
                             let dispatchingitems_auto=cloneDeep(res.data.items)
                             let disitems_auto =dispatchingitems_auto.map((d)=>{

                                 return { ...d, qty:  Number(d.pivot.quantity)+Number((this.invoice.orginal_items.filter(oi=>oi.itemCode===d.pivot.itemCode && oi.itemDescription ===d.pivot.itemDescription))[0].picked)-Number(d.pivot.picked), itemDescription:d.pivot.itemDescription};
                             })
                             this.add_dis_items(disitems_auto)
                             await  this.save_transaction('Dispatch_All')

                         }



                     } else if (dispactch_all.isDenied) {
                         // Swal.fire('Changes are not saved', '', 'info');
                     }
                 }
             }
            // dispatch management end

             Swal.fire('Success!', 'Saved Successfully!', 'success');

             // await Promise.all([
             //     this.getCustomers(),
             //     this.getItems()
             // ]);
                 this.getCustomers()
                 this.getItems()
             this.showspinner=false;
             this.disableradio()
             this.saving=false


            // this.newpos()
            // this.$router.push({ path: '/orders' })

             this.loadorder(load_order,false)

             this.have_changes=false

         } catch (error) {
             console.error(error);
             this.update_invoice('status', 'Quote');
             Swal.fire('Error', 'Failed to add invoice.', 'info');
         }




     },
        async   save_payment(){

            this.payment.invoice_ref=this.invoice.invoice_ref
            this.payment.invoice_id=this.invoice.id
            this.payment.cashier_id=this.invoice.cashier_id
            this.payment.customer_id=this.selected_customer.id



              const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/create-payment`, this.payment);

                if (res) {
                  //  Swal.fire('Success!', 'Payment Saved Successfully!', 'success');
                   //  console.log(res.data,'before payments')

                    // let payments=this.invoice.payments
                    // if(payments){
                    //     payments = [ ...payments, res.data[0] ] ;
                    // }
                    // else {
                    //     payments=res.data
                    // }
                    let payments=res.data

                    this.update_invoice('payments', payments);
                    if(this.payment.payment_type.includes('Credit')){
                       // alert(Number(this.payment.payment_amount))
                      //  this.selected_customer.walletBalance-=this.payment.payment_amount
                      this.set_selectedcustomer_balance(-Number(this.payment.payment_amount))
                        console.log(this.selected_customer.walletBalance,'balance')
                        //console.log(this.cus_balance)
                        if(this.invoice.refund==0){
                            this.paymenttypes=[
                                'Cash',
                                'Card',
                                'Bank',
                                'Pay By Link',
                                `Credit(${this.cus_balance})`
                            ]
                        }
                        else {
                            this.paymenttypes=['Refund Cash','Refund Card','Refund Bank' ,'Refund Credit']
                        }



                    }
                    this.setNewpayment(cloneDeep(newpayment));


                    //  console.log(this.invoice.payments,'after payments')
                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to add invoice.', 'info');
            }


        },
        async latestinvoice(){
            const {data} = await apiService.get(`/latestinvoice`);
            const currentDate = new Date().toLocaleString('en-US', {timeZone: 'Europe/London'});
            const currentYear = currentDate.getFullYear();
            let id=1
            if(data){
              id=  data.id+1
            }
            let ref = 'INV-'+currentYear+'-'+(id)
           this.update_invoice('invoice_ref', ref)
            const fcurrentDate = moment().format('YYYY-MM-DD HH:mm:ss');
            this.update_invoice('issueDate', fcurrentDate)
            this.update_invoice('dueDate', fcurrentDate)
            console.log(data,currentYear, 'latestinvoice')
        },

        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },
        async draft_collection(){
            let branch_id;
            if(this.authenticatedUser.permissions.includes('super')){
            //    cbranch_id=this.invoice.branch_id
            }
            else {
                branch_id=this.authenticatedUser.branch.id
                const {data} = await apiService.get(`/draft_collection/${branch_id}`);
                console.log('draft_collection', data)
            }

        },
        newpos() {
            if (!this.have_changes) {
                this.resetInvoiceData();
                return;
            }

            Swal.fire({
                title: 'Are you sure?',
                text: 'You may lose unsaved data!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, leave',
                cancelButtonText: 'No, stay',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.resetInvoiceData();
                }
            });
        },



        resetInvoiceData() {
            let newinv = cloneDeep(newinvoice);
            this.updateFullInvoice(newinv);
            this.attachinvoiceitems([]);
            this.attachinvoicenotes([]);
            // this.selectedCus('0123456789','yes');
            this.$store.commit('customers/setSelectedCustomer', cloneDeep(newcustomer));
            this.addsearchitem();
            this.searchcustomer = '';
            this.setNewpayment(cloneDeep(newpayment));
            this.add_dis_items([]);
            this.attach_invoice_transactions([]);
            this.newtransaction.id = '';
            this.newtransaction.invoice_id = '';
            this.newtransaction.cashier_id = '';
            this.newtransaction.picked_by = '';
            this.newtransaction.mode = '';
            this.disradio = false;
            this.notesmodalShow = false;
            this.newposclicked = true;

            this.paymenttypes = [
                'Cash',
                'Card',
                'Bank',
                'Pay By Link',
                `Credit(${this.cus_balance})`
            ];
        },

        saveitem() {

            $(document).on('change keyup keydown focus','#swal-input3', function() {
                let val= $(this).val().toUpperCase()
                $(this).val(val)
            });
            Swal.fire({
                title: 'Add Item',
               html: `
          <div class="row">
          <input id="swal-input1"  class="swal2-input col-md-4" placeholder="Item Name">

          <input id="swal-input2" class="swal2-input col-md-4" placeholder="Description">
          </div>
         <div class="row">
          <input id="swal-input3" class="swal2-input col-md-4" placeholder="Item Code"> <br>

          <input id="swal-input4" class="swal2-input col-md-4" type="number" placeholder="Default Price">
          </div>
          <label for="swal-input5 ">Status:</label>
          <input id="swal-input5" class="" type="checkbox">
        `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: 'Add',
                cancelButtonText: 'Cancel',
                preConfirm: () => {
                    this.itemName = Swal.getPopup().querySelector('#swal-input1').value;
                    this.itemDescription = Swal.getPopup().querySelector('#swal-input2').value;
                    this.itemCode = Swal.getPopup().querySelector('#swal-input3').value;
                    this.defaultPrice = Number(Swal.getPopup().querySelector('#swal-input4').value);
                    this.status = Swal.getPopup().querySelector('#swal-input5').checked;
                },



            }).then((result) => {
                if (result.isConfirmed) {
                    const itemData = {
                        itemName: this.itemName,
                        itemDescription: this.itemDescription,
                        itemCode: this.itemCode,
                        defaultPrice: this.defaultPrice,
                        status: this.status,
                    };

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }

                    axios.post(`${URL.BASEURL}/api/add-item`, itemData)
                        .then(async (res) => {
                            console.log(res)
                            Swal.fire('Success', 'Item added successfully!', 'success');
                           await  this.getItems();
                            let idata = res.data;
                            let datawithqty = { ...idata, qty: 1 };
                            this.add_searched_items(datawithqty)
                            this.update_invoice('subTotal', this.subTotal)
                            this.update_invoice('grandTotal', Number(this.subTotal)+Number((this.subTotal*0.2).toFixed(2)))
                            this.update_invoice('vat', this.subTotal*0.2)

                            this.resetForm();
                        })
                        .catch((error) => {
                            console.error(error);
                            Swal.fire('Error', 'Failed to add item.', 'error');
                        });
                }
            });
        },

        resetForm() {
            this.itemName = '';
            this.itemDescription = '';
            this.itemCode = '';
            this.defaultPrice = 0;
            this.status = true;
        },


        savecustomer() {
            const cashiers = this.cashiers;
            const coptions = cashiers.map(cashier => `<option value="${cashier.id}">${cashier.fullName}</option>`);
            const branches = this.branches;
            const boptions = branches.map(branch => {
                if(this.authenticatedUser.branch.id===branch.id){
                    return `<option value="${branch.id}" selected>${branch.name}</option>`
                }
                else {
                    return `<option value="${branch.id}" >${branch.name}</option>`
                }
            });
            $(document).on('change keyup keydown focus','#swal-input106', function() {
                let val= $(this).val()
                $('#swal-input115').val(val)
            });


            Swal.fire({
                title: 'Add Customer',
                width:600,
                html: `


      <div class="row">


    <select id="swal-input100" class="swal2-input col-md-4" disabled>
      ${boptions.join('')}
       </select>

       <select id="swal-input101" class="swal2-input col-md-4">
      ${coptions.join('')}
       </select>


      </div>
   <div class="row">
      <input id="swal-input102" class="swal2-input col-md-4" placeholder="Title">

      <input id="swal-input103" class="swal2-input col-md-4" placeholder="First Name" required>
      </div>
 <div class="row">
      <input id="swal-input104" class="swal2-input col-md-4" placeholder="Last Name" required>


            <input id="swal-input106" class="swal2-input col-md-4" placeholder="Mobile" required>
      </div>
 <div class="row ">



  <input id="swal-input115" class="swal2-input col-md-4" placeholder="Customer Code" disabled>
    <input id="swal-input107" class="swal2-input col-md-4  no-gutters" placeholder="Company Name">

      </div>
 <div class="row">


           <input id="swal-input109" type="number" class="swal2-input col-md-4" placeholder="Discount Type">

             <select id="swal-input108" class="swal2-input col-md-4">
           <option value="" >Customer Type</option>
          <option value="Retail" >Retail</option>
          <option value="Trade" >Trade</option>
         </select>
      </div>
 <div class="row">
      <input id="swal-input110" class="swal2-input col-md-4" placeholder="Address Line 1">

      <input id="swal-input111" class="swal2-input col-md-4" placeholder="Address Line 2">
      </div>
      <div class="row">

       <input id="swal-input112" class="swal2-input col-md-4" placeholder="Postcode">
      <input id="swal-input113" class="swal2-input col-md-4 " placeholder="Notes">
      </div>
         <div class="row ">


      <input id="swal-input117" class="swal2-input col-md-4 " placeholder="Email" required>
       <input id="swal-input118" class="swal2-input col-md-4 " placeholder="Contact Person" >
      </div>
      <label for="swal-input114" style="visibility: hidden">Status:</label>
      <input id="swal-input114" class="" style="visibility: hidden" type="checkbox" checked>

    `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: 'Add',
                cancelButtonText: 'Cancel',
                preConfirm: () => {
                    // Retrieve customer data from the form
                    const branchId = Swal.getPopup().querySelector('#swal-input100').value;
                    const cashierId = Swal.getPopup().querySelector('#swal-input101').value;
                    const title = Swal.getPopup().querySelector('#swal-input102').value;
                    const firstName = Swal.getPopup().querySelector('#swal-input103').value;
                    const lastName = Swal.getPopup().querySelector('#swal-input104').value;
                    const customerCode = Swal.getPopup().querySelector('#swal-input106').value;
                    const mobile = Swal.getPopup().querySelector('#swal-input106').value;
                    const companyName = Swal.getPopup().querySelector('#swal-input107').value;
                    const customerType = Swal.getPopup().querySelector('#swal-input108').value;
                    const discountType = Swal.getPopup().querySelector('#swal-input109').value;
                    const addressLine1 = Swal.getPopup().querySelector('#swal-input110').value;
                    const addressLine2 = Swal.getPopup().querySelector('#swal-input111').value;
                    const postcode = Swal.getPopup().querySelector('#swal-input112').value;
                    const email = Swal.getPopup().querySelector('#swal-input117').value;
                    const contactPerson = Swal.getPopup().querySelector('#swal-input118').value;
                    const notes = Swal.getPopup().querySelector('#swal-input113').value;
                    const status2 = Swal.getPopup().querySelector('#swal-input114').checked;
                    if (!mobile) {
                        Swal.showValidationMessage('Please enter a mobile number');
                    }
                    // if (!email) {
                    //     Swal.showValidationMessage('Please enter a valid email');
                    // }
                    if (!firstName) {
                        Swal.showValidationMessage('Please enter first name');
                    }
                    if (!lastName) {
                        Swal.showValidationMessage('Please enter last name');
                    }
                    // Assign the customer data to the state
                    this.branchId = branchId;
                    this.cashierId = cashierId;
                    this.title = title;
                    this.firstName = firstName;
                    this.lastName = lastName;
                    this.customerCode = customerCode;
                    this.mobile = mobile;
                    this.companyName = companyName;
                    this.customerType = customerType;
                    this.discountType = discountType;
                    this.addressLine1 = addressLine1;
                    this.addressLine2 = addressLine2;
                    this.postcode = postcode;
                    this.notes = notes;
                    this.email=email;
                    this.contactPerson=contactPerson;
                    this.status2 = status2;

                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const customerData = {
                        branch_id: this.branchId,
                        cashier_id: this.cashierId,
                        title: this.title,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        customerCode: this.customerCode,
                        mobile: this.mobile,
                        companyName: this.companyName,
                        customerType: this.customerType,
                        discountType: this.discountType,
                        addressLine1: this.addressLine1,
                        addressLine2: this.addressLine2,
                        postcode: this.postcode,
                        email:this.email,
                        contactPerson:this.contactPerson,
                        notes: this.notes,
                        status: this.status2,
                    };
                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }

                    axios.post(`${URL.BASEURL}/api/create-customer`, customerData)
                        .then(async (res) => {
                            console.log(res)
                            Swal.fire('Success', 'Customer added successfully!', 'success');
                            await  this.getCustomers();
                            let cdata = res.data;

                            this.selectedCus(cdata.customerCode,'yes');
                            this.resetForm2();
                        })
                        .catch((error) => {
                            console.error(error);
                            Swal.fire('Error', 'Failed to add customer.', 'error');
                        });
                }
            });
        },
        resetForm2() {
            this.branchId = '';
            this.cashierId = '';
            this.title = '';
            this.firstName = '';
            this.lastName = '';
            this.customerCode = '';
            this.mobile = '';
            this.companyName = '';
            this.customerType = '';
            this.discountType = '';
            this.addressLine1 = '';
            this.postcode = '';
            this.notes = '';
            this.status2 = true;
        },


        updateCustomer() {
            const cashiers = this.cashiers;
            const options = cashiers.map(cashier =>
                 {
                if(this.selected_customer.cashier_id==cashier.id){
                    return `<option value="${cashier.id}" selected>${cashier.fullName}</option>`
                }
                else {
                    return `<option value="${cashier.id}" >${cashier.fullName}</option>`
                }
                 });
            const branches = this.branches;
            const boptions = branches.map(branch => {
                if(this.authenticatedUser.branch.id===branch.id){
                    return `<option value="${branch.id}" selected>${branch.name}</option>`
                }
                else {
                    return `<option value="${branch.id}" >${branch.name}</option>`
                }
            });
            const ctypes=[{'id':'Retail'},{'id':'Trade'}]
            const coptions = ctypes.map(ctype => {
                if(ctype.id==this.selected_customer.customerType){
                    return `<option value="${ctype.id}" selected>${ctype.id}</option>`
                }
                else {
                    return `<option value="${ctype.id}" >${ctype.id}</option>`
                }
            });

            // $(document).on('change keyup keydown focus','#swal-input106', function() {
            //     let val= $(this).val()
            //     $('#swal-input115').val(val)
            // });

            Swal.fire({
                title: 'Update Customer',
                html: `

      <div class="row">
        <div class="col-md-6">
          <label for="swal-input100" class="col-form-label ">Branch:  </label>
         <select id="swal-input100" class="swal2-input col-md-4" disabled>
          ${boptions.join('')}
           </select>

        </div>
     <div class="col-md-6 ">
          <label for="swal-input101" class="col-form-label ">Cashier:   </label>

            <select id="swal-input101" class="swal2-input ">
           ${options.join('')}
       </select>

        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label for="swal-input102" class="col-form-label">Title: <span class="" style="visibility: hidden">cust</span></label>
            <input id="swal-input102" class="swal2-input" placeholder="Title" value="${this.selected_customer.title !=null ? this.selected_customer.title:''}">
        </div>

      </div>

      <div class="row">
        <div class="col-6">
          <label for="swal-input104" class="col-form-label">Full Name:</label>
          <input id="swal-input104" class="swal2-input" placeholder="Last Name" value="${this.selected_customer.lastName}" required>
        </div>
          <div class="col-md-6">
          <label for="swal-input106" class="col-form-label">Mobile:</label>
             <input id="swal-input106" class="swal2-input" placeholder="Mobile" value="${this.selected_customer.mobile !=null ? this.selected_customer.mobile:'' }" required>
        </div>

      </div>

      <div class="row">

  <div class="col-md-6">
           <label for="swal-input115" class="col-form-label">Cust. Code:</label>
            <input id="swal-input115" class="swal2-input" placeholder="Customer Code" value="${this.selected_customer.customerCode}" disabled>
           </div>
          <div class="col-md-6">
          <label for="swal-input107" class="col-form-label">Comp. Name:</label>
           <input id="swal-input107" class="swal2-input" placeholder="Company Name" value="${this.selected_customer.companyName !=null ? this.selected_customer.companyName:''}">
        </div>

      </div>

      <div class="row">

           <div class="col-md-6">
          <label for="swal-input109" class="col-form-label">Discount Type:</label>
               <input type="number" id="swal-input109" class="swal2-input" placeholder="Discount Type" value="${this.selected_customer.discountType}">
        </div>

             <div class="col-md-6">
          <span  class="">Cust. Type:</span>

                 <select id="swal-input108" class="swal2-input col-md-4">
                  ${coptions.join('')}
                 </select>
        </div>


      </div>

      <div class="row">
        <div class="col-md-6">
          <label for="swal-input110" class="col-form-label">Address Line 1:</label>
          <input id="swal-input110" class="swal2-input" placeholder="Address Line 1" value="${this.selected_customer.addressLine1 !=null  ? this.selected_customer.addressLine1:''}">
        </div>
     <div class="col-md-6">
          <label for="swal-input111" class="col-form-label">Address Line 2:</label>
             <input id="swal-input111" class="swal2-input" placeholder="Address Line 2" value="${this.selected_customer.addressLine2 !=null ? this.selected_customer.addressLine2:''}">
        </div>
      </div>

      <div class="row">

           <div class="col-md-6">
           <label for="swal-input112" class="col-form-label">Postcode:</label>
            <input id="swal-input112" class="swal2-input" placeholder="Postcode" value="${this.selected_customer.postcode !=null ? this.selected_customer.postcode:''}">
           </div>
           <div class="col-md-6">
          <label for="swal-input113" class="col-form-label" >Notes:</label>
            <input id="swal-input113" class="swal2-input "  placeholder="Notes" value="${this.selected_customer.notes !=null ? this.selected_customer.notes:''}">
          </div>
      </div>
        <div class="row">

           <div class="col-md-6">
           <label for="swal-input117" class="col-form-label">Email:</label>
            <input id="swal-input117" class="swal2-input" placeholder="Email" value="${this.selected_customer.email !=null ? this.selected_customer.email:''}" required>
           </div>
           <div class="col-md-6">
          <label for="swal-input118" class="col-form-label" >Cont. Person:</label>
            <input id="swal-input118" class="swal2-input "  placeholder="Contact Person" value="${this.selected_customer.contactPerson !=null  ? this.selected_customer.contactPerson:''}">
          </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="swal-input114" class="col-form-label">Status:</label>
             <input id="swal-input114" class="" type="checkbox" ${this.selected_customer.status ? 'checked' : ''}>
        </div>

      </div>
    `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: 'Update',
                cancelButtonText: 'Cancel',
                width: '900px',
                background:'#b9d1b6',
                preConfirm: () => {
                    // Retrieve updated customer data from the form
                    const branchId = Swal.getPopup().querySelector('#swal-input100').value;
                    const cashierId = Swal.getPopup().querySelector('#swal-input101').value;
                    const title = Swal.getPopup().querySelector('#swal-input102').value;

                    const lastName = Swal.getPopup().querySelector('#swal-input104').value;

                    const mobile = Swal.getPopup().querySelector('#swal-input106').value;
                    const companyName = Swal.getPopup().querySelector('#swal-input107').value;
                    const customerType = Swal.getPopup().querySelector('#swal-input108').value;
                    const discountType = Swal.getPopup().querySelector('#swal-input109').value;
                    const addressLine1 = Swal.getPopup().querySelector('#swal-input110').value;
                    const addressLine2 = Swal.getPopup().querySelector('#swal-input111').value;
                    const postcode = Swal.getPopup().querySelector('#swal-input112').value;
                    const notes = Swal.getPopup().querySelector('#swal-input113').value;
                    const email = Swal.getPopup().querySelector('#swal-input117').value;
                    const contactPerson = Swal.getPopup().querySelector('#swal-input118').value;
                    const status2 = Swal.getPopup().querySelector('#swal-input114').checked;
                    if (!mobile) {
                        Swal.showValidationMessage('Please enter a mobile number');
                    }
                    // if (!email) {
                    //     Swal.showValidationMessage('Please enter a valid email');
                    // }
                    // if (!firstName) {
                    //     Swal.showValidationMessage('Please enter first name');
                    // }
                    if (!lastName) {
                        Swal.showValidationMessage('Please enter last name');
                    }

                    // Assign the updated customer data to the state
                    this.branchId = branchId;
                    this.cashierId = cashierId;
                    this.title = title;

                    this.lastName = lastName;
                    this.customerCode = mobile;
                    this.mobile = mobile;
                    this.companyName = companyName;
                    this.customerType = customerType;
                    this.discountType = discountType;
                    this.addressLine1 = addressLine1;
                    this.addressLine2 = addressLine2;
                    this.postcode = postcode;
                    this.email = email;
                    this.contactPerson = contactPerson;
                    this.notes = notes;
                    this.status2 = status2;
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const customerData = {
                        branch_id: this.branchId,
                        cashier_id: this.cashierId,
                        title: this.title,

                        lastName: this.lastName,
                        customerCode: this.customerCode,
                        mobile: this.mobile,
                        companyName: this.companyName,
                        customerType: this.customerType,
                        discountType: this.discountType,
                        addressLine1: this.addressLine1,
                        addressLine2: this.addressLine2,
                        postcode: this.postcode,
                        email: this.email,
                        contactPerson: this.contactPerson,
                        notes: this.notes,
                        status: this.status2,
                    };

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }

                    axios
                        .put(`${URL.BASEURL}/api/update-customer/${this.selected_customer.id}`, customerData)
                        .then(async (res) => {
                            await this.getCustomers();
                            this.selectedCus(res.data.customerCode,'yes');
                            console.log(res);
                            Swal.fire('Success', 'Customer updated successfully!', 'success');


                        })
                        .catch((error) => {
                            console.error(error);
                            Swal.fire('Error', 'Failed to update customer.', 'error');
                        });
                }
            });
        },

        // savenew(){
        //     const customerData = {
        //         branch_id: this.authenticatedUser.branch.id,
        //         lastName: this.selected_customer.lastName,
        //         customerCode: this.selected_customer.customerCode,
        //         customerType: 'Retail',
        //
        //     };
        //     const apiToken = localStorage.getItem('token');
        //     if (apiToken) {
        //         // Set the Authorization header with the API token
        //         axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
        //     }
        //
        //     axios.post(`${this.posapi}/api/create-customer`, customerData)
        //         .then(async (res) => {
        //             console.log(res)
        //             Swal.fire('Success', 'Customer added successfully!', 'success');
        //             await  this.getCustomers();
        //             let cdata = res.data;
        //
        //             this.selectedCus(cdata.customerCode,'yes');
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //             Swal.fire('Error', 'Failed to add customer.', 'error');
        //         });
        // }

        async savenew() {
            let cbranch_id;
            if(this.authenticatedUser.permissions.includes('super')){
                   cbranch_id=this.invoice.branch_id
            }
            else {
                cbranch_id=this.authenticatedUser.branch.id
            }
            const customerData = {
                id:this.selected_customer.id,

                branch_id:cbranch_id ,
                lastName: this.selected_customer.lastName,
                mobile:this.selected_customer.mobile,
                addressLine1: this.selected_customer.addressLine1,
                addressLine2: this.selected_customer.addressLine2,
                postcode: this.selected_customer.postcode,
                cashier_id:this.selected_customer.cashier_id,
                customerType: this.selected_customer.customerType=='Trade'?'Trade':'Retail',


            };

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/create-customer`, customerData);
                console.log(res);
                this.$store.commit('customers/setSelectedCustomer', res.data)
                return res.data

              //  Swal.fire('Success', 'Customer added successfully!', 'success');
              //  await this.getCustomers();
             //   let cdata = res.data;
              //  this.selectedCus(cdata.customerCode, 'yes');
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to add customer.', 'error');
              //  throw new Error("customer not created")

            }
        },



    }

}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';



.comment-send-button:hover {
    background-color: #2851A3;
}

.make_red{
    border-bottom: 3px solid red;
}
.make_green{
    border-bottom: 3px solid #06f606;
}

.comment-send-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #D24294;
}

.comment-send-button:active {
    background-color: #1D3C78;
}


.swal-wide{
    width:850px !important;
}

.hide{
    visibility: hidden !important;
}
.larger-modal .modal-dialog {
    max-width: 800px;
}

.h6,h6,p {
    font-size: 1.2rem !important;
}


.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}


.bform select::placeholder {
    color: white !important;
}



.facebook-comments {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.comment-avatar {
    width: 40px;
    height: 40px;
    background-color: rgb(246 246 246);
    border-radius: 50%;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.comment-content {
    flex-grow: 1;
}

.comment-author {
    font-weight: bold;
}

.comment-date {
    font-size: 12px;
    color: #888;
}

.comment-text {
    margin-top: -2px;
}

.dark-datepicker {


    color: black !important;

}

.disable-page{
    pointer-events: none;
    cursor: not-allowed;
}

.unsaved-dot {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
}


</style>


