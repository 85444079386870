export default {
    id:0,

    name: "",
     description: "",

    qty:0,
    price:0,

}
