import apiService from "../../service/apiService";
import newcustomer from "@/constants/newcustomer";
import {cloneDeep} from "lodash";

export default {
    namespaced: true,
    state: () => ({
        allcategories: [],
        allcolors:[],
        allsizes:[],
        alltypes:[],
        allmaterials:[],
        allfinishes:[],
        pagecats:[],

        cfilters:{
            searchcatname:'',

            supplier_id:'',

        }



    }),
    mutations: {

        setAllcategories(state, data) {
            state.allcategories = data

        },
        setAllcolors(state, data) {
            state.allcolors = data

        },
        setAllsizes(state, data) {
            state.allsizes = data

        },
        setAlltypes(state, data) {
            state.alltypes = data

        },
        setAllmaterials(state, data) {
            state.allmaterials = data

        },
        setAllfinishes(state, data) {
            state.allfinishes = data

        },

        setpagecats(state, data) {
            state.pagecats = data

        },




    },
    actions: {


        set_page_cats({commit},item){
            commit('setpagecats', item)

        },
        async getAllcategories({commit}) {



            let {data} = await apiService.get(`/allcategories`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

            //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setAllcategories', data)




        },
        async getAllcolors({commit}) {



            let {data} = await apiService.get(`/allcolors`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

            //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setAllcolors', data)




        },
        async getAllsizes({commit}) {



            let {data} = await apiService.get(`/allsizes`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

            //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setAllsizes', data)




        },
        async getAlltypes({commit}) {



            let {data} = await apiService.get(`/alltypes`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

            //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setAlltypes', data)




        },
        async getAllmaterials({commit}) {



            let {data} = await apiService.get(`/allmaterials`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

            //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setAllmaterials', data)




        },
        async getAllfinishes({commit}) {



            let {data} = await apiService.get(`/allfinishes`);
            // console.log(data,'cus_b')

            // console.log(branch_id,'cus_bn')

            //  data=data.filter(c=>c.branch_id==branch_id)
            // console.log(data,'cus_bn2')
            commit('setAllfinishes', data)




        },




    },
    getters: {}
}
