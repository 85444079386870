<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row  mt-2">

                    <b-link class="" :to="{ path: '/stock-adjustment',query: { type: 'Add'} }">
                        <b-button class="btn-info mb-2" > Create Adjustment </b-button>
                    </b-link>

                    <div class="col-md-1 ">
                        <b-input v-model="adjustmentfilters.id"
                                 @input="()=>{
                                 this.adjustmentfilters.page=1;
                                 this.gethistory()
                              }"
                                 placeholder="ID"></b-input>
                    </div>
                    <div class="col-md-2">
                                           <b-form-datepicker
                                               :reset-button=true
                                               reset-button-variant="btn btn-warning"

                                               id="example-datepicker"
                                               :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                               v-model="adjustmentfilters.date"
                                               placeholder="Date"
                                               dropdown  calendar-width="100%"
                                               @input="()=>{
                                                     this.adjustmentfilters.page=1;
                                                     this.gethistory()
                                                  }"
                                           ></b-form-datepicker>
                    </div>

                    <div class="col-md-2 text-light">
                        <v-select
                            v-model="adjustmentfilters.location_id"
                            @input="()=>{
                                 this.adjustmentfilters.page=1;
                                 this.gethistory()
                              }"
                            class=""
                            placeholder="Location"
                            :options="locations"
                            label="location_name"
                            :disabled="!authenticatedUser.permissions.includes('super')"
                            :reduce="option => option.id"

                        />
                    </div>
                    <div class="col-md-2">
                        <v-select
                            v-model="adjustmentfilters.reason"
                            @input="()=>{
                                 this.adjustmentfilters.page=1;
                                 this.gethistory()
                              }"
                            class=""
                            placeholder="Reason"
                            :options="['Opening', 'Damage', 'Lost','Consumed','Other','Stock Check']"

                        />
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="adjustmentfilters.note"
                                 @input="()=>{
                                 this.adjustmentfilters.page=1;
                                 this.gethistory()
                              }"
                                 placeholder="Note"></b-input>
                    </div>
                    <div class="col-md-2">
                        <v-select
                            v-model="adjustmentfilters.status"
                            @input="()=>{
                                 this.adjustmentfilters.page=1;
                                 this.gethistory()
                              }"
                            class=""
                            placeholder="Status"
                            :options="['Pending','Approved']"

                        />
                    </div>





                </div>
                <div class="row mt-2" >
                    <div class="col-md-2">
                        <!--                        <button class="btn-sm btn-info" @click="getinvoices">-->
                        <!--                            <span v-if="showloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
                        <!--                            Refresh-->
                        <!--                        </button>-->
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-1">
                        <button class="btn-sm btn-info"     :disabled="history?.current_page==1" @click="get_paged_data('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2" v-if="!showloader" style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="history?.to-50>0">{{history?.to-50}}</span> <span v-else="">0</span>  to {{history.to}}) out of {{history.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "    :disabled="history?.current_page==totalPages" @click="get_paged_data('next')">Next</button>
                    </div>
                    <div class="col-md-2 ">

                    </div>

                    <!--                    <div class="col-md-2 text-right">-->
                    <!--                        <button class="btn-sm btn-warning"    @click="clearfilters" >Clear Filters</button>-->
                    <!--                    </div>-->

                </div>
                <div class="row mt-1">
                    <div v-if="showloader" class="col-md-10 offset-1 text-center">
                        <loader/>
                    </div>
                </div>
                <div class="row mt-2">
                    <table class="table table-bordered table-striped text-light">
                        <thead class="thead-dark">
                        <tr>
                            <th class="col-md-1">ID</th>
                            <th class="col-md-1">Date</th>
                            <th class="col-md-1">User</th>
                            <th class="col-md-3">Location</th>
                            <th class="col-md-2">Reason</th>
                            <th class="col-md-2">Note</th>
                            <th class="col-md-1">status</th>
                            <th class="col-md-1">Action</th>
                        </tr>
                        </thead>
                        <tbody id="adjustmentsTableBody">
                        <tr v-for="his in history.data" :key="his.id">

                            <td> <a href="#" @click.prevent="showmodaldata(his.id)">{{his.id}}</a> </td>
                            <td>{{formatDate(his.date)}}</td>
                            <td>{{users.find(cs=>cs.id==his.user_id)?.first_name }} {{users.find(cs=>cs.id==his.user_id).last_name }}</td>

                            <td>{{locations.find(lc=>lc.id==his.location_id).location_name}}</td>
                            <td>{{his.adjustment_reason}}</td>
                            <td>{{his.note}}</td>
                            <td>{{his.status}}</td>
                            <td>
                                <button class="btn btn-success" v-if="his.status=='Pending' && authenticatedUser.permissions.includes('super') " @click="approve_adjust_stock(his.id)" :disabled="approving_adjustment">
                                    Approve
                                </button>
                                <button class="btn btn-warning ml-1 mr-1 mt-1" v-if="his.status=='Draft' && !authenticatedUser.permissions.includes('super')" @click="submit_adjustment(his.id)">
                                    Submit
                                </button>

                                <b-link class=""  v-if="his.status=='Draft' || his.status=='Pending'" class="" :to="{ path: '/stock-adjustment',query: { type: 'Update', 'id': his.id } }">
                                    <b-button class="btn-info ml-1" > Edit </b-button>
                                </b-link>

                                <button class="btn btn-danger ml-1 mt-1" v-if="his.status=='Pending' || his.status=='Draft'" @click="delete_adjust_stock(his.id)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <b-modal  v-model="modalShow"  centered  >
                        <div class="row mt-5">
                            <div class="col-md-12">
                                <h1 class=" text-center">Items Detail</h1>
                            </div>
                            <div class="col-md-12">
                                <table class="table table-hover" >
                                    <thead >

                                    <th class="col-md-1">ID</th>
                                    <th class="col-md-3">Item Code</th>
                                    <!--                                <th class="col-md-3">Description</th>-->
                                    <th class="col-md-3">Quantity</th>


                                    </thead>
                                    <tbody >
                                    <tr v-for="item in selected_history.dispatchitems" :key="item.id">

                                        <td> {{item.id}} </td>
                                        <td>{{item.itemCode}}</td>
                                        <!--                                    <td>{{item.itemDescription}}</td>-->
                                        <td>{{item.qty}}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </b-modal>
                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import vSelect from "vue-select";
import Welcomenote from "@/pages/admin/mypos/welcomenote.vue";
import URL from "@/constants/baseurl";
import Loader from "@/components/loader.vue";
import axios from "axios";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import moment from "moment/moment";
//import vSelect from "vue-select";


export default {
    name: "adjustments",

    components:{Loader, Welcomenote, 'v-select': vSelect,'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('stockadjustments',['adjustmentfilters']),

        // ...mapState('invoice', ['branches']),
        totalPages() {
            const perpage = this.history.per_page;
            const total=this.history.total;

            return Math.ceil(total / perpage);
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },


    },
    data() {
        return {
            users:[],
            modalShow:false,
            selected_history:{},
            fetching_history:false,
            locations:[],
            history:[],
            roles: [],
            user: {},
            user_id:'',
            branch_id:'',
            submitting_adjustment:false,
            approving_adjustment:false,
            showloader:false

        };
    },
    async created() {


        const promises =[
            this.getlocations(),
            this.getUsers(),
            this.gethistory()
        ]

        await Promise.all(promises);

        if(!this.authenticatedUser.permissions.includes('super')){
          this.adjustmentfilters.location_id=this.locations.find((l)=>l.branch_id==this.authenticatedUser.branch_id && l.status==1)
        }

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),


        // ...mapActions('invoice', ['getbranches','getusers']),
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, YYYY');
            //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },
        async  gethistory(){
            this.fetching_history=true;
            this.approving_adjustment=true;


            let user = localStorage.getItem('user');
            user=  JSON.parse(user)
            const apiToken = localStorage.getItem('token');
            let url=`${URL.BASEURL}/api/adjustments`
            let filters ={
                id:this.adjustmentfilters.id,
                date:this.adjustmentfilters.date,
                location_id:this.adjustmentfilters.location_id,
                reason:this.adjustmentfilters.reason,
                note:this.adjustmentfilters.note,
                status:this.adjustmentfilters.status,
                page:this.adjustmentfilters.page,


            };

            this.showloader=true
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify(filters)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const res = await response.json();
                this.history=res

            } catch (error) {
                console.error('Error:', error);
            }
            this.showloader=false

            this.fetching_history=false;
            this.approving_adjustment=false;

            // console.log('history',this.history)
        },
        async approve_adjust_stock(id) {
            // Show confirmation prompt
            const confirmDeletion = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, approve it!',
                cancelButtonText: 'No'
            });

            if (confirmDeletion.isConfirmed) {
                const apiToken = localStorage.getItem('token');
                if (apiToken) {

                    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                }

                try {
                    this.approving_adjustment=true;

                    const res = await axios.post(`${URL.BASEURL}/api/approve-stock-adjustment/${id}`);

                    if (res) {
                        Swal.fire('Success', 'Stock Adjustment Approved', 'success');
                        await this.gethistory();
                        this.approving_adjustment=false;


                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Error', 'Failed to approve stock adjustment.', 'info');
                    this.approving_adjustment=false;
                }
            } else {

                //  Swal.fire('Cancelled', 'Stock adjustment was not deleted', 'error');
            }
        },
        async delete_adjust_stock(id) {
            // Show confirmation prompt
            const confirmDeletion = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            });

            if (confirmDeletion.isConfirmed) {
                const apiToken = localStorage.getItem('token');
                if (apiToken) {

                    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                }

                try {
                    this.approving_adjustment=true;

                    const res = await axios.delete(`${URL.BASEURL}/api/delete-stock-adjustment/${id}`);

                    if (res) {
                        Swal.fire('Success', 'Stock Adjustment Deleted', 'success');
                        await this.gethistory();
                        this.approving_adjustment=false;
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Error', 'Failed to delete stock adjustment.', 'info');
                    this.approving_adjustment=false;
                }
            } else {

                //  Swal.fire('Cancelled', 'Stock adjustment was not deleted', 'error');
            }
        },
        async submit_adjustment(adjustment_id){


            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            let api_url=`${URL.BASEURL}/api/submit-adjustment/${adjustment_id}`;


            try {
                this.fetching_history=true;
                this.submitting_adjustment=true;
                this.approving_adjustment=true;
                const res = await axios.post(api_url);

                if (res) {
                    this.fetching_history=false;
                    this.submitting_adjustment=false;
                    this.approving_adjustment=false;

                    Swal.fire('Success', 'Stock Adjustment Submitted', 'success');



                    await this.gethistory()


                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to Submit Adjustment.', 'info');
                this.fetching_history=false;
                this.submitting_adjustment=false;
                this.approving_adjustment=false;

            }
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },
        showmodaldata(id){
            this.selected_history=this.history.data.find((hs)=>hs.id==id)
            this.modalShow = ! this.modalShow
        },
        async signOut() {
            await this.processLogoutManual();
            // await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.users = data;
        },
        // async getRoles() {
        //     const { data } = await this.$axios.get('role');
        //     this.roles = data.roles;
        // },
        filterUsers(value) {
            this.getUsers(value)
        },

        async get_paged_data(button){
            let current_page= this.history.current_page
            this.adjustmentfilters.page=current_page



            if(button=='next'){

                this.adjustmentfilters.page=current_page+1

                await   this.gethistory()

            }
            if(button=='prev'){
                this.adjustmentfilters.page=current_page-1

                await  this.gethistory()


            }

        },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
