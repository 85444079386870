
export default {
    namespaced: true,
    state: () => ({

        adjustmentfilters:{
            selectedBranch:'',
            id:'',
            date:'',
            location_id:'',
            reason:'',
            note:'',
            status:'',



            page:1,


        }


    }),
    mutations: {



    },
    actions: {





    },
    getters: {}
}
