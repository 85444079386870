<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
              <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">
                           Internal Transfer
                        </h1>
                    </div>

                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Details</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <!--                        <div class="row mt-1">-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <p><strong>Enter Purchase Order Reference</strong></p>-->
                        <!--                            </div>-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <b-input v-model="por"></b-input>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="row ">
                            <div class="col-md-3">
                                <label><strong>Transfer Stock From</strong></label>
                                <b-form-select
                                    v-model="location_id_from"
                                    :options="Trans_from_locations"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="location_name"
                                >

                                    <template #first>
                                        <b-form-select-option value="">From Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Transfer Stock To</strong></label>
                                <b-form-select
                                    v-model="location_id_to"
                                    :options="locations_stock_to"
                                    class="bgsecond bform text-white mb-2 "


                                    value-field="id"
                                    text-field="location_name"
                                >

                                    <template #first>
                                        <b-form-select-option value="">To Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Select Date</strong></label>
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="datepicsuper"

                                    v-model="date"
                                    placeholder="Date"

                                    dropup  calendar-width="100%">

                                </b-form-datepicker>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Order Id</strong></label>
                                <b-input v-model="order_id">

                                </b-input>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
<!--                                <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }">-->
<!--                                    <b-button class="btn-info " > Add New Item </b-button>-->
<!--                                </b-link>-->
                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Items</h1>
                            </div>
                        </div>


                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col" >Qty</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in items" :key="item.mapid" :value="item.itemCode"> {{ item.itemName }}  | {{item.itemDescription}}  </option>

                            </datalist>
                            <template v-if="internaltransfer_searched_items">
                                <tr class="text-light"  v-for="item in internaltransfer_searched_items" :key="item.mapid">
                                    <th class="col-md-3 ">
                                        <div class="input-container w-100">
                                            <i  class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem(item.mapid)" style="margin-right: 0.5rem;"></i>
                                            <input class="bg-light col-md-11 form-control" v-model="item.itemCode"  list="item-list-id"  @change="addsearchitem('',item.itemCode, item.mapid)"  >

                                        </div>
                                    </th>

                                    <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="true"> </b-input></td>
                                    <td class="col-md-2 "><input type="number" v-model="item.qty" @change="handlechange(item.mapid)"  class="bg-light form-control" ></td>



                                </tr>
                            </template>
                            <tr>
                                <th scope="row" >

                                    <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus" @click="addsearchitem('yes')"></i>
                                </th>
                                <td></td>
                                <td></td>




                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6 offset-3">
                        <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesavestock" @click="transfer_stock"> <span v-if="edit">Update Transfer</span> <span v-else="">Transfer Stock</span></button>
                    </div>
                </div>






            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import newitem from "@/constants/newitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','users','branches']),
        ...mapState('items',['items','internaltransfer_searched_items']),

        Trans_from_locations(){
            // may be revert in future
            if(true || this.authenticatedUser.permissions.includes('super')){
                return this.locations
            } else {
                return  this.locations.filter((location)=>location.branch_id==this.authenticatedUser.branch_id)
            }

        },

        locations_stock_to(){
            if(this.authenticatedUser.permissions.includes('super') || this.authenticatedUser.id==21 || this.authenticatedUser.id==7){
                return this.locations
            } else {
                return this.locations.filter((lc)=>[this.locations.find((bl)=>bl.branch_id==this.authenticatedUser.branch_id).id,7,13].includes(lc.id))
            }
        },
        disablesavestock(){
            if(this.approving || this.location_id_from=='' || this.date=='' || this.location_id_to=='' || (this.internaltransfer_searched_items.filter((is)=>is.id!=0)).length==0){
                return true
            } else return false
        },

        showitemdatalist(){
            if(this.internaltransfer_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.internaltransfer_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0){
                return true
            }
            else {
                return false
            }
        },



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            fetching_history:false,
            dispatch_id:'',
            transfer_id:'',
            edit:false,
            modalShow:false,
            selected_history:{},
            history:[],
            roles: [],
            user: {},
            user_id:'',
            branch_id:'',
            searchitem:'',
            locations:[],
            location_id_from:'',
            location_id_to:'',
            order_id:'',
            date:new Date(),
            approving:false

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()
        // await this.getcashiers()

        this.viewType = this.$route.query.type;
        this.transfer_id = this.$route.query.id;

        const p1 =[

            this.getlocations(),

            this.reloadcb(),
            this.setTransfer()


        ]
        await Promise.all(p1);




    },
    async mounted() {

        this.addsearchitem()


    },

    // watch: {
    //     internaltransfer_searched_items: {
    //         handler(newValue) {
    //             // Note: `newValue` will be equal to `oldValue` here
    //             // on nested mutations as long as the object itself
    //             // hasn't been replaced.
    //             //    console.log(newValue,'by watch',oldValue)
    //             let targetobj=  newValue.find((n)=>Number(n.qty)==0)
    //             if (targetobj){
    //                 //  console.log(targetobj.qty,'target qty')
    //                 if(targetobj.id!=0 && targetobj.qty!=''){
    //                     targetobj.qty=targetobj.box_qty
    //                 }
    //
    //
    //             }
    //
    //         },
    //         deep: true
    //     }
    // },

    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getusers']),
        ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removeinternaltransferSearchedItem']),
        ...mapActions('items',['setforedit_internal_transfer_searched_items','add_internal_transfer_searched_items','reset_internal_transfer_searched_items']),
        ...mapActions('dispatch', ['add_selected_dispatch']),

        async  setTransfer(){
            if(this.viewType!=='Add'){

                this.approving=true
                const {data} = await apiService.get(`/get-transfer/${this.transfer_id}`);
                this.edit_transfer(data)
                this.approving=false



            }
        },
        edit_transfer(dispatch){

            this.dispatch_id=dispatch.id
            this.location_id_from=dispatch.location_id_from
            this.location_id_to=dispatch.location_id
            this.date=dispatch.date
            this.order_id=dispatch.order_id
            this.edit=true
            this.setforedit_internal_transfer_searched_items(dispatch.dispatchitems)
        },



        handlechange(mapid) {
            //  alert(mapid);
            let item = this.internaltransfer_searched_items.find((item) => item.mapid == mapid);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(qty==0){
                if(item.id!=0 && item.qty!=''){
                    item.qty=Number(item.box_qty).toFixed(2)
                }
            }
            let validqty = qty / box_qty
           // alert(validqty)
            if (box_qty) {
                if (validqty < 1) {
                    item.qty = Number(item.box_qty).toFixed(2)
                }
                // else if (qty % box_qty == 0) {
                //     //
                // }
                else {
                    console.log(item.qty/item.box_qty,'========')
                    item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                }
        }
            (this.internaltransfer_searched_items.find((item)=>item.qty=='')).qty=1

        },

        reset_items(){
            this.reset_internal_transfer_searched_items()
            this.addsearchitem()
        },
        async transfer_stock(){
            let ledger_entry={
                dispatch_id:this.dispatch_id,
                items:this.internaltransfer_searched_items,
                location_id_from:this.location_id_from,
                location_id_to:this.location_id_to,
                order_id:this.order_id,

                date:moment(this.date).format('YYYY-MM-DD HH:mm:ss'),
                user_id:this.authenticatedUser.id

            }
            console.log(ledger_entry)

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                this.approving=true
                const res = await axios.post(`${URL.BASEURL}/api/transfer-stock`, ledger_entry);
                this.approving=false
                if (res) {

                    Swal.fire('Success', 'Stock Transferred', 'success');
                    console.log(res.data)
                    this.reset_internal_transfer_searched_items()
                    this.addsearchitem()

                    this.location_id_from='';
                    this.location_id_to='';
                    this.order_id='';

                    this.date=new Date();
                    this.$router.push('/intransfers')



                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to Add Stock.', 'info');
            }
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data

            // if(!this.authenticatedUser.permissions.includes('super')){
            //
            //     this.location_id_to= this.locations.find((lc)=>lc.branch_id==this.authenticatedUser.branch_id).id
            // }


        },
        addsearchitem(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.internaltransfer_searched_items).length==0) {
                this.add_internal_transfer_searched_items(newitem)
            }
            else if(itemcode) {
                const   item=this.items.filter((i) => i.itemCode==itemcode)
                console.log(item,'item')
                if(item.length){
                    //  console.log(item[0])
                    this.add_internal_transfer_searched_items(item[0])


                    const   dummyitem=(this.internaltransfer_searched_items).find((i) => i.id==0)
                    console.log(dummyitem)
                    this.removeinternaltransferSearchedItem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

        removeitem(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removeinternaltransferSearchedItem(id)

        },


        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMM, YYYY');
            //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){
                //   alert(this.branches.length)
                await this.getItems();

            }
            if(this.users.length==0){
                await this.getusers()
            }
        },



        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}
.not_received{
    background-color: #c09104;
}



</style>
