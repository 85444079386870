
export default {
    namespaced: true,
    state: () => ({

        inboundfilters:{
            selectedBranch:'',
            id:'',
            date:'',
            location_id:'',
            po_ref:'',

            status:'',



            page:1,


        }


    }),
    mutations: {



    },
    actions: {





    },
    getters: {}
}
