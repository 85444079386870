<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-3 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-2 pb-2 bgsecond">

                <div class="row   text-light">

                    <div class="col-md-12">

                            <div class="row ">
                                <h1 class="ml-1 mt-1">
                                    <span v-if="actiontype=='Save'">Enter</span>
                                    <span v-if="actiontype=='Update'">Update</span>
                                    Product Details  <span v-if="fetching"  class=" h6 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></h1>

                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="firstName" class="form-label">Product Name</label>
                                    <b-form-input v-model="item.itemName" type="text" class="form-control" id="itemName" required :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="title" class="form-label">SKU Code</label>
                                    <b-form-input v-model="item.itemCode" @change="codeavailability" type="text" class="form-control" :state="code_availability"  id="itemCode" :disabled="actiontype=='View' || code_used"> </b-form-input>
                                </div>


                                <div class="col-md-3">
                                    <label for="email" class="form-label">Delivery Note</label>
                                    <b-form-input v-model="item.delivery_note_name" type="text" class="form-control" id="delivery_note_name" :disabled="actiontype=='View'"> </b-form-input>
                                </div>

                            </div>

                            <div class="row mt-3">
                                <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                    <label for="companyName" class="form-label">Cost Price</label>
                                    <b-form-input v-model="item.costPrice" type="number" step="any" class="form-control" id="costPrice" :disabled="actiontype=='View'"> </b-form-input>
                                </div>
                                <div class="col-md-3">
                                    <label for="customerCode" class="form-label">Default Price (£/M2+VAT)</label>
                                    <b-form-input v-model="item.defaultPrice" type="number" step="any" class="form-control" id="defaultPrice" :disabled="actiontype=='View'"> </b-form-input>
                                </div>

                                <div class="col-md-2">
                                    <label for="box_qty" class="form-label">Box Quantity</label>
                                    <b-form-input v-model="item.box_qty" type="number" step="any" class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                                </div>


                                <div class="col-md-1">
                                    <label for="status" class="form-label">Status</label>
                                    <b-form-checkbox v-model="item.status" id="status" :disabled="actiontype=='View'"> Active </b-form-checkbox>
                                </div>

                            </div>
                        <div class="row mt-3">
                            <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                <label for="companyName" class="form-label">Max Discount</label>
                                <b-form-input v-model="item.max_discount" type="text"  class="form-control" id="costPrice" :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label for="customerCode" class="form-label">Trade Card</label>
                                <b-form-input v-model="item.trade_card" type="text"  class="form-control" id="defaultPrice" :disabled="actiontype=='View'"> </b-form-input>
                            </div>

                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Normal/Sale/Clearance</label>
                                <b-form-input v-model="item.normal_sale_clearance" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Min Quantity</label>
                                <b-form-input v-model="item.min_qty" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Default Supplier</label>
                                <b-form-select
                                    v-model="item.def_supplier_id"
                                    :options="suppliers"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="name"
                                >

                                    <template #first>
                                        <b-form-select-option value="" >Select One</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.name }}</div>
                                    </template>

                                </b-form-select>                            </div>
                            <div class="col-md-3">
                                <label for="box_qty" class="form-label">Supplier Sku</label>
                                <b-form-input v-model="item.supplier_sku" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                        </div>

                            <div class="row mt-3">
                                <div class="col-md-9">
                                    <label for="delivery_note_name" class="form-label">Product Description</label>
                                    <b-form-textarea v-model="item.itemDescription" rows="2" class="form-control" id="delivery_note_name" :disabled="actiontype=='View'"></b-form-textarea>
                                </div>

                            </div>

                        <template v-if="authenticatedUser.id==22 && item.product">
                            <div class="row">
                                <h1 class="col-9 "> <hr class="hr0"></h1>
                            </div>
                            <div class="row mt-3" >

                                <div class="col-md-3">
                                    <label  class="form-label">Categories</label>
                                    <v-select
                                        v-model="category_ids"
                                        class="text-dark"
                                        placeholder="Choose Categories"
                                        label="name"
                                        :options="allcategories"
                                        :reduce="option => option.id"
                                        multiple
                                    >
                                        <template v-slot:selection="props">
                                            <div v-for="category in props.selectedOptions" :key="category.id">
                                                {{ category.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                                <div class="col-md-3">
                                    <label  class="form-label">Color</label>

                                    <b-form-select
                                        v-model="item.product.color_id"
                                        :options="allcolors"
                                        class="bgsecond bform text-white mb-2 "

                                        value-field="id"
                                        text-field="name"
                                    >

                                        <template #first>
                                            <b-form-select-option value="" >Select One</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.name }}</div>
                                        </template>

                                    </b-form-select>



                                </div>
                                <div class="col-md-3">
                                    <label  class="form-label">Size</label>


                                    <b-form-select
                                        v-model="item.product.size_id"
                                        :options="allsizes"
                                        class="bgsecond bform text-white mb-2 "

                                        value-field="id"
                                        text-field="title"
                                    >

                                        <template #first>
                                            <b-form-select-option value="" >Select One</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.title }}</div>
                                        </template>

                                    </b-form-select>
                                </div>

                            </div>
                            <div class="row mt-3" >
                                <div class="col-md-3">
                                    <label  class="form-label">Type</label>


                                    <b-form-select
                                        v-model="item.product.type_id"
                                        :options="alltypes"
                                        class="bgsecond bform text-white mb-2 "

                                        value-field="id"
                                        text-field="name"
                                    >

                                        <template #first>
                                            <b-form-select-option value="" >Select One</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.name }}</div>
                                        </template>

                                    </b-form-select>
                                </div>
                                <div class="col-md-3">
                                    <label  class="form-label">Material</label>

                                    <b-form-select
                                        v-model="item.product.material_id"
                                        :options="allmaterials"
                                        class="bgsecond bform text-white mb-2 "

                                        value-field="id"
                                        text-field="name"
                                    >

                                        <template #first>
                                            <b-form-select-option value="" >Select One</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.name }}</div>
                                        </template>

                                    </b-form-select>
                                </div>
                                <div class="col-md-3">
                                    <label  class="form-label">Finish</label>

                                    <b-form-select
                                        v-model="item.product.finish_id"
                                        :options="allfinishes"
                                        class="bgsecond bform text-white mb-2 "

                                        value-field="id"
                                        text-field="name"
                                    >

                                        <template #first>
                                            <b-form-select-option value="" >Select One</b-form-select-option>
                                        </template>
                                        <template #option="{ option }">
                                            <div>{{ option.name }}</div>
                                        </template>

                                    </b-form-select>
                                </div>

                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="hide">ok</label>
                                            <b-form-checkbox v-model="item.product.rectified"   > Rectified </b-form-checkbox>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="hide">ok</label>
                                            <b-form-checkbox v-model="item.product.wall"   > Wall </b-form-checkbox>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="hide">ok</label>
                                            <b-form-checkbox v-model="item.product.floor"   > Floor </b-form-checkbox>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <label for="weight" class="form-label">Weight</label>
                                    <b-form-input v-model="item.product.weight" type="text" class="form-control" id="weight" > </b-form-input>

                                </div>
                                <div class="col-md-3">
                                    <label for="box_pieces" class="form-label">Box Pieces</label>
                                    <b-form-input v-model="item.product.box_pieces" type="text" class="form-control" id="box_pieces" > </b-form-input>

                                </div>


                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <label for="boxes_on_pallet" class="form-label">Boxes On pallet</label>
                                    <b-form-input v-model="item.product.boxes_on_pallet" type="text" class="form-control" id="boxes_on_pallet" > </b-form-input>

                                </div>
                                <div class="col-md-3">
                                    <label for="m2_pallet" class="form-label">m<sup>2</sup> Pallet</label>
                                    <b-form-input v-model="item.product.m2_pallet" type="text" class="form-control" id="m2_pallet" > </b-form-input>

                                </div>
                                <div class="col-md-3">
                                    <label for="weight_pallet" class="form-label">Weight Pallet</label>
                                    <b-form-input v-model="item.product.weight_pallet" type="text" class="form-control" id="weight_pallet" > </b-form-input>

                                </div>
                            </div>
                            <div class="row mt-3">

                                <div class="col-md-9">
<!--                                    <div class="row">-->
<!--                                        <div v-for="img in this.attached_images" :key="img.id" class="col-md-3">-->
<!--                                            <b-img thumbnail fluid :src="img.path" alt="" class="trendingAvatarFit "></b-img>-->
<!--                                            <div class="text-center">-->

<!--                                                <b-button @click="warn(img.id)" variant="danger" size="sm" style="margin-top: -70px" >Remove</b-button>-->
<!--                                            </div>-->
<!--                                        </div>-->

<!--                                    </div>-->
                                </div>


                            </div>
                            <div class="row mt-3">
                                <div class="col-md-9">
                                    <b-form-group class="col-span-4" label="Attach Images" v-if="item.product.id">
                                        <vue-dropzone
                                            ref="myDropzone"
                                            id="dropzone"
                                            :options="dropzoneOptions"
                                            @vdropzone-success="fileUploaded"
                                            @vdropzone-removed-file="fileDeleted"
                                            @vdropzone-error="fileUploadError"
                                            @vdropzone-mounted="onDropzoneMounted"
                                        />

                                    </b-form-group>
                                    <b-form-group v-else>
                                        <vue-dropzone
                                            ref="productDropzone"
                                            id="dropzone"

                                            :options="adddropzoneOptions"


                                            v-on:vdropzone-error-multiple="showErrors"
                                            v-on:vdropzone-success-multiple="showSuccess"
                                            :useCustomSlot="true"
                                        >
                                        </vue-dropzone>
                                    </b-form-group>

                                </div>

                            </div>
                            <div class="row mt-3" >
                                <div class="col-md-9">

                                    <div class="row" >
                                        <h1 class="col-12 "> <hr class="hr0"></h1>
                                        <h1 class="col-12 text-center " >Rigt Tiles(RTO)</h1>
                                        <div class="col-md-4 mt-2">
                                            <label for="box_qty" class="form-label">Website Sku</label>
                                            <b-form-input v-model="item.product.rto_sku" type="text" @change="productcodeavailability('rto_sku')" :state="rto_sku_availability"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>

                                        </div>
                                        <div class="col-md-4 mt-2">
                                            <label for="box_qty" class="form-label">Website Name</label>
                                            <b-form-input v-model="item.product.rto_name" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                                        </div>
                                        <div class="col-md-4 mt-2">
                                            <label for="box_qty" class="form-label"> Discount</label>
                                            <b-form-input v-model="item.product.rto_onlinediscount" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                                        </div>
                                        <div class="col-md-4 mt-2">
                                            <label for="box_qty" class="form-label"> Was</label>
                                            <b-form-input v-model="item.product.rto_was" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                                        </div>
                                        <div class="col-md-4 mt-2">
                                            <label for="box_qty" class="form-label"> Price</label>
                                            <b-form-input v-model="item.product.rto_price" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>
                                        </div>
                                        <div class="col-md-4 mt-2">
                                            <label for="rto_website_status" class="form-label  mt-3">  Status</label>
                                            <b-form-checkbox v-model="item.product.rto_active"  id="rto_website_status" :disabled="actiontype=='View'"> Active </b-form-checkbox>
                                        </div>


                                    </div>

<!--                                    <div class="row" >-->
<!--                                        <h1 class="col-12 "> <hr class="hr0"></h1>-->
<!--                                        <h1 class="col-12 text-center " >Order Tiles(OTO)</h1>-->
<!--                                        <div class="col-md-4 mt-2">-->
<!--                                            <label for="box_qty" class="form-label">Website Sku</label>-->
<!--                                            <b-form-input v-model="item.product.oto_sku" type="text" @change="productcodeavailability('oto_sku')" :state="oto_sku_availability"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 mt-2">-->
<!--                                            <label for="box_qty" class="form-label">Website Name</label>-->
<!--                                            <b-form-input v-model="item.product.oto_name" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 mt-2">-->
<!--                                            <label for="box_qty" class="form-label"> Discount</label>-->
<!--                                            <b-form-input v-model="item.product.oto_onlinediscount" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 mt-2">-->
<!--                                            <label for="box_qty" class="form-label"> Was</label>-->
<!--                                            <b-form-input v-model="item.product.oto_was" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 mt-2">-->
<!--                                            <label for="box_qty" class="form-label"> Price</label>-->
<!--                                            <b-form-input v-model="item.product.oto_price" type="text"  class="form-control" id="box_qty" :disabled="actiontype=='View'"> </b-form-input>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 mt-2">-->
<!--                                            <label for="oto_website_status" class="form-label  mt-3">  Status</label>-->
<!--                                            <b-form-checkbox v-model="item.product.oto_active"  id="oto_website_status" :disabled="actiontype=='View'"> Active </b-form-checkbox>-->
<!--                                        </div>-->


<!--                                    </div>-->


                                </div>

                            </div>
                        </template>




                            <button v-if="actiontype!='View'" type="submit" class="btn btn-primary mt-3" @click="saveProduct" :disabled="makedis"> {{actiontype}} Product</button>



                    </div>

                </div>
                <div class="row" v-if="actiontype=='View'">
                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                  <div class="col-md-3 mx-2">
                      <table class="table text-light mt-5" >
                          <thead >

                          <th class="col-md-3">Stock Level</th>
                          <th class="col-md-3">Actual</th>
                          <th class="col-md-3">Forecast</th>
                          </thead>
                          <tbody >
                          <tr >
                              <td >Isleworth Warehouse</td>
                              <td >{{Number(item.IsleworthWarehouseStock).toFixed(2)}}</td>
                              <td >{{Number(item.IsleworthWarehouseAfterOpen).toFixed(2)}}</td>
                          </tr>
<!--                          <tr >-->
<!--                              <td >Isleworth Shop Floor </td>-->
<!--                              <td >{{item.IsleworthShopFloorStock}}</td>-->
<!--                              <td >{{item.IsleworthShopFloorAfterOpen}}</td>-->
<!--                          </tr>-->
                          <tr >
                              <td >Bounds Green </td>
                              <td >{{Number(item.BoundsGreenStock).toFixed(2)}}</td>
                              <td >{{Number(item.BoundsGreenAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Hanworth</td>
                              <td >{{Number(item.HanworthStock).toFixed(2)}}</td>
                              <td >{{Number(item.HanworthAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Gatwick </td>
                              <td >{{Number(item.GatwickStock).toFixed(2)}}</td>
                              <td >{{Number(item.GatwickAfterOpen).toFixed(2)}}</td>
                          </tr>
<!--                          <tr >-->
<!--                              <td >Farn borough Shop</td>-->
<!--                              <td >{{item.FarnboroughShopStock}}</td>-->
<!--                              <td >{{item.FarnboroughShopAfterOpen}}</td>-->
<!--                          </tr>-->
                          <tr >
                              <td >Farnborough Warehouse</td>
                              <td >{{Number(item.FarnboroughWarehouseStock).toFixed(2)}}</td>
                              <td >{{Number(item.FarnboroughWarehouseAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Hays </td>
                              <td >{{Number(item.HayesStock).toFixed(2)}}</td>
                              <td >{{Number(item.HayesAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Wembley </td>
                              <td >{{Number(item.WembleyStock).toFixed(2)}}</td>
                              <td >{{Number(item.WembleyAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Watford </td>
                              <td >{{Number(item.WatfordStock).toFixed(2)}}</td>
                              <td >{{Number(item.WatfordAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Croydon </td>
                              <td >{{Number(item.CroydonStock).toFixed(2)}}</td>
                              <td >{{Number(item.CroydonAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Online </td>
                              <td >{{Number(item.OnlineStock).toFixed(2)}}</td>
                              <td >{{Number(item.OnlineAfterOpen).toFixed(2)}}</td>
                          </tr>
                          <tr >
                              <td >Wholesale </td>
                              <td >{{Number(item.WholesaleStock).toFixed(2)}}</td>
                              <td >{{Number(item.WholesaleAfterOpen).toFixed(2)}}</td>
                          </tr>

                          </tbody>
                      </table>
                  </div>

                </div>

                <div class="row" v-if="actiontype=='View'">
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Ledger History</h1>
                        <table class="table text-light">
                            <thead >

                            <th>Branch In</th>
                            <th>Branch Out</th>
                            <th>Mode</th>
                            <th>Date</th>
                            <th>Ref</th>
                            <th>Qty</th>
                            </thead>
                            <tbody>
                            <tr v-for="led in item.ledger_history" :key="led.id">
                                <td > <span v-if="Number(led.quantity)>0"> {{led.location.location_name}}</span>   </td>
                                <td>  <span v-if="Number(led.quantity)<0"> {{led.location.location_name}}</span>  </td>
                                <td>  {{led.mode}}  </td>
                                <td>  {{formatDate(led.date_time)}}  </td>
                                <td> <span  v-if="led.dispatch_item"> <span v-if="led.dispatch_item.dispatch"><span v-if="led.dispatch_item.dispatch.purchase_order_ref">Po:{{led.dispatch_item.dispatch.purchase_order_ref}}</span> <span v-if="led.dispatch_item.dispatch.invoice_id">OrderId:{{led.dispatch_item.dispatch.invoice_id}}</span></span></span> </td>
                                <td>  {{led.quantity}}  </td>


                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>



            </div>



        </div>

    </div>

</template>

<script>
import apiService from "@/service/apiService";
import {mapActions, mapState} from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import axios from "axios";
import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import showrealtime from "@/components/showrealtime";
import URL from "@/constants/baseurl"
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from "moment";
//import newinvoice from "@/constants/newinvoice";
//import newcustomer from "@/constants/newcustomer";
//import newpayment from "@/constants/newpayment";
//import newitem from "@/constants/newitem";
import vSelect from "vue-select";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";



export default {
    name: "customerform",

    components:{Welcomenote, 'sidebar': sidebar, vueDropzone: vue2Dropzone, 'v-select': vSelect,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers']),
        ...mapState('customers', ['customers']),
        ...mapState('products', ['allcategories','allcolors','allsizes','alltypes','allmaterials','allfinishes']),
        ...mapState('items', ['pageitems']),

        dropzoneOptions() {
            return {
                url: this.item?.product?.id
                    ? `${URL.BASEURL}/api/product_image_upload/${this.item.product.id}`
                    : ``,
                thumbnailWidth: 150,
                maxFilesize: 5, // In MB
                acceptedFiles: "image/*",
                addRemoveLinks: this.actiontype !== 'View',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                clickable: this.actiontype !== 'View'
            };
        },
        adddropzoneOptions() {
            return {
                url:`${this.product_image_upload_url}`,
                thumbnailWidth: 150,
                maxFilesize: 5, // In MB
                acceptedFiles: "image/*",

                uploadMultiple: true,
                autoProcessQueue: false,
                parallelUploads: 4,
                addRemoveLinks: this.actiontype !== 'View',

                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                clickable: this.actiontype !== 'View'
            };
        },
        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

        makedis(){
            if(this.code_availability && ! this.item.itemCode=="" && ! this.item.itemName==""){
                return false
            }
            else  return true
        },

        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        }

    },
    data() {
        return {

            fetching:false,
            uploadedImages:[],

            password:'',

            category_ids: [],
            product_image_upload_url:'/',
            color_id:'',
            size_id:'',
            material_id:'',
            type_id:'',
            finish_id:'',

            suppliers:[],
            code_availability:true,
            rto_sku_availability:true,
            oto_sku_availability:true,

            code_used:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            item: {
                id:"",
                itemName: "",
                itemCode: "",
                itemDescription: "",
                defaultPrice: "",
                status: true,
                costPrice: "",
                delivery_note_name: "",
                box_qty: "",
                max_discount: "",
                trade_card: "",
                normal_sale_clearance: "",
                def_supplier_id:"",
                min_qty:"",
                supplier_sku:""

            },

            attached_images: {},

            errors: {},




        };
    },
    async created() {
     //   await this.getUsers();
     //    await this.getRoles();

        // await this.getcashiers()
        this.viewType = this.$route.query.type;
        this.item_id = this.$route.query.id;

      this.fetching=true;
        const p1=[
            this.setItem(),
            this.getsuppliers(),
        ]
        await Promise.all(p1);
        this.fetching=false;
        this.item.status= this.item.status==1 ? true:false

        if (this.item.product) {
            // If the product exists, update rto_active and category_ids
            this.item.product.floor = this.item.product.floor == 1 ? true : false;
            this.item.product.wall = this.item.product.wall == 1 ? true : false;
            this.item.product.rectified = this.item.product.rectified == 1 ? true : false;
            this.item.product.rto_active = this.item.product.rto_active == 1 ? true : false;
            this.category_ids = cloneDeep(this.item.product.categories).map((c) => c.id);

        } else {

            // Handle the case where there is no product
            this.item.product = {
                rto_name:'',
                rto_onlinediscount:'',
                rto_was:'',
                rto_price:'',
                rto_active:'',
                rto_sku:'',
                color_id:'',
                size_id:'',
                type_id:'',
                material_id:'',
                finish_id:'',
                rectified:false,
                wall:false,
                floor:false,
                weight:'',
                box_pieces:'',
                boxes_on_pallet:'',
                m2_pallet:'',
                weight_pallet:'',

            };  // Or handle as needed
            this.category_ids = [];   // Reset category_ids if no product
        }
        // await this.getsuppliers()
      await  this.code_is_used()
        const promises =[


            this.getAllcategories(),
            this.getAllcolors(),
            this.getAllsizes(),
            this.getAlltypes(),
            this.getAllmaterials(),
            this.getAllfinishes(),

        ]

        await Promise.all(promises);


    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['updateFullInvoice']),
      //  ...mapActions('payment', ['setNewpayment']),
        ...mapActions('items',['add_searched_items','getAllItems']),
        ...mapActions('products',['getAllcategories','getAllcolors','getAllsizes','getAlltypes','getAllmaterials','getAllfinishes']),
       // ...mapActions('payment', ['setNewpayment']),

        showErrors(files, message, xhr) {
            this.errors = message.errors;
        },
        showSuccess(files, res) {
            let swalOptions = {
                title: "Success!",
                icon: "success",
                confirmButtonText: "Show All Products",
            };
            if (this.actiontype === "add" || this.actiontype === "edit")
                swalOptions = {
                    ...swalOptions,
                    confirmButtonText: "Show All Products",
                    showCancelButton: false,
                    // cancelButtonText: "Create a new one",
                };
            this.$swal.fire(swalOptions).then((res) => {
                if (res.isConfirmed) {
                    window.location = "/products";
                }
                // if (res.isDismissed) {
                //     this.product = {...product};
                //     this.$refs.productDropzone.removeAllFiles();
                // }
            });
        },

        onDropzoneMounted() {
            console.log("Dropzone initialized!");
            const imgElement = document.querySelector('.dz-upload');
            if (imgElement) {

                imgElement.style.backgroundColor = "green !important";
                console.log(imgElement,'imgElement')

            }
            this.loadUploadedImages();
        },
        loadUploadedImages() {
            try {
                if (!this.$refs.myDropzone || !this.$refs.myDropzone.dropzone) {
                    console.warn("Dropzone is not initialized yet.");
                    return;
                }

                this.uploadedImages = this.item?.product?.images || [];

                this.uploadedImages.forEach((image) => {
                    const mockFile = {
                        name: image.name,
                        size: 12345, // Dummy size
                        serverPath: image.path,
                        id: image.id,
                    };

                    let dropzone = this.$refs.myDropzone.dropzone;
                    dropzone.emit("addedfile", mockFile);
                    dropzone.emit("thumbnail", mockFile, image.path);
                    dropzone.emit("complete", mockFile);

                    const imgElement = document.querySelector(`.dz-image img[src="${image.path}"]`);
                    if (imgElement) {
                        imgElement.style.width = "150px";
                        imgElement.style.height = "150px";
                        imgElement.style.objectFit = "contain";
                    }
                });
            } catch (error) {
                console.error("Error loading images:", error);
            }
        },


        fileUploaded(file, response) {
            console.log("File uploaded successfully", response);
            console.log(file, 'file')
            this.uploadedImages.push({
                file,
                serverPath: response.image.path,
                id:response.image.id
            });


            let image=response.image;

            const mockFile = {
                name: image.name,
                size: file.size,
                serverPath: image.path,
                id: image.id,
            };

            let dropzone = this.$refs.myDropzone.dropzone;

            dropzone.files.forEach((fileInDropzone) => {
                if (fileInDropzone.id === undefined) {
                    dropzone.removeFile(fileInDropzone);
                }
            });

            dropzone.emit("addedfile", mockFile);
            dropzone.emit("thumbnail", mockFile, image.path);
            dropzone.emit("complete", mockFile);

            const imgElement = document.querySelector(`.dz-image img[src="${image.path}"]`);
            if (imgElement) {
                imgElement.style.width = "150px";
                imgElement.style.height = "150px";
                imgElement.style.objectFit = "contain";
            }
        },
        async fileDeleted(file) {
        console.log(file,'file')
            // Find the corresponding file on the server
            const fileEntry = this.uploadedImages.find(f => f.id === file.id);
            if (!fileEntry) return;


            try {
                const response = await axios.post(`${URL.BASEURL}/api/product_image_delete`, {
                    product_id:this.item.product.id,
                    image_id:file.id
                });

                if (response.data.success==true) {
                    console.log("File deleted successfully");
                    this.uploadedImages = this.uploadedImages.filter(f => f.id !== file.id);
                } else {
                    // console.error("Error deleting file");
                }
            } catch (error) {
                console.error("File delete failed", error);
            }
        },
        fileUploadError(file, errorMessage) {
            console.error("File upload failed", errorMessage);
        },

        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, YYYY, hh:mm A');

            return date2;
        },
        async codeavailability(){
            let code=this.item.itemCode
                const {data} = await apiService.get(`/item_code_availability/${code}`);
             //   console.log('code_availability', data.code_availability)
             //   alert(data.code_availability)
            this.code_availability=data.code_availability
        },
        async productcodeavailability(column){
            let code=this.item.product.rto_sku
            const {data} = await apiService.get(`/product_code_availability/${column}/${code}`);

            this[column + '_availability'] = data.code_availability;
        },

        async code_is_used(){

            const {data} = await apiService.get(`/code_used/${this.item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            this.code_used=data.code_used
        },
        // async product_code_is_used(column){
        //
        //     const {data} = await apiService.get(`/code_used/${column}/${this.item_id}`);
        //     //   console.log('code_availability', data.code_availability)
        //     //   alert(data.code_availability)
        //     this.code_used=data.code_used
        // },


        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
          //  console.log('suppliers', data)
            this.suppliers=data
        },



        async saveProduct(files) {



            console.log(files,'files')

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
           // let productdata=cloneDeep(this.customer)
            let productdata=cloneDeep(this.item)
            productdata.product.category_ids=this.category_ids

            console.log(productdata,'productdata')

            // return
            // if(this.suppliers.find((s)=>s.id==productdata.def_supplier_id)){
            //     productdata.supplier_sku=this.suppliers.find((s)=>s.id==productdata.def_supplier_id).sku
            // }


            // let cbranch_id;
            // if(this.authenticatedUser.permissions.includes('super')){
            //     cbranch_id=this.customer.branch_id
            // }
            // else {
            //     cbranch_id=this.authenticatedUser.branch.id
            // }

           // productdata.branch_id=cbranch_id
            try {
                const response = await axios.post(`${URL.BASEURL}/api/add-item`,  productdata);
                console.log("Product saved:", response.data);


                if(this.actiontype=='Save'){
                    if(this.authenticatedUser.id==22 && item.product) {
                        if (this.$refs.productDropzone && this.$refs.productDropzone.getQueuedFiles() && this.$refs.productDropzone.getQueuedFiles().length > 0) {

                            this.product_image_upload_url = `${URL.BASEURL}/api/product_multi_image_upload/${response.data.product.id}`

                            if (this.$refs.productDropzone && this.$refs.productDropzone.dropzone) {
                                this.$refs.productDropzone.dropzone.options.url = this.product_image_upload_url;

                                this.$refs.productDropzone.dropzone.on("queuecomplete", async () => {
                                    await this.getAllItems();
                                    await Swal.fire("Success", "Product and images uploaded successfully!", "success");
                                    this.$router.push({path: '/products'})
                                });

                                this.$refs.productDropzone.processQueue();
                            } else {
                                await this.getAllItems();
                                await Swal.fire('Success', 'Product Updated successfully!', 'success');
                                this.$router.push({path: '/products'})
                            }


                        }
                    } else {
                        await this.getAllItems();
                        await Swal.fire('Success', 'Product Saved successfully!', 'success');
                        this.$router.push({path: '/products'})
                    }

                } else {
                    await this.getAllItems();
                    await Swal.fire('Success', 'Product Updated successfully!', 'success');
                    this.$router.push({ path: '/products' })
                }





                // this.customer = {
                //     id:"",
                //     branch_id: "",
                //     cashier_id: "",
                //     title: "",
                //     firstName: "",
                //     lastName: "",
                //     customerCode: "",
                //     mobile: "",
                //     companyName: "",
                //     customerType: "",
                //     discountType: 1,
                //     addressLine1: "",
                //     addressLine2: "",
                //     postcode: "",
                //     email: "",
                //     notes: "",
                //     status: true
                // };
            } catch (error) {
                console.error("Error saving product:", error);
            }
        },
     async  setItem(){
            if(this.viewType!=='add'){

                // this.fetching=true;
                // this.item=cloneDeep(this.pageitems).find(c=>c.id==this.item_id)
                const {data} = await apiService.get(`/get-item/${this.item_id}`);
                // this.fetching=false;
                this.item=data;



            }
        }
    }
};
</script>



<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid #ffffff;
    max-width: 100%;

}
.trendingAvatarFit{
    width: 100%;
    height: 320px !important;
    object-fit: cover !important;
    object-position: center !important;
    max-width: 100%;
    max-height: 100%;
}

.dz-image {
    width: 100px !important;
    height: 100px !important;
    object-fit: contain;
}




</style>
