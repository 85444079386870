<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut">Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">
                <div class="row mb-2">
                    <h1 class="col-md-12">Download Csv </h1>
                </div>

                <div class="row ">
                    <div class="col-md-2  ">


                        <b-form-select
                            v-model="location_name"
                            :options="locations"


                            value-field="location_name"
                            text-field="location_name"

                        >

                            <template #first>
                                <b-form-select-option value="">Select Location</b-form-select-option>
                            </template>
                            <template #option="{ option }">
                                <div>{{ option.fullName }}</div>
                            </template>

                        </b-form-select>

                    </div>
                 <div class="col-md-1">

                     <button class="btn btn-info" :class="{ 'disabled': loading }" @click="downloadCSV" :disabled="loading">
                         <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                         <span >Download</span>
                     </button>
                 </div>

                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import apiService from "@/service/apiService";
import * as XLSX from 'xlsx';
import axios from "axios";
import URL from "@/constants/baseurl";

//import vSelect from "vue-select";


export default {
    name: "settings",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        // ...mapState('invoice', ['latestinvoices','cashiers','branches']),
        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            roles: [],
            user: {},
             loading:false,
            location_name:'',
            cashier_id:'',
            order_status:'',
            branch_id:'',
            locations:[]

        };
    },
    async created() {
        // await this.getUsers();
        // await this.getRoles();
         await this.getlocations()
        // await this.getcashiers()

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),


        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },

      async  downloadCSV() {
            this.loading=true;
           // const {data} = await apiService.get(`/download-csv`);
          let stock_data=[];
          const apiToken = localStorage.getItem('token');
          if (apiToken) {
              // Set the Authorization header with the API token
              axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
          }
          try {
              const response = await axios.post(`${URL.BASEURL}/api/download-csv`,  {location_name:this.location_name});
              stock_data=response.data
              this.loading=false;

              //   alert(this.found)
              //   console.log(this.cardcustomers,'===================')



          } catch (error) {
              this.loading=false;
              console.error("Error getting stock_data:", error);
          }
         //   console.log(data)

          // const data = [
          //     { itemCode: "10MMNT", location_name: "Bounds Green", qty: 4 },
          //     { itemCode: "10MMNT", location_name: "Crawley", qty: 2 },
          //     { itemCode: "10MMNT", location_name: "Farnborough Warehouse", qty: 3 }
          // ];

          const ws = XLSX.utils.json_to_sheet(stock_data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, "stock_report.xlsx");
        },

        async getlocations(){
            const {data} = await apiService.get(`/locations`);
          //  console.log('branches', data)
            this.locations=data
        },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
