<template>
    <div class="container-fluid" id="printMe">




        <div class="row mt-4">
            <h1 class="col-7 text-success" style="font-size: 80px; margin-top: -15px !important;">
                <!--                <span v-if="delivery_note.status=='Draft'"> Pick List </span>-->
                <span > Purchase Order
                </span>
            </h1>
            <div class="col-5">
                <img class="col-12 w-100 " src="@/assets/images/rtlogo.png" @load="print"  >


            </div>
            <h1 class="col-12 "> <hr class="hr0"></h1>
          <div class="col-12">
              <h2 class="text-danger  text-center" v-if="selected_poprint.deliver_to_customer">
                  This PO delivery is direct to customer, Please don't include any pricing data.
              </h2>
          </div>

        </div>
        <div class="row mt-2">
            <div class="col-6 offset-1" >
                <h4 class="mt-5">Delivery Details</h4>
                <address v-if="selected_poprint.deliver_to_customer">

                        <p v-if="selected_poprint.customer.lastName"><strong> Customer Name:</strong> {{selected_poprint.customer.lastName}}</p>
                        <p v-if="selected_poprint.customer.mobile"><strong>Mobile:</strong> {{selected_poprint.customer.mobile}}</p>
                        <p v-if="selected_poprint.customer.email"><strong>Email:</strong> {{selected_poprint.customer.email}}</p>
                        <p>{{selected_poprint.customer.addressLine1}}</p>
                        <p>{{selected_poprint.customer.addressLine2}}</p>
                        <p>{{selected_poprint.customer.postcode}}, UK</p>

                </address>
                <address v-else="">
                    <p>{{selected_poprint.delivery_location.location_name}}</p>
                    <p>{{selected_poprint.delivery_location.address_1}}</p>
                    <p>{{selected_poprint.delivery_location.address_2}}</p>
                    <p>{{selected_poprint.delivery_location.post_code}}, UK</p>
                </address>






            </div>
            <div class="col-4 mt-3" style="background-color: #f6f6f6; border: 1px solid #f6f6f6; border-radius: 2px">
                <div class="row " >
                    <!--                    <div class="col-6"><p>Reference Number</p></div>-->
                    <!--                    <div class="col-6"><p> </p></div>-->
                    <div class="col-6"><p>PO #:</p></div>
                    <div class="col-6"><p>{{selected_poprint.purchase_order_ref}}</p></div>
                    <!--                  <div class="col-6"><p>Location</p></div>-->
                    <!--                  <div class="col-6"><p>London</p></div>-->
                    <!--                    <div class="col-6"><p>Order Number</p></div>-->
                    <!--                    <div class="col-6"><p>  </p></div>-->
                    <!--                  <div class="col-6"><p>Client Number</p></div>-->
                    <!--                  <div class="col-6"><p>{{selected_customer.customerCode}}</p></div>-->
                    <div class="col-6"><p>Date: </p></div>
                    <div class="col-6"><p>{{formattedDate(selected_poprint.created_at)}}</p></div>

                    <div class="col-6"><p>Expected Date: </p></div>
                    <div class="col-6"><p>{{formattedDate(selected_poprint.date)}}</p></div>
                    <div class="col-6"><p>Supplier: </p></div>
                    <div class="col-6"><p>{{selected_poprint.supplier.name}}</p></div>
                </div>



            </div>



        </div>
        <div class="row mt-5">
            <!--            <div class="col-1"></div>-->
            <!--           <div class="col-10">-->
            <!--               <h1>Additional Information</h1>-->
            <!--               <p>This is additional information for this delivery not</p>-->
            <!--           </div>-->

            <h1 class="col-12 "> <hr class="hr0"></h1>
        </div>

        <div class="row mt-5">
            <div class="col-10 offset-1">
                <table class="table table-borderless" >
                    <thead style="border-bottom: 3px solid black">

                    <th class="col-1">Sr.</th>
                    <th class="col-4">Product Code</th>
                    <th class="col-5">Description</th>
                    <th class="col-5">Supplier Sku</th>
                    <th class="col-2">Quantity</th>
                    <!--                 <th class="col-3">Price (Incl. VAT)</th>-->


                    </thead>
                    <tbody >
                    <template v-if="selected_poprint" class="content">
                        <tr v-for="(po_item, index) in selected_poprint.po_items"  :key="po_item.id">
                            <td>{{ index + 1 }}</td>
                            <td > {{po_item.item_code}} </td>

                            <td>{{po_item.item_description}} </td>
                            <td>{{po_item.supplier_sku}}</td>
                            <td>{{po_item.qty}}</td>

                        </tr>
                    </template>



                    </tbody>
                </table>

                <div class="footer">

                    <address>
                        Lloyds ceramics Ltd <br/>
                        Unit 16 & 17 Clock Tower Road <br/>
                        Isleworth TW76GF <br/>
                        United Kingdom <br/>
                    </address>
                </div>
            </div>
        </div>


<!--        <div class="row mt-5" style="margin-top: 400px !important;" v-if="delivery_note.status!='Draft'">-->
<!--            <div class="col-10 offset-1">-->
<!--                <div  class="row ">-->
<!--                    <div class="col-8">-->
<!--                        <h4 class="mb-5 d-inline">Goods Received By: </h4>-->

<!--                        <div class="row mb-5 mt-5">-->
<!--                            <div class="col-3"><p class="">Name: </p></div>-->
<!--                            <div class="col-9"><p class=""><u>__________________ </u> </p></div>-->
<!--                            <div class="col-3"><p class="">Date: </p></div>-->
<!--                            <div class="col-9"><p class="">__________________ </p></div>-->
<!--                            <div class="col-3"><p class="">Signature: </p></div>-->
<!--                            <div class="col-9"><p class="">__________________ </p></div>-->

<!--                        </div>-->

<!--                    </div>-->

<!--                    <div class="col-4">-->
<!--                        <h4 class="mb-5 d-inline">Goods Delivered By: </h4>-->

<!--                        <div class="row mb-5 mt-5">-->
<!--                            <div class="col-6"><p class="">Name: </p></div>-->
<!--                            <div class="col-6">-->
<!--                                <p class="">-->
<!--                                    <u> __________________-->
<!--                                    </u>-->

<!--                                </p>-->
<!--                            </div>-->
<!--                            <div class="col-6"><p class="">Date: </p></div>-->
<!--                            <div class="col-6"><p class="">__________________ </p></div>-->
<!--                            <div class="col-6"><p class="">Signature: </p></div>-->
<!--                            <div class="col-6"><p class="">__________________ </p></div>-->

<!--                        </div>-->

<!--                    </div>-->

<!--                </div>-->

<!--            </div>-->
<!--        </div>-->



    </div>
</template>

<script>


import {mapActions} from 'vuex';
import { mapState } from 'vuex';
//import $ from "jquery";
import moment from "moment";




export default {
    name: "printpo",
    props: ['mode'],
    computed: {

        ...mapState('purchaseorder',['selected_poprint',]),
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },





        // accountref() {
        //     let ref=''
        //     if(this.invoice.cashPayment>0){
        //         ref= ref+'Cash'
        //         return ref
        //     }
        //     if(this.invoice.bankPayment>0){
        //         ref= ref+' Bank'
        //         return ref
        //     }
        //     if(this.invoice.cardPayment>0){
        //         ref= ref+' Card'
        //         return ref
        //     }
        //     else {
        //         return ''
        //     }
        // },

    },
    data() {
        return {


        };
    },
    created() {


//


    },
    mounted() {

        //this.print()

    },
    unmounted() {
        this.add_selected_poprint(null)
    },
    methods: {
        ...mapActions('purchaseorder', ['add_selected_poprint']),
        formattedDate(date) {
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, YYYY');

            return date2;
        },
        print () {
          //  console.log(this.selected_poprint,'print======')

            if (this.mode!='send' && this.mode!='down') {
                window.print()
                // window.history.back();
                if(this.selected_poprint){
                    this.add_selected_poprint(null)
                    this.$router.push({path:'/purchase-orders'})
                }


            }


        },


    },
}
</script>

<style scoped>



#printMe{
    background-color: white !important;
}
.table-bordered {
    outline: 1px solid black;

}
tbody tr {

    border-bottom: 1px solid black;
}
.table {
    border: 0px solid black !important;
}
.hr1{
    margin-top: -5px;
    margin-bottom: 0px;
    margin-right: 6%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}

.hr2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 2%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.wrap {
    word-wrap: break-word; /* Wrap long words within the available space */
}

.content {
    margin-bottom: 50px; /* Ensure space for the footer */
}

.footer {

    margin-top: 20px;
    bottom: 0 !important;
    width: 100%;

    padding: 10px;
    background-color: #f2f2f2; /* Change as needed */


}
/* Ensure the footer appears on every printed page */
/*@media print {*/
/*    .footer {*/
/*        position: fixed !important;*/
/*        bottom: 0;*/
/*    }*/
/*}*/

</style>
