<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">


                <div class="row mt-3 mb-3">


                    <div class="col-md">

                        <v-select
                            v-model="selectedRange"

                            class=""
                            placeholder="Range"
                            :options="['Today','Yesterday','This Week', 'Last Week', 'This Month', 'Last Month']"




                        />
                    </div>



                    <div class="col-md">

                        <b-form-datepicker
                            :reset-button=true
                            reset-button-variant="btn btn-warning"

                            id="example-datepicker"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="dateFrom"
                            placeholder="Date From"
                            :disabled="selectedRange!==''&& selectedRange!==null"

                            dropdown  calendar-width="100%"
                        ></b-form-datepicker>
                    </div>
                    <div class="col-md">

                        <b-form-datepicker
                            :reset-button=true
                            reset-button-variant="btn btn-warning"

                            id="example-datepicker2"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="dateTo"
                            placeholder="Date To"

                            :disabled="selectedRange!==''&& selectedRange!==null"
                            dropdown  calendar-width="100%"



                        ></b-form-datepicker>
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="cashier_id"

                            class=""
                            placeholder="Sales Man"
                            :options="cashiers"
                            label="fullName"
                            :reduce="option => option.id"

                        />
                    </div>

                    <div class="col-md" v-if="authenticatedUser.permissions.includes('super')">
                        <v-select
                            v-model="branch_id"
                            :multiple="true"
                            class=""
                            placeholder="Branch"
                            :options="branches.filter((b)=>b.id!=9)"
                            label="name"
                            :reduce="option => option.id"

                        />
                    </div>


                </div>


                <div class="row" v-if="showloader=='yes'">
                    <div class="col-md-12 text-center">
                        <loader></loader>
                    </div>
                </div>
             <div class="row mt-4">
                <div class="col-md-6 offset-3">
                 <h1 class="text-light text-center">
                     Expense Summary
                 </h1>
                 <table  class="table text-light ">
                     <thead >
                     <th class="col-md-6">Branch</th>
                     <th>Total Expense</th>
                     <th>Details</th>

                     </thead>

                     <tbody >
                     <tr class="" v-for="(report, key ) in expensereports" :key="key">
                         <td> {{report.name}}</td>
                         <td>£{{formatNumberWithCommas(Number(report.total_expense).toFixed(2))}}</td>

                         <td>
                             <a :href="`#branch_${report.id}`"  @click.prevent="smoothScroll(report.id)">
                                 <button class="btn btn-info" type="button">
                                     View Details
                                 </button>
                             </a>
                         </td>
                     </tr>
                     </tbody>
                 </table>


                 <table class="table text-light ">
                     <thead style="visibility: hidden">
                     <th class="col-md-6"></th>
                     <th>Total Expense</th>
                     <th>Details</th>

                     </thead>
                     <tbody>
                     <tr class="">
                         <td>Grand Total </td>
                         <td>
                     <span v-if="expensereports">
                                 £{{
                             formatNumberWithCommas(
                                 expensereports
                                     .map(report => report.total_expense)
                                     .reduce((total, expense) => Number(total) + Number(expense), 0)
                                     .toFixed(2)
                             )
                         }}
                     </span>
                         </td>
                         <td>

                         </td>

                     </tr>

                     </tbody>
                 </table>
                </div>

             </div>

                <div class="row mt-4">
                    <div class="col-md-6 offset-3">
                        <h1 class="text-light text-center">
                           Category Expense Summary
                        </h1>
                        <table  class="table text-light ">
                            <thead >
                            <th class="col-md-10">Category</th>
                            <th>Total Expense</th>


                            </thead>

                            <tbody >
                            <tr class="" v-for="(category, key ) in categories_sum" :key="key">
                                <td> {{category.name}}</td>
                                <td>£{{formatNumberWithCommas(Number(category.total_expense).toFixed(2))}}</td>


                            </tr>
                            </tbody>
                        </table>


                        <table class="table text-light ">
                            <thead style="visibility: hidden">
                            <th class="col-md-10"></th>
                            <th>Total Expense</th>


                            </thead>
                            <tbody>
                            <tr class="">
                                <td>Grand Total </td>
                                <td>
                                 <span v-if="categories_sum">
                                        £{{
                                         formatNumberWithCommas(
                                             categories_sum
                                                 .map(report => report.total_expense)
                                                 .reduce((total, expense) => Number(total) + Number(expense), 0)
                                                 .toFixed(2)
                                         )
                                     }}
                                 </span>
                                </td>
                                <td>

                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>

                </div>

                <div class="row mt-4" v-for="(branch, key ) in expensereports" :key="key">>
                    <div class="col-md-6 offset-3" :id="`branch_${branch.id}`">
                        <h1 class="text-light text-center">
                            {{branch.name}}
                        </h1>
                        <table  class="table text-light ">
                            <thead >
                            <th class="col-md-10">Category</th>
                            <th>Total Expense</th>


                            </thead>

                            <tbody >
                            <tr class="" v-for="(items, key ) in branch.expense_items" :key="key">
                                <td> {{items[0].expenseCategory_name}}</td>
                                <td>
                                    £{{
                                        formatNumberWithCommas(
                                            items
                                                .map(report => report.sub_total)
                                                .reduce((total, expense) => Number(total) + Number(expense), 0)
                                                .toFixed(2)
                                        )
                                    }}
                                </td>


                            </tr>
                            </tbody>
                        </table>

                    </div>

                </div>


            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";
import Welcomenote from "@/pages/admin/mypos/welcomenote";


export default {
    name: "expensereports",

    components:{Welcomenote, Loader, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {

            roles: [],
            user: {},
            dateFrom: '',
            dateTo: '',
            selectedRange: 'This Month',

            cashier_id:'',

            branch_id:'',

            expensereports:null,
            categories_sum:null,
            showloader:''

        };
    },
    watch: {
        // whenever question changes, this function will run
        selectedRange() {
            this.dateFrom='';
            this.dateTo='';
            this.getadvancereport();
        },
        dateFrom() {
            this.getadvancereport();
        },
        dateTo() {
            this.getadvancereport();
        },
        cashier_id() {
            this.getadvancereport();
        },
        branch_id() {
            this.getadvancereport();
        }

    },

    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()


    },
    async mounted() {

        await this.getadvancereport();

    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers']),
        ...mapActions('invoice', ['updateFullInvoice']),
        ...mapActions('items', ['attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('notes',['attachinvoicenotes']),
        ...mapActions('dispatch',['add_dis_items','attach_invoice_transactions']),


        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        smoothScroll(id) {
            const targetElement = document.getElementById(`branch_${id}`);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.error(`Element with ID branch_${id} not found.`);
            }
        },

        async  getadvancereport(){
            this.showloader='yes'
            this.expensereports=[]
            let reportparams={
                dateFrom:this.dateFrom,
                dateTo:this.dateTo,
                selectedRange:this.selectedRange,
                branch_ids:this.branch_id,
                cashier_id:this.cashier_id

            }
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/expense-report`, reportparams);

                if (res) {
                    console.log(res.data)
                    this.showloader='no'
                    // this.reports=res.data
                    const sortedEntries = res.data.branches.sort((a, b) => b.total_expense - a.total_expense);
                    const sortedcategories = res.data.categories.sort((a, b) => b.sub_total - a.sub_total);



              this.expensereports=sortedEntries
              this.categories_sum=sortedcategories
                    console.log( this.expensereports, 'expense reports')
                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get report', 'info');
            }
        },
        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
        },
        GiveSum(items){
            if(items.length){
                return (items.reduce((total, item) => total + Number(item.payment_amount), 0)).toFixed(2);
            }
            else{
                return 0
            }

        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        // async getRoles() {
        //     const { data } = await this.$axios.get('role');
        //     this.roles = data.roles;
        // },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
