<template>

    <div class="row mt-2">

        <div class="col-md-12">


            <div class="row mt-3 mb-3">
                <h1 class=" col-md-12 text-center text-light">

                    Branch League Table
                </h1>
                <h1 class="col-md-6 offset-3 "> <hr class="hr0" style="margin-top: 0px !important;"></h1>



            </div>
            <div class="row mt-3 mb-3">
<!--                <div class="col-md-5 offset-1">-->
<!--                    <h1 class="text-center text-light">This Week</h1>-->
<!--                    <table  class="table text-light ">-->
<!--                        <thead >-->

<!--                        <th class="col-md-8">Branch</th>-->
<!--                        <th>Position</th>-->


<!--                        </thead>-->

<!--                        <tbody >-->
<!--                        <template v-for="(report, key ) in reports_this_week" >-->
<!--                            <tr class="" :key="key" :class="giveclasss(key)">-->
<!--                                <td> {{(branches.find((b)=>b.id==report.branch_id)).name}}</td>-->
<!--                                <td v-if="key=='branch_1'"> First  </td>-->
<!--                                <td v-else-if="key=='branch_2'"> Second </td>-->
<!--                                <td v-else-if="key=='branch_3'"> Third </td>-->
<!--                                <td v-else=""> {{key.split('_')[1]}}<sup>th</sup> </td>-->


<!--                                &lt;!&ndash;                                <td>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    {{&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        Math.round(&ndash;&gt;-->
<!--                                &lt;!&ndash;                                            (Number(report.total_sale) /&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                Object.values(reports_this_week)&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                    .map(report => Number(report.total_sale))&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                    .reduce((total, sale) => total + sale, 0)) * 100 * 100&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        ) / 100&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    }} %&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </td>&ndash;&gt;-->

<!--                            </tr>-->
<!--                        </template>-->

<!--                        </tbody>-->
<!--                    </table>-->
<!--                </div>-->
                <div class="col-md-6 offset-3">
                    <h1 class="text-center text-light">This Month</h1>
                    <table  class="table text-light ">
                        <thead >
                        <th class="col-md-8">Branch</th>
                        <th>Position</th>


                        </thead>

                        <tbody >
                        <template v-for="(report, key ) in reports_this_month">
                            <tr class=""  :key="key" :class="giveclasss(key)">

                                <td> {{(branches.find((b)=>b.id==report.branch_id)).name}}</td>
                                <td v-if="key=='branch_1'"> First  </td>
                                <td v-else-if="key=='branch_2'"> Second </td>
                                <td v-else-if="key=='branch_3'"> Third </td>
                                <td v-else=""> {{key.split('_')[1]}}<sup>th</sup> </td>


                                <!--                                <td>-->
                                <!--                                    {{-->
                                <!--                                        Math.round(-->
                                <!--                                            (Number(report.total_sale) /-->
                                <!--                                                Object.values(reports_this_month)-->
                                <!--                                                    .map(report => Number(report.total_sale))-->
                                <!--                                                    .reduce((total, sale) => total + sale, 0)) * 100 * 100-->
                                <!--                                        ) / 100-->
                                <!--                                    }} %-->
                                <!--                                </td>-->

                            </tr>
                        </template>

                        </tbody>
                    </table>
                </div>

            </div>





            <div class="row" v-if="showloader=='yes'">
                <div class="col-md-12 text-center">
                    <loader></loader>
                </div>
            </div>



        </div>


    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";
import Welcomenote from "@/pages/admin/mypos/welcomenote";


export default {
    name: "reports",

    components:{Welcomenote, Loader, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },




    },
    data() {
        return {

            roles: [],
            user: {},
            dateFrom: '',
            dateTo: '',
            selectedRange: '',

            cashier_id:'',

            branch_id:'',

            reports_this_week:null,
            reports_this_month:null,
            showloader:''

        };
    },


    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()

        // let user = localStorage.getItem('user');
        // user=  JSON.parse(user)
        //     if(user.permissions.includes('super')){
        //         this.branch_id=''
        //
        //     } else {
        //         this.branch_id=[user.branch.id]
        //     }



    },
    async mounted() {

        // reduce water fall
        const promises =[
            this.getadvancereport_this_week(),
            this.getadvancereport_this_month()

        ]

        await Promise.all(promises);


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers']),
        ...mapActions('invoice', ['updateFullInvoice']),
        ...mapActions('items', ['attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('notes',['attachinvoicenotes']),
        ...mapActions('dispatch',['add_dis_items','attach_invoice_transactions']),


        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        giveclasss(k) {
            if (k === 'branch_1') {
                return 'first';
            } else if (k === 'branch_2') {
                return 'second';
            } else if (k === 'branch_3') {
                return 'third';
            } else {
                return '';
            }
        },


        async  getadvancereport_this_week(){



            this.showloader='yes'
            this.reports_this_week=[]

            let reportparams={
                dateFrom:'',
                dateTo:'',
                selectedRange:'This Week',
                branch_ids:'',
                cashier_id:''

            }
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/advance-report`, reportparams);

                if (res) {
                    //console.log(res.data)
                    this.showloader='no'

                    const sortedEntries = Object.entries(res.data)
                        .filter(([, value]) => ![9].includes(value.branch_id))
                        .sort(([, a], [, b]) => b.total_collection - a.total_collection)
                        .map((entry, index) => [`branch_${index + 1}`, entry[1]]);


                    this.reports_this_week = Object.fromEntries(sortedEntries);

                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get report', 'info');
            }
        },
        async  getadvancereport_this_month(){
            this.showloader='yes'
            this.reports_this_month=[]
            let reportparams={
                dateFrom:'',
                dateTo:'',
                selectedRange:'This Month',
                branch_ids:'',
                cashier_id:''

            }
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/advance-report`, reportparams);

                if (res) {
                    //console.log(res.data)
                    this.showloader='no'
                    // this.reports=res.data
                    const sortedEntries = Object.entries(res.data)
                        .filter(([, value]) => ![9].includes(value.branch_id))
                        .sort(([, a], [, b]) => b.total_collection - a.total_collection)
                        .map((entry, index) => [`branch_${index + 1}`, entry[1]]);


                    this.reports_this_month = Object.fromEntries(sortedEntries);

                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get report', 'info');
            }
        },



        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
        },
        GiveSum(items){
            if(items.length){
                return (items.reduce((total, item) => total + Number(item.payment_amount), 0)).toFixed(2);
            }
            else{
                return 0
            }

        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.first {
    background-color: #bb9f18;
}
.second {
    background-color: #a49e9e;
}
.third {
    background-color: #8d8533;
}

.red-background{
    background-color: red;
}

</style>
