<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>


        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white" v-if="credentials.report2_password=='Online1776!'">


                <div class="row mt-3 mb-3">


<!--                    <div class="col-md">-->

<!--                        <v-select-->
<!--                            v-model="selectedRange"-->

<!--                            class=""-->
<!--                            placeholder="Range"-->
<!--                            :options="['Today','Yesterday','This Week', 'Last Week', 'This Month', 'Last Month']"-->




<!--                        />-->
<!--                    </div>-->
<!--                    <div class="col-md">-->

<!--                        <b-form-datepicker-->
<!--                            :reset-button=true-->
<!--                            reset-button-variant="btn btn-warning"-->

<!--                            id="example-datepicker"-->
<!--                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
<!--                            v-model="dateFrom"-->
<!--                            placeholder="Date From"-->
<!--                            :disabled="selectedRange!==''&& selectedRange!==null"-->

<!--                            dropdown  calendar-width="100%"-->
<!--                        ></b-form-datepicker>-->
<!--                    </div>-->
<!--                    <div class="col-md">-->

<!--                        <b-form-datepicker-->
<!--                            :reset-button=true-->
<!--                            reset-button-variant="btn btn-warning"-->

<!--                            id="example-datepicker2"-->
<!--                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
<!--                            v-model="dateTo"-->
<!--                            placeholder="Date To"-->

<!--                            :disabled="selectedRange!==''&& selectedRange!==null"-->
<!--                            dropdown  calendar-width="100%"-->



<!--                        ></b-form-datepicker>-->
<!--                    </div>-->
<!--                    <div class="col-md">-->
<!--                        <v-select-->
<!--                            v-model="cashier_id"-->

<!--                            class=""-->
<!--                            placeholder="Sales Man"-->
<!--                            :options="cashiers"-->
<!--                            label="fullName"-->
<!--                            :reduce="option => option.id"-->

<!--                        />-->
<!--                    </div>-->

                    <div class="col-md-2">
                        <v-select
                            v-model="branch_id"
                            :multiple="true"
                            class=""
                            placeholder="Branch"
                            :options="branches"
                            label="name"
                            :reduce="option => option.id"

                        />
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="searchprdtcode" placeholder="SKU" @input="getadvancereport2"></b-input>
                    </div>
                    <div class="col-md-2">

                        <v-select
                            v-model="selectedRange"

                            class=""
                            placeholder="Range"
                            :options="['Today','Yesterday','This Week', 'Last Week', 'This Month', 'Last Month']"




                        />
                    </div>
                    <div class="col-md">

                        <b-form-datepicker
                            :reset-button=true
                            reset-button-variant="btn btn-warning"

                            id="example-datepicker"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="dateFrom"
                            :disabled="selectedRange!==''&& selectedRange!==null"
                            placeholder="Date From"


                            dropdown  calendar-width="100%"
                        ></b-form-datepicker>
                    </div>
                    <div class="col-md">

                        <b-form-datepicker
                            :reset-button=true
                            reset-button-variant="btn btn-warning"

                            id="example-datepicker2"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="dateTo"
                            :disabled="selectedRange!==''&& selectedRange!==null"
                            placeholder="Date To"


                            dropdown  calendar-width="100%"



                        ></b-form-datepicker>
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="supplier_id"
                            class=""
                            placeholder="Supplier"
                            :options="suppliers"
                            label="name"
                            :reduce="option => option.id"

                        />
                    </div>
                    <div class="col-md">
                                <v-select
                                    v-model="cashier_id"

                                    class=""
                                    placeholder="Sales Man"
                                    :options="cashiers"
                                    label="fullName"
                                    :reduce="option => option.id"

                                />
                    </div>


                </div>


                <div class="row ">
                    <div class="col-md-2">
                        <button class="btn-sm btn-info" @click="getadvancereport2">
                            <span v-if="showloader=='yes'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Refresh
                        </button>
                    </div>
                    <div class="col-md-1">

                    </div>
                    <div class="col-md-1">
                        <button class="btn-sm btn-info"     :disabled="getadvancereport2.current_page==1" @click="handle_get_reports('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2" v-if="showloader!='yes'" style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="reports.to-15>0">{{reports.to-15}}</span> <span v-else="">0</span>  to {{reports.to}}) out of {{reports.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "    :disabled="getadvancereport2.current_page==totalPages" @click="handle_get_reports('next')">Next</button>
                    </div>
                    <div class="col-md-2 ">

                    </div>

                    <!--                    <div class="col-md-2 text-right">-->
                    <!--                        <button class="btn-sm btn-warning"    @click="clearfilters" >Clear Filters</button>-->
                    <!--                    </div>-->

                </div>
                <table  class="table text-light ">
                    <thead >

                    <th class="col-md-1">ID</th>
                    <th class="col-md-5">SKU</th>
                    <th class="col-md-2" >
                                                        <span class="cursor-pointer" @click="toggle_sold">
                                                            <b-icon icon="arrow-down-up "></b-icon>
                                                            <span> SOLD  </span>
                                                        </span>
                    </th>
                    <th class="col-md-2">TURN OVER</th>
                    <th class="col-md-2">PROFIT</th>

                    </thead>

                    <tbody >
                    <tr v-for="report in reports.data" >

                        <td class="col-md-1">{{report.id}} </td>
                        <td class="col-md-5">{{report.itemCode}} </td>
                        <td class="col-md-2">{{report.sold}}</td>
                        <td class="col-md-2">{{report.turnover}}</td>
                        <td class="col-md-2">{{report.profit}}</td>



                    </tr>
                    </tbody>
                </table>

                <div class="row" v-if="showloader=='yes'">
                    <div class="col-md-12 text-center">
                        <loader></loader>
                    </div>
                </div>
                <table class="table text-light ">
                    <thead style="visibility: hidden">
                    <th >ID</th>
                    <th>SKU</th>
                    <th>SOLD</th>
                    <th>TURN OVER</th>
                    <th>PROFIT</th>
                    </thead>
                    <tbody>
                    <tr class="">
                        <td class="col-md-6">Grand Total </td>
                        <td class="col-md-2">
<!--                            {{-->
<!--                                formatNumberWithCommas(reports.data-->
<!--                                    .map(report => report.sold)-->
<!--                                    .reduce((total, sold) => total + sold, 0)-->
<!--                                    .toFixed(2))-->
<!--                            }}-->
                          <span v-if="totals">
                                {{totals.totalSold}}
                          </span>
                        </td>
                        <td class="col-md-2 ">
<!--                            {{-->
<!--                                formatNumberWithCommas(reports.data-->
<!--                                    .map(report => report.turnover)-->
<!--                                    .reduce((total, turnover) => total + turnover, 0)-->
<!--                                    .toFixed(2))-->
<!--                            }}-->
                            <span v-if="totals">
                                {{totals.totalTurnover}}
                          </span>
                        </td>
                        <td >
<!--                            {{-->
<!--                                formatNumberWithCommas(reports.data-->
<!--                                    .map(report => report.profit)-->
<!--                                    .reduce((total, profit) => total + profit, 0)-->
<!--                                    .toFixed(2))-->
<!--                            }}-->
                            <span v-if="totals">
                                {{totals.totalProfit}}
                          </span>
                        </td>

                    </tr>

                    </tbody>
                </table>




            </div>
            <div v-else="" class="col-md-11 " style="border: 2px solid white" >
                <b-input class="col-md-1  mt-1" v-model="credentials.report2_password" placeholder="Enter Password"></b-input>
            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import apiService from "@/service/apiService";



export default {
    name: "reports",

    components:{Welcomenote, Loader, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {

        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),
        ...mapState('passwordprotection', ['credentials']),



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {

            suppliers:[],
            roles: [],
            user: {},
            dateFrom: '',
            dateTo: '',
            selectedRange: '',

            cashier_id:'',

            branch_id:'',
            supplier_id:'',

            reports:null,
            totals:null,
            showloader:'',
            current_page:1,
            searchprdtcode:'',
            sold_toggle:'desc'

        };
    },
    watch: {
        // whenever question changes, this function will run
        selectedRange() {
            this.dateFrom='';
            this.dateTo='';
            this.getadvancereport2();
        },
        dateFrom() {
            this.getadvancereport2();
        },
        dateTo() {
            this.getadvancereport2();
        },
        cashier_id() {
            this.getadvancereport2();
        },
        branch_id() {
            this.getadvancereport2();
        },
        supplier_id() {
            this.getadvancereport2();
        }


    },

    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()
        await this.getsuppliers()


    },
    async mounted() {

        await this.getadvancereport2();

    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers']),
        ...mapActions('invoice', ['updateFullInvoice']),
        ...mapActions('items', ['attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('notes',['attachinvoicenotes']),
        ...mapActions('dispatch',['add_dis_items','attach_invoice_transactions']),

        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('suppliers', data)
            this.suppliers=data
        },
        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        toggle_sold(){
            if(this.sold_toggle=='asc'){
                this.sold_toggle='desc'
            } else {
                this.sold_toggle='asc'
            }
            this.getadvancereport2();
        },

        async handle_get_reports(button){
            let current_page= this.reports.current_page
            // let orfilters=
            //     {
            //         branch_id:this.orderfilters.branch_id,
            //         invoice_ref:this.orderfilters.order_id,
            //         status:this.orderfilters.order_status,
            //         cashier_id:this.orderfilters.cashier_id,
            //         deliveryDate:this.orderfilters.delivery_date,
            //         grandTotal:this.orderfilters.totalamount,
            //         lastName:this.orderfilters.searchcustname,
            //         mobile:this.orderfilters.searchcustphone,
            //         customerCode:this.orderfilters.searchcustpostcode,
            //         itemsquery:this.orderfilters.searchQuery,
            //         displaypage:'orders',
            //         page:current_page
            //     }



            if(button=='next'){
                // console.log(orfilters,'status check')
                this.current_page=current_page+1
                this.showloader=true

                await this.getadvancereport2();
                this.showloader=false
            }
            if(button=='prev'){
                this.current_page=current_page-1
                this.showloader=true

                await this.getadvancereport2();
                this.showloader=false

            }

        },
        async  getadvancereport2(){
            this.showloader='yes'
            this.reports=[]

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/advance-report2`,
                    {

                        branch_ids:this.branch_id,
                        page:this.current_page,
                        sku:this.searchprdtcode,
                        dateFrom:this.dateFrom,
                        dateTo:this.dateTo,
                        selectedRange:this.selectedRange,
                        sold_toggle:this.sold_toggle,
                        supplier_id:this.supplier_id,
                        cashier_id:this.cashier_id


                    }
                );

                if (res) {
                    console.log(res.data.paginated_data,'res.data.paginated_data')
                    this.showloader='no'
                    this.reports=res.data.paginated_data
                    this.totals=res.data.totals

                }
                else {
                    //
                }



            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to get report', 'info');
            }
        },

        totalPages() {
            const perpage = this.reports.per_page;
            const total=this.reports.total;

            return Math.ceil(total / perpage);
        },

        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
            if(this.suppliers.length==0){
             await this.getsuppliers()
            }
        },
        GiveSum(items){
            if(items.length){
                return (items.reduce((total, item) => total + Number(item.payment_amount), 0)).toFixed(2);
            }
            else{
                return 0
            }

        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
