
export default {
    namespaced: true,
    state: () => ({

        transferfilters:{
            selectedBranch:'',
            id:'',
            date:'',
            location_id_from:'',
            location_id_to:'',
            oder_id:'',


            status:'',



            page:1,


        }


    }),
    mutations: {



    },
    actions: {





    },
    getters: {}
}
