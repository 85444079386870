<template>
    <div class="container-fluid" id="printMe">




        <div class="row mt-4">
            <h1 class="col-7 text-success" style="font-size: 80px; margin-top: -15px !important;">
<!--                <span v-if="delivery_note.status=='Draft'"> Pick List </span>-->
                <span > Internal Transfer </span>
            </h1>
            <div class="col-5">
                <img class="col-12 w-100 " src="@/assets/images/rtlogo.png" @load="print"  >


            </div>
            <h1 class="col-12 "> <hr class="hr0"></h1>

        </div>
        <div class="row mt-2">
            <div class="col-7 offset-1" >
                <p class="mt-5">  From Location</p>
                <h4 class="">  {{from}} </h4>



                <p class="mt-3">  To Location</p>
                <h4 class="">  {{to}} </h4>

            </div>
            <div class="col-3 mt-3" style="background-color: #f6f6f6; border: 1px solid #f6f6f6; border-radius: 2px">
                <div class="row " style="margin-top: 10px" >
<!--                    <div class="col-6"><p>Reference Number</p></div>-->
<!--                    <div class="col-6"><p> </p></div>-->
                    <div class="col-6"><p>Date</p></div>
                    <div class="col-6"><p>{{formattedDate}}</p></div>
                    <!--                  <div class="col-6"><p>Location</p></div>-->
                    <!--                  <div class="col-6"><p>London</p></div>-->
<!--                    <div class="col-6"><p>Order Number</p></div>-->
<!--                    <div class="col-6"><p>  </p></div>-->
                    <!--                  <div class="col-6"><p>Client Number</p></div>-->
                    <!--                  <div class="col-6"><p>{{selected_customer.customerCode}}</p></div>-->
                    <div class="col-6"><p>Delivery Method</p></div>
                    <div class="col-6"><p>{{delivery_note.mode}}</p></div>
                </div>



            </div>



        </div>
        <div class="row mt-5">
            <!--            <div class="col-1"></div>-->
            <!--           <div class="col-10">-->
            <!--               <h1>Additional Information</h1>-->
            <!--               <p>This is additional information for this delivery not</p>-->
            <!--           </div>-->

            <h1 class="col-12 "> <hr class="hr0"></h1>
        </div>

        <div class="row mt-5">
            <div class="col-10 offset-1">
                <table class="table table-borderless" >
                    <thead style="border-bottom: 3px solid black">

                    <th class="col-4">Product Code</th>
                    <th class="col-5">Description</th>
                    <th class="col-2">Quantity</th>
                    <!--                 <th class="col-3">Price (Incl. VAT)</th>-->


                    </thead>
                    <tbody >
                    <template v-if="selected_dispatch">
                        <tr v-for="dispath_item in selected_dispatch.dispatchitems"  :key="dispath_item.id">

                            <td > {{dispath_item.itemCode}} </td>

                            <td>{{dispath_item.itemDescription}} </td>
                            <td>{{dispath_item.qty}}</td>

                        </tr>
                    </template>



                    </tbody>
                </table>
            </div>
        </div>


        <div class="row mt-5" style="margin-top: 400px !important;" v-if="delivery_note.status!='Draft'">
            <div class="col-10 offset-1">
                <div  class="row ">
                    <div class="col-8">
                        <h4 class="mb-5 d-inline">Goods Received By: </h4>

                        <div class="row mb-5 mt-5">
                            <div class="col-3"><p class="">Name: </p></div>
                            <div class="col-9"><p class=""><u>__________________ </u> </p></div>
                            <div class="col-3"><p class="">Date: </p></div>
                            <div class="col-9"><p class="">__________________ </p></div>
                            <div class="col-3"><p class="">Signature: </p></div>
                            <div class="col-9"><p class="">__________________ </p></div>

                        </div>

                    </div>

                    <div class="col-4">
                        <h4 class="mb-5 d-inline">Goods Delivered By: </h4>

                        <div class="row mb-5 mt-5">
                            <div class="col-6"><p class="">Name: </p></div>
                            <div class="col-6">
                                <p class="">
                                    <u> __________________
                                     </u>

                                </p>
                            </div>
                            <div class="col-6"><p class="">Date: </p></div>
                            <div class="col-6"><p class="">__________________ </p></div>
                            <div class="col-6"><p class="">Signature: </p></div>
                            <div class="col-6"><p class="">__________________ </p></div>

                        </div>

                    </div>

                </div>

            </div>
        </div>



    </div>
</template>

<script>


import {mapActions, mapGetters} from 'vuex';
import { mapState } from 'vuex';
//import $ from "jquery";
import moment from "moment";




export default {
    name: "delivery-note",
    props: ['mode'],
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['invoice','cashiers']),
        ...mapState('items',['searched_items']),
        ...mapState('customers', ['selected_customer']),
        ...mapState('dispatch',['transactionshistory','selected_dispatch']),
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        formattedDate() {
            // let currentDate = new Date();
            // if(this.selected_dispatch){
            //     if (this.selected_dispatch.date !== "") {
            //         currentDate = new Date(this.invoice.issueDate);
            //     }
            // }else {
            //     if (this.invoice.issueDate !== "") {
            //         currentDate = new Date(this.invoice.issueDate);
            //     }
            // }


            const formattedDate = moment(this.selected_dispatch.date).format('YYYY-MM-DD');

            return formattedDate;
        },
        selectedCashier(){
            if(this.selected_dispatch){
                return  this.cashiers.find((c)=>c.id==this.selected_dispatch.invoice.cashier_id)
            } else{
                return  this.cashiers.find((c)=>c.id==this.invoice.cashier_id)
            }

        },
        delivery_note(){
            console.log(this.selected_dispatch)
            if(this.selected_dispatch){
                return  this.selected_dispatch
            }
            else {
                return (this.transactionshistory).find((tran)=>tran.id==this.$route.params.id)
            }

        },
        from(){
           return this.$route.params.from

        },
        to(){
            return this.$route.params.to

        },

        // accountref() {
        //     let ref=''
        //     if(this.invoice.cashPayment>0){
        //         ref= ref+'Cash'
        //         return ref
        //     }
        //     if(this.invoice.bankPayment>0){
        //         ref= ref+' Bank'
        //         return ref
        //     }
        //     if(this.invoice.cardPayment>0){
        //         ref= ref+' Card'
        //         return ref
        //     }
        //     else {
        //         return ''
        //     }
        // },

    },
    data() {
        return {


        };
    },
    created() {


//


    },
    mounted() {

        //this.print()

    },
    methods: {
        ...mapActions('dispatch', ['add_selected_dispatch']),
        print () {
            console.log(this.invoice,'print')

            if (this.mode!='send') {
                window.print()
                // window.history.back();
                if(this.selected_dispatch){
                    this.add_selected_dispatch(null)
                    this.$router.push({path:'/intransfers'})
                }
                else {
                    this.add_selected_dispatch(null)
                    this.$router.push({ path: '/' })
                }

            }


        },


    },
}
</script>

<style scoped>



#printMe{
    background-color: white !important;
}
.table-bordered {
    outline: 1px solid black;

}
tbody tr {
    outline: 1px solid black;
    border: 0px solid black;
}
.table {
    border: 0px solid black !important;
}
.hr1{
    margin-top: -5px;
    margin-bottom: 0px;
    margin-right: 6%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}

.hr2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 2%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.wrap {
    word-wrap: break-word; /* Wrap long words within the available space */
}

</style>
