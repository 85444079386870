<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row mt-1">

                    <div class="col-md-1 offset-4">
                        <button class="btn-sm btn-info"     :disabled="catsdata.current_page==1" @click="getAllCats('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2"  style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="catsdata.to-10>0">{{catsdata.to-10}}</span> <span v-else="">0</span>  to {{catsdata.to}}) out of {{catsdata.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "     @click="getAllCats('next')">Next</button>
                    </div>



                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                </div>
                <div class="row  mt-3">

                    <b-link class="" :to="{ path: '/categoryform',query: { type: 'add'} }" v-if="authenticatedUser.permissions.includes('super')">
                        <b-button class="btn-info mb-2" > Add New Category </b-button>
                    </b-link>

                    <div class="col-md-2 ">
                        <b-input v-model="cfilters.searchcatname"  placeholder="Category Name" @input="getAllCats"></b-input>
                    </div>
<!--                    <div class="col-md-2">-->
<!--                        <b-input v-model="pfilters.searchprdtcode" placeholder="SKU" @input="getAllItems"></b-input>-->
<!--                    </div>-->
                    <div class="col-md-1">
                        <button class="btn btn-info" @click="reloaditems">
                            <span v-if="showloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Refresh
                        </button>
                    </div>







                    <table class="bg-light table table-striped table-hover ">
                        <thead>
                        <tr >
                            <th class="bold-columns " >Real name</th>
                            <th class="bold-columns " >Real Range</th>
                            <th class="bold-columns " >Showroom name</th>
                            <th class="bold-columns " >RT Full Name</th>
                            <th class="bold-columns " >RTO name</th>



                            <th class="bold-columns text-right" >Actions</th>
                        </tr>

                        </thead>

                            <tbody>
                            <tr v-for="(category, index) in catsdata.data" :key="index">
                                <td >{{ category.real_name }}</td>
                                <td >{{ category.real_range }}</td>
                                <td >{{ category.showroomname }}</td>
                                <td >{{ category.rt_full_name }}</td>
                                <td >{{ category.name }}</td>





                                <td class="text-right">
                                    <b-link v-if="authenticatedUser.permissions.includes('super')" :to="{ path: '/categoryform', query: { type: 'edit', 'id': category.id } }">
                                        <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                    </b-link>

                                    <b-link :to="{ path: '/categoryform', query: { type: 'view', 'id': category.id } }">
                                        <b-button class="btn-info mb-2"> View </b-button>
                                    </b-link>
<!--                                    <b-button @click="deleteproduct(category.id)" v-if="authenticatedUser.permissions.includes('super')" class="btn-danger mb-2"> Delete </b-button>-->

                                </td>
                            </tr>
                            </tbody>

                    </table>


                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import apiService from "@/service/apiService";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from 'moment';
import VTooltip from 'v-tooltip';

//import vSelect from "vue-select";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{Welcomenote,VTooltip, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers', ['customers']),
        ...mapState('products', ['allcategories']),
        ...mapState('items', ['pfilters','pageitems']),
        ...mapState('products', ['cfilters']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            showloader:false,
            allitems:[],
            catsdata:'',

            loading:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',

            searchcustemail:'',
            custtype:'',
            credit:null,
            suppliers:[],
            supplier_id:'',
            upcomingDates:[],
            modalOpen:false,




        };
    },
    async created() {

        // await this.getbranches()
        // await this.getcashiers()
        this.loading=true
        await this.getAllCats();
        this.loading=false
        await this.getsuppliers()


        const promises =[

            this.getAllcategories(),
            this.getAllcolors(),
            this.getAllsizes(),
            this.getAlltypes(),
            this.getAllmaterials(),
            this.getAllfinishes(),

        ]

        await Promise.all(promises);
        // await this.getCustomers();



    },
    async mounted() {
        // window.addEventListener("scroll", () => {
        //     // console.log("Scroll event triggered");
        //     const scroll = window.scrollY;
        //     console.log(scroll, "scroll");
        //     const nav = document.getElementById("products_header_fix");
        //
        //     if (nav) {
        //         if (scroll >= 170) {
        //             nav.classList.add("products_header_fix");
        //         } else {
        //             nav.classList.remove("products_header_fix");
        //         }
        //     }
        // });


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),
        ...mapActions('items', ['set_page_items']),
        ...mapActions('products', ['set_page_cats']),
        ...mapActions('products',['getAllcategories','getAllcolors','getAllsizes','getAlltypes','getAllmaterials','getAllfinishes']),









        async getAllCats(button=null) {

            // if(this.pfilters.searchprdtcode || this.pfilters.searchprdtname){
            // //
            // } else {
            //     if(this.pfilters.supplier_id){
            //         //
            //     } else {
            //         this.pfilters.supplier_id=26
            //     }
            //
            // }

            let current_page= this.catsdata.current_page
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            let filters={
                name:this.cfilters.searchcatname,

                page:current_page,


            }
            if(button=='next'){

                filters.page=current_page+1

            }
            if(button=='prev'){
                filters.page=current_page-1


            }
            this.showloader=true
            const {data} = await apiService.post(`/pcats`,filters);
            this.showloader=false


           this.catsdata=data

            this.set_page_cats(data)

            // this.items = itemdata;
            // this.$store.commit('items/setItems', itemdata);

            // console.log('ok')
        },
        async reloaditems(){
            this.loading=true
            //  await this.getItems();
            await this.getAllItems();
            this.loading=false

        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('branches', data)
            this.suppliers=data
        },

        async deleteproduct(item_id){

            const {data} = await apiService.get(`/code_used/${item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            if(data.code_used){
                Swal.fire('Product is used!', 'You cannot delete used products.', 'info');
            }
            else {
                const result = await Swal.fire({
                    title: 'Are you sure you want to delete this product?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) {

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }
                    try {
                        const res = await axios.delete(`${URL.BASEURL}/api/delete-item/${item_id}`);

                        if (res) {
                            this.getItems();

                            Swal.fire('success', 'Product  Deleted', 'success');

                        } else {
                            Swal.fire('info', 'Product Cant be Deleted', 'info');
                        }
                    } catch (error) {
                        console.error(error);
                        Swal.fire('Error', 'Failed to delete Product.', 'info');
                    }
                }
            }
        },
        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        // async getRoles() {
        //     const { data } = await this.$axios.get('role');
        //     this.roles = data.roles;
        // },
        // filterUsers(value) {
        //     this.getUsers(value)
        // },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.bold-columns th.IsleworthWarehouseAfterOpen,
.bold-columns th.BoundsGreenAfterOpen,
.bold-columns th.HanworthAfterOpen,
.bold-columns th.GatwickAfterOpen,
.bold-columns th.FarnboroughWarehouseAfterOpen {
    font-weight: bold;
}

.custom-tooltip .tooltip-inner {
    max-width: 500px;
}

.arrow {
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.asc::before {
    content: '';
    border-bottom: 5px solid black;
}
.desc::after {
    content: '';
    border-top: 5px solid black;
}
.hidden {
    visibility: hidden;
}




</style>
