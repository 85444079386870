<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">
                          Stock Adjustment
                        </h1>
                    </div>
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Details</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <!--                        <div class="row mt-1">-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <p><strong>Enter Purchase Order Reference</strong></p>-->
                        <!--                            </div>-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <b-input v-model="por"></b-input>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="row ">
                            <div class="col-md-2">
                                <label><strong>Select Reason</strong></label>
                                <b-form-select
                                    v-model="adjustment_reason"
                                    :options="['Opening', 'Damage', 'Lost','Consumed','Other','Stock Check']"
                                    class="bgsecond bform text-white mb-2 "
                                    @change="reset_items"

                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Reason</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-2">
                                <label><strong>Select Location</strong></label>
                                <b-form-select
                                    v-model="location_id"
                                    :options="locations_stock"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="location_name"
                                    @change="handle_location_change"
                                    :disabled="viewType=='Update'"
                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-2">
                                <label><strong>Select Supplier</strong></label>
                                <b-form-select
                                    v-model="supplier_id"
                                    :options="suppliers.filter((s)=>s.allowed_in_po==1)"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="name"
                                    @change="handle_location_change"
                                    :disabled="viewType=='Update' || adjustment_reason!='Stock Check'"
                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Supplier</b-form-select-option>
                                    </template>


                                </b-form-select>
                            </div>

                            <div class="col-md-3">
                                <label><strong>Select Date</strong></label>
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="datepicsuper"

                                    v-model="date"
                                    placeholder="Date"

                                    dropup  calendar-width="100%">

                                </b-form-datepicker>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Note</strong></label>
                                <b-form-textarea
                                    id="textarea"
                                    v-model="note"
                                    placeholder="Enter Note"
                                    rows="2"
                                ></b-form-textarea>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-1">
                                <label class="text-light"><strong>Sort Type</strong></label>
                                <b-form-select
                                    v-model="sort_type"
                                    :options="[
                                                { name: 'a_z', label: 'A to Z ' },
                                                { name: 'z_a', label: 'Z to A ' },
                                                { name: '1_9', label: '1 to 9 ' },
                                                { name: '9_1', label: '9 to 1 ' }
                                            ]"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="name"
                                    text-field="label"
                                    @change="handle_searched_items_sort"

                                >


                                    <template #option="{ option }">
                                        <div>{{ option.label }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Items</h1>
                            </div>
                        </div>


                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col" class="col-md-1" v-if="adjustment_reason=='Other'||adjustment_reason=='Stock Check'">System Stock</th>
                                <th scope="col" class="col-md-1" v-if="adjustment_reason=='Other'||adjustment_reason=='Stock Check'">Actual Stock</th>
                                <th scope="col"  class="col-md-1" >Adjustment</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in items" :key="item.mapid" :value="item.itemCode"> {{ item.itemName }}  | {{item.itemDescription}}  </option>

                            </datalist>
                            <template v-if="stock_adjustment_searched_items">
                                <template v-for="item in stock_adjustment_searched_items" >
                                    <tr class="text-light"  :class="Number(item.system_stock)!=Number(item.branch_actual_stock)&&item.id&&location_id&&(adjustment_reason=='Other'||adjustment_reason=='Stock Check')?'bg-warning':''">
                                        <th class="col-md-3 ">
                                            <div class="input-container w-100">

                                                <i  class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem(item.mapid)" style="margin-right: 0.5rem;"></i>
                                                <b-checkbox class="checkbox ml-2" style="cursor: pointer" :checked="Number(item.system_stock)!=Number(item.branch_actual_stock)&&item.id&&location_id&&(adjustment_reason=='Other'||adjustment_reason=='Stock Check')"></b-checkbox>
                                                <b-input class="bg-light col-md-11 form-control" v-model="item.itemCode"  list="item-list-id" :disabled="adjustment_reason==''||re_fetching_items||items.length==0 || item.selected"
                                                         @change="addsearchitem('',item.itemCode, item.mapid)"
                                                         :state="hasDuplicateSku.length? hasDuplicateSku[0]!=(item.itemCode+item.itemDescription).trim():null" />


                                            </div>
                                        </th>

                                        <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="true"> </b-input> </td>
                                        <td v-if="adjustment_reason=='Other'||adjustment_reason=='Stock Check'">

                                            <b-input v-if="location_id || supplier_id" class=" bg-light form-control"
                                                     v-model="item.system_stock"
                                                     type="number"

                                                     :disabled="true"> </b-input>
                                            <b-input  v-else class=" bg-light form-control" :disabled="true" > </b-input>
                                        </td>
                                        <td v-if="adjustment_reason=='Other'||adjustment_reason=='Stock Check'">


                                            <b-input  v-if="location_id || supplier_id" class=" bg-light form-control"  v-model="item.branch_actual_stock"   @change="handleactualstockchange(item.mapid)" type="number"> </b-input>
                                            <b-input  v-else class=" bg-light form-control" :disabled="true" > </b-input>

                                        </td>

                                        <td ><input type="number" v-model="item.qty"  @change="handlechange(item.mapid)" :disabled="adjustment_reason=='Other'||adjustment_reason=='Stock Check'" class="bg-light form-control" ></td>
                                    </tr>
                                </template>


                                <tr  v-if="items.length==0 || re_fetching_items">
                                <td>
                                        <span class="text-light">
                                    Please wait, items are loading...   <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </span>
                                </td>
                                </tr>


                            </template>
                            <tr>
                                <th scope="row" >

                                    <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus" @click="addsearchitem('yes')"></i>
                                    <span class="text-light">
                                    <button class="btn btn-info ml-5"  v-if="!(items.length==0 || re_fetching_items)" @click="refresh_items">Refresh Items</button>
                                    </span>
                                </th>
                                <td></td>
                                <td></td>




                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row" v-if="!authenticatedUser.permissions.includes('super')">
                    <div class="col-md-6 offset-3">
                        <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesavestock || saving_adjustment" @click="save_stock">
                            <span v-if="viewType=='Add'">
                              Save
                            </span>
                            <span v-else>
                                Update Stock
                            </span>

                        </button>
                    </div>
<!--                    <div class="col-md-1" v-if="viewType=='Update'">-->
<!--                        <button class="btn btn-info" @click="add_new_adjustemnt" style="margin-top: 62px">-->
<!--                            Add New-->
<!--                        </button>-->
<!--                    </div>-->
                </div>





            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import newitem from "@/constants/newitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote.vue";
import {cloneDeep} from "lodash";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','branches']),
        ...mapState('invoice', ['users']),
        ...mapState('items',['stock_adjustment_searched_items']),

        disablesavestock(){
            if(this.location_id=='' || this.date=='' || (this.stock_adjustment_searched_items.filter((is)=>is.id!=0)).length==0){
                return true
            } else return false
        },
        locations_stock(){
            if(this.authenticatedUser.permissions.includes('super')){
                return this.locations
            } else {
                return this.locations.filter((lc)=>[this.locations.find((bl)=>bl.branch_id==this.authenticatedUser.branch_id).id].includes(lc.id))
            }
        },

        showitemdatalist(){
            if(this.stock_adjustment_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.stock_adjustment_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0){
                return true
            }
            else {
                return false
            }
        },

        hasDuplicateSku() {
            const duplicates = [];
            if (this.stock_adjustment_searched_items.length) {
                const uniqueItems = new Set();


                for (const obj of this.stock_adjustment_searched_items) {
                    const itemKey = (obj.itemCode + obj.itemDescription).trim();

                    if (uniqueItems.has(itemKey) && !duplicates.includes(itemKey)) {
                        duplicates.push(itemKey);
                    } else {
                        uniqueItems.add(itemKey);
                    }
                }

                return  duplicates
            }

            return duplicates;
        },

        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            items:[],
            sort_type:'a_z',

            searchitem:'',
            locations:[],
            adjustment_reason:'',
            location_id:'',
            supplier_id:'',
            suppliers:[],
            date:new Date(),
            note:'',
            viewType:'',
            stock_id:null,
            saving_adjustment:false,

            re_fetching_items:false,
            adjustment_id:null


        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()


        // reduce water fall
        this.viewType = this.$route.query.type;
        this.adjustment_id = this.$route.query.id;

        const p1 =[

            this.getbranches(),
            this.getlocations(),
            this.getsuppliers(),
            this.setAdjustment()


        ]
        await Promise.all(p1);
        const promises =[
            this.getItems(),
            this.getusers(),

        ]

        await Promise.all(promises);
        await this.reloadcb()





    },
    async mounted() {


        this.addsearchitem()
        // await this.getlocations()

    },



    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getbranches','getusers']),
     //  ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removestockadjustmentSearchedItem']),
        ...mapActions('items',['add_stock_adjustment_searched_items','reset_stock_adjustment_searched_items','refill_stock_adjustment_searched_items']),


        async  setAdjustment(){
            if(this.viewType!=='Add'){

                this.saving_adjustment=true
                const {data} = await apiService.get(`/get-adjustment/${this.adjustment_id}`);
              this.edit_adjustment(data)
                this.saving_adjustment=false



            }
        },
        async getItems() {
            const {data} = await apiService.get(`/items_with_stock`);


            let  itemdata=data.map((d)=>{
                let mapid=Math.random()
                return { ...d, qty: 1, picked:0 , mapid:mapid};
            })

            this.items=itemdata

        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('branches', data)
            this.suppliers=data
        },

        // watch: {
        //     stock_adjustment_searched_items: {
        //         handler(newValue) {
        //             // Note: `newValue` will be equal to `oldValue` here
        //             // on nested mutations as long as the object itself
        //             // hasn't been replaced.
        //             //    console.log(newValue,'by watch',oldValue)
        //             if(this.adjustment_reason=='Opening'){
        //                 let targetobj=  newValue.find((n)=>Number(n.qty)<1)
        //                 if (targetobj){
        //                     //  console.log(targetobj.qty,'target qty')
        //                     if(targetobj.id!=0 && targetobj.qty!=''){
        //                         targetobj.qty=1
        //                     }
        //                 }
        //             }
        //             else if(this.adjustment_reason=='Damage' || this.adjustment_reason=='Lost' || this.adjustment_reason=='Consumed'){
        //                 let targetobj=  newValue.find((n)=>Number(n.qty)>-1)
        //                 if (targetobj){
        //                     //  console.log(targetobj.qty,'target qty')
        //                     if(targetobj.id!=0 && targetobj.qty!=''){
        //                         targetobj.qty=-1
        //                     }
        //                 }
        //             }
        //             else if(this.adjustment_reason=='Other' ){
        //                 let targetobj=  newValue.find((n)=>Number(n.qty)==0)
        //                 if (targetobj){
        //                     //  console.log(targetobj.qty,'target qty')
        //                     if(targetobj.id!=0 && targetobj.qty!=''){
        //                         targetobj.qty=1
        //                     }
        //                 }
        //             }
        //
        //         },
        //         deep: true
        //     }
        // },



       async refresh_items(){
           this.re_fetching_items=true;
            await this.getItems()
           this.re_fetching_items=false;

        },
        add_new_adjustemnt(){
           this.viewType='Add'
            this.refill_stock_adjustment_searched_items([])
            this.adjustment_reason='';
                this.location_id='';
                this.date=new Date();

        },

        edit_adjustment(adjustment){
         this.stock_id=adjustment.id
         this.viewType='Update';
         this.adjustment_reason=adjustment.adjustment_reason;
         this.location_id=adjustment.location_id;
         this.date=adjustment.date;
         this.note=adjustment.note;



         let mapiddata=adjustment.dispatchitems.map((ai)=>{
             let mapid=Math.random()
             return {
                 ...ai, mapid:mapid
             }

         })


            console.log('mapiddata',mapiddata)



         this.refill_stock_adjustment_searched_items(mapiddata)

            // console.log(this.stock_adjustment_searched_items)



        },
        handle_searched_items_sort() {
            let sort_type=this.sort_type
            let searched_items = cloneDeep(this.stock_adjustment_searched_items);

            const naturalSort = (a, b) => {
                return a.itemCode.localeCompare(b.itemCode, undefined, { numeric: true, sensitivity: 'base' });
            };

            if (sort_type === 'a_z') {
                searched_items.sort(naturalSort);
            } else if (sort_type === 'z_a') {
                searched_items.sort((a, b) => naturalSort(b, a));
            } else if (sort_type === '1_9') {
                searched_items.sort(naturalSort);
            } else if (sort_type === '9_1') {
                searched_items.sort((a, b) => naturalSort(b, a));
            }

            this.refill_stock_adjustment_searched_items(searched_items)
        },
        handle_location_change(){




            if(this.adjustment_reason=='Stock Check'){
                let p_n_items;

                if(this.supplier_id){
                     p_n_items=cloneDeep(this.items).filter((i)=>Number(i['Stock']).toFixed(2)!=0)
                    p_n_items=p_n_items.filter((i)=>i.def_supplier_id==this.supplier_id)

                    if(!this.authenticatedUser.permissions.includes('super')){
                        this.location_id=this.locations.find((bl)=>bl.branch_id==this.authenticatedUser.branch_id).id

                    }

                    p_n_items=p_n_items.map((p)=>{
                        let ss=Number(p['stock']).toFixed(2);
                        let bas=Number(p['stock']).toFixed(2);
                        return {
                            ...p,
                            selected:true,
                            qty:Number(bas-ss).toFixed(2),
                            branch_actual_stock:bas,
                            system_stock:ss
                        }
                    }).sort((a, b) => a.itemCode.localeCompare(b.itemCode));
                }

                if(this.location_id){
                     p_n_items=cloneDeep(this.items).filter((i)=>Number(i[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2)!=0)
                   if(this.supplier_id){
                       p_n_items=p_n_items.filter((i)=>i.def_supplier_id==this.supplier_id)
                   }

                    this.fill_branch_actual_stock()
                    p_n_items=p_n_items.map((p)=>{
                        let ss=Number(p[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2);
                        let bas=Number(p[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2);
                        return {
                            ...p,
                            selected:true,
                            qty:Number(bas-ss).toFixed(2),
                            branch_actual_stock:bas,
                            system_stock:ss
                        }
                    }).sort((a, b) => a.itemCode.localeCompare(b.itemCode));
                }



                this.refill_stock_adjustment_searched_items(p_n_items)


               console.log(this.stock_adjustment_searched_items,'this.stock_adjustment_searched_items')
            }

            if(this.viewType=='Update'){
                // this.reset_items()
            }

        },

        fill_branch_actual_stock(){
            try {
                if(this.location_id && (this.adjustment_reason=='Other' || this.adjustment_reason=='Stock Check') ){
                    // this.reset_items()
                    let filled_stock_adjustment_searched_items=cloneDeep(this.stock_adjustment_searched_items).map((sa)=>{
                        let new_cal= {
                            ...sa,
                            selected:true,
                            qty:'branch_actual_stock' in sa?sa.qty:Number((Number(sa[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2))-(Number(sa[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2))).toFixed(2),
                            branch_actual_stock:'branch_actual_stock' in sa?sa.branch_actual_stock:Number(sa[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2),
                            system_stock:'system_stock' in sa?sa.system_stock:Number(this.items.find((i)=>i.itemCode==sa.itemCode)[`${(this.locations.find((lc)=>lc.id==this.location_id).location_name).replace(/\s/g, '')}Stock`]).toFixed(2)

                        }

                        // console.log(new_cal,'new_cal')


                        return new_cal
                    })


                    this.refill_stock_adjustment_searched_items(filled_stock_adjustment_searched_items)
                    // this.stock_adjustment_searched_items.forEach((item)=>{
                    //    this.handle_actual_stock_change(item.mapid)
                    // })
                }
            }
            catch(err) {
               //
            }


        },
        handle_actual_stock_change(mapid){
            let item = this.stock_adjustment_searched_items.find((item) => item.mapid == mapid);

            if(Number(item.branch_actual_stock)<0){
                item.branch_actual_stock=0
            }
         let bas=Number(item.branch_actual_stock).toFixed(2);
            let ss=Number(item.system_stock).toFixed(2)
            if (ss=='NaN'){
                ss=0
            }
            item.qty=Number(bas-ss).toFixed(2)


            // this.handlechange(mapid)
        },
        handlechange(mapid) {
            //  alert(mapid);
            let item = this.stock_adjustment_searched_items.find((item) => item.mapid == mapid);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(this.adjustment_reason=='Opening'){

                if(qty<1){
                    if(item.id!=0 && item.qty!=''){
                        if (box_qty) {
                            item.qty = Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty = 1
                        }
                    }
                }
                let validqty = qty / box_qty
                if (box_qty) {
                    if (validqty < 1) {
                        item.qty = Number(item.box_qty).toFixed(2)
                    }
                        // else if (qty % box_qty == 0) {
                        //     //
                    // }
                    else {
                        item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                    }
                }
            }
            else if (this.adjustment_reason=='Damage' || this.adjustment_reason=='Lost' || this.adjustment_reason=='Consumed'){

                if(qty>-1){
                    if(item.id!=0 && item.qty!=''){
                        if (box_qty) {
                            item.qty=-Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty=-1
                        }

                    }
                }
                let validqty = Number(item.qty) / box_qty


                if (box_qty) {

                    if (validqty > -1 && validqty<0) {

                        item.qty = -Number(item.box_qty).toFixed(2)
                    }
                        // else if (qty % box_qty == 0) {
                        //     //
                    // }
                    else {

                        item.qty = Number((Math.floor(Number(Number(item.qty) / box_qty).toFixed(2))) * box_qty).toFixed(2)
                    }
                }
            }
            else if(this.adjustment_reason=='Other'){
                // if(qty==0){
                //     item.qty=1
                // }
            }



        },
        handleactualstockchange(mapid) {
            //  alert(mapid);
            let item = this.stock_adjustment_searched_items.find((item) => item.mapid == mapid);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.branch_actual_stock);
            if(this.adjustment_reason=='Other' || this.adjustment_reason=='Stock Check'){

                if(qty<1){
                    // if(item.id!=0 && item.qty!=''){
                    //     if (box_qty) {
                    //         item.qty = Number(item.box_qty).toFixed(2)
                    //     } else {
                    //         item.qty = 1
                    //     }
                    // }
                }
                let validqty = qty / box_qty
                if (box_qty) {
                    // alert(validqty)
                    if (validqty < 0) {



                         //   item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                           item.branch_actual_stock = -Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)




                    }
                        // else if (qty % box_qty == 0) {
                        //     //
                    // }
                    else {
                      //  item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                        item.branch_actual_stock = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)

                    }
                }
            }

            this.handle_actual_stock_change(mapid)



        },


        reset_items(){
            this.supplier_id='';
            this.reset_stock_adjustment_searched_items()
            this.addsearchitem()
        },


        async save_stock(){
            let ledger_entry={
                items:this.stock_adjustment_searched_items,
                location_id:this.location_id,
                adjustment_reason:this.adjustment_reason,
                date:moment(this.date).format('YYYY-MM-DD HH:mm:ss'),
                user_id:this.authenticatedUser.id,
                note:this.note

            }
            console.log(ledger_entry)

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            let api_url=`${URL.BASEURL}/api/save-stock`;
            if(this.viewType=='Update'){
                api_url=`${URL.BASEURL}/api/update-saved-stock/${this.stock_id}`;
            }

            try {
                this.fetching_history=true;
                this.saving_adjustment=true;
                this.approving_adjustment=true;
                const res = await axios.post(api_url, ledger_entry);

                if (res) {
                    this.fetching_history=false;
                    this.saving_adjustment=false;
                    this.approving_adjustment=false;

                    if(this.viewType=='Add'){
                        Swal.fire('Success', 'Stock Adjustment Saved', 'success');
                    } else {
                        Swal.fire('Success', 'Stock Adjustment Updated', 'success');
                    }

                    console.log(res.data)
                    this.reset_stock_adjustment_searched_items()
                    this.addsearchitem()

                    this.location_id='';
                    this.adjustment_reason='';
                    this.note='';
                    this.date=new Date();

                    this.$router.push('/adjustments')



                }
            } catch (error) {
                console.error(error);
                if (this.viewType=='Add'){
                    Swal.fire('Error', 'Failed to Add Stock.', 'info');
                } else {
                    Swal.fire('Error', 'Failed to Update Stock Adjustment.', 'info');
                }
                this.fetching_history=false;
                this.saving_adjustment=false;
                this.approving_adjustment=false;

            }
        },




        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data


        },
        addsearchitem(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)

            if(dummy=='yes' || (this.stock_adjustment_searched_items).length==0) {
                this.add_stock_adjustment_searched_items(newitem)
            }
            else if(itemcode) {
                let   item=this.items.find((i) => i.itemCode==itemcode)
                console.log(item,'item')
                if(item){
                    //  console.log(item[0])
                    let box_qty=item.box_qty
                    if(this.adjustment_reason=='Opening'){
                        if (box_qty) {
                            item.qty=Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty=1
                        }
                    } else if (this.adjustment_reason=='Damage' || this.adjustment_reason=='Lost' || this.adjustment_reason=='Consumed'){
                        if (box_qty) {
                            item.qty=-Number(item.box_qty).toFixed(2)
                        } else {
                            item.qty=-1
                        }
                    }

                    item.selected=true;

                    this.add_stock_adjustment_searched_items(item)


                    const   dummyitem=(this.stock_adjustment_searched_items).find((i) => i.id==0)
                    // console.log(dummyitem)
                    this.removestockadjustmentSearchedItem(dummyitem.mapid)

                    this.fill_branch_actual_stock()


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

        removeitem(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removestockadjustmentSearchedItem(id)

        },


        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){

                this.re_fetching_items=true
                await this.getItems();
                this.re_fetching_items=false

            }


            if(this.suppliers.length==0){
                await this.getsuppliers()
            }
            if(this.users.length==0){
                await this.getusers()
                 // console.log(this.users,'users')
            }

        },



        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
