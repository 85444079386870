<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">

                            <span v-if="actiontype=='Save'"> Create Expense</span>
                            <span v-if="actiontype=='Update'">Update Expense</span>

                        </h1>
                    </div>

                    <div class="col-md-12 text-light">
                        <!--                        <h1 class="text-center">Details</h1>-->
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <!--                        <div class="row mt-1">-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <p><strong>Enter Purchase Order Reference</strong></p>-->
                        <!--                            </div>-->
                        <!--                            <div class="col-md-6">-->
                        <!--                                <b-input v-model="por"></b-input>-->
                        <!--                            </div>-->
                        <!--                        </div>-->


                    </div>
                    <div class="col-md-12">
<!--                        <div class="row">-->
<!--                            <div class="col-md-3">-->
<!--                                &lt;!&ndash;                                <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <b-button class="btn-info " > Add New Item </b-button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </b-link>&ndash;&gt;-->
<!--                            </div>-->
<!--                            <div class="col-md-6">-->
<!--                                <h1 class="text-light text-center">Expense Items</h1>-->
<!--                            </div>-->
<!--                        </div>-->

                        <div class="row mb-2" >
                            <div class="col-md " v-if="authenticatedUser.permissions.includes('super')">
                                <v-select
                                    v-model="branch_id"

                                    class=""
                                    placeholder="Choose a Branch"
                                    :options="branches"
                                    label="name"
                                    :reduce="option => option.id"
                                    :disabled="actiontype=='View'"

                                />
                            </div>

                            <div class="col-md-2 " v-if="supplier_id!=4">
                                <v-select
                                    v-model="supplier_id"

                                    class=""
                                    placeholder="Choose a Supplier"
                                    :options="expensesuppliers"
                                    label="name"
                                    :reduce="option => option.id"
                                    :disabled="actiontype=='View'"

                                />
                            </div>
                            <div class="col-md-1 " v-if="supplier_id==4">
                                <v-select
                                    v-model="supplier_id"

                                    class=""
                                    placeholder="Choose a Supplier"
                                    :options="expensesuppliers"
                                    label="name"
                                    :reduce="option => option.id"
                                    :disabled="actiontype=='View'"

                                />
                            </div>
                            <div class="col-md " v-if="supplier_id==4">
                                <b-input
                                    type="text"
                                    v-model="other_supplier_name"

                                    placeholder="Enter Supplier Name"
                                    :disabled="actiontype=='View'"
                                ></b-input>
                            </div>
                            <div class="col-md " >
                                <v-select
                                    v-model="cashier_id"

                                    class=""
                                    placeholder="Choose who bought"
                                    :options="plus_cashiers"
                                    label="fullName"
                                    :reduce="option => option.id"
                                    :disabled="actiontype=='View'"

                                />
                            </div>
                            <div class="col-md-2 " >
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="example-datepicker"
                                    v-model="date"
                                    placeholder="Select Date"
                                    class="mb-2"
                                    dropup  calendar-width="100%">
                                    :disabled="actiontype=='View'"

                                </b-form-datepicker>
                            </div>

                            <div class="col-md " >
                                <v-select
                                    v-model="payment_menthod"

                                    class=""
                                    placeholder="Payment Method"
                                    :options="['Pleo','Cash', 'Amazon']"
                                    label="name"
                                    :disabled="actiontype=='View'"


                                />
                            </div>
                            <div class="col-md text-light" >
                                <b-form-file
                                    class="text-light"

                                    v-model="receipt"
                                    :placeholder="'Upload Receipt'"
                                    browse-text="Browse"
                                    :disabled="actiontype=='View'"

                                />
<!--                                <input type="file" @change="handlereceiptChange" />-->
                            </div>

<!--                            <div class="col-md-2 " v-if="authenticatedUser.permissions.includes('super')">-->
<!--                                <v-select-->
<!--                                    v-model="status"-->

<!--                                    class=""-->
<!--                                    placeholder="Choose a Status"-->
<!--                                    :options="['Draft','Pending', 'Approved','Rejected']"-->
<!--                                    label="name"-->
<!--                                    :disabled="actiontype=='View'"-->


<!--                                />-->
<!--                            </div>-->


                        </div>


                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col">Price</th>
                                <th scope="col" >Qty</th>
                                <th scope="col" >Sub Total</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in EItems" :key="item.mapid" :value="`${item.id}_${item.name}`">{{ item.category.name }}  </option>

                            </datalist>
                            <template v-if="expense_searched_items">
                                <tr class="text-light"  v-for="item in expense_searched_items" :key="item.mapid">
                                    <th class="col-md-3 ">
                                        <div class="input-container w-100">
                                            <i v-if="actiontype!='View'"  class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem(item.mapid)" style="margin-right: 0.5rem;"></i>
                                            <input class="bg-light col-md-11 form-control" v-model="item.name"  list="item-list-id"  @change="addsearchitem('',item.name, item.mapid)"  :disabled="actiontype=='View'" >

                                        </div>
                                    </th>

                                    <td><b-input class=" bg-light form-control" v-model="item.description"   :disabled="actiontype=='View'"> </b-input></td>
                                    <td class="col-md-2 "><input type="number" v-model="item.qty"  :disabled="actiontype=='View'"  class="bg-light form-control" ></td>
                                    <td class="col-md-2 "><input type="number" v-model="item.price"  :disabled="actiontype=='View'"  class="bg-light form-control" ></td>
                                    <td class="col-md-2 "><input type="number" :disabled="true" :value="Number(item.qty*item.price).toFixed(2)"  class="bg-light form-control" ></td>



                                </tr>
                            </template>
                            <tr>
                                <th scope="row"  >

                                    <span v-if="actiontype!='View'">
                                         <i   class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus" @click="addsearchitem('yes')"  ></i>
                                    </span>

                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-light" style="font-size: 1.2rem !important;font-weight: bolder"><span v-if="expense_grandTotal!='NaN'">£{{expense_grandTotal}}</span> </td>




                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row ">
                    <div class="col-md-2">
                        <!--                        <label><strong>Transfer Stock From</strong></label>-->

                    </div>
                    <div class="col-md-4 offset-2" v-if="actiontype!='View'">
                        <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesaveexpense" @click="add_expense">

                         <span v-if="actiontype=='Save'">
                                Add Expense
                         </span>
                         <span v-if="actiontype=='Update'">
                                Update Expense
                         </span>

                        </button>
                    </div>
                    <!--                            <div class="col-md-4">-->
                    <!--                                <label><strong>Transfer Stock To</strong></label>-->
                    <!--                                <b-form-select-->
                    <!--                                    v-model="location_id_to"-->
                    <!--                                    :options="locations"-->
                    <!--                                    class="bgsecond bform text-white mb-2 "-->

                    <!--                                    value-field="id"-->
                    <!--                                    text-field="location_name"-->
                    <!--                                >-->

                    <!--                                    <template #first>-->
                    <!--                                        <b-form-select-option value="">To Location</b-form-select-option>-->
                    <!--                                    </template>-->
                    <!--                                    <template #option="{ option }">-->
                    <!--                                        <div>{{ option.location_name }}</div>-->
                    <!--                                    </template>-->

                    <!--                                </b-form-select>-->
                    <!--                            </div>-->
                    <!--                            <div class="col-md-4">-->
                    <!--                                <label><strong>Select Date</strong></label>-->
                    <!--                                <b-form-datepicker-->

                    <!--                                    :reset-button=false-->
                    <!--                                    reset-button-variant="btn btn-warning"-->
                    <!--                                    id="datepicsuper"-->

                    <!--                                    v-model="date"-->
                    <!--                                    placeholder="Date"-->

                    <!--                                    dropup  calendar-width="100%">-->

                    <!--                                </b-form-datepicker>-->
                    <!--                            </div>-->
                </div>

                <div class="row" v-if="actiontype!='Save' && expense.receipt_url">
                  <div class="col-6 offset-3">
                      <figure class=" text-light">
                          <figcaption class="mt-2">Receipt</figcaption>
                          <a :href="expense.receipt_url" target="_blank">
                              <img :src="expense.receipt_url" class="w-100" alt="Receipt" />
                          </a>

                      </figure>
                  </div>
                </div>



            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import newexpenseitem from "@/constants/newexpenseitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote.vue";
import vSelect from "vue-select";
import {cloneDeep} from "lodash";
// import cashiers from "@/pages/admin/mypos/cashiers.vue";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{Welcomenote, 'sidebar': sidebar,'v-select': vSelect,'ShowRealTime':showrealtime},
    computed: {

        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['users','branches','cashiers']),

        ...mapState('expenseitems',['expenseitems','expensesuppliers','expense_searched_items','pageitems']),
      //  ...mapState('expenseitems',['expense_grandTotal']),

        expense_grandTotal(){
            return (this.expense_searched_items.reduce((total, item) => total + Number(item.price) * Number(item.qty), 0)).toFixed(2);
        },
        disablesaveexpense(){
            if( (!this.receipt && this.actiontype=='Save') ||!this.supplier_id || !this.cashier_id || !this.payment_menthod || (!this.branch_id && this.authenticatedUser.permissions.includes('super')) || !this.date || (this.supplier_id==4 && !this.other_supplier_name) || this.expense_searched_items.find((e)=>e.price==0 || e.qty==0) || !this.expense_searched_items.length){
                return true
            } else return false
        },
        EItems(){
            return this.expenseitems.filter((ei)=>ei.parent_category_id!=null)
        },

        showitemdatalist(){
            if(this.expense_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.expense_searched_items.filter((item)=>item.name=='')
            if(newitems.length==0){
                return true
            }
            else {
                return false
            }
        },

        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        },



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {


            plus_cashiers:[],
            expense:'',
            expense_id:'',
            status:'Draft',
            user_id:'',
            branch_id:'',
            supplier_id:'',
            other_supplier_name:'',
            searchitem:'',
            cashier_id:'',
            payment_menthod:'',
            receipt:null,


            date:new Date()

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()
        // await this.getcashiers()
        const promises =[


            this.reloadcb()


        ]
        // Wait for all promises to complete
        await Promise.all(promises);

        this.expense_id = this.$route.query.id;
        this.setExpense()



    },
    async mounted() {
        if(this.actiontype=='Save'){
            this.addsearchitem()
        }



    },



    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getusers','getcashiers']),
        ...mapActions('expenseitems', ['getExpenseItems','getExpenseSuppliers','attach_expense_searched_items']),
        ...mapActions('expenseitems', ['removeexpenseSearchedItem']),
        ...mapActions('expenseitems',['add_expense_searched_items','reset_expense_searched_items']),

        setExpense(){
            let c = cloneDeep(this.cashiers);

            const additionalCashiers = [
                { id:56, fullName:"Dhrumi" },
                { id:53, fullName:"Kunal" },
                { id:5, fullName:"Samir Ilyas" },
                { id:29, fullName:"Asim" }
            ];


            const newCashiers = additionalCashiers.filter(newCashier =>
                !c.find(existingCashier => existingCashier.id === newCashier.id)
            );

            this.plus_cashiers = [...c, ...newCashiers];


            if(this.actiontype=='Update' || this.actiontype=='View'){

                this.expense=cloneDeep(this.pageitems).find(c=>c.id==this.expense_id)
                 this.status=this.expense.status


                 this.branch_id=this.expense.branch_id
                 this.supplier_id=this.expense.expenseSupplier_id
                 this.cashier_id=this.expense.cashier_id
                this.other_supplier_name=this.expense.other_supplier_name
                this.date=this.expense.date

                 this.payment_menthod=this.expense.payment_method


                let searched_items=this.expense.expenseitems.map((si)=>{

                    let mapid=Math.random()
                    let datawithmapid = { ...si, mapid: mapid,id:si.expenseCategory_id,name:si.expenseCategory_name,description:si.expenseCategory_description };
               return datawithmapid
                })

                this.attach_expense_searched_items(searched_items)





            } else{
        this.reset_items()
            }
        },

        // handlechange(mapid) {
        //     //  alert(mapid);
        //     let item = this.mr_searched_items.find((item) => item.mapid == mapid);
        //     let box_qty = Number(item.box_qty);
        //     let qty = Number(item.qty);
        //     if(qty==0){
        //         if(item.id!=0 && item.qty!=''){
        //             item.qty=Number(item.box_qty).toFixed(2)
        //         }
        //     }
        //     let validqty = qty / box_qty
        //     if (box_qty) {
        //         if (validqty < 1) {
        //             item.qty = Number(item.box_qty).toFixed(2)
        //         }
        //             // else if (qty % box_qty == 0) {
        //             //     //
        //         // }
        //         else {
        //             item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
        //         }
        //     }
        //     (this.mr_searched_items.find((item)=>item.qty=='')).qty=1
        //
        // },

        handlereceiptChange(event) {
            const file = event.target.files[0];
            console.log(file,'file')
           this.receipt=file;
        },





        reset_items(){
            this.reset_expense_searched_items()
            this.addsearchitem()
        },
        async add_expense(){
            // console.log(this.receipt)
            let ex_entry={
                status:this.status,
                items:JSON.stringify(this.expense_searched_items),
                branch_id:this.branch_id,
                supplier_id:this.supplier_id,
                cashier_id:this.cashier_id,
                other_supplier_name:this.other_supplier_name,
                receipt:this.receipt,
                date:moment(this.date).format('YYYY-MM-DD HH:mm:ss'),
                payment_menthod:this.payment_menthod,
                total_payment:this.expense_grandTotal,
                user_id:this.authenticatedUser.id

            }
            const formData = new FormData();
            // Append form data here, for example:
            for (const [key, value] of Object.entries(ex_entry)) {
                formData.append(key, value);
            }

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            let api_url='api/add-expense'
            if(this.actiontype=='Update'){
                api_url=`api/update-expense/${this.expense_id}`
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/${api_url}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });

                if (res) {

                    if(this.actiontype=='Save')
                    {
                        Swal.fire('Success', 'Expense Added', 'success');
                    }
                    if(this.actiontype=='Update')
                    {
                        Swal.fire('Success', 'Expense Updated', 'success');

                        this.$router.push('/expenses');
                    }

                    // console.log(res.data)
                    this.reset_expense_searched_items()
                    this.addsearchitem()

                    this.branch_id='';
                    this.cashier_id='';
                    this.supplier_id='';
                    this.payment_menthod='';
                    this.other_supplier_name='';
                    this.receipt=null;
                    this.status='Draft';
                    this.date=new Date();



                }
            } catch (error) {
                console.error(error);
                if(this.actiontype=='Save'){
                    Swal.fire('Error', 'Failed to Add Expense.', 'info');
                }
                if(this.actiontype=='Update'){
                    Swal.fire('Error', 'Failed to Update Expense.', 'info');
                }

            }
        },

        addsearchitem(dummy='', idname=null,mapid=null){


            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.expense_searched_items).length==0) {
                this.add_expense_searched_items(newexpenseitem)
            }
            else if(idname) {
                const [id, name]=idname.split("_");
                let   item=this.EItems.find((i) => i.id==id)

                // if(item.min_qty){
                //     item.qty=item.min_qty
                // } else if(item.box_qty){
                //     item.qty=item.box_qty
                // }

                //   console.log(item,'item======')
                if(item){
                    //  console.log(item)
                    this.add_expense_searched_items({...item,price:0,qty:0})


                    const   dummyitem=(this.expense_searched_items).find((i) => i.id==0)
                    //   console.log(dummyitem)
                    this.removeitem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this name', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

        removeitem(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removeexpenseSearchedItem(id)

        },


        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, YYYY');
            //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.EItems.length==0){
                //   alert(this.branches.length)
                await this.getExpenseItems();

            }
            if(this.expensesuppliers.length==0){
                await this.getExpenseSuppliers();
            }
            if(this.cashiers.length==0){
                await this.getcashiers();
                let c = cloneDeep(this.cashiers);

                const additionalCashiers = [
                    { id:56, fullName:"Dhrumi" },
                    { id:53, fullName:"Kunal" },
                    { id:5, fullName:"Samir Ilyas" },
                    { id:29, fullName:"Asim" }
                ];


                const newCashiers = additionalCashiers.filter(newCashier =>
                    !c.find(existingCashier => existingCashier.id === newCashier.id)
                );

                this.plus_cashiers = [...c, ...newCashiers];
            }

        },



        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
