<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
             <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#"  @click.prevent="signOut">Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

           <div class="row">
               <div class="col-md-6">
                   <div class="row  mt-2">

                       <b-link class="" :to="{ path: '/cashierform',query: { type: 'add'} }">
                           <b-button class="btn-info mb-2" > Add New Cashier </b-button>
                       </b-link>

                       <b-table id="cashiers-table"  class="bg-light" :items="cashiers" :fields="fields" striped hover responsive  :per-page="perPage" :current-page="currentPage">


                           <template #cell(actions)="data">

                               <b-link class="" :to="{ path: '/cashierform',query: { type: 'edit', 'id': data.item.id } }">
                                   <b-button class="btn-info mb-2" > Edit </b-button>
                               </b-link>
                               <b-link class="mx-1" :to="{ path: '/cashierform',query: { type: 'view', 'id': data.item.id } }">
                                   <b-button class="btn-info mb-2" > View </b-button>
                               </b-link>
<!--                               <b-link class="mx-1" :to="{ path: '/cashierform',query: { type: 'view', 'id': data.item.id } }">-->
<!--                                   <b-button class="btn-info mb-2" > Orders </b-button>-->
<!--                               </b-link>-->

                           </template>

                       </b-table>
                       <b-pagination
                           class=""
                           v-model="currentPage"
                           :total-rows="cashiers.length"
                           :per-page="perPage"
                           aria-controls="cashiers-table"
                       ></b-pagination>
                   </div>
               </div>

           </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import vSelect from "vue-select";


export default {
    name: "cashiers",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),

         ...mapState('invoice', ['cashiers']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            roles: [],
            user: {},

            cashier_id:'',
            order_status:'',
            branch_id:'',
            perPage:11,
            currentPage: 1,
            fields: [

                { key: 'fullName', label: 'Name' },

                'actions'

            ],

        };
    },
    async created() {
        await this.getUsers();
        await this.getRoles();
        // await this.getbranches()
         await this.getcashiers()


    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getcashiers']),



        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
