<template>
    <div class="container-fluid" id="printMe">




        <div class="row mt-5">
                 <div class="col-6 offset-1 ">
                     <img class="col-12 w-100 " src="@/assets/images/rtlogo.png" @load="print" >


                 </div>
                 <div class="col-5">
                     {{branches.find((b)=>b.id==invoice.branch_id).addressLine1}}, <br>
                     {{branches.find((b)=>b.id==invoice.branch_id).addressLine2}}, <br>
                     {{branches.find((b)=>b.id==invoice.branch_id).state}}, <br>
                     {{branches.find((b)=>b.id==invoice.branch_id).postcode}} <br> <br>
                     Tel: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  {{branches.find((b)=>b.id==invoice.branch_id).tel}} <br>
                     Email: &nbsp; &nbsp; &nbsp;   {{branches.find((b)=>b.id==invoice.branch_id).email}}


                 </div>
             </div>

        <div class="row mt-5">
            <div class="col-6 offset-1 ">
            <h3 class="mx-5">VAT Reg No : 342773884</h3>
             <div class="row">
             <div  style="min-height: 128px; border: 1px solid black; border-radius: 10px" class=" col-11 ">
               <h4>  {{selected_customer.lastName}} </h4>
               <h4>  {{selected_customer.mobile}} </h4>
               <h4>  {{selected_customer.email}} </h4>
               <h4> {{selected_customer.addressLine1}} </h4>
               <h4> {{selected_customer.addressLine2}} </h4>
               <h4> {{selected_customer.postcode}} </h4>
             </div>
             </div>

            </div>
            <div class="col-5">
                <div class="row">
                    <h3 class="col-6">
                       <span v-if="invoice.status=='Quote'">QUOTE</span>
                        <span v-else-if="invoice.status=='Deposit'">SALES ORDER </span>
                        <span v-else="">INVOICE </span>
                      </h3>
                    <h3 class="col-6">Page 1</h3>

                </div>
                <div class="row">
                    <div  style="" class=" col-11 ">
                        <table class="table table-bordered">

                            <tbody>
                            <tr>

                                <td> <strong>

                                    <span v-if="invoice.status=='Quote'">Quote</span>
                                    <span v-else=""> Invoice</span>
                               No </strong> </td>
                                <td> <strong>{{invoice.invoice_ref}} </strong></td>
                            </tr>
                            <tr>
                                <td><strong>

                                    <span v-if="invoice.status=='Quote'">Quote</span>
                                    <span v-else=""> Invoice </span>

                               Date</strong></td>
                                <td><strong>{{formattedDate}}</strong></td>

                            </tr>
                            <tr>
                                <td><strong>Sales Person</strong></td>
                                <td><strong>{{selectedCashier.fullName}}</strong></td>

                            </tr>
<!--                            <tr>-->
<!--                                <td><strong>Account Ref</strong></td>-->
<!--                                <td class="text-uppercase"><strong>{{accountref}}</strong></td>-->

<!--                            </tr>-->

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <div class="row mt-3">
            <div class="col-10 offset-1" >
<!--                <h3>This is not a V.A.T-->

<!--                    <span v-if="invoice.status=='Quote'">Quotation</span>-->
<!--                    <span v-else=""> Invoice</span>-->
<!--                </h3>-->
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-10 offset-1" >
            <div class="row" style=" border: 1px solid black; border-radius: 7px">

                <div class="col-1 text-center">
                    <strong> QTY </strong>
                </div>
                <div class="col-2">
                    <strong> Product Code </strong>
                </div>
                <div class="col-6">
                    <strong> Description </strong>
                </div>

                <div class="col- text-right">
                    <strong> Unit Price </strong>
                </div>
                <div class="col-2 text-right">
                    <strong> Net Amount </strong>
                </div>
            </div>
           <div class="row" v-for="item in searched_items" :key="item.mapid">

               <div class="col-1 text-center">
                   <strong> {{item.qty}} </strong>
               </div>
               <div class="col-2 ">
                   <strong class="wrap">  {{item.itemCode}}  </strong>
               </div>
               <div class="col-6">
                   <strong class="wrap"> {{item.itemDescription}}  </strong>
               </div>

               <div class="col-1 text-center">
                   <strong> {{item.defaultPrice}} </strong>
               </div>
               <div class="col-2 text-center">
                   <strong> £{{(item.qty*item.defaultPrice).toFixed(2)}}  </strong>
               </div>
           </div>

            </div>
        </div>


        <br> <br> <br> <br> <br> <br> <br> <br> <br> <br> <br> <br> <br> <br>
        <div class="row mt-3">
            <div class="col-10 offset-1" >
            <div class="row">
               <div class="col-12">
                   <h2 class="font-weight-bold">COLLECTION TIMES: MON - FRI 9AM - 4PM</h2>

               </div>
                <h1 class="col-9 "> <hr class="hr1"></h1>
            </div>
            </div>
            <div class="col-12 " >
              <div class="row">
                  <h1 class="col-10 offset-1"> <hr class="hr2"></h1>
                  <div class="col-7 offset-1 sp" style="line-height: 2px">
                  <p>1. ALL OUR DELIVERIES ARE KERB SIDE ONLY</p>
                  <p>2. ONCE DELIVERY NOTE IS SIGNED DISCREPANCIES WILL NOT BE ACCEPTED</p>
                  <p>3. ADHESIVE, GROUT & PERISHABLE GOODS ARE NON RE-FUNDABLE</p>
                  <p>4. NO RETURNS ON SPECIAL ORDERS</p>
                  <p>5. GOODS MUST BE RETURNED IN 28 DAYS</p>
                  <p>6. REFUND & COLLECTIONS CAN ONLY BE DONE WITH ORIGINAL RECEIPT</p>
                  <p>7. FULL UNDAMAGED PACKS OF STOCKED TILES (10% OF TOTAL PURCHASE)</p>
                  <p class="wrap">8. 25% - 50% RESTOCKING CHARGE ON ALL GOODS (MUST BE IN ORIGINAL PACKAGING)</p>
                  <p>9. ALL ITEMS REMAIN THE PROPERTY OF RIGHT TILES UNTIL PAID IN FULL</p>
                      <h3 class="font-weight-bold"> LLOYDS CERAMICS LTD</h3>
                      <p>LLOYDS BANK</p>
                      <p>30-99-09</p>
                      <p>43462668</p>
                  </div>

                  <div class="col-3" >
                      <table class="table table-bordered margin-leftmd">

                          <tbody>
                          <tr>

                              <td> <strong>Total Net Amount </strong> </td>
                              <td> <strong>£ {{(invoice.grandTotal-invoice.vat).toFixed(2)}} </strong></td>
                          </tr>
<!--                          <tr>-->
<!--                              <td><strong>Carriage</strong></td>-->
<!--                              <td><strong>£ 0</strong></td>-->

<!--                          </tr>-->
                          <tr>
                              <td><strong>Total VAT Amount</strong></td>
                              <td><strong>£ {{(invoice.vat).toFixed(2)}}</strong></td>

                          </tr>
                          <tr>
                              <td><strong>Order Total</strong></td>
                              <td><strong>£ {{(invoice.grandTotal).toFixed(2)}}</strong></td>

                          </tr>
                          <tr v-if="invoice.status!='Quote'">
                              <td><strong>Paid</strong></td>
                              <td><strong>£ {{(invoice.totalPaid).toFixed(2)}}</strong></td>

                          </tr>
                          <tr v-if="invoice.status!='Quote'">
                              <td><strong>Balance</strong></td>
                              <td><strong>£ {{((invoice.grandTotal).toFixed(2)-(invoice.totalPaid).toFixed(2)).toFixed(2)}}</strong></td>

                          </tr>

                          </tbody>
                      </table>
                  </div>
                  <div class="col-1">

                  </div>

              </div>
            </div>
        </div>


    </div>
</template>

<script>


import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
//import $ from "jquery";
import moment from "moment";




export default {
    name: "print-invoice",
    props: ['mode'],
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['invoice','cashiers','branches']),
        ...mapState('items',['searched_items']),
        ...mapState('customers', ['selected_customer']),
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        formattedDate() {
            let currentDate = new Date();
            if (this.invoice.issueDate !== "") {
                currentDate = new Date(this.invoice.issueDate);
            }

            const formattedDate = moment(currentDate).format('YYYY-MM-DD');

            return formattedDate;
        },
        selectedCashier(){
            return  this.cashiers.filter((c)=>c.id==this.invoice.cashier_id)[0]
        },
        accountref() {
            let ref=''
           if(this.invoice.cashPayment>0){
              ref= ref+'Cash'
               return ref
           }
            if(this.invoice.bankPayment>0){
                ref= ref+' Bank'
                return ref
            }
            if(this.invoice.cardPayment>0){
                ref= ref+' Card'
                return ref
            }
            else {
                return ''
            }
        },

    },
    data() {
        return {


        };
    },
      created() {


//


    },
     mounted() {

     //this.print()

    },
    methods: {
        print () {
            console.log(this.invoice,'print')

                if (this.mode!='send') {
                    window.print()
                    // window.history.back();
                    this.$router.push({ path: '/' })
                }


        },


    },
}
</script>

<style scoped>



#printMe{
    background-color: white !important;
}
.table-bordered {
    outline: 1px solid black;

}
tbody tr {
    outline: 1px solid black;
     border: 0px solid black;
}
.table {
    border: 0px solid black !important;
}
.hr1{
    margin-top: -5px;
    margin-bottom: 0px;
    margin-right: 6%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}

.hr2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 2%;
    border: 0;
    border-top: 3px solid #4f4e4e;
    max-width: 100%;

}
.wrap {
    word-wrap: break-word; /* Wrap long words within the available space */
}

</style>
