<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row mt-1">

                    <div class="col-md-1 offset-4">
                        <button class="btn-sm btn-info"     :disabled="itemsdata.current_page==1" @click="getExpenses('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2"  style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="itemsdata.to-10>0">{{itemsdata.to-10}}</span> <span v-else="">0</span>  to {{itemsdata.to}}) out of {{itemsdata.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "     @click="getExpenses('next')">Next</button>
                    </div>



                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                </div>
                <div class="row  mt-3 ml-1">

                    <b-link class="" :to="{ path: '/expenseform',query: { type: 'add'} }" >
                        <b-button class="btn-info mb-2" > Add New Expense </b-button>
                    </b-link>

<!--                    <div class="col-md-2 ">-->
<!--                        <b-input v-model="pfilters.searchprdtname"  placeholder="Product Name" @input="getExpenses"></b-input>-->
<!--                    </div>-->
<!--                    <div class="col-md-2">-->
<!--                        <b-input v-model="pfilters.searchprdtcode" placeholder="SKU" @input="getExpenses"></b-input>-->
<!--                    </div>-->
                    <div class="col-md-1">
                        <button class="btn btn-info" @click="reloaditems">
                            <span v-if="showloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Refresh
                        </button>
                    </div>
                    <div class="col-md-2" v-if="authenticatedUser.permissions.includes('super')">
                        <v-select
                            v-model="month"

                            class=""
                            placeholder="Choose a Month"
                            :options="[
                                  'January', 'February', 'March', 'April',
                                  'May', 'June', 'July', 'August',
                                  'September', 'October', 'November', 'December'
                                ]"
                            @input="getitemdetails"



                        />
                    </div>
                    <div class="col-md-2" v-if="authenticatedUser.permissions.includes('super')">
                        <v-select
                            v-model="branch_id"

                            class=""
                            placeholder="Choose a Branch"
                            :options="branches"
                            label="name"
                            :reduce="option => option.id"
                            @input="getitemdetails"

                        />
                    </div>
                     <div class="col-md-2" v-if="authenticatedUser.permissions.includes('super')">
                            <v-select
                                v-model="category_id"

                                class=""
                                placeholder="Choose a Category"
                                :options="categories"
                                label="name"
                                :reduce="option => option.id"
                                @input="()=>{
                                    c_item_id=null;
                                    this.getitemdetails()
                                }"


                            />
                    </div>
                    <div class="col-md" v-if="authenticatedUser.permissions.includes('super') && category_id">
                        <v-select
                            v-model="c_item_id"

                            class=""
                            placeholder="Choose a Item"
                            :options="expenseitems.filter((i)=>i.parent_category_id==category_id)"
                            label="name"
                            :reduce="option => option.id"
                            @input="getitemdetails"

                        />
                    </div>
                    <div class="col-md">

                    </div>
                    <div class="col-md-1">
                        <b-link class="mx-1" :to="{ path: '/expense-reports' }">
                            <button class="btn btn-warning btn-sm " >Expense Reports</button>
                        </b-link>
                    </div>
<!--                    <div class="col-md-2">-->

<!--                        <b-form-select-->
<!--                            v-model="pfilters.supplier_id"-->
<!--                            :options="suppliers"-->
<!--                            class="bgsecond bform text-white mb-2 "-->

<!--                            value-field="id"-->
<!--                            text-field="name"-->
<!--                            @input="getExpenses"-->


<!--                        >-->

<!--                            &lt;!&ndash;                            <template #first v-if="pfilters.searchprdtcode || pfilters.searchprdtname">&ndash;&gt;-->
<!--                            &lt;!&ndash;                                <b-form-select-option value="">All Suppliers</b-form-select-option>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </template>&ndash;&gt;-->
<!--                            <template #first >-->
<!--                                <b-form-select-option value="">All Suppliers</b-form-select-option>-->
<!--                            </template>-->

<!--                            <template #option="{ option }">-->
<!--                                <div>{{ option.name }}</div>-->
<!--                            </template>-->

<!--                        </b-form-select>-->
                    </div>







                    <table v-if="!category_id" class="bg-light table  table-responsive">
                        <thead>
                        <tr>
                            <th class="bold-columns col-md-1" >ID</th>

                            <th class="bold-columns col-md-1" >Date</th>
                            <th class="bold-columns col-md-5" >
                                <table class="bg-light  " style="margin-bottom: -7px; line-height: 0.5">
                                                                        <thead>
                                                                        <tr>
                                                                            <th class=" col-md-1" >Name</th>
                                                                            <th class=" col-md-1" >Category</th>
                                                                            <th class=" col-md-1" >QTY</th>
                                                                            <th class=" col-md-1" >Price</th>
                                                                            <th class="col-md-1" >Sub Total</th>
                                                                        </tr>
                                                                        </thead>
                                </table>
                            </th>
                            <th class="bold-columns col-md-1" >Supplier</th>
                            <th class="bold-columns col-md-1" >Grand Total</th>
                            <th class="bold-columns col-md-1" >Tender</th>
                            <th class="bold-columns col-md-1" >Branch</th>
                            <th class="bold-columns col-md-1" >Purchaser</th>
                            <th class="bold-columns col-md-1" >Status</th>


                            <th class="bold-columns">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                      <template v-for="(expense, index) in allexpenses">
                          <tr  :class="expense.highlighted=='1'?'bg-warning':''">
                              <td>{{ expense.id }}</td>
                              <td>
                                  {{formatDate(expense.date)}}
                              </td>
                              <td class="col-md-5">
                                  <table class="bg-light  ">
                                      <!--                                    <thead>-->
                                      <!--                                    <tr>-->
                                      <!--                                        <th class=" col-md-2" >Name</th>-->
                                      <!--                                        <th class=" col-md-2" >Category</th>-->
                                      <!--                                        <th class=" col-md-1" >QTY</th>-->
                                      <!--                                        <th class=" col-md-1" >Price</th>-->
                                      <!--                                        <th class="col-md-1" >Total</th>-->
                                      <!--                                    </tr>-->
                                      <!--                                    </thead>-->
                                      <tbody>
                                      <tr v-for="item in  expense.expenseitems " :key="item.id" style="outline: 0px solid #9efa9e; background-color: transparent;" >
                                          <td class="col-md-1">
                                              {{item.expenseCategory_name}}
                                          </td>
                                          <td class="col-md-1">
                                              {{item.categoryitem.category.name}}
                                          </td>
                                          <td class="col-md-1">
                                              {{item.qty}}
                                          </td>
                                          <td class="col-md-1">
                                              {{item.price}}
                                          </td>
                                          <td class="col-md-1">
                                              £{{item.sub_total}}
                                          </td>

                                      </tr>
                                      </tbody>
                                  </table>

                              </td>
                              <td><span v-if="expense.supplier">
                                <span v-if="expense.supplier.name=='Other'">
                                    {{expense.other_supplier_name}}
                                </span>
                                 <span v-else="">
                                        {{ expense.supplier.name }}
                                </span>

                            </span></td>
                              <td> £{{ expense.total_payment }}</td>
                              <td>{{ expense.payment_method }}</td>
                              <td>{{ expense.branch.name }}</td>
                              <td><span v-if="expense.employee">{{ expense.employee.fullName }}</span></td>
                              <td>{{ expense.status }}</td>





                              <td>
                                  <b-button @click="updatestatus(expense.id,'Approved')" v-if="expense.status=='Pending' && authenticatedUser.permissions.includes('super')" class="btn-success mb-2"> Approve </b-button>

                                  <b-button @click="togglehighlight(expense.id,true)" v-if="(expense.status=='Pending' || expense.status=='Draft') && authenticatedUser.permissions.includes('super') && expense.highlighted=='0'" class="btn-warning mb-2"> Highlight </b-button>
                                  <b-button @click="togglehighlight(expense.id,false)" v-if="(expense.status=='Pending' || expense.status=='Draft') && authenticatedUser.permissions.includes('super') && expense.highlighted=='1'" class="btn-info mb-2"> Normal </b-button>

                                  <b-button @click="updatestatus(expense.id,'Pending')" v-if="expense.status=='Draft'" class="btn-warning mb-2"> Submit </b-button>
                                  <b-link v-if="expense.status=='Draft'" :to="{ path: '/expenseform', query: { type: 'edit', 'id': expense.id } }">
                                      <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                  </b-link>
                                  <b-link :to="{ path: '/expenseform', query: { type: 'view', 'id': expense.id } }">
                                      <b-button class="btn-info mb-2"> View </b-button>
                                  </b-link>
                                  <b-button @click="deleteexpense(expense.id)" v-if="authenticatedUser.permissions.includes('super') && expense.status!='Draft'" class="btn-danger mb-2"> Delete </b-button>
                                  <b-button @click="deleteexpense(expense.id)" v-if="expense.status=='Draft'" class="btn-danger mb-2"> Delete </b-button>
                              </td>
                          </tr>
                      </template>
                        </tbody>
                    </table>
                    <div class="row mt-4" v-else>
                        <div class="col-md-12 ">
                            <h1 class="text-light text-center">
                                Item History
                            </h1>
                            <table  class="table text-light ">
                                <thead >
                                <th>Date</th>
                                <th>Name</th>
                                <th class="col-md-2">Description</th>
                                <th class="col-md-2">Branch</th>
                                <th>Who Bought</th>
                                <th>Supplier</th>
                                <th>Payment Method</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Sub Total</th>


                                </thead>

                                <tbody >
                                <tr class="" v-for="(item, key ) in itemHistory" :key="key">
                                    <td> {{formatDate(item.expense.date)}}</td>
                                    <td> {{item.expenseCategory_name}}</td>
                                    <td> {{item.expenseCategory_description}}</td>
                                    <td> {{item.expense.branch.name}}</td>
                                    <td> {{item.expense.employee.fullName}}</td>
                                    <td> {{item.expense.supplier.name}}</td>
                                    <td> {{item.expense.payment_method}}</td>
                                    <td> {{item.price}}</td>
                                    <td> {{item.qty}}</td>
                                    <td>£{{formatNumberWithCommas(Number(item.sub_total).toFixed(2))}}</td>


                                </tr>
                                </tbody>
                            </table>


                            <table class="table text-light ">
                                <thead style="visibility: hidden">
                                <th class="col-md-11"></th>
                                <th>Total Expense</th>


                                </thead>
                                <tbody>
                                <tr class="">
                                    <td>Grand Total </td>
                                    <td>
                     <span v-if="itemHistory">
                                 £{{
                             formatNumberWithCommas(
                                 itemHistory
                                     .map(report => report.sub_total)
                                     .reduce((total, expense) => Number(total) + Number(expense), 0)
                                     .toFixed(2)
                             )
                         }}
                     </span>
                                    </td>


                                </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import apiService from "@/service/apiService";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from 'moment';
import VTooltip from 'v-tooltip';
import vSelect from "vue-select";




export default {
    name: "customers",

    components:{Welcomenote,VTooltip, 'v-select': vSelect,'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers', ['customers']),
        ...mapState('invoice', ['branches']),
        ...mapState('expenseitems', ['expenseitems','pfilters','pageitems']),

        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },

        categories(){
            return this.expenseitems.filter((ei)=>ei.parent_category_id==null)
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },


    },
    data() {
        return {
            showloader:false,
            allexpenses:[],
            itemHistory:[],
            itemsdata:'',
            month:'',
            category_id:'',
            c_item_id:'',

            loading:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',

            searchcustemail:'',
            custtype:'',
            credit:null,
            suppliers:[],
            supplier_id:'',
            upcomingDates:[],
            modalOpen:false,






        };
    },
    async created() {


        this.loading=true

        this.loading=false


        const promises =[

            this.getExpenses(),
            this.reloadcb()


        ]

        await Promise.all(promises);



    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),
        ...mapActions('expenseitems', ['set_page_items','getExpenseItems']),
        ...mapActions('invoice', ['getbranches']),



        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.expenseitems.length==0){
                //   alert(this.branches.length)
                await this.getExpenseItems();

            }
            if(this.expensesuppliers.length==0){
                await this.getExpenseSuppliers();
            }
            if(this.expenseitems.length==0){
                await this.getExpenseItems();
            }
            if(this.cashiers.length==0){
                await this.getcashiers();
            }

        },

        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('MMM D, YYYY');

            return date2;
        },







        async getitemdetails() {




        if(this.category_id){
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            let payload={
                item_id:this.c_item_id,
                branch_id:this.branch_id,
                category_id:this.category_id

            }
            if(this.month){
                payload.month=moment(`${this.month} 1`, 'MMMM D').startOf('month').format('YYYY-MM-DD')
            }
            this.showloader=true
            const {data} = await apiService.post(`/expense-item-details`,payload);
            this.showloader=false
            this.itemHistory=data
        }


        },
        async getExpenses(button=null) {

            // if(this.pfilters.searchprdtcode || this.pfilters.searchprdtname){
            // //
            // } else {
            //     if(this.pfilters.supplier_id){
            //         //
            //     } else {
            //         this.pfilters.supplier_id=26
            //     }
            //
            // }

            let current_page= this.itemsdata.current_page
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            let filters={

                page:current_page,
                supplier_id:this.pfilters.supplier_id,

            }
            if(button=='next'){

                filters.page=current_page+1

            }
            if(button=='prev'){
                filters.page=current_page-1


            }
            this.showloader=true
            const {data} = await apiService.post(`/expenses`,filters);
            this.showloader=false

            let  itemdata=data.data
            this.itemsdata=data

            // if(this.pfilters.upcoming=='desc'){
            //     this.allitems=itemdata.sort((a, b) => b.stock_forecast2 - a.stock_forecast2);
            // } else {
            //     this.allitems = itemdata.sort((a, b) => a.stock_forecast2 - b.stock_forecast2);
            // }
            this.allexpenses=itemdata

            this.set_page_items(itemdata)

            // this.items = itemdata;
            // this.$store.commit('items/setItems', itemdata);

            // console.log('ok')
        },
        async reloaditems(){
            this.loading=true
            //  await this.getItems();
            await this.getExpenses();

            this.loading=false
        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('branches', data)
            this.suppliers=data
        },

        async deleteexpense(id){

                const result = await Swal.fire({
                    title: 'Are you sure you want to delete this expense?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) {

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }
                    try {
                        const res = await axios.delete(`${URL.BASEURL}/api/delete-expense/${id}`);

                        if (res) {

                            this.getExpenses()
                            Swal.fire('success', 'Expense  Deleted', 'success');

                        } else {
                            Swal.fire('info', 'Expense Cant be Deleted', 'info');
                        }
                    } catch (error) {
                        console.error(error);
                        Swal.fire('Error', 'Failed to delete Expense.', 'info');
                    }
                }

        },
        async updatestatus(id, status){

            let status_msg=''
            if(status=='Pending'){
                status_msg='Submit'
            }
            if(status=='Approved'){
                status_msg='Approve'
            }
            if(status=='Rejected'){
                status_msg='Reject'
            }
            const result = await Swal.fire({
                title: `Are you sure you want to ${status_msg} this expense?`,
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, ${status_msg} it!`,
            });
            if (result.isConfirmed) {

                const apiToken = localStorage.getItem('token');
                if (apiToken) {
                    // Set the Authorization header with the API token
                    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                }

                let data={
                    status:status
                }
                try {
                    const res = await axios.post(`${URL.BASEURL}/api/update-expense-status/${id}`,data);

                    if (res) {

                        this.getExpenses()
                        if(status=='Pending'){
                            Swal.fire('success', 'Expense  Submitted', 'success');
                        }
                        if(status=='Approved'){
                            Swal.fire('success', 'Expense  Approved', 'success');
                        }
                        if(status=='Rejected'){
                            Swal.fire('success', 'Expense  Rejected', 'success');
                        }


                    } else {
                        Swal.fire('info', 'Expense Cant be Deleted', 'info');
                    }
                } catch (error) {
                    console.error(error);

                }
            }

        },
        async togglehighlight(id,highlighted){



                const apiToken = localStorage.getItem('token');
                if (apiToken) {
                    // Set the Authorization header with the API token
                    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                }

                let data={
                    highlighted:highlighted
                }
                try {
                    const res = await axios.post(`${URL.BASEURL}/api/highlight-expense/${id}`,data);

                    if (res) {

                      await this.getExpenses()



                    } else {
                        Swal.fire('info', 'Expense Cant be Highlighted', 'info');
                    }
                } catch (error) {
                    console.error(error);

                }


        },
        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        // async getRoles() {
        //     const { data } = await this.$axios.get('role');
        //     this.roles = data.roles;
        // },
        // filterUsers(value) {
        //     this.getUsers(value)
        // },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.bold-columns th.IsleworthWarehouseAfterOpen,
.bold-columns th.BoundsGreenAfterOpen,
.bold-columns th.HanworthAfterOpen,
.bold-columns th.GatwickAfterOpen,
.bold-columns th.FarnboroughWarehouseAfterOpen {
    font-weight: bold;
}

.custom-tooltip .tooltip-inner {
    max-width: 500px;
}

.arrow {
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.asc::before {
    content: '';
    border-bottom: 5px solid black;
}
.desc::after {
    content: '';
    border-top: 5px solid black;
}
.hidden {
    visibility: hidden;
}

</style>
