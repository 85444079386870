// import apiService from "../../service/apiService";


export default {
    namespaced: true,
    state: () => ({

        credentials:{
            report2_password:'',


        }

    }),
    mutations: {



    },


    actions: {


    },


}
