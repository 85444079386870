<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
              <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

<!--        <div class="row " v-if="authenticatedUser.permissions.includes('super')">-->
<!--            <div class="col-md-12 text-right position-absolute " style="margin-top: 30px">-->

<!--                <b-link class="" :to="{ path: '/reports' }">-->
<!--                    <button class="btn btn-warning " >Advance</button>-->
<!--                </b-link>-->
<!--            </div>-->
<!--        </div>-->



        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-3" style="border: 2px solid white">
                <div class="row mb-1 mt-1" >

                    <div class="col-md-12 text-right  " v-if="authenticatedUser.permissions.includes('super')" >


                        <b-input class="col-md-1 offset-11 mb-1" v-model="credentials.report2_password" placeholder="Enter Password"></b-input>

                        <b-link class="mx-1" :to="{ path: '/expense-reports' }">
                            <button class="btn btn-warning btn-sm " >Expense Reports</button>
                        </b-link>
                        <b-link class="" :to="{ path: '/collection-reports' }">
                            <button class="btn btn-warning btn-sm " >Collection Reports</button>
                        </b-link>
                        <b-link class="mx-1" :to="{ path: '/reports2' }">
                            <button class="btn btn-warning btn-sm " :disabled="credentials.report2_password!='Online1776!'">Reports2</button>
                        </b-link>
                        <b-link class="" :to="{ path: '/reports' }">
                            <button class="btn btn-warning btn-sm " >Advance</button>
                        </b-link>
                    </div>
                    <div v-else="" class="col-md-12 text-right " >

                        <b-link class="mx-1" :to="{ path: '/collection-reports' }">
                            <button class="btn btn-warning btn-sm " >Collection Reports</button>
                        </b-link>
                        <b-link class="mx-1" :to="{ path: '/expense-reports' }">
                            <button class="btn btn-warning btn-sm " >Expense Reports</button>
                        </b-link>

                    </div>


                </div>

               <div class="row">
                   <div class="col-md-3">

                   </div>
                   <div class="col-md-6">
                       <h1 class="text-center text-light">This Month</h1>
                       <table class="table text-light " >
                           <thead >

                           <th class="col-md-3">Cash</th>
                           <th class="col-md-2">Card</th>
                           <th class="col-md-2">Bank</th>
                           <th class="col-md-2">Pay By Link</th>
                           <th class="col-md-2">Refund</th>
                           <th class="col-md-3">Total</th>

                           </thead>
                           <tbody v-if="showloader=='no'">
                           <tr >

                               <td>{{ formatNumberWithCommas(Number(branch_report.thismonth.cash).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thismonth.card).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thismonth.bank).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thismonth.link).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thismonth.refund).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thismonth.total).toFixed(2))}}</td>
                           </tr>

                           </tbody>
                       </table>
                       <div class="row" v-if="showloader=='yes'">
                           <div class="col-md-12 text-center">
                               <loader></loader>
                           </div>
                       </div>
                       <h1 class="col-md-12 "> <hr class="hr0"></h1>

                       <h1 class="text-center text-light">This Week</h1>
                       <table class="table text-light ">
                           <thead >

                           <th class="col-md-3">Cash</th>
                           <th class="col-md-2">Card</th>
                           <th class="col-md-2">Bank</th>
                           <th class="col-md-2">Pay By Link</th>
                           <th class="col-md-2">Refund</th>
                           <th class="col-md-3">Total</th>

                           </thead>
                           <tbody v-if="showloader=='no'">
                           <tr >


                               <td>{{formatNumberWithCommas(Number(branch_report.thisweek.cash).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thisweek.card).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thisweek.bank).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thisweek.link).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thisweek.refund).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.thisweek.total).toFixed(2))}}</td>

                           </tr>

                           </tbody>
                       </table>

                       <h1 class="col-md-12 "> <hr class="hr0"></h1>
                       <h1 class="text-center text-light">Today</h1>
                       <table class="table text-light ">
                           <thead >

                           <th class="col-md-3">Cash</th>
                           <th class="col-md-2">Card</th>
                           <th class="col-md-2">Bank</th>
                           <th class="col-md-2">Pay By Link</th>
                           <th class="col-md-2">Refund</th>
                           <th class="col-md-3">Total</th>

                           </thead>
                           <tbody v-if="showloader=='no'">
                           <tr >
                               <td>{{formatNumberWithCommas(Number(branch_report.today.cash).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.today.card).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.today.bank).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.today.link).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.today.refund).toFixed(2))}}</td>
                               <td>{{formatNumberWithCommas(Number(branch_report.today.total).toFixed(2))}}</td>
                           </tr>

                           </tbody>
                       </table>
                   </div>
                   <div class="col-md-2 text-right offset-1">

                       <v-select
                           v-model="branch_id"
                           v-if="authenticatedUser.permissions.includes('super')"
                           class=""
                           placeholder="Branch"
                           :options="branches"
                           label="name"
                           :reduce="option => option.id"
                           @input="getbranchreports"

                       />

                   </div>
               </div>

<!--                <h1 class="col-md-12 "> <hr class="hr0"></h1>-->
<!--                <h1 class="text-center text-light">Summary</h1>-->
<!--                <table class="table text-light table-bordered">-->
<!--                    <thead >-->

<!--                    <th class="text-center"></th>-->
<!--                    <th class="text-center" v-for="reslt in branch_report.latest_months_data" :key="reslt.mo_ye">-->

<!--                        <span v-html="reslt.mo_ye"></span>-->
<!--                    </th>-->



<!--                    </thead>-->
<!--                    <tbody>-->
<!--                    <tr  >-->
<!--                   <td> Cash </td>-->
<!--                   <td v-for="(value, key) in branch_report.latest_months_data" :key="key" class="text-center" >-->
<!--                       {{formatNumberWithCommas(Number(value.cash).toFixed(2))}}-->
<!--&lt;!&ndash;                       {{value.mo_ye}} uncomment to varify the month is according to header above &ndash;&gt;-->
<!--                   </td>-->
<!--                    </tr>-->

<!--                    <tr  >-->
<!--                        <td> Card </td>-->
<!--                        <td v-for="(value, key) in branch_report.latest_months_data" :key="key" class="text-center">-->
<!--                            {{formatNumberWithCommas(Number(value.card).toFixed(2))}}-->
<!--                            &lt;!&ndash;                       {{value.mo_ye}} uncomment to varify the month is according to header above &ndash;&gt;-->
<!--                        </td>-->
<!--                    </tr>-->

<!--                    <tr  >-->
<!--                        <td> Bank </td>-->
<!--                        <td v-for="(value, key) in branch_report.latest_months_data" :key="key" class="text-center">-->
<!--                            {{formatNumberWithCommas(Number(value.bank).toFixed(2))}}-->
<!--                            &lt;!&ndash;                       {{value.mo_ye}} uncomment to varify the month is according to header above &ndash;&gt;-->
<!--                        </td>-->
<!--                    </tr>-->

<!--                    <tr  >-->
<!--                        <td> Refund </td>-->
<!--                        <td v-for="(value, key) in branch_report.latest_months_data" :key="key" class="text-center">-->
<!--                            {{formatNumberWithCommas(Number(value.refund).toFixed(2))}}-->
<!--                            &lt;!&ndash;                       {{value.mo_ye}} uncomment to varify the month is according to header above &ndash;&gt;-->
<!--                        </td>-->
<!--                    </tr>-->

<!--                    <tr  >-->
<!--                        <td> Total </td>-->
<!--                        <td v-for="(value, key) in branch_report.latest_months_data" :key="key" class="text-center">-->
<!--                            {{formatNumberWithCommas(Number(value.total).toFixed(2))}}-->
<!--                            &lt;!&ndash;                       {{value.mo_ye}} uncomment to varify the month is according to header above &ndash;&gt;-->
<!--                        </td>-->
<!--                    </tr>-->





<!--                    </tbody>-->
<!--                </table>-->

                <h1 class="col-md-12 "> <hr class="hr0"></h1>
                 <div class="row mt-5" v-if="showloader=='no'">
                <div class="col-md-12">
                    <div class="col-md-3 offset-1  text-dark">
                        <label class="text-light">
                            Select Year and Month
                        </label>
                        <vue-monthly-picker
                            v-model="selectedMonth"
                            placeHolder="Select Month"
                            @input="getbranchreports(false)"

                        >

                        </vue-monthly-picker>

                    </div>
                </div>
                <h1 class=" col-md-12 text-center text-light">

                    League Table
                </h1>
<!--                <div class="col-md-4 text-right  offset-7" >-->

<!--                    <b-link class="mx-1" :to="{ path: '/branch-league' }">-->
<!--                        <button class="btn btn-warning btn-sm " >Branch League</button>-->
<!--                    </b-link>-->

<!--                </div>-->
                <h1 class="col-md-6 offset-3 "> <hr class="hr0" style="margin-top: 0px !important;"></h1>
<!--                <div class="col-md-5 offset-1">-->
<!--                    <h1 class="text-center text-light">This Week</h1>-->
<!--                    <table class="table text-light table-bordered" >-->
<!--                        <thead >-->

<!--                        <th >Position</th>-->
<!--                        <th >Sales Person</th>-->
<!--                        <th >This Week</th>-->

<!--                        </thead>-->
<!--                        <tbody>-->
<!--                        <tr v-for="(sale_person, index) in sorted_cashiers.sortedCashiersthisweek" :key="sale_person.id" :class="[sale_person.twbgclass, { 'red-background': index >= sorted_cashiers.sortedCashiersthisweek.length - 3 }]">-->
<!--                            <td v-if="sale_person.thisweek_no==1"> First </td>-->
<!--                            <td v-else-if="sale_person.thisweek_no==2"> Second </td>-->
<!--                            <td v-else-if="sale_person.thisweek_no==3"> Third </td>-->
<!--                            <td v-else=""> {{sale_person.thisweek_no}}<sup>th</sup> </td>-->
<!--                            <td> {{sale_person.fullName}} </td>-->
<!--                            <td>-->
<!--                              <span v-if="((Number(sale_person.this_week) / Number(sale_person.this_week_comapny_sale)) * 100).toFixed(2)=='NaN'"> 0 </span>-->
<!--                                <span v-else="">-->
<!--                                       {{((Number(sale_person.this_week) / Number(sale_person.this_week_comapny_sale)) * 100).toFixed(2)}}-->
<!--                                </span>-->
<!--                                %-->

<!--                            </td>-->
<!--                        </tr>-->

<!--                        </tbody>-->
<!--                    </table>-->

<!--                    <h1 class="col-md-12 "> <hr class="hr0"></h1>-->
<!--                </div>-->
                <div class="col-md-6 offset-3 ">
                    <h1 class="text-center text-light">This Month</h1>
                    <table class="table text-light table-bordered">
                        <thead >

                        <th >Position</th>
                        <th >Sales Person</th>
                        <th >This Month</th>


                        </thead>
                        <tbody>
                        <tr v-for="(sale_person_tm, index) in sorted_cashiers.sortedCashiersthismonth" :key="sale_person_tm.id" :class="[sale_person_tm.bgclass, { 'red-background': index >= sorted_cashiers.sortedCashiersthismonth.length - 3 }]">
                            <td v-if="sale_person_tm.thismonth_no==1"> First </td>
                            <td v-else-if="sale_person_tm.thismonth_no==2"> Second </td>
                            <td v-else-if="sale_person_tm.thismonth_no==3"> Third </td>
                            <td v-else=""> {{sale_person_tm.thismonth_no}}<sup>th</sup> </td>
                            <td> {{sale_person_tm.fullName}} </td>
                            <td>
                                <span v-if="((sale_person_tm.this_month / sale_person_tm.this_month_comapny_sale) * 100).toFixed(2)=='NaN'">
                                    0
                                </span>
                                <span v-else="">
                                     {{((sale_person_tm.this_month / sale_person_tm.this_month_comapny_sale) * 100).toFixed(2)}}
                                </span>
                                %
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                </div>

<!--                <h1 class=" col-md-12 text-center text-light">League Table</h1>-->
<!--                <table class="table text-light table-bordered">-->
<!--                    <thead >-->

<!--                    <th >Sales Person</th>-->
<!--                    <th >This Week</th>-->
<!--                    <th >Last Week</th>-->
<!--                    <th >This Month</th>-->
<!--                    <th >Last Month</th>-->

<!--                    </thead>-->
<!--                    <tbody>-->
<!--                    <tr v-for="sale_person in this.branch_report.league_table" :key="sale_person.id">-->
<!--                        <td> {{sale_person.fullName}} </td>-->
<!--                        <td>-->
<!--                            <sup v-if="(sorted_cashiers.sortedCashiersthisweek.find((sc)=>sc.id==sale_person.id)).thisweek_no==1">-->
<!--                                <div class="notify_position">-->
<!--                                    {{(sorted_cashiers.sortedCashiersthisweek.find((sc)=>sc.id==sale_person.id)).thisweek_no }}-->
<!--                                </div>-->
<!--                            </sup>-->
<!--                            £ {{formatNumberWithCommas(Number(sale_person.this_week).toFixed(2))}}-->
<!--                        </td>-->
<!--                        <td>-->
<!--                            <sup v-if="(sorted_cashiers.sortedCashierslastweek.find((sc)=>sc.id==sale_person.id)).lastweek_no==1">-->
<!--                                <div class="notify_position">-->
<!--                                    {{(sorted_cashiers.sortedCashierslastweek.find((sc)=>sc.id==sale_person.id)).lastweek_no }}-->
<!--                                </div>-->
<!--                            </sup>-->
<!--                            £ {{formatNumberWithCommas(Number(sale_person.last_week).toFixed(2))}} </td>-->
<!--                        <td>-->
<!--                            <sup v-if="(sorted_cashiers.sortedCashiersthismonth.find((sc)=>sc.id==sale_person.id)).thismonth_no==1">-->
<!--                                <div class="notify_position">-->
<!--                                    {{(sorted_cashiers.sortedCashiersthismonth.find((sc)=>sc.id==sale_person.id)).thismonth_no }}-->
<!--                                </div>-->
<!--                            </sup>-->
<!--                            £ {{formatNumberWithCommas(Number(sale_person.this_month).toFixed(2))}} </td>-->
<!--                        <td>-->
<!--                            <sup v-if="(sorted_cashiers.sortedCashierslastmonth.find((sc)=>sc.id==sale_person.id)).lastmonth_no==1">-->
<!--                                <div class="notify_position">-->
<!--                                    {{(sorted_cashiers.sortedCashierslastmonth.find((sc)=>sc.id==sale_person.id)).lastmonth_no }}-->
<!--                                </div>-->
<!--                            </sup>-->
<!--                            £ {{formatNumberWithCommas(Number(sale_person.last_month).toFixed(2))}} </td>-->


<!--                    </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
            </div>

                <BranchLeague/>

                 </div>



        </div>

    </div>

</template>

<script>
import apiService from "@/service/apiService";
//import { mapActions } from "vuex";
import {mapActions, mapGetters} from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
import branchleague from "@/pages/admin/mypos/branchleague";
//import {cloneDeep} from "lodash";
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from "moment";
import Loader from "@/components/loader";
import {cloneDeep} from "lodash";
import vSelect from "vue-select";
import Welcomenote from "@/pages/admin/mypos/welcomenote";


export default {
    name: "reports",

    components:{Welcomenote, Loader,'v-select': vSelect, 'BranchLeague':branchleague, 'sidebar': sidebar,'ShowRealTime':showrealtime,VueMonthlyPicker},
    computed: {
        ...mapGetters(['AuthenticatedUser']),

        ...mapState('invoice', ['branches']),
        ...mapState('passwordprotection', ['credentials']),


        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        sorted_cashiers(){
          //  const N = 3;
            let sortedCashiers={}
            let lgtable= cloneDeep(this.branch_report.league_table)
            const sortedCashiersthisweek = lgtable.slice().sort((a, b) => b.this_week - a.this_week);
            sortedCashiersthisweek.forEach((item, index) => {
                item.thisweek_no = index + 1;
                if(item.thisweek_no==1){
                    item.twbgclass='first'
                }
                else if (item.thisweek_no==2){
                    item.twbgclass='second'
                }
                else if (item.thisweek_no==3){
                    item.twbgclass='third'
                }
                else {
                    item.twbgclass='normalbg'
                }
            });
            const sortedCashierslastweek = lgtable.slice().sort((a, b) => b.last_week - a.last_week);
            sortedCashierslastweek.forEach((item, index) => {
                item.lastweek_no = index + 1;
            });
            const sortedCashiersthismonth = lgtable.slice().sort((a, b) => b.this_month - a.this_month);
            sortedCashiersthismonth.forEach((item, index) => {
                item.thismonth_no = index + 1;
                if(item.thismonth_no==1){
                    item.bgclass='first'
                }
                else if (item.thismonth_no==2){
                    item.bgclass='second'
                }
                else if (item.thismonth_no==3){
                    item.bgclass='third'
                }
                else {
                    item.bgclass='normalbg'
                }
            });
            const sortedCashierslastmonth = lgtable.slice().sort((a, b) => b.last_month - a.last_month);
            sortedCashierslastmonth.forEach((item, index) => {
                item.lastmonth_no = index + 1;
            });

         //   const topNCashiers = sortedCashier.slice(0, N);
            sortedCashiers.sortedCashiersthisweek=sortedCashiersthisweek;
            sortedCashiers.sortedCashierslastweek=sortedCashierslastweek;
            sortedCashiers.sortedCashiersthismonth=sortedCashiersthismonth;
            sortedCashiers.sortedCashierslastmonth=sortedCashierslastmonth;
            return sortedCashiers;
        }

    },
    data() {
        return {
            selectedMonth:"",
            roles: [],
            user: {},
            branch_report:{},
            showloader:'',
            branch_id:"",



        };
    },
    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()

       //



    },
    async mounted() {
        await this.getbranchreports()




    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getbranches']),
        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            // if(this.cashiers.length==0){
            //     await this.getcashiers()
            // }
        },
        async getbranchreports(curdate=true){
            this.showloader='yes'
            let branch_id=this.authenticatedUser.branch.id
            if(this.branch_id){
                branch_id=this.branch_id
            }
            let formattedDate=moment().format('YYYY-MM-DD HH:mm:ss');
            if(this.selectedMonth){
                formattedDate = moment(this.selectedMonth).format('YYYY-MM-DD HH:mm:ss');
            }

            if(curdate==true){

                formattedDate= moment().format('YYYY-MM-DD HH:mm:ss');


            }
            // console.log(this.selectedMonth,'ok')
            const {data} = await apiService.get(`/branch-reports/${formattedDate}/${branch_id}`);
          //  console.log('branch_reports', data)
            this.showloader='no'
            this.branch_report=data
              console.log('branch_reports', this.branch_report.league_table)

        },
        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },


        // updateRangeOptions() {
        //     // Parse the selected date values
        //     const fromDate = new Date(this.dateFrom)
        //     const toDate = new Date(this.dateTo)
        //     const today = new Date()
        //
        //   let  getrangeOptions={
        //       tday:false,
        //       yday:false,
        //       tweek:false,
        //       lweek:false,
        //       tmonth:false,
        //       lmonth:false,
        //     }
        //
        //
        //     // Calculate date differences
        //     const daysDifference = (toDate - fromDate) / (1000 * 60 * 60 * 24);
        //
        //     // Update range options based on date selections
        //    // console.log(daysDifference,today.getDay())
        //     if( toDate.getDay() == today.getDay()) {
        //         getrangeOptions.tday=true;
        //     }
        //     if(daysDifference > 0 ) {
        //         getrangeOptions.yday=true
        //     }
        //
        //
        //     if(daysDifference >1 && daysDifference <= today.getDay()) {
        //         getrangeOptions.tweek=true
        //
        //     }
        //    if(daysDifference > today.getDay()) {
        //        getrangeOptions.lweek=true
        //        getrangeOptions.tweek=true
        //
        //     }
        //    if( daysDifference+today.getDay() > 15 &&
        //         fromDate.getMonth() === today.getMonth() &&
        //         toDate.getMonth() === today.getMonth()) {
        //        getrangeOptions.tmonth=true
        //     }
        //   if(
        //       fromDate.getMonth() === today.getMonth() - 1) {
        //       getrangeOptions.lmonth=true
        //       getrangeOptions.tmonth=true
        //     }
        //
        //
        //     this.rangeOptions=[]
        //     for (let x in getrangeOptions) {
        //
        //         if(getrangeOptions[x]){
        //             this.rangeOptions.push(x)
        //         }
        //     }
        //   //  console.log(this.rangeOptions)
        //    this.rangeOptions =  this.rangeOptions.map(ro=>{
        //         if (ro=='tday') return 'Today'
        //         else if(ro=='yday') return 'Yesterday'
        //         else if(ro=='tweek') return 'This Week'
        //         else if(ro=='lweek') return 'Last Week'
        //         else if(ro=='tmonth') return 'This Month'
        //         else if(ro=='lmonth') return 'Last Month'
        //     })
        // //    console.log(this.rangeOptions)
        //
        // },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}
.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid #ffffff;
    max-width: 100%;

}

.notify_position {

    margin-right: -6px;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: green;
    color: white;
    border-radius: 50%; /* Makes it a circle */
    text-align: center;
    line-height: 20px; /* Vertically centers the text */
    font-size: 11px; /* Adjust the font size as needed */
    display: inline-block;
    font-weight: bolder;
}

.first {
    background-color: #bb9f18;
}
.second {
    background-color: #a49e9e;
}
.third {
    background-color: #8d8533;
}

.red-background{
    background-color: red;
}


</style>
