<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-3 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 mt-2 pb-2 bgsecond">

                <div class="row   text-light">

                    <div class="col-md-12">

                        <div class="row ">
                            <h1 class="ml-1 mt-1">
                                <span v-if="actiontype=='Save'">Enter</span>
                                <span v-if="actiontype=='Update'">Update</span>
                                Category Details </h1>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="firstName" class="form-label">Real Name</label>
                                <b-form-input v-model="category.real_name" type="text" class="form-control" id="real_name" required :disabled="actiontype=='View'"> </b-form-input>
                            </div>
                            <div class="col-md-4">
                                <label for="title" class="form-label">Showroom Name</label>
                                <b-form-input v-model="category.showroomname"  type="text" class="form-control" id="showroomname" :disabled="actiontype=='View'"> </b-form-input>
                            </div>


                            <div class="col-md-4">
                                <label  class="form-label">Parent Categories</label>
                                <v-select
                                    v-model="category_ids"
                                    class="text-dark"
                                    placeholder="Choose Categories"
                                    label="name"
                                    :options="allcategories"
                                    :reduce="option => option.id"
                                    multiple
                                >
                                    <template v-slot:selection="props">
                                        <div v-for="category in props.selectedOptions" :key="category.id">
                                            {{ category.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>

                        </div>





                            <div class="row">
                                <h1 class="col-12 "> <hr class="hr0"></h1>
                            </div>




                            <div class="row mt-3">

                                <div class="col-md-12">
                                    <!--                                    <div class="row">-->
                                    <!--                                        <div v-for="img in this.attached_images" :key="img.id" class="col-md-3">-->
                                    <!--                                            <b-img thumbnail fluid :src="img.path" alt="" class="trendingAvatarFit "></b-img>-->
                                    <!--                                            <div class="text-center">-->

                                    <!--                                                <b-button @click="warn(img.id)" variant="danger" size="sm" style="margin-top: -70px" >Remove</b-button>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->

                                    <!--                                    </div>-->
                                </div>


                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <b-form-group class="col-span-4" label="Attach Images" v-if="category.id">
                                        <vue-dropzone
                                            ref="myDropzone"
                                            id="dropzone"
                                            :options="dropzoneOptions"
                                            @vdropzone-success="fileUploaded"
                                            @vdropzone-removed-file="fileDeleted"
                                            @vdropzone-error="fileUploadError"
                                            @vdropzone-mounted="onDropzoneMounted"
                                            :useCustomSlot=true
                                            :disabled="actiontype=='View'"
                                        >


                                        </vue-dropzone>


                                    </b-form-group>
                                    <b-form-group v-else>
                                        <vue-dropzone
                                            ref="catDropzone"
                                            id="dropzone"

                                            :options="adddropzoneOptions"


                                            v-on:vdropzone-error-multiple="showErrors"
                                            v-on:vdropzone-success-multiple="showSuccess"
                                            :useCustomSlot="true"
                                        >

                                        </vue-dropzone>
                                    </b-form-group>

                                </div>

                            </div>
                            <div class="row mt-3" >
                                <div class="col-md-12">

                                    <div class="row" >
                                        <h1 class="col-12 "> <hr class="hr0"></h1>
                                        <h1 class="col-12 text-center " >Rigt Tiles(RTO)</h1>
                                        <div class="col-md-3 mt-2">
                                            <label for="rto_name" class="form-label">Website Name</label>
                                            <b-form-input v-model="category.name" type="text"    class="form-control" id="rto_name" :disabled="actiontype=='View'"> </b-form-input>

                                        </div>
                                        <div class="col-md-2 mt-2">
                                            <label for="rto_slug" class="form-label">Website Slug</label>
                                            <b-form-input v-model="category.slug" type="text"    class="form-control" id="rto_name" :disabled="actiontype=='View'"> </b-form-input>

                                        </div>
                                        <div class="col-md-1 mt-2">
                                            <label for="rto_order" class="form-label">Display Order</label>
                                            <b-form-input v-model="category.listing_order" type="number"    class="form-control" id="rto_name" :disabled="actiontype=='View'"> </b-form-input>

                                        </div>




                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-1 mt-2 text-center">
                                            <b-tooltip target="rto_featured" triggers="hover focus">
                                                Featured Categories are displayed on home and Navbar
                                            </b-tooltip>

                                            <label for="rto_featured" id="rto_featured" class="text-light">
                                                Featured  <i class="fas fa-info-circle"></i>
                                            </label>

                                            <b-form-checkbox v-model="category.featured"  id="rto_featured" :disabled="actiontype=='View'" style="transform: scale(1.7); font-size: 12px;">  </b-form-checkbox>
                                        </div>
                                        <div class="col-md-1 mt-2 text-center">
                                            <label for="rto_active" class="text-light">

                                                Active
                                            </label>

                                            <b-form-checkbox v-model="category.rto_active"  id="rto_active" :disabled="actiontype=='View'" style="transform: scale(1.7); font-size: 12px;">  </b-form-checkbox>
                                        </div>
                                        <div class="col-md-1 mt-2 text-center">
                                            <label for="rto_new_arrival" class="text-light">

                                                New Arrival
                                            </label>

                                            <b-form-checkbox v-model="category.new_arrival"  id="rto_new_arrival" :disabled="actiontype=='View'" style="transform: scale(1.7); font-size: 12px;">  </b-form-checkbox>
                                        </div>
                                        <div class="col-md-1 mt-2 text-center">
                                            <label for="rto_off" class="text-light">

                                                % Off
                                            </label>

                                            <b-form-input v-model="category.off" type="number"    class="form-control" id="rto_off" :disabled="actiontype=='View'" style="transform: scale(0.8); margin-top: -5px;"> </b-form-input>
                                        </div>
                                        <div class="col-md-1 mt-2 text-center">
                                            <label for="rto_special_offer" class="text-light">

                                               Special Offer
                                            </label>

                                            <b-form-checkbox v-model="category.special_offer"  id="rto_special_offer" :disabled="actiontype=='View'" style="transform: scale(1.7); font-size: 12px;">  </b-form-checkbox>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <label for="delivery_note_name" class="form-label">Full Description</label>
                                            <b-form-textarea v-model="category.des_key_html"   class="form-control" id="delivery_note_name" :disabled="actiontype=='View'"></b-form-textarea>
                                        </div>

                                    </div>



                                </div>

                            </div>





                        <button v-if="actiontype!='View'" type="submit" class="btn btn-primary mt-3" @click="saveCategory"  :disabled="makedis"> {{actiontype}} Category</button>



                    </div>

                </div>




            </div>



        </div>

    </div>

</template>

<script>
import apiService from "@/service/apiService";
import {mapActions, mapState} from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import axios from "axios";
import {cloneDeep} from "lodash";
import Swal from "sweetalert2";
import showrealtime from "@/components/showrealtime";
import URL from "@/constants/baseurl"
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from "moment";
//import newinvoice from "@/constants/newinvoice";
//import newcustomer from "@/constants/newcustomer";
//import newpayment from "@/constants/newpayment";
//import newitem from "@/constants/newitem";
import vSelect from "vue-select";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";



export default {
    name: "categoryform",

    components:{Welcomenote, 'sidebar': sidebar, vueDropzone: vue2Dropzone, 'v-select': vSelect,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['cashiers']),
        ...mapState('customers', ['customers']),
        ...mapState('products', ['allcategories','allcolors','allsizes','alltypes','allmaterials','allfinishes','pagecats']),


        dropzoneOptions() {
            return {
                url: this.category?.id
                    ? `${URL.BASEURL}/api/cat_image_upload/${this.category.id}`
                    : ``,
                thumbnailWidth: 150,
                maxFilesize: 5, // In MB
                acceptedFiles: "image/*",
                addRemoveLinks: this.actiontype !== 'View',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                clickable: this.actiontype !== 'View'
            };
        },
        adddropzoneOptions() {
            return {
                url:`${this.cat_image_upload_url}`,
                thumbnailWidth: 150,
                maxFilesize: 5, // In MB
                acceptedFiles: "image/*",

                uploadMultiple: true,
                autoProcessQueue: false,
                parallelUploads: 4,
                addRemoveLinks: true,

                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
        },
        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

        makedis(){
            if(this.code_availability && ! this.item.itemCode=="" && ! this.item.itemName==""){
                return false
            }
            // else  return true
        else  return false
        },

        actiontype(){
            if (this.$route.query.type=='add'){
                return 'Save'
            }
            else if (this.$route.query.type=='edit'){
                return 'Update'
            }
            else {
                return 'View'
            }
        }

    },
    data() {
        return {
            uploadedImages:[],

            category:null,
            cat_id:null,

            password:'',

            category_ids: [],
            cat_image_upload_url:'/',
            color_id:'',
            size_id:'',
            material_id:'',
            type_id:'',
            finish_id:'',

            suppliers:[],
            code_availability:true,
            rto_sku_availability:true,
            oto_sku_availability:true,

            code_used:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            item: {
                id:"",
                itemName: "",
                itemCode: "",
                itemDescription: "",
                defaultPrice: "",
                status: true,
                costPrice: "",
                delivery_note_name: "",
                box_qty: "",
                max_discount: "",
                trade_card: "",
                normal_sale_clearance: "",
                def_supplier_id:"",
                min_qty:"",
                supplier_sku:""

            },

            attached_images: {},

            errors: {},




        };
    },
    async created() {
        //   await this.getUsers();
        //    await this.getRoles();

        // await this.getcashiers()
        this.viewType = this.$route.query.type;
        this.cat_id = this.$route.query.id;

        this.getCategory()

        if(this.category){
            this.category.featured=  this.category.featured==1 ? true:false
            this.category.rto_active=  this.category.rto_active==1 ? true:false
            this.category.new_arrival=  this.category.new_arrival==1 ? true:false
            this.category.special_offer=  this.category.special_offer==1 ? true:false

            this.category_ids = cloneDeep(this.category.parentcategories).map((c) => c.id);
        } else {
            this.category={
              featured:false,
                rto_active:false,
               new_arrival:false,
               special_offer:false
            }
        }




        await this.getsuppliers()
        await  this.code_is_used()


    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['updateFullInvoice']),
        //  ...mapActions('payment', ['setNewpayment']),
        ...mapActions('items',['add_searched_items','getAllItems']),

        // ...mapActions('payment', ['setNewpayment']),

        showErrors(files, message, xhr) {
            this.errors = message.errors;
        },
        showSuccess(files, res) {
            let swalOptions = {
                title: "Success!",
                icon: "success",
                confirmButtonText: "Show All Products",
            };
            if (this.actiontype === "add" || this.actiontype === "edit")
                swalOptions = {
                    ...swalOptions,
                    confirmButtonText: "Show All Products",
                    showCancelButton: false,
                    // cancelButtonText: "Create a new one",
                };
            this.$swal.fire(swalOptions).then((res) => {
                if (res.isConfirmed) {
                    window.location = "/products";
                }
                // if (res.isDismissed) {
                //     this.product = {...product};
                //     this.$refs.productDropzone.removeAllFiles();
                // }
            });
        },

        onDropzoneMounted() {
            console.log("Dropzone initialized!");
            const imgElement = document.querySelector('.dz-upload');
            if (imgElement) {

                imgElement.style.backgroundColor = "green !important";
                console.log(imgElement,'imgElement')

            }
            this.loadUploadedImages();
        },
        loadUploadedImages() {
            try {
                if (!this.$refs.myDropzone || !this.$refs.myDropzone.dropzone) {
                    console.warn("Dropzone is not initialized yet.");
                    return;
                }

                this.uploadedImages = this.category.images || [];

                this.uploadedImages.forEach((image) => {
                    const mockFile = {
                        name: image.name,
                        size: 12345, // Dummy size
                        serverPath: image.path,
                        id: image.id,
                    };

                    let dropzone = this.$refs.myDropzone.dropzone;
                    dropzone.emit("addedfile", mockFile);
                    dropzone.emit("thumbnail", mockFile, image.path);
                    dropzone.emit("complete", mockFile);

                    const imgElement = document.querySelector(`.dz-image img[src="${image.path}"]`);
                    if (imgElement) {
                        imgElement.style.width = "150px";
                        imgElement.style.height = "150px";
                        imgElement.style.objectFit = "contain";
                    }
                });
            } catch (error) {
                console.error("Error loading images:", error);
            }
        },


        fileUploaded(file, response) {
            console.log("File uploaded successfully", response);
            console.log(file, 'file')
            this.uploadedImages.push({
                file,
                serverPath: response.image.path,
                id:response.image.id
            });


            let image=response.image;

            const mockFile = {
                name: image.name,
                size: file.size,
                serverPath: image.path,
                id: image.id,
            };

            let dropzone = this.$refs.myDropzone.dropzone;

            dropzone.files.forEach((fileInDropzone) => {
                if (fileInDropzone.id === undefined) {
                    dropzone.removeFile(fileInDropzone);
                }
            });

            dropzone.emit("addedfile", mockFile);
            dropzone.emit("thumbnail", mockFile, image.path);
            dropzone.emit("complete", mockFile);

            const imgElement = document.querySelector(`.dz-image img[src="${image.path}"]`);
            if (imgElement) {
                imgElement.style.width = "150px";
                imgElement.style.height = "150px";
                imgElement.style.objectFit = "contain";
            }
        },
        async fileDeleted(file) {
            console.log(file,'file')
            // Find the corresponding file on the server
            const fileEntry = this.uploadedImages.find(f => f.id === file.id);
            if (!fileEntry) return;


            try {
                const response = await axios.post(`${URL.BASEURL}/api/cat_image_delete`, {
                    cat_id:this.category.id,
                    image_id:file.id
                });

                if (response.data.success==true) {
                    console.log("File deleted successfully");
                    this.uploadedImages = this.uploadedImages.filter(f => f.id !== file.id);
                } else {
                    // console.error("Error deleting file");
                }
            } catch (error) {
                console.error("File delete failed", error);
            }
        },
        fileUploadError(file, errorMessage) {
            console.error("File upload failed", errorMessage);
        },

        formatDate(date){
            let date1 = new Date(date).toLocaleString('en-US', {timeZone: 'Europe/London'});
            let date2 =  moment(date1).format('D MMM, YYYY, hh:mm A');

            return date2;
        },
        async codeavailability(){
            let code=this.item.itemCode
            const {data} = await apiService.get(`/item_code_availability/${code}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            this.code_availability=data.code_availability
        },
        async productcodeavailability(column){
            let code=this.item.product.rto_sku
            const {data} = await apiService.get(`/product_code_availability/${column}/${code}`);

            this[column + '_availability'] = data.code_availability;
        },

        async code_is_used(){

            const {data} = await apiService.get(`/code_used/${this.item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            this.code_used=data.code_used
        },
        // async product_code_is_used(column){
        //
        //     const {data} = await apiService.get(`/code_used/${column}/${this.item_id}`);
        //     //   console.log('code_availability', data.code_availability)
        //     //   alert(data.code_availability)
        //     this.code_used=data.code_used
        // },


        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('suppliers', data)
            this.suppliers=data
        },



        async saveCategory() {




            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            // let productdata=cloneDeep(this.customer)
            let catdata=cloneDeep(this.category)
            catdata.category_ids=this.category_ids

            console.log(catdata,'catdata')

            let api_url=`${URL.BASEURL}/api/add-category`
            if (this.actiontype=='Update'){
                api_url=`${URL.BASEURL}/api/update-category/${this.category.id}`
            }



            try {

                const response = await axios.post(api_url,  catdata);
                console.log("cat saved:", response.data);


                if (this.actiontype=='Save'){
                    if (this.$refs.catDropzone && this.$refs.catDropzone.getQueuedFiles() && this.$refs.catDropzone.getQueuedFiles().length > 0) {

                        this.cat_image_upload_url=`${URL.BASEURL}/api/cat_multi_image_upload/${response.data.category.id}`

                        if (this.$refs.catDropzone && this.$refs.catDropzone.dropzone) {
                            this.$refs.catDropzone.dropzone.options.url = this.cat_image_upload_url;
                        }

                        this.$refs.catDropzone.dropzone.on("queuecomplete", async () => {
                            await Swal.fire("Success", "Category and images uploaded successfully!", "success");
                            this.$router.push({ path: '/categories' })
                        });

                        this.$refs.catDropzone.processQueue();
                    }
                }


                if (this.actiontype=='Update'){
                    await Swal.fire('Success', 'Category Updated successfully!', 'success');
                    this.$router.push({ path: '/categories' })
                }




                // this.customer = {
                //     id:"",
                //     branch_id: "",
                //     cashier_id: "",
                //     title: "",
                //     firstName: "",
                //     lastName: "",
                //     customerCode: "",
                //     mobile: "",
                //     companyName: "",
                //     customerType: "",
                //     discountType: 1,
                //     addressLine1: "",
                //     addressLine2: "",
                //     postcode: "",
                //     email: "",
                //     notes: "",
                //     status: true
                // };
            } catch (error) {
                console.error("Error saving category:", error);
            }
        },


        async  getCategory(){

          if(this.viewType!=='add'){

              this.category=cloneDeep(this.pagecats.data).find(c=>c.id==this.cat_id)



          }

        }
    }
};
</script>



<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.hr0{
    margin-top: 15px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid #ffffff;
    max-width: 100%;

}
.trendingAvatarFit{
    width: 100%;
    height: 320px !important;
    object-fit: cover !important;
    object-position: center !important;
    max-width: 100%;
    max-height: 100%;
}

.dz-image {
    width: 100px !important;
    height: 100px !important;
    object-fit: contain;
}




</style>
