<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">
                <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
                <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row  mt-2">

                    <b-link class="" :to="{ path: '/customerform',query: { type: 'add'} }">
                        <b-button class="btn-info mb-2" > Add New Customer </b-button>
                    </b-link>

                    <div class="col-md-2 ">
                        <b-input v-model="customerfilters.name"
                                 @input="()=>{
                                 this.customerfilters.page=1;
                                 this.get_customers()
                              }"
                                 placeholder="Name"></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="customerfilters.telephone"
                                 @input="()=>{
                                 this.customerfilters.page=1;
                                 this.get_customers()
                              }"
                                 placeholder="Telephone"></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="customerfilters.email"
                                 @input="()=>{
                                 this.customerfilters.page=1;
                                 this.get_customers()
                              }"
                                 placeholder="Email"></b-input>
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="customerfilters.type"
                            @input="()=>{
                                 this.customerfilters.page=1;
                                 this.get_customers()
                              }"
                            class=""
                            placeholder="Type"
                            :options="['Retail','Trade']"

                        />
                    </div>
                    <div class="col-md">
                        <b-form-checkbox

                            @input="()=>{
                                 this.customerfilters.page=1;
                                 this.get_customers()
                              }"
                            v-model="customerfilters.has_credit"

                        >
                            Customers With Credit
                        </b-form-checkbox>
                    </div>




<!--                    <b-table id="customers-table"  class="bg-light" :items="filteredCustomers" :fields="fields" striped hover responsive :per-page="perPage" :current-page="currentPage">-->


<!--                        <template #cell(actions)="data">-->

<!--                            <b-link class="" :to="{ path: '/customerform',query: { type: 'edit', 'id': data.item.id } }">-->
<!--                                <b-button class="btn-info mb-2" > Edit </b-button>-->
<!--                            </b-link>-->
<!--                            <b-link class="mx-1" :to="{ path: '/customerform',query: { type: 'view', 'id': data.item.id } }">-->
<!--                                <b-button class="btn-info mb-2" > View </b-button>-->
<!--                            </b-link>-->
<!--&lt;!&ndash;                            <b-link class="mx-1" :to="{ path: '/customerform',query: { type: 'view', 'id': data.item.id } }">&ndash;&gt;-->
<!--&lt;!&ndash;                                <b-button class="btn-info mb-2" > Orders </b-button>&ndash;&gt;-->
<!--&lt;!&ndash;                            </b-link>&ndash;&gt;-->

<!--                        </template>-->

<!--                    </b-table>-->
<!--                    <b-pagination-->
<!--                        class=""-->
<!--                        v-model="currentPage"-->
<!--                        :total-rows="customers.length"-->
<!--                        :per-page="perPage"-->
<!--                        aria-controls="customers-table"-->
<!--                    ></b-pagination>-->
                </div>
                <div class="row mt-2" >
                    <div class="col-md-2">
                        <!--                        <button class="btn-sm btn-info" @click="getinvoices">-->
                        <!--                            <span v-if="showloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
                        <!--                            Refresh-->
                        <!--                        </button>-->
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-1">
                        <button class="btn-sm btn-info"     :disabled="customers?.current_page==1" @click="get_paged_data('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2" v-if="!showloader" style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="customers?.to-50>0">{{customers?.to-50}}</span> <span v-else="">0</span>  to {{customers.to}}) out of {{customers.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "    :disabled="customers?.current_page==totalPages" @click="get_paged_data('next')">Next</button>
                    </div>
                    <div class="col-md-2 ">

                    </div>

                    <!--                    <div class="col-md-2 text-right">-->
                    <!--                        <button class="btn-sm btn-warning"    @click="clearfilters" >Clear Filters</button>-->
                    <!--                    </div>-->

                </div>
                <div class="row mt-1">
                    <div v-if="showloader" class="col-md-10 offset-1 text-center">
                        <loader/>
                    </div>
                </div>
                <div class="row mt-2">
                    <table class="table table-bordered table-striped text-light">
                        <thead class="thead-dark">
                        <tr>
<!--                            <th>Id</th>-->
                            <th>Name</th>
                            <th>Mobile</th>

                            <th>Email</th>
                            <th>Type</th>
                            <th>Balance</th>
                            <th class="text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody id="customerTableBody">
                        <tr v-for="customer in customers.data">
<!--                            <td>{{customer.id}}</td>-->
                            <td>{{customer.lastName}}</td>
                            <td>{{customer.mobile}}</td>

                            <td>{{customer.email}}</td>
                            <td>{{customer.customerType}}</td>

                            <td>{{customer.walletBalance}}</td>

                            <td class="text-center">
                                <b-link class="" :to="{ path: '/customerform',query: { type: 'edit', 'id': customer.id } }">
                                    <b-button class="btn-info mb-2" > Edit </b-button>
                                </b-link>
                                <b-link class="mx-1" :to="{ path: '/customerform',query: { type: 'view', 'id': customer.id } }">
                                    <b-button class="btn-info mb-2" > View </b-button>
                                </b-link>
                                <!--                            <b-link class="mx-1" :to="{ path: '/customerform',query: { type: 'view', 'id': customer.id } }">-->
                                <!--                                <b-button class="btn-info mb-2" > Orders </b-button>-->
                                <!--                            </b-link>-->
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import vSelect from "vue-select";
import Welcomenote from "@/pages/admin/mypos/welcomenote.vue";
import URL from "@/constants/baseurl";
import Loader from "@/components/loader.vue";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{Loader, Welcomenote, 'v-select': vSelect,'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers',['customerfilters']),
       //  ...mapState('customers', ['customers']), not using this state
        // ...mapState('invoice', ['branches']),
        totalPages() {
            const perpage = this.customers.per_page;
            const total=this.customers.total;

            return Math.ceil(total / perpage);
        },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },


    },
    data() {
        return {
            roles: [],
            user: {},
            // perPage:100,
            // currentPage: 1,
            customers:[],
            showloader:false,


            // cashier_id:'',
            // order_status:'',
            // branch_id:'',
            // searchcustname:'',
            // searchcustphone:'',
            // searchcustemail:'',
            // custtype:'',
            // credit:true,

            // fields: [
            //
            //     { key: 'lastName', label: 'Name' },
            //     { key: 'mobile', label: 'Mobile' },
            //     { key: 'customerType', label: 'Customer Type' },
            //     { key: 'email', label: 'Email' },
            //     { key: 'walletBalance', label: 'Balance' },
            //     'actions'
            //
            // ],

        };
    },
    async created() {
      //  await this.getUsers();
     //   await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
          await this.get_customers();

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
     //   ...mapActions('customers', ['getCustomers']), not using this method


        async signOut() {
            await this.processLogoutManual();
           // await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },

        async get_paged_data(button){
            let current_page= this.customers.current_page
            this.customerfilters.page=current_page



            if(button=='next'){

                this.customerfilters.page=current_page+1

                await   this.get_customers()

            }
            if(button=='prev'){
                this.customerfilters.page=current_page-1

                await  this.get_customers()


            }

        },
        async  get_customers() {
            let user = localStorage.getItem('user');
            user=  JSON.parse(user)
            const apiToken = localStorage.getItem('token');
            let url=`${URL.BASEURL}/api/customers`
            let filters ={
                name:this.customerfilters.name,
                telephone:this.customerfilters.telephone,
                email:this.customerfilters.email,
                type:this.customerfilters.type,
                has_credit:this.customerfilters.has_credit,
                page:this.customerfilters.page,


            };
            if(user.permissions.includes('super')){

              filters.branch_id=null;

            } else {
              filters.branch_id=user.branch.id;
            }
            this.showloader=true
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify(filters)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const res = await response.json();
                this.customers=res
               // console.log('customers:', res);
            } catch (error) {
                console.error('Error:', error);
            }
            this.showloader=false
        }

    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
