import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
// import './assets/styles/scss/app.scss'
import './assets/sass/app.scss';
import './assets/sass/arabic.css';
import router from "./router/router";
import apiService from "./service/apiService";
import store from "./store";
import excel from 'vue-excel-export'
import loading from 'vuejs-loading-screen'
import ToggleButton from 'vue-js-toggle-button';
import 'vue2-datepicker/index.css';
import './registerServiceWorker'
import Toasted from 'vue-toasted';
Vue.use(Toasted);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToggleButton)
Vue.use(excel)
Vue.use(loading,{
    bg: 'rgba(98, 98, 98, 0.68)',
    slot: `<div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>`
  })
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.config.productionTip = false
Vue.prototype.$axios = apiService
Vue.directive('can', {
    inserted(el, binding) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.user_type =='admin') {
            // console.log(user);
            if (!user.permissions.includes(binding.value)) {
                const parent = el.parentNode;
                const comment = document.createComment('');
                el.appendChild(comment);
                parent.replaceChild(comment, el);
            }
        }
    }
})





// Vue.directive("disable-all", {
//     componentUpdated: function(el, binding) {
//         if (!binding.value) return;
//         const tags = ["input", "textarea", "select"];
//         tags.forEach(tagName => {
//             for (const node of el.querySelectorAll(tagName)) {
//                 node.disabled = true;
//
//             }
//         });
//     }
// });


new Vue({
    store,
    render: h => h(App),
    router,

}).$mount('#app')
