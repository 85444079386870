<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
            <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">


                <div class="row mt-3 mb-3">


                    <div class="col-md-2">

                        <v-select
                            v-model="selectedRange"

                            :clearable="false"
                            class=""
                            placeholder="Range"
                            :options="['Today','Yesterday','This Week', 'Last Week', 'This Month', 'Last Month']"

                            @input="()=>{
                                       dateRange=[null,null];
                                         getadvancereport()
                                     }"



                        />
                    </div>



<!--                    <div class="col-md">-->

<!--                        <b-form-datepicker-->
<!--                            :reset-button=true-->
<!--                            reset-button-variant="btn btn-warning"-->

<!--                            id="example-datepicker"-->
<!--                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
<!--                            v-model="dateFrom"-->
<!--                            placeholder="Date From"-->
<!--                            :disabled="selectedRange!==''&& selectedRange!==null"-->

<!--                            dropdown  calendar-width="100%"-->
<!--                        ></b-form-datepicker>-->
<!--                    </div>-->
<!--                    <div class="col-md">-->

<!--                        <b-form-datepicker-->
<!--                            :reset-button=true-->
<!--                            reset-button-variant="btn btn-warning"-->

<!--                            id="example-datepicker2"-->
<!--                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
<!--                            v-model="dateTo"-->
<!--                            placeholder="Date To"-->

<!--                            :disabled="selectedRange!==''&& selectedRange!==null"-->
<!--                            dropdown  calendar-width="100%"-->



<!--                        ></b-form-datepicker>-->
<!--                    </div>-->
                    <div class="col-md-6">
                        <date-picker v-model="dateRange" range
                                     format="DD MMM, YYYY"
                                     value-type="format"
                                     :clearable="false"
                                     :disabled-date="disableExceedingRange"
                                     @input="()=>{
                                         this.handleDateSelection()

                                     }"
                        ></date-picker>
                    </div>
                    <div class="col-md-2">
                        <v-select
                            v-model="cashier_id"

                            class=""
                            placeholder="Sales Man"
                            :options="cashiers"
                            label="fullName"
                            :reduce="option => option.id"

                        />
                    </div>

                    <div class="col-md-2">
                        <v-select
                            v-model="branch_id"
                            :multiple="true"
                            class=""
                            placeholder="Branch"
                            :options="branches.filter((b)=>b.id!=9)"
                            label="name"
                            :reduce="option => option.id"

                        />
                    </div>


                </div>


                <table  class="table text-light ">
                    <thead >
                    <th class="col-md-4">Branch</th>
                    <th>Total Sales</th>
                    <th>Total Collection</th>
                    <th>Cash</th>
                    <th>Card</th>
                    <th>Bank</th>
                    <th>Pay By Link</th>
                    <th>Refund</th>
                    </thead>

                    <tbody >
                    <tr class="" v-for="(report, key ) in reports" :key="key">
                        <td> {{(branches.find((b)=>b.id==report.branch_id)).name}}</td>
                        <td>{{formatNumberWithCommas(Number(report.total_sale).toFixed(2))}}</td>
                        <td>{{formatNumberWithCommas(Number(report.total_collection).toFixed(2))}}</td>
                        <td>{{formatNumberWithCommas(Number(report.cash).toFixed(2))}}</td>
                        <td>{{formatNumberWithCommas(Number(report.card).toFixed(2))}}</td>
                        <td>{{formatNumberWithCommas(Number(report.bank).toFixed(2))}}</td>
                        <td>{{formatNumberWithCommas(Number(report.link).toFixed(2))}}</td>
                        <td>{{formatNumberWithCommas(Number(report.refund).toFixed(2))}}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="row" v-if="showloader=='yes'">
                    <div class="col-md-12 text-center">
                        <loader></loader>
                    </div>
                </div>

                    <table class="table text-light ">
                        <thead style="visibility: hidden">
                        <th class="col-md-4"></th>
                        <th>Total Sales</th>
                        <th>Total Collection</th>
                        <th>Cash</th>
                        <th>Card</th>
                        <th>Bank</th>
                        <th>Pay By Link</th>
                        <th>refund</th>
                        </thead>
                        <tbody>
                        <tr class="">
                            <td>Grand Total </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                .map(report => report.total_sale)
                                .reduce((total, sale) => total + sale, 0)
                                .toFixed(2))
                                }}
                            </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                .map(report => report.total_collection)
                                .reduce((total, collection) => total + collection, 0)
                                .toFixed(2))
                                }}
                            </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                .map(report => report.cash)
                                .reduce((total, csh) => total + csh, 0)
                                .toFixed(2))
                                }}
                            </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                .map(report => report.card)
                                .reduce((total, crd) => total + crd, 0)
                                .toFixed(2))
                                }}
                            </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                .map(report => report.bank)
                                .reduce((total, bnk) => total + bnk, 0)
                                .toFixed(2))
                                }}
                            </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                        .map(report => report.link)
                                        .reduce((total, lnk) => total + lnk, 0)
                                        .toFixed(2))
                                }}
                            </td>
                            <td>
                                {{
                                    formatNumberWithCommas(Object.values(reports)
                                .map(report => report.refund)
                                .reduce((total, rfnd) => total + rfnd, 0)
                                .toFixed(2))
                                }}
                            </td>
                        </tr>

                        </tbody>
                    </table>


            </div>
<!--            <div class="col-md-4 text-light ">-->
<!--                <h1 class="text-center">Refunds</h1>-->
<!--            </div>-->

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
//import {cloneDeep} from "lodash";
import moment from "moment";
import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Swal from "sweetalert2";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";


export default {
    name: "reports",

    components:{Welcomenote,DatePicker, Loader, 'v-select': vSelect, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('invoice', ['allinvoices','cashiers','branches']),
        ...mapState('invoice', ['branches']),



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {

            dateRange:[null,null],
            roles: [],
            user: {},
            dateFrom: '',
            dateTo: '',
            selectedRange: 'Today',

            cashier_id:'',

            branch_id:'',

            reports:null,
            showloader:''

        };
    },
    watch: {
        // whenever question changes, this function will run
        // selectedRange() {
        //
        //     this.getadvancereport();
        // },
        // dateFrom() {
        //     this.getadvancereport();
        // },
        // dateTo() {
        //     this.getadvancereport();
        // },
        cashier_id() {
            this.getadvancereport();
        },
        branch_id() {
            this.getadvancereport();
        }

    },

    async created() {
      //  await this.getUsers();
      //  await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()
        await this.reloadcb()


    },
    async mounted() {

        await this.getadvancereport();

    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers']),
        ...mapActions('invoice', ['updateFullInvoice']),
        ...mapActions('items', ['attachinvoiceitems']),
        ...mapActions('payment', ['setNewpayment']),
        ...mapActions('notes',['attachinvoicenotes']),
        ...mapActions('dispatch',['add_dis_items','attach_invoice_transactions']),


        formatNumberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        disableExceedingRange(date) {
            if (!this.dateRange || this.dateRange.length !== 1) return false; // Allow selecting the first date
            const startDate = this.dateRange[0];
            if (!startDate) return false;

            // Get max allowed date (6 months ahead)
            const maxDate = new Date(startDate);
            maxDate.setMonth(maxDate.getMonth() + 6);

            return date > maxDate; // Disable dates beyond 6 months from start
        },
        handleDateSelection() {
            if (this.dateRange.length === 2) {
                const start = new Date(this.dateRange[0]);
                const end = new Date(this.dateRange[1]);

                const diffMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

                // if (diffMonths > 12) {
                //     this.dateRange = [];
                //     Swal.fire({
                //         icon: "warning",
                //         title: "Date Range",
                //         text: "Date range should not more than 12 months.",
                //         confirmButtonText: "OK"
                //     });
                // } else {
                //     this.selectedRange = '';
                //     this.getadvancereport();
                // }

                this.selectedRange = '';
                this.getadvancereport();
            }


        },


      async  getadvancereport(){
          this.showloader='yes'
          this.reports=[]
            let reportparams={
                // dateFrom:this.dateFrom,
                // dateTo:this.dateTo,
                dateRange:this.dateRange,
                selectedRange:this.selectedRange,
                branch_ids:this.branch_id,
                cashier_id:this.cashier_id

            }
          const apiToken = localStorage.getItem('token');
          if (apiToken) {
              // Set the Authorization header with the API token
              axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
          }

          try {
              const res = await axios.post(`${URL.BASEURL}/api/advance-report`, reportparams);

              if (res) {
                 //console.log(res.data)
                  this.showloader='no'
                  // this.reports=res.data
                  const sortedEntries = Object.entries(res.data)
                      .sort(([, a], [, b]) => b.total_sale - a.total_sale)
                      .map((entry, index) => [`branch_${index + 1}`, entry[1]]);


                  this.reports = Object.fromEntries(sortedEntries);
                  console.log( this.reports, 'advance')
              }
              else {
                  //
              }



          } catch (error) {
              console.error(error);
              Swal.fire('Error', 'Failed to get report', 'info');
          }
        },
       async reloadcb(){
            if(this.branches.length==0){
             //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.cashiers.length==0){
                await this.getcashiers()
            }
        },
        GiveSum(items){
            if(items.length){
                return (items.reduce((total, item) => total + Number(item.payment_amount), 0)).toFixed(2);
            }
            else{
                return 0
            }

        },
        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },

        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
