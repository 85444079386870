
import moment from "moment";

export default {
    namespaced: true,
    state: () => ({

        wareghousefilters:{
            selectedBranch:'',
            selectedMonth:moment().format('YYYY-MM-DD HH:mm:ss'),
            orderType:'',
            status:'Draft',
            invoice_id:null,
            customer_name:'',
            date:'',
            dispatch_date:'',
            page:1,
            selectedRange: [
                // moment().subtract(1, "weeks").format("DD MMM, YYYY"),
                // moment().format("DD MMM, YYYY")
                null, null
            ]

        }

    }),
    mutations: {



    },


    actions: {


    },


}
