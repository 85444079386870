import apiService from "../../service/apiService";
//import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        expenseitems: [],
        expensesuppliers: [],
        expense_searched_items:[],
        pageitems: [],
        pfilters:{

            supplier_id:'',

        }


    }),
    mutations: {

        setExpenseItems(state, data) {
            state.expenseitems = data

        },
        attachexpenseSearchedItems(state, data) {
            state.expense_searched_items = data

        },
        setExpenseSuppliers(state, data) {
            state.expensesuppliers = data

        },




        resetexpensesearchitems(state) {
            state.expense_searched_items =[]

        },

        addexpenseSearchedItems(state,data){
            let mapid=Math.random()
            let datawithmapid = { ...data, mapid: mapid };
            state.expense_searched_items.push(datawithmapid)
        },

        removeexpensesearchedItem(state, map_id){
            //  console.log(state.searched_items,map_id)
            //  alert('removing')
            const filtered= state.expense_searched_items.filter((item)=>item.mapid!=map_id)
            state.expense_searched_items = filtered
        },

        setpagetems(state, data) {
            state.pageitems = data

        },



    },
    actions: {

        async getExpenseItems({commit}) {
            const {data} = await apiService.get(`/expense-items`);



            commit('setExpenseItems', data)
            // console.log('ok')
        },

        async getExpenseSuppliers({commit}) {
            const {data} = await apiService.get(`/expense-suppliers`);



            commit('setExpenseSuppliers', data)
            // console.log('ok')
        },

        add_expense_searched_items({commit},item){
            commit('addexpenseSearchedItems', item)

        },
        attach_expense_searched_items({commit},data){
            commit('attachexpenseSearchedItems', data)

        },



        reset_expense_searched_items({commit}){
            commit('resetexpensesearchitems')

        },

        removeexpenseSearchedItem({commit},item_id){
            commit('removeexpensesearchedItem', item_id)
        },

        set_page_items({commit},item){
            commit('setpagetems', item)

        },

    },
    getters: {

        // expense_grandTotal: (state) => {
        //     return (state.expense_searched_items.reduce((total, item) => total + Number(item.price) * Number(item.qty), 0)).toFixed(2);
        // },
        // po_TotalQty: (state) => {
        //     return (state.po_searched_items.reduce((total, item) => total + Number(item.qty), 0)).toFixed(2);
        // }
    }




}
