<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
               <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light">

                <div class="row mt-1">

                    <div class="col-md-1 offset-4">
                        <button class="btn-sm btn-info"     :disabled="itemsdata.current_page==1" @click="getAllItems('prev')">Previous</button>
                    </div>
                    <div class="col-md-3">
                        <span class="text-light mx-2 px-2"  style="border: 3px solid white; border-radius: 5px"> showing  ( <span v-if="itemsdata.to-50>0">{{itemsdata.to-50}}</span> <span v-else="">0</span>  to {{itemsdata.to}}) out of {{itemsdata.total}} records</span>
                    </div>
                    <div class="col-md-1">

                        <button class="btn-sm btn-info "     @click="getAllItems('next')">Next</button>
                    </div>



                    <h1 class="col-md-12 "> <hr class="hr0"></h1>
                </div>
                <div class="row  mt-3">

                    <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }" v-if="authenticatedUser.permissions.includes('super')">
                        <b-button class="btn-info mb-2" > Add New Product </b-button>
                    </b-link>

                    <div class="col-md-2 ">
                        <b-input v-model="pfilters.searchprdtname"  placeholder="Product Name" @input="getAllItems"></b-input>
                    </div>
                    <div class="col-md-2">
                        <b-input v-model="pfilters.searchprdtcode" placeholder="SKU" @input="getAllItems"></b-input>
                    </div>
                   <div class="col-md-1">
                       <button class="btn btn-info" @click="reloaditems">
                           <span v-if="showloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                           Refresh
                       </button>
                   </div>
                    <div class="col-md-2">

                        <b-form-select
                            v-model="pfilters.supplier_id"
                            :options="suppliers"
                            class="bgsecond bform text-white mb-2 "

                            value-field="id"
                            text-field="name"
                            @input="getAllItems"


                        >

<!--                            <template #first v-if="pfilters.searchprdtcode || pfilters.searchprdtname">-->
<!--                                <b-form-select-option value="">All Suppliers</b-form-select-option>-->
<!--                            </template>-->
                            <template #first >
                                <b-form-select-option value="">All Suppliers</b-form-select-option>
                            </template>

                            <template #option="{ option }">
                                <div>{{ option.name }}</div>
                            </template>

                        </b-form-select>
                    </div>
                    <div class="col-md-2  text-light">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="pfilters.viewinactive"
                            name="checkbox-1"
                            @change="getAllItems"
                        >
                            View In Active
                        </b-form-checkbox>
                    </div>





                    <table class="bg-light table table-striped table-hover table-responsive">
                        <thead>
                        <tr >
                            <th class="bold-columns col-md-1 test_color" >Product Name</th>
                            <th class="bold-columns col-md-2">SKU</th>
                            <th class="bold-columns col-md-1">Note Name</th>
                            <th class="bold-columns"><span class="hidden">....</span>
<!--                                <span class="hidden display_in" style="display: none">....</span>-->
                                Price</th>
                            <th class="bold-columns"> Qty</th>
                            <th class="bold-columns " style=" color: green">
<!--                                <span class="cursor-pointer" @click="toggle_sort('coming')">-->
<!--                                          Coming-->
<!--                                </span>-->
                                Coming


                            </th>
                            <th class="bold-columns">

<!--                                <span class="cursor-pointer" @click="toggle_sort_stock_forecast">-->
<!--                                    <b-icon icon="arrow-down-up "></b-icon>-->
<!--                                    <span> Stock  </span>-->
<!--                                </span>-->
                                Stock
                            </th>
                            <th class="bold-columns">Farnborough</th>
                            <th class="bold-columns">Croydon</th>
                            <th class="bold-columns">Isleworth</th>
                            <th class="bold-columns">
<!--                                <span class="hidden display_in" style="display: none">....</span> -->
                                Bounds G.</th>
                            <th class="bold-columns">Gatwick</th>
                            <th class="bold-columns">Hanworth <span class="hidden">.</span></th>
                            <th class="bold-columns">Hayes Branch</th>
                            <th class="bold-columns">Wembley</th>
<!--                            <th class="bold-columns">Online</th>-->
<!--                            <th class="bold-columns">Wholesale</th>-->
                            <th class="bold-columns">Watford </th>

                            <th class="bold-columns text-right" >Actions</th>
                        </tr>

                        </thead>
                        <div class="table-container" v-if="allitems.length>5">
                        <tbody>
                        <tr v-for="(item, index) in allitems" :key="index">
                            <td class="col-md-1">{{ item.itemName }}</td>
                            <td class="col-md-2">{{ item.itemCode }}</td>
                            <td class="col-md-1">{{ item.delivery_note_name }}</td>
                            <td>{{ item.defaultPrice }}</td>
                            <td>{{ item.box_qty }}</td>
                            <td>
                                <strong>
<!--                                    &nbsp; {{-->
<!--                                        (-->
<!--                                            Number(item.FarnboroughWarehouseAfterOpen2) +-->
<!--                                            Number(item.IsleworthWarehouseAfterOpen2) +-->
<!--                                            Number(item.BoundsGreenAfterOpen2) +-->
<!--                                            Number(item.CrawleyAfterOpen2) +-->
<!--                                            Number(item.HanworthAfterOpen2)+-->
<!--                                            Number(item.HayesAfterOpen2)+-->
<!--                                            Number(item.WembleyAfterOpen2)+-->
<!--                                            Number(item.OnlineAfterOpen2)+-->
<!--                                            Number(item.WholesaleAfterOpen2)-->
<!--                                        ).toFixed(2)-->
<!--                                    }}-->

                                    <span v-b-tooltip.hover.html="formatUpcomingDates(item)" popover-class="custom-tooltip" style="color: green">
                                        {{ Number(item.upcoming).toFixed(2) }}
                                      </span>
<!--                                    <span-->

<!--                                        data-bs-toggle="tooltip"-->
<!--                                        data-bs-placement="top"-->
<!--                                        :title="Number(item.upcoming).toFixed(2)"-->
<!--                                    >-->
<!--                                          {{ Number(item.upcoming).toFixed(2) }}-->
<!--                                    </span>-->


                                </strong>
                            </td>
                            <td>
                                <strong>
<!--                                    &nbsp; {{-->
<!--                                        (-->
<!--                                            Number(item.FarnboroughWarehouseAfterOpen) +-->
<!--                                            Number(item.IsleworthWarehouseAfterOpen) +-->
<!--                                            Number(item.BoundsGreenAfterOpen) +-->
<!--                                            Number(item.CrawleyAfterOpen) +-->
<!--                                            Number(item.HanworthAfterOpen)+-->
<!--                                            Number(item.HayesAfterOpen)+-->
<!--                                            Number(item.WembleyAfterOpen)+-->
<!--                                            Number(item.OnlineAfterOpen)+-->
<!--                                            Number(item.WholesaleAfterOpen)-->
<!--                                        ).toFixed(2)-->
<!--                                    }}-->

                                    {{Number(item.stock_forecast).toFixed(2)}}
                                </strong>
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.FarnboroughWarehouseAfterOpen).toFixed(2) }} </strong>
                                ({{ Number(item.FarnboroughWarehouseStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.CroydonAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.CroydonStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.IsleworthWarehouseAfterOpen).toFixed(2) }} </strong>
                                ({{ Number(item.IsleworthWarehouseStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.BoundsGreenAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.BoundsGreenStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.GatwickAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.GatwickStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.HanworthAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.HanworthStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.HayesAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.HayesStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.WembleyAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.WembleyStock).toFixed(2) }})
                            </td>
<!--                            <td>-->
<!--                                <strong>&nbsp; {{ Number(item.OnlineAfterOpen).toFixed(2) }}</strong>-->
<!--                                ({{ Number(item.OnlineStock).toFixed(2) }})-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <strong>&nbsp; {{ Number(item.WholesaleAfterOpen).toFixed(2) }}</strong>-->
<!--                                ({{ Number(item.WholesaleStock).toFixed(2) }})-->
<!--                            </td>-->
                            <td>
                                <strong>&nbsp; {{ Number(item.WatfordAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.WatfordStock).toFixed(2) }})
                            </td>

                            <td>
                                <b-link v-if="authenticatedUser.permissions.includes('super')" :to="{ path: '/productform', query: { type: 'edit', 'id': item.id } }">
                                    <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                </b-link>
                                <b-link v-if="!authenticatedUser.permissions.includes('super')" :to="{ path: '/productform', query: { type: 'edit', 'id': item.id } }" class="hidden">
                                    <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                </b-link>
                                <b-link :to="{ path: '/productform', query: { type: 'view', 'id': item.id } }">
                                    <b-button class="btn-info mb-2"> View </b-button>
                                </b-link>
                                <b-button @click="deleteproduct(item.id)" v-if="authenticatedUser.permissions.includes('super')" class="btn-danger mb-2"> Delete </b-button>
                                <b-button @click="deleteproduct(item.id)" v-if="!authenticatedUser.permissions.includes('super')" class="btn-danger hidden mb-2"> Delete </b-button>
                            </td>
                        </tr>
                        </tbody>
                        </div>
                        <tbody v-if="allitems.length<6">
                        <tr v-for="(item, index) in allitems" :key="index">
                            <td class="col-md-1">{{ item.itemName }}</td>
                            <td class="col-md-2">{{ item.itemCode }}</td>
                            <td class="col-md-1">{{ item.delivery_note_name }}</td>
                            <td>{{ item.defaultPrice }}</td>
                            <td>{{ item.box_qty }}</td>
                            <td>
                                <strong>
                                    <!--                                    &nbsp; {{-->
                                    <!--                                        (-->
                                    <!--                                            Number(item.FarnboroughWarehouseAfterOpen2) +-->
                                    <!--                                            Number(item.IsleworthWarehouseAfterOpen2) +-->
                                    <!--                                            Number(item.BoundsGreenAfterOpen2) +-->
                                    <!--                                            Number(item.CrawleyAfterOpen2) +-->
                                    <!--                                            Number(item.HanworthAfterOpen2)+-->
                                    <!--                                            Number(item.HayesAfterOpen2)+-->
                                    <!--                                            Number(item.WembleyAfterOpen2)+-->
                                    <!--                                            Number(item.OnlineAfterOpen2)+-->
                                    <!--                                            Number(item.WholesaleAfterOpen2)-->
                                    <!--                                        ).toFixed(2)-->
                                    <!--                                    }}-->

                                    <span v-b-tooltip.hover.html="formatUpcomingDates(item)" popover-class="custom-tooltip" style="color: green">
                                        {{ Number(item.upcoming).toFixed(2) }}
                                      </span>
                                    <!--                                    <span-->

                                    <!--                                        data-bs-toggle="tooltip"-->
                                    <!--                                        data-bs-placement="top"-->
                                    <!--                                        :title="Number(item.upcoming).toFixed(2)"-->
                                    <!--                                    >-->
                                    <!--                                          {{ Number(item.upcoming).toFixed(2) }}-->
                                    <!--                                    </span>-->


                                </strong>
                            </td>
                            <td>
                                <strong>
                                    <!--                                    &nbsp; {{-->
                                    <!--                                        (-->
                                    <!--                                            Number(item.FarnboroughWarehouseAfterOpen) +-->
                                    <!--                                            Number(item.IsleworthWarehouseAfterOpen) +-->
                                    <!--                                            Number(item.BoundsGreenAfterOpen) +-->
                                    <!--                                            Number(item.CrawleyAfterOpen) +-->
                                    <!--                                            Number(item.HanworthAfterOpen)+-->
                                    <!--                                            Number(item.HayesAfterOpen)+-->
                                    <!--                                            Number(item.WembleyAfterOpen)+-->
                                    <!--                                            Number(item.OnlineAfterOpen)+-->
                                    <!--                                            Number(item.WholesaleAfterOpen)-->
                                    <!--                                        ).toFixed(2)-->
                                    <!--                                    }}-->

                                    {{Number(item.stock_forecast).toFixed(2)}}
                                </strong>
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.FarnboroughWarehouseAfterOpen).toFixed(2) }} </strong>
                                ({{ Number(item.FarnboroughWarehouseStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.CroydonAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.CroydonStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.IsleworthWarehouseAfterOpen).toFixed(2) }} </strong>
                                ({{ Number(item.IsleworthWarehouseStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.BoundsGreenAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.BoundsGreenStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.GatwickAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.GatwickStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.HanworthAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.HanworthStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.HayesAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.HayesStock).toFixed(2) }})
                            </td>
                            <td>
                                <strong>&nbsp; {{ Number(item.WembleyAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.WembleyStock).toFixed(2) }})
                            </td>
                            <!--                            <td>-->
                            <!--                                <strong>&nbsp; {{ Number(item.OnlineAfterOpen).toFixed(2) }}</strong>-->
                            <!--                                ({{ Number(item.OnlineStock).toFixed(2) }})-->
                            <!--                            </td>-->
                            <!--                            <td>-->
                            <!--                                <strong>&nbsp; {{ Number(item.WholesaleAfterOpen).toFixed(2) }}</strong>-->
                            <!--                                ({{ Number(item.WholesaleStock).toFixed(2) }})-->
                            <!--                            </td>-->
                            <td>
                                <strong>&nbsp; {{ Number(item.WatfordAfterOpen).toFixed(2) }}</strong>
                                ({{ Number(item.WatfordStock).toFixed(2) }})
                            </td>

                            <td>
                                <b-link v-if="authenticatedUser.permissions.includes('super')" :to="{ path: '/productform', query: { type: 'edit', 'id': item.id } }">
                                    <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                </b-link>
                                <b-link v-if="!authenticatedUser.permissions.includes('super')" :to="{ path: '/productform', query: { type: 'edit', 'id': item.id } }" class="hidden">
                                    <b-button class="btn-info mb-2 mx-1"> Edit </b-button>
                                </b-link>
                                <b-link :to="{ path: '/productform', query: { type: 'view', 'id': item.id } }">
                                    <b-button class="btn-info mb-2"> View </b-button>
                                </b-link>
                                <b-button @click="deleteproduct(item.id)" v-if="authenticatedUser.permissions.includes('super')" class="btn-danger mb-2"> Delete </b-button>
                                <b-button @click="deleteproduct(item.id)" v-if="!authenticatedUser.permissions.includes('super')" class="btn-danger hidden mb-2"> Delete </b-button>
                            </td>
                        </tr>
                        </tbody>

                    </table>


                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import apiService from "@/service/apiService";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
import moment from 'moment';
import VTooltip from 'v-tooltip';

//import vSelect from "vue-select";
//import vSelect from "vue-select";


export default {
    name: "customers",

    components:{Welcomenote,VTooltip, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapState('customers', ['customers']),
        ...mapState('items', ['pfilters','pageitems']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },
        // filteredProducts() {
        //
        //
        //     let  fproducts=this.allitems;
        //
        //     if(this.branch_id){
        //         fproducts=fproducts.filter(dfo=>dfo.branch_id===this.branch_id)
        //     }
        //     if(this.cashier_id){
        //         fproducts=fproducts.filter(dfo=>dfo.cashier_id===this.cashier_id)
        //     }
        //     if(this.order_status){
        //         fproducts=fproducts.filter(dfo=>dfo.status===this.order_status)
        //     }
        //     if(this.searchprdtname){
        //         let searchprdtname=this.searchprdtname.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const name = product.itemName ? product.itemName.toLowerCase() : '';
        //             return name.includes(searchprdtname)
        //         })
        //     }
        //     if(this.searchprdtcode){
        //         const searchprdtcode =this.searchprdtcode.toLowerCase();
        //
        //         fproducts=fproducts.filter(product=> {
        //             const code = product.itemCode ? product.itemCode.toLowerCase() : '';
        //             return code.includes(searchprdtcode)
        //         })
        //     }
        //
        //     if(this.searchcustemail){
        //         let searchcustemail=this.searchcustemail.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const email = customer.email ? customer.email.toLowerCase() : '';
        //             return email.includes(searchcustemail)
        //         })
        //     }
        //     if(this.custtype){
        //         let custtype=this.custtype.toLowerCase();
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const customerType = customer.customerType ? customer.customerType.toLowerCase() : '';
        //             return customerType.includes(custtype)
        //         })
        //     }
        //
        //     if(this.credit){
        //
        //
        //         fproducts=fproducts.filter(customer=> {
        //             const balance = customer.walletBalance ? Number(customer.walletBalance)>0:false;
        //             return balance
        //         })
        //     }
        //
        //     fproducts=fproducts.filter(item=> {
        //        if(this.viewinactive==true){
        //            if(item.status==0){
        //                return true
        //            }
        //        }
        //        else {
        //            if(item.status==1){
        //                return true
        //            }
        //        }
        //     })
        //
        //     return fproducts
        // },

    },
    data() {
        return {
            showloader:false,
            allitems:[],
            itemsdata:'',

            loading:false,
            roles: [],
            user: {},
            perPage:10,
            currentPage: 1,

            cashier_id:'',
            order_status:'',
            branch_id:'',

            searchcustemail:'',
            custtype:'',
            credit:null,
            suppliers:[],
            supplier_id:'',
            upcomingDates:[],
            modalOpen:false,





            fields: [

                { key: 'itemName', label: 'Product Name' },
                { key: 'itemCode', label: 'SKU' },
                { key: 'delivery_note_name', label: 'Note Name' },
                { key: 'defaultPrice', label: 'Default Price' },

                { key: 'box_qty', label: 'Box Quantity' },


                { key: 'forecaststock', label: 'Stock' },

                { key: 'Farnborough', label: 'Farnborough' },
                { key: 'Isleworth', label: 'Isleworth' },
                { key: 'Bounds_Green', label: 'Bounds G. ' },
                { key: 'Gatwick', label: 'Gatwick' },
                { key: 'Hanworth', label: 'Hanworth' },


                'actions'

            ],

        };
    },
    async created() {

        // await this.getbranches()
        // await this.getcashiers()
        this.loading=true
        await this.getAllItems();
        this.loading=false
        await this.getsuppliers()



        // await this.getCustomers();



    },
    async mounted() {
        // window.addEventListener("scroll", () => {
        //     // console.log("Scroll event triggered");
        //     const scroll = window.scrollY;
        //     console.log(scroll, "scroll");
        //     const nav = document.getElementById("products_header_fix");
        //
        //     if (nav) {
        //         if (scroll >= 170) {
        //             nav.classList.add("products_header_fix");
        //         } else {
        //             nav.classList.remove("products_header_fix");
        //         }
        //     }
        // });


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('customers', ['getCustomers']),
        ...mapActions('items', ['set_page_items']),


        formatUpcomingDates(item) {
            const upcomingDates = [
                {
                    date: item.IsleworthWarehouseUpcomingDate,
                    branch: 'Isleworth',
                    upcoming: item.IsleworthWarehouseUpcomingStock ? Number(item.IsleworthWarehouseUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.BoundsGreenUpcomingDate,
                    branch: 'Bounds Green',
                    upcoming: item.BoundsGreenUpcomingStock ? Number(item.BoundsGreenUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.HanworthUpcomingDate,
                    branch: 'Hanworth',
                    upcoming: item.HanworthUpcomingStock ? Number(item.HanworthUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.GatwickUpcomingDate,
                    branch: 'Gatwick',
                    upcoming: item.GatwickUpcomingStock ? Number(item.GatwickUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.FarnboroughWarehouseUpcomingDate,
                    branch: 'Farnborough',
                    upcoming: item.FarnboroughWarehouseUpcomingStock ? Number(item.FarnboroughWarehouseUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.HayesUpcomingDate,
                    branch: 'Hayes',
                    upcoming: item.HayesUpcomingStock ? Number(item.HayesUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.WembleyUpcomingDate,
                    branch: 'Wembley',
                    upcoming: item.WembleyUpcomingStock ? Number(item.WembleyUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.OnlineUpcomingDate,
                    branch: 'Online',
                    upcoming: item.OnlineUpcomingStock ? Number(item.OnlineUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.WholesaleUpcomingDate,
                    branch: 'Wholesale',
                    upcoming: item.WholesaleUpcomingStock ? Number(item.WholesaleUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.WatfordUpcomingDate,
                    branch: 'Watford',
                    upcoming: item.WatfordUpcomingStock ? Number(item.WatfordUpcomingStock).toFixed(0) : null
                },
                {
                    date: item.CroydonUpcomingDate,
                    branch: 'Croydon',
                    upcoming: item.CroydonUpcomingStock ? Number(item.CroydonUpcomingStock).toFixed(0) : null
                },

            ]
                .filter(({ date }) => date)
                .map(({upcoming, date, branch }) => {
                    const formattedDate = moment(date).format('DD MMM, YYYY');
                    return `<strong>(${upcoming})</strong> ${branch}:<strong>${formattedDate}</strong>`;
                })
                .join('<br>');

            return upcomingDates;
        },
        getClosestUpcomingDates(item) {
            const upcomingDates = [
                { date: item.IsleworthWarehouseUpcomingDate, branch: 'Isleworth' },
                { date: item.BoundsGreenUpcomingDate, branch: 'Bounds Green' },
                { date: item.HanworthUpcomingDate, branch: 'Hanworth' },
                { date: item.GatwickUpcomingDate, branch: 'Gatwick' },
                { date: item.FarnboroughWarehouseUpcomingDate, branch: 'Farnborough' },
                { date: item.HayesUpcomingDate, branch: 'Hayes' },
                { date: item.WembleyUpcomingDate, branch: 'Wembley' },
                { date: item.OnlineUpcomingDate, branch: 'Online' },
                { date: item.WholesaleUpcomingDate, branch: 'Wholesale' },
                { date: item.WatfordUpcomingDate, branch: 'Watford' },
            ]
                .filter(({ date }) => date)
                .map(({ date, branch }) => ({ date: new Date(date), branch }));

            if (upcomingDates.length === 0) return [];

            upcomingDates.sort((a, b) => a.date - b.date);

            return upcomingDates.map(({ date, branch }) => ({
                formattedDate: moment(date).format('DD MMM, YYYY'),
                branch
            }));
        },




        toggle_sort(column){
            if(column=='stock'){
                if(this.pfilters.sort_stock_forecast=='asc'){
                    this.pfilters.sort_stock_forecast='desc'
                } else {
                    this.pfilters.sort_stock_forecast='asc'
                }
            }
            if(column=='coming'){
                if(this.pfilters.up_coming=='asc'){
                    this.pfilters.up_coming='desc'
                } else {
                    this.pfilters.up_coming='asc'
                }
            }

          this.getAllItems();
        },

        async getAllItems(button=null) {

            // if(this.pfilters.searchprdtcode || this.pfilters.searchprdtname){
            // //
            // } else {
            //     if(this.pfilters.supplier_id){
            //         //
            //     } else {
            //         this.pfilters.supplier_id=26
            //     }
            //
            // }

            let current_page= this.itemsdata.current_page
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
          let filters={
                name:this.pfilters.searchprdtname,
                sku:this.pfilters.searchprdtcode,
                status:this.pfilters.viewinactive,
                page:current_page,
              supplier_id:this.pfilters.supplier_id,
              sort_stock_forecast:this.pfilters.sort_stock_forecast,
              up_coming:this.pfilters.up_coming,
            }
            if(button=='next'){

                filters.page=current_page+1

            }
            if(button=='prev'){
                filters.page=current_page-1


            }
            this.showloader=true
            const {data} = await apiService.post(`/allitems`,filters);
            this.showloader=false

            let  itemdata=data.data.map((d)=>{
                let mapid=Math.random()
                return { ...d, qty: 1, picked:0 , mapid:mapid};
            })
            this.itemsdata=data

           // if(this.pfilters.upcoming=='desc'){
           //     this.allitems=itemdata.sort((a, b) => b.stock_forecast2 - a.stock_forecast2);
           // } else {
           //     this.allitems = itemdata.sort((a, b) => a.stock_forecast2 - b.stock_forecast2);
           // }
            this.allitems=itemdata

            this.set_page_items(itemdata)

            // this.items = itemdata;
            // this.$store.commit('items/setItems', itemdata);

            // console.log('ok')
        },
         async reloaditems(){
            this.loading=true
           //  await this.getItems();
             await this.getAllItems();
             this.loading=false

         },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('branches', data)
            this.suppliers=data
        },

        async deleteproduct(item_id){

            const {data} = await apiService.get(`/code_used/${item_id}`);
            //   console.log('code_availability', data.code_availability)
            //   alert(data.code_availability)
            if(data.code_used){
                Swal.fire('Product is used!', 'You cannot delete used products.', 'info');
            }
            else {
                const result = await Swal.fire({
                    title: 'Are you sure you want to delete this product?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) {

                    const apiToken = localStorage.getItem('token');
                    if (apiToken) {
                        // Set the Authorization header with the API token
                        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
                    }
                    try {
                        const res = await axios.delete(`${URL.BASEURL}/api/delete-item/${item_id}`);

                        if (res) {
                            await this.getAllItems();

                            Swal.fire('success', 'Product  Deleted', 'success');

                        } else {
                            Swal.fire('info', 'Product Cant be Deleted', 'info');
                        }
                    } catch (error) {
                        console.error(error);
                        Swal.fire('Error', 'Failed to delete Product.', 'info');
                    }
                }
            }
        },
        async signOut() {
            await this.processLogoutManual();
          //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        // async getRoles() {
        //     const { data } = await this.$axios.get('role');
        //     this.roles = data.roles;
        // },
        // filterUsers(value) {
        //     this.getUsers(value)
        // },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.bold-columns th.IsleworthWarehouseAfterOpen,
.bold-columns th.BoundsGreenAfterOpen,
.bold-columns th.HanworthAfterOpen,
.bold-columns th.GatwickAfterOpen,
.bold-columns th.FarnboroughWarehouseAfterOpen {
    font-weight: bold;
}

.custom-tooltip .tooltip-inner {
    max-width: 500px;
}

.arrow {
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.asc::before {
    content: '';
    border-bottom: 5px solid black;
}
.desc::after {
    content: '';
    border-top: 5px solid black;
}
.hidden {
    visibility: hidden;
}
.products_header_fix {
    position: fixed;
    top: 0 !important;

    z-index: 10;
}

.table-container {
    height: 665px;
    overflow-y: auto;
    position: relative;
}

@media (min-width: 20.1in) {
    .display_in{
        display: inline-block !important;
    }
}



</style>
