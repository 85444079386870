import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const files = require.context('../pages', true, /^(?!.*(?:Login|ForgotPassword|TwoFactorAuthentication)).*\.vue$/);

let pages = {};
files.keys().map(key => {
    pages[key.split('/').pop().split('.')[0]] = {
        component: files(key).default,
        path: key.split('/').pop().split('.')[0]
    }
})

pages = {
    ...pages,
    Users: {
        ...pages.Users,
        meta: {
            permission: 'view users'
        }
    },
    EditUser: {
        ...pages.EditUser,
        path: pages.EditUser.path += '/:id'
    },






}
const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: 'history',
    routes: [
        // {
        //     path: '/',
        //     redirect: '/pos',
        // },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('../pages/Dashboard'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },
        {
            path: '/data-export',
            name: 'DataExport',
            component: () => import('../pages/DataExport')
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('../pages/Login'),
            beforeEnter(to, from, next) {
                // Check if the protocol is HTTPS, then redirect to HTTP
                if (window.location.protocol === 'https:') {
                    next('http://' + window.location.host + '/login');
                } else {
                    // If already HTTP, proceed to the route
                    next();
                }
            },
        },
        {
            path: '/users',
            name: 'Users',
            component: () => import('../pages/admin/users/Users'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                   next()
                }
                else {
                  router.push({
                    name: 'Unauthorized'
                  })
                }
                },
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import('../pages/admin/mypos/reports'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },
        {
            path: '/expense-reports',
            name: 'expenseReports',
            component: () => import('../pages/admin/mypos/expenseReports'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/reports2',
            name: 'reports2',
            component: () => import('../pages/admin/mypos/reports2'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },
        {
            path: '/trade-card',
            name: 'trade_card',
            component: () => import('../pages/admin/mypos/trade_card'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/suppliers',
            name: 'suppliers',
            component: () => import('../pages/admin/mypos/suppliers'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },

        {
            path: '/printpo',
            name: 'printpo',
            component: () => import('../pages/admin/mypos/printpo'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },

        {
            path: '/purchase-orders',
            name: 'purchaseorders',
            component: () => import('../pages/admin/mypos/purchaseorders'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/csv',
            name: 'csv',
            component: () => import('../pages/admin/mypos/csv'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },

        {
            path: '/products',
            name: 'products',
            component: () => import('../pages/admin/mypos/products'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/categories',
            name: 'categories',
            component: () => import('../pages/admin/mypos/categories'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.id==22 ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },

        {
            path: '/inbounds',
            name: 'inbounds',
            component: () => import('../pages/admin/mypos/inbounds'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },


        {
            path: '/productform',
            name: 'productform',
            component: () => import('../pages/admin/mypos/productform'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/categoryform',
            name: 'categoryform',
            component: () => import('../pages/admin/mypos/categoryform'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.id==22 ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },
        {
            path: '/expenseform',
            name: 'expenseform',
            component: () => import('../pages/admin/mypos/expenseform'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },

        {
            path: '/adjustments',
            name: 'adjustments',
            component: () => import('../pages/admin/mypos/adjustments'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/supplierform',
            name: 'supplierform',
            component: () => import('../pages/admin/mypos/supplierform'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/intransfers',
            name: 'intransfers',
            component: () => import('../pages/admin/mypos/intransfers'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/supplierform',
            name: 'supplierform',
            component: () => import('../pages/admin/mypos/supplierform'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },
        {
            path: '/poform',
            name: 'poform',
            component: () => import('../pages/admin/mypos/poform'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },

        {
            path: '/tradecardform',
            name: 'tradecardform',
            component: () => import('../pages/admin/mypos/tradecardform'),
            // beforeEnter(to, from, next){
            //
            //     let user = localStorage.getItem('user');
            //     user = JSON.parse(user)
            //
            //
            //     if (user.permissions.includes('super') ) {
            //         next()
            //     }
            //     else {
            //         router.push({
            //             name: 'Unauthorized'
            //         })
            //     }
            // },
        },


        {
            path: '/Unauthorized',
            name: 'Unauthorized',
            component: () => import('../pages/admin/mypos/Unauthorized.vue'),

        },
        {
            path: '/',
            name: 'pos',
            component: () => import('../pages/admin/mypos/pos'),
            beforeEnter(to, from, next) {
                // Check if the protocol is HTTPS, then redirect to HTTP
                if (window.location.protocol === 'https:') {
                    next('http://' + window.location.host + '/');
                } else {
                    // If already HTTP, proceed to the route
                    next();
                }
            },
        },
        {
            path: '/cash-collections',
            name: 'collections',
            component: () => import('../pages/admin/mypos/collections')
        },
        {
            path: '/expenses',
            name: 'expenses',
            component: () => import('../pages/admin/mypos/expenses')
        },
        {
            path: '/pos/print-invoice',
            name: 'printinvoice',
            component: () => import('../pages/admin/mypos/printinvoice')
        },
        {
            path: '/delivery-note/:id',
            name: 'deliverynote',
            component: () => import('../pages/admin/mypos/deliverynote'),
            props: true
        },
        {
            path: '/print-internal-transfer/:id',
            name: 'printinternaltransfer',
            component: () => import('../pages/admin/mypos/printinternaltransfer'),
            props: true
        },
        {
            path: '/print-collection',
            name: 'printcollection',
            component: () => import('../pages/admin/mypos/printcollection'),
            props: true
        },
        {
            path: '/inbound-stock',
            name: 'inboundstock',
            component: () => import('../pages/admin/mypos/inboundstock'),
            props: true
        },

        {
            path: '/stock-adjustment',
            name: 'stockadjustment',
            component: () => import('../pages/admin/mypos/stockadjustment'),
            props: true
        },
        {
            path: '/internal-transfer',
            name: 'internaltransfer',
            component: () => import('../pages/admin/mypos/internaltransfer'),
            props: true
        },

        {
            path: '/stock-calculation',
            name: 'stockcalculation',
            component: () => import('../pages/admin/mypos/stockcalculation'),
            props: true
        },

        {
            path: '/orders',
            name: 'orders',
            component: () => import('../pages/admin/mypos/orders')
        },
        {
            path: '/deliveries',
            name: 'deliveries',
            component: () => import('../pages/admin/mypos/deliveries')
        },
        {
            path: '/dispatch-planner',
            name: 'dispatchplanner',
            component: () => import('../pages/admin/mypos/dispatchplanner')
        },
        {
            path: '/transfer-guide',
            name: 'transferguide',
            component: () => import('../pages/admin/mypos/transferguide')
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('../pages/admin/mypos/settings')
        },
        {
            path: '/purchase',
            name: 'purchase',
            component: () => import('../pages/admin/mypos/purchase')
        },
        {
            path: '/materialrequest',
            name: 'materialrequest',
            component: () => import('../pages/admin/mypos/materialrequest')
        },
        {
            path: '/stock',
            name: 'stock',
            component: () => import('../pages/admin/mypos/stock')
        },
        {
            path: '/branch-reports',
            name: 'branchReports',
            component: () => import('../pages/admin/mypos/branchReports')
        },
        {
            path: '/collection-reports',
            name: 'collectionreports',
            component: () => import('../pages/admin/mypos/collectionreports')
        },
        {
            path: '/customers',
            name: 'customers',
            component: () => import('../pages/admin/mypos/customers')
        },
        {
            path: '/customerform',
            name: 'customerform',
            component: () => import('../pages/admin/mypos/customerform'),

        },
        {
            path: '/branch-league',
            name: 'branchleague',
            component: () => import('../pages/admin/mypos/branchleague'),
            props: true
        },
        {
            path: '/cashiers',
            name: 'cashiers',
            component: () => import('../pages/admin/mypos/cashiers')
        },
        {
            path: '/cashierform',
            name: 'cashierform',
            component: () => import('../pages/admin/mypos/cashierform'),

        },
        {
            path: '/create-user',
            name: 'CreateUser',
            component: () => import('../pages/admin/users/CreateUser'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },
        {
            path: '/edit-user/:id',
            name: 'EditUser',
            component: () => import('../pages/admin/users/EditUser'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },

        },

        // {
        //     path: '/edit-device/:id',
        //     name: 'EditDevice',
        //     component: () => import('../pages/EditDevice')
        // },
        {
            path: '/roles',
            name: 'Roles',
            component: () => import('../pages/admin/roles/Roles'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },
        {
            path: '/permissions',
            name: 'Permissions',
            component: () => import('../pages/admin/permissions/Permissions'),
            beforeEnter(to, from, next){

                let user = localStorage.getItem('user');
                user = JSON.parse(user)


                if (user.permissions.includes('super') ) {
                    next()
                }
                else {
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            },
        },










        {
            path: '*',
            name: '404',
            component: () => import('../pages/errors/404')
        }
    ]
})

const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return token ?? null;
}
const canUserAccess = (to) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
        /*if((to.name === 'Applications' || to.name === 'UserApplication' || to.name === 'ApplicationUser' || to.name === 'Application' ) && !user.permissions.includes('view_enterprise_applications')){
            return false;
        }
        if((to.name === 'permissionsManager' || to.name === 'permissionsManagerDetails' || to.name === 'permissionsManagerManageMultiUsers' ) && !user.permissions.includes('view_custom_applications')){
            return false;
        }
        if((to.name === 'Directories' || to.name === 'DirectoryGroups' || to.name === 'DirectoryUsers') && !user.permissions.includes('view_directories')){
            return false;
        }
        if((to.name === 'People' || to.name === 'User'   ) && !user.permissions.includes('view_people')){
            return false;
        }
        if((to.name === 'Users' ) && !user.permissions.includes('view_kfupm_dir_users')){
            return false;
        }
        if((to.name === 'Groups')  && !user.permissions.includes('view_kfupm_dir_groups')){
            return false;
        }
        if((to.name === 'IamUsers')  && !user.permissions.includes('view_iam_users')){
            return false;
        }*/
        if(to.name === 'Dashboard'){
            return true;
        }
    }
    return true;
}

router.beforeEach((to, from, next) => {
    if (!isAuthenticated() && to.name !== 'Login' && to.name !== 'LoginCallBack'&& to.name !== 'LogoutCallBack') {
        next({name: 'Login'})
    } else if (isAuthenticated() && to.name==='Login') {
        next({name: 'Dashboard'})
    } else if(!canUserAccess(to)){
        next({name: 'Dashboard'})
    }
    else {
        window.scrollTo(0, 0)
        next();
    }
})

export default router;
