<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
              <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut" >Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11" style="border: 2px solid white">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-light text-center">
                        <span v-if="viewType=='add'">
                             Create Purchase Order
                        </span>
                        <span v-if="viewType=='edit'">
                             Update Purchase Order
                        </span>

                        </h1>
                    </div>
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">
                            <span v-if="getting_po" style="margin-bottom: 0px" class="spinner-border h6 " role="status" aria-hidden="true"></span>
                              <span v-if="viewType=='view'">
                               View
                              </span>
                            Details
                            <span v-if="po_status!=''">
                         ( {{po_status}} )
                              </span>
                            <button class="btn btn-warning" v-if="allowcreateremaining" @click="create_remaining_inbounds(po_id)">Create Remaining Inbounds</button>
                        </h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <div class="row mt-1">

                            <div class="col-md-3">
                                <label><strong>Enter Purchase Order Reference</strong></label>
                                <b-input v-model="por" disabled></b-input>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Expected Inbound Date</strong></label>
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="datepicsuper"
                                     :disabled="viewType=='view'"
                                    v-model="date"
                                    placeholder="Date"

                                    dropup  calendar-width="100%">

                                </b-form-datepicker>
                            </div>
                            <div class="col-md-3">
                                <label><strong>Select Supplier</strong></label>
                                <b-form-select
                                    v-model="supplier_id"
                                    :options="suppliers.filter((s)=>s.allowed_in_po==1)"
                                    class="bgsecond bform text-white mb-2 "

                                    value-field="id"
                                    text-field="name"
                                    :disabled="viewType=='view'"
                                    @input="handlesupchange"

                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Supplier</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.name }}</div>
                                    </template>

                                </b-form-select>
                            </div>
                            <div class="col-md-3" v-if="authenticatedUser.permissions.includes('super')">
                                <label><strong>Select Location</strong></label>
                                <b-form-select
                                    v-model="location_id"
                                    :options="locations"
                                    class="bgsecond bform text-white mb-2 "
                                    :disabled="viewType=='view'"
                                    value-field="id"
                                    text-field="location_name"
                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>

                        </div>


                    </div>

                    <div class="col-md-12">
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <div class="row mt-2">
                            <div class="col-md-2 text-light">

                                <b-form-checkbox v-model="deliver_to_customer" name="checkbox">Deliver to customer</b-form-checkbox>
                            </div>
                            <div class="col-md-2 text-light" v-if="deliver_to_customer">


                                <b-form-input v-model="invoice_id" type="text" class="form-control "  placeholder="Invoice Id" required list="invoices-list-id" autocomplete="off" @change="setcustomer" > </b-form-input>
                                <datalist id="invoices-list-id" v-if="invoices.length">
                                    <option v-for="invoice in invoices" :key="invoice.id" :value="invoice.id"> {{ invoice.id }}    </option>
                                </datalist>
                            </div>


                        </div>
                        <div class="row mt-2">

                            <div class="col-md-2 text-light" v-if="deliver_to_customer">

                                <b-form-input v-model="d_customer.lastName" placeholder="Customer Name"></b-form-input>
                            </div>
                            <div class="col-md-2 text-light" v-if="deliver_to_customer">

                                <b-form-input v-model="d_customer.mobile" placeholder="Customer Phone"></b-form-input>
                            </div>
                            <div class="col-md-2 text-light" v-if="deliver_to_customer">

                                <b-form-input v-model="d_customer.email" placeholder="Customer Email"></b-form-input>
                            </div>
                            <div class="col-md-2 text-light" v-if="deliver_to_customer">

                                <b-form-input v-model="d_customer.addressLine1" placeholder="Address Line 2"></b-form-input>
                            </div>
                            <div class="col-md-2 text-light" v-if="deliver_to_customer">

                                <b-form-input v-model="d_customer.addressLine2" placeholder="Address Line 2"></b-form-input>
                            </div>
                            <div class="col-md-2 text-light" v-if="deliver_to_customer">

                                <b-form-input v-model="d_customer.postcode" placeholder="Post Code"></b-form-input>
                            </div>

                        </div>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <div class="row">
                            <div class="col-md-3">
                                <!--                                <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }">-->
                                <!--                                    <b-button class="btn-info " > Add New Item </b-button>-->
                                <!--                                </b-link>-->
                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Items</h1>
                            </div>
                        </div>

                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col">Supplier Sku</th>
                                <th scope="col">internal Ref</th>
                                <th scope="col" >Qty</th>
                                <th scope="col" v-if="viewType=='view'" >Received</th>
                                <th scope="col" >SubTotal</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in po_items" :key="item.mapid" :value="item.itemCode"> {{ item.itemName }}  | {{item.itemDescription}}  </option>

                            </datalist>
                            <template v-if="po_searched_items">
                              <template v-for="item in po_searched_items">
                                  <tr class="text-light "  :class="(viewType=='view' && Number(item.qty).toFixed(2)==Number(item.received).toFixed(2))?'bg-success':''" :key="item.mapid">
                                      <th class="col-md-3 ">
                                          <div class="input-container w-100">
                                              <i  class="bi bi-x-circle custom-icon cursor-pointer " v-if="viewType!='view'|| (viewType=='view' && Number(item.received).toFixed(2)==0) && authenticatedUser.permissions.includes('super')" @click="removeitem(item.mapid,item)" style="margin-right: 0.5rem;"></i>
                                              <input class="bg-light col-md-11 form-control" v-model="item.itemCode"  list="item-list-id" @change="addsearchitem('',item.itemCode, item.mapid)" :disabled="!supplier_id || viewType=='view'" >

                                          </div>
                                      </th>

                                      <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="true"> </b-input></td>
                                      <td><b-input class=" bg-light form-control" v-model="item.supplier_sku" :disabled="viewType=='view'"> </b-input></td>
                                      <td><b-input class=" bg-light form-control" v-model="item.internal_ref" :disabled="viewType=='view'"> </b-input></td>
                                      <td class="col-md-2 "><input type="number" v-model="item.qty" @change="handlechange(item.mapid)" :disabled="viewType=='view'"  class="bg-light form-control" ></td>
                                      <td class="col-md-2 " v-if="viewType=='view'"><input type="number" v-model="item.received"  :disabled="true"  class="bg-light form-control" ></td>
                                      <td class="col-md-2 " ><input type="number" :value="(item.qty*item.costPrice).toFixed(2)"  :disabled="true"  class="bg-light form-control" ></td>


                                  </tr>
                              </template>
                            </template>
                            <tr>
                                <th scope="row" class="col-md-2">

                                  <span v-if="items.length>0">
                                        <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus && viewType!='view'" @click="addsearchitem('yes')"></i>
                                  </span>

                                    <span v-if="!items.length>0 && viewType!='view'" style="margin-bottom: 0px" class="spinner-border text-light " role="status" aria-hidden="true"></span>

<!--                                    <span v-if="viewType=='view'" >-->
<!--                                     <button class="btn btn-success" :disabled="!remove_clicked" v-if="po_searched_items.length>0 && authenticatedUser.permissions.includes('super')">-->
<!--                                         Save-->
<!--                                     </button>-->
<!--                                    </span>-->
                                </th>
                                <td class="col-md-2">

                                </td>
                                <td class="col-md-2"></td>
                                <td class="col-md-2"></td>
                                <td class="text-light" style="font-size: 1.2rem !important;font-weight: bolder"><span v-if="po_TotalQty!='NaN'">{{po_TotalQty}}</span> </td>
                                <td class="col-md-1" v-if="po_status=='Received'"></td>
                                <td class="col-md-1" v-if="po_status=='Submitted' && viewType!='edit' "></td>



                                <td class="text-light" style="font-size: 1.2rem !important;font-weight: bolder"><span v-if="po_grandTotal!='NaN'">£{{po_grandTotal}}</span> </td>





                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6 offset-3">
                        <button  v-if="viewType!='view'"  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesavestock" @click="save_stock">

                            <span v-if="viewType=='add'">
                                 Create
                            </span>
                            <span v-if="viewType=='edit'">
                                 Update
                            </span>

                        </button>
                    </div>
                </div>

                <div class="row mt-5" v-if="viewType=='view' && draft_in">
                    <div class="col-md-12 text-light">
                        <h1 class="text-center">Receive InBound {{draft_in.id}}</h1>
                        <h1 class="col-12 "> <hr class="hr0"></h1>
                        <div class="row mt-1">

                            <div class="col-md-4">
                                <label><strong>Enter Purchase Order Reference</strong></label>
                                <b-input v-model="porin" disabled></b-input>
                            </div>
                            <div class="col-md-4">
                                <label><strong>Select Date</strong></label>
                                <b-form-datepicker

                                    :reset-button=false
                                    reset-button-variant="btn btn-warning"
                                    id="datepicin"

                                    v-model="datein"
                                    placeholder="Date"

                                    dropup  calendar-width="100%">

                                </b-form-datepicker>
                            </div>
                            <div class="col-md-4">
                                <label><strong>Select Location</strong></label>
                                <b-form-select
                                    v-model="location_id_in"
                                    :options="locations_in"
                                    class="bgsecond bform text-white mb-2 "
                                    :disabled="edit"
                                    value-field="id"
                                    text-field="location_name"
                                >

                                    <template #first>
                                        <b-form-select-option value="">Select Location</b-form-select-option>
                                    </template>
                                    <template #option="{ option }">
                                        <div>{{ option.location_name }}</div>
                                    </template>

                                </b-form-select>
                            </div>

                        </div>


                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <!--                                <b-link class="" :to="{ path: '/productform',query: { type: 'add'} }">-->
                                <!--                                    <b-button class="btn-info " > Add New Item </b-button>-->
                                <!--                                </b-link>-->
                            </div>
                            <div class="col-md-6">
                                <h1 class="text-light text-center">Items</h1>
                            </div>
                        </div>


                        <table class="table   table-responsive-sm">
                            <thead  class="thead-dark table-bordered " >
                            <tr class="">
                                <th scope="col">Code </th>
                                <th scope="col">Description</th>
                                <th scope="col" >Qty</th>

                            </tr>
                            </thead>
                            <tbody class="" >
                            <datalist id="item-list-id" >
                                <option v-for="item in items" :key="item.mapid" :value="item.itemCode"> {{ item.itemName }}  | {{item.itemDescription}}  </option>

                            </datalist>
                            <template v-if="inbound_searched_items">
                                <tr class="text-light"  v-for="item in inbound_searched_items" :key="item.mapid">
                                    <th class="col-md-3 ">
                                        <div class="input-container w-100">
                                            <i  class="bi bi-x-circle custom-icon cursor-pointer" @click="removeitem_in(item.mapid)" style="margin-right: 0.5rem;"></i>
                                            <input class="bg-light col-md-11 form-control" v-model="item.itemCode" :disabled="edit" list="item-list-id" @change="addsearchitem_in('',item.itemCode, item.mapid)"  >

                                        </div>
                                    </th>

                                    <td><b-input class=" bg-light form-control" v-model="item.itemDescription" :disabled="true"> </b-input></td>
                                    <td class="col-md-2 "><input type="number" v-model="item.qty" @change="handlechange_in(item.mapid)"  class="bg-light form-control" ></td>



                                </tr>
                            </template>
                            <tr>
                                <th scope="row" >

                                    <i class="fa-solid fa-plus fa-2xl  cursor-pointer" style="color: #08e234; " v-if="showplus_in" @click="addsearchitem_in('yes')" ></i>

                                </th>
                                <td></td>
                                <td></td>




                            </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="row" v-if="viewType=='view' && draft_in">
                    <div class="col-md-6 offset-3">
                        <button  type="button" class="btn custom-btn2 w-100 btn-lg text-light   mt-5 mtmb" :disabled="disablesavestock" @click="save_stock_in"> <span v-if="edit">Update & Receive</span>  <span v-else="">Receive</span> </button>
                    </div>
                </div>


                <div class="row mt-5" v-if="viewType=='view'">
                    <div class="col-md-12">

                        <h1 class="text-light text-center">Inbound History</h1>
                    </div>
                    <div class="col-md-12">
                        <table class="table text-light " >
                            <thead >

                            <th class="col-md-1">ID</th>
                            <th class="col-md-3">Date</th>
                            <th class="col-md-3">User</th>
                            <th class="col-md-3">Location</th>
                            <th class="col-md-2">PO Ref</th>
                            <th class="col-md-2">Status</th>
                            <th class="col-md-2">Action</th>

                            </thead>
                            <tbody >
                            <tr v-for="his in history" :key="his.id">

                                <td> <a href="#" @click.prevent="showmodaldata(his.id)">{{his.id}}</a> </td>
                                <td>{{formatDate(his.date)}}</td>
                                <td>{{users.find(cs=>cs.id==his.user_id).first_name}} {{users.find(cs=>cs.id==his.user_id).last_name}}</td>
                                <td>{{locations.find(lc=>lc.id==his.location_id).location_name}}</td>
                                <td>{{his.purchase_order_ref}}</td>
                                <td>


                                    <span v-if="his.status"> {{his.status}} </span>
                                    <span v-else=""> Completed </span>

                                </td>
                                <td>
                                    <span v-if="his.status=='Draft'">
<!--                                    <button class="btn btn-info" @click="approve_inbound(his.id)">Receive</button>-->
                                        <!--                                    <button v-if="(! authenticatedUser.permissions.includes('super') && ! authenticatedUser.permissions.includes('warehouse-manager')) && his.user_id==authenticatedUser.id" class="btn btn-info" @click="edit_transfer(his)">Edit</button>-->
<!--                                    <b-link class="mx-1" :to="{ path: '/inbound-stock',query: { type: 'edit', id:his.id} }" >-->
<!--                                    <b-button class="btn-info mt-1" > Edit </b-button>-->
<!--                                    </b-link>-->
                                    </span>

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>


                <b-modal  v-model="modalShow"  centered  >
                    <div class="row mt-5">
                        <div class="col-md-12">
                            <h1 class=" text-center">Items Detail</h1>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-hover" >
                                <thead >

                                <th class="col-md-1">ID</th>
                                <th class="col-md-3">Item Code</th>
                                <!--                                <th class="col-md-3">Description</th>-->
                                <th class="col-md-3">Quantity</th>


                                </thead>
                                <tbody >
                                <tr v-for="item in selected_history.dispatchitems" :key="item.id">

                                    <td> {{item.id}} </td>
                                    <td>{{item.itemCode}}</td>
                                    <!--                                    <td>{{item.itemDescription}}</td>-->
                                    <td>{{item.qty}}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </b-modal>







            </div>


        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
//import vSelect from "vue-select";
import showrealtime from "@/components/showrealtime";
import {cloneDeep} from "lodash";
import moment from "moment";
import newitem from "@/constants/newitem";
import Swal from "sweetalert2";
import apiService from "@/service/apiService";
import axios from "axios";
import URL from "@/constants/baseurl";
import Welcomenote from "@/pages/admin/mypos/welcomenote";
//import Loader from "@/components/loader";
//import apiService from "@/service/apiService";
//import axios from "axios";
//import URL from "@/constants/baseurl";
//import Swal from "sweetalert2";
//import apiService from "@/service/apiService";


export default {
    name: "reports",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        ...mapGetters('items', ['po_grandTotal','po_TotalQty']),
        ...mapState('invoice', ['allinvoices','branches']),
        ...mapState('invoice', ['users']),
        ...mapState('items',['items','po_searched_items','inbound_searched_items']),

        po_items(){
            let or_items=cloneDeep(this.items)
           let pi= or_items.filter((i)=>i.def_supplier_id==this.supplier_id)
            return pi
        },
        grandTotal(){

        },

        allowcreateremaining(){
            if(this.po_status=='Partial'){
                if (this.history.find((dis)=>dis.status=='Draft')){
                    return false
                } else {
                    return true
                }

            } else {
                return false
            }
        },

        disablesavestock(){
            if( this.date==''  || (this.po_searched_items.filter((is)=>is.id!=0)).length==0  || (this.edit && (this.inbound_searched_items.length==0 || this.inbound_searched_items.find((in_b)=>in_b.qty=='' || in_b.qty==0 || in_b.qty==null || in_b.qty==undefined || in_b.qty=="NaN")))) {
                return true
            } else return false
        },


        showitemdatalist(){
            if(this.po_searched_items.length==0){
                // return false uncomment and return false on demand
                return true
            }
            else {
                return true
            }
        },

        showplus(){
            let newitems = this.po_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0){
                return true
            }
            else {
                return false
            }
        },

        showplus_in(){
            let newitems = this.inbound_searched_items.filter((item)=>item.itemCode=='')
            if(newitems.length==0 && ! this.edit){
                return true
            }
            else {
                return false
            }
        },



        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            getting_po:false,
            invoice_id:'',
            remove_clicked:false,
            invoices:[],
            deliver_to_customer:'',
            d_customer:{
                lastName:'',
                mobile:'',
                email:'',
                addressLine1:'',
                addressLine2:'',
                postcode:''
            },
            po_status:'',
            viewType:'',
            po_id:null,
            selected_history:{},
            modalShow:false,
            history:[],
            roles: [],
            user: {},
            user_id:'',
            branch_id:'',
            searchitem:'',
            suppliers:[],
            supplier_id:'',
            locations:[],
            locations_in:[],
            location_id:'',
            location_id_in:'',
            por:'',
            porin:'',
            date:new Date(),
            datein:new Date(),
            draft_in:'',
            edit:false

        };
    },



    async created() {
        //  await this.getUsers();
        //  await this.getRoles();
        //   await this.getbranches()
        // await this.getcashiers()

        this.reset_po_searched_items()
        this.viewType = this.$route.query.type;
        this.po_id = this.$route.query.id;
       await this.getpo();
        await  this.handleenter()
      //  this.addsearchitem()
        await this.getlocations()
        await this.getsuppliers()
        await this.reloadcb()


    },
    async mounted() {



    },

    watch: {

        async invoice_id(newVal) {


            //  alert('cardcustomername changed. New value: ' + newVal);

            // You can also call your gettradecustomers method here if needed
            await this.getinvoices(newVal);
        },
        // po_searched_items: {
        //     handler(newValue) {
        //         // Note: `newValue` will be equal to `oldValue` here
        //         // on nested mutations as long as the object itself
        //         // hasn't been replaced.
        //         //    console.log(newValue,'by watch',oldValue)
        //         let targetobj=  newValue.find((n)=>Number(n.qty)<1)
        //         if (targetobj){
        //             //  console.log(targetobj.qty,'target qty')
        //             if(targetobj.id!=0 && targetobj.qty!=''){
        //                 targetobj.qty=1
        //             }
        //
        //
        //         }
        //
        //     },
        //     deep: true
        // },
        // inbound_searched_items: {
        //     handler(newValue) {
        //         // Note: `newValue` will be equal to `oldValue` here
        //         // on nested mutations as long as the object itself
        //         // hasn't been replaced.
        //         //    console.log(newValue,'by watch',oldValue)
        //         let targetobj=  newValue.find((n)=>Number(n.qty)<1)
        //         if (targetobj){
        //             //  console.log(targetobj.qty,'target qty')
        //             if(targetobj.id!=0 && targetobj.qty!=''){
        //                 targetobj.qty=1
        //             }
        //
        //
        //         }
        //
        //     },
        //     deep: true
        // }
    },



    methods: {
        ...mapActions(['login', 'processLogoutManual']),
        ...mapActions('invoice', ['getAllInvoices','getbranches','getcashiers','getusers']),
        ...mapState('items', ['items']),
        ...mapActions('items', ['getItems']),
        ...mapActions('items', ['removepoSearchedItem','removeinboundSearchedItem']),
        ...mapActions('items',['setforedit_inbound_searched_items','add_po_searched_items','reset_po_searched_items','add_inbound_searched_items','reset_inbound_searched_items']),


        async getinvoices(invoice_id){
            //  alert(name)
            //  console.log(name)
            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }
            try {
                const response = await axios.post(`${URL.BASEURL}/api/get-invoices`,  {id:invoice_id});
                this.invoices=response.data

            } catch (error) {
                console.error("Error getting invoices:", error);
            }

        },
        setcustomer(){
            let invoice=this.invoices.find((invoice)=>invoice.id==this.invoice_id)
            if(invoice){
                this.d_customer=invoice.customer
            } else {
                this.d_customer= {
                    lastName:'',
                        mobile:'',
                    email:'',
                    addressLine1:'',
                    addressLine2:'',
                    postcode:''
                }
            }

           // console.log(this.d_customer)
        },

        async create_remaining_inbounds(po_id){
            const res = await apiService.get(`/create-remaining-inbounds/${po_id}`);
           // console.log(res)
            if(res){
                Swal.fire('Success!', 'PO Submitted Successfully!', 'success');
                this.getpo()
            }
        },
        handlesupchange(){
           if(this.viewType=='add'){
               this.reset_po_searched_items()
           }
        },
        handlechange(mapid) {
            //  alert(mapid);
            let item = this.po_searched_items.find((item) => item.mapid == mapid);
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(qty==0){
                if(item.id!=0 && item.qty!=''){
                    item.qty=Number(item.box_qty).toFixed(2)
                }
            }
            let validqty = qty / box_qty
            // alert(validqty)
            if (box_qty) {
                if (validqty < 1) {
                    item.qty = Number(item.box_qty).toFixed(2)
                }
                    // else if (qty % box_qty == 0) {
                    //     //
                // }
                else {
                    //  console.log(item.qty/item.box_qty,'========')
                    item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                }
            }
            (this.po_searched_items.find((item)=>item.qty=='')).qty=1

        },
        handlechange_in(mapid) {
            //  alert(mapid);
            let item = this.inbound_searched_items.find((item) => item.mapid == mapid);
               console.log(item,'item==================================')
            let box_qty = Number(item.box_qty);
            let qty = Number(item.qty);
            if(qty==0){
                if(item.id!=0 && item.qty!=''){
                    item.qty=Number(item.box_qty).toFixed(2)
                }
            }
            let validqty = qty / box_qty
            // alert(validqty)
            if (box_qty) {
                if (validqty < 1) {
                    item.qty = Number(item.box_qty).toFixed(2)
                }
                    // else if (qty % box_qty == 0) {
                    //     //
                // }
                else {
                    //  console.log(item.qty/item.box_qty,'========')
                    item.qty = Number((Math.ceil(Number(qty / box_qty).toFixed(2))) * box_qty).toFixed(2)
                }
            }
            (this.inbound_searched_items.find((item)=>item.qty=='')).qty=1

        },
        async handleenter(){
            this.viewType = this.$route.query.type;

            // if (this.viewType=='add'){
            //     this.reset_inbound_searched_items()
            //     this.addsearchitem()
            // }
            if(this.viewType=='view'){

                this.edit=true

                if(this.draft_in){
                    let dispatch=this.draft_in

                    this.location_id_in=dispatch.location_id
                    this.datein=dispatch.date

                    this.porin=dispatch.purchase_order_ref
                    this.setforedit_inbound_searched_items(dispatch.dispatchitems)
                }
            }
        },
        showmodaldata(id){
            this.selected_history=this.history.find((hs)=>hs.id==id)
            console.log(  this.selected_history,'here')
            this.modalShow = ! this.modalShow
        },
        handleempty(){
            (this.po_searched_items.find((item)=>item.qty=='')).qty=1
        },
        // async  gethistory(mode){
        //     const {data} = await apiService.get(`/stock-history/${mode}`);
        //
        //     this.history=data
        //     // console.log('history',this.history)
        // },
        async  getpo(){

            if(this.viewType!='add'){
                this.reset_po_searched_items()
                this.getting_po=true
                const {data} = await apiService.get(`/getpo/${this.po_id}`);
                this.getting_po=false

                let po_items=cloneDeep(data.po_items)




                if(data.deliver_to_customer){
                    this.deliver_to_customer=true
                }

                if(data.customer){
                    this.d_customer=data.customer
                }

                this.invoice_id=data.invoice_id
                   this.supplier_id=data.supplier_id
                  this.location_id=data.delivery_location_id
                  this.date=data.date
                  this.por=data.purchase_order_ref
                po_items.map((item)=>{
                    // console.log(item)
                    let mapid=Math.random()
                    let datawithmapid = { ...item, id:item.item_id, mapid: mapid,itemCode:item.item_code, itemDescription:item.item_description, costPrice:item.cost_price };
                //    let mditem={...item, itemCode:item.item_code, itemDescription:item.item_description}
                    this.add_po_searched_items(datawithmapid)
                })

                this.history=data.dispatches
                 this.po_status=data.status
                this.draft_in=data.dispatches.find((d)=>d.status=='Draft')
                //console.log('po',data)
            }

        },
        async remove_out_of_stock(item){
            let ledger_entry={
                po_id:this.po_id,
                item:item,


            }


            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/remove-out-of-stock`, ledger_entry);

                if (res) {
                    return res
                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to remove out of stock', 'info');
            }
        },
        async save_stock(){
            let ledger_entry={
                po_id:this.po_id,
                items:this.po_searched_items,
                location_id:this.location_id,
                date:moment(this.date).format('YYYY-MM-DD HH:mm:ss'),
                por:this.por,
                user_id:this.authenticatedUser.id,
                supplier_id:this.supplier_id,
                deliver_to_customer:this.deliver_to_customer,
                invoice_id:this.invoice_id,
                d_customer:this.d_customer,
                remove_clicked:this.remove_clicked

            }
            if(!this.authenticatedUser.permissions.includes('super')){
                ledger_entry.location_id=this.locations.find((l)=>l.branch_id==this.authenticatedUser.branch_id).id
            }
            //   console.log(ledger_entry)

            const apiToken = localStorage.getItem('token');
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${URL.BASEURL}/api/create-direct-pos`, ledger_entry);

                if (res) {


                    if(this.viewType=='add'){
                        Swal.fire('Success', 'PO Created', 'success');
                    }
                    if(this.viewType=='edit'){
                        Swal.fire('Success', 'PO Updated', 'success');
                    }

                    console.log(res.data)
                    this.reset_po_searched_items()
                    this.addsearchitem()

                    this.location_id='';
                    this.date=new Date();
                    this.por='';
                    this.note='';
                    this.$router.push({ path: '/purchase-orders' })




                }
            } catch (error) {
                console.error(error);

                if(this.viewType=='add'){
                    Swal.fire('Error', 'Failed to Create PO.', 'info');
                }
                if(this.viewType=='edit'){
                    Swal.fire('Error', 'Failed to Update PO.', 'info');
                }
            }
        },

        async save_stock_in(){
            let ledger_entry={
                dispatch_id:this.draft_in.id,
                items:this.inbound_searched_items,
                location_id:this.location_id_in,
                date:moment(this.datein).format('YYYY-MM-DD HH:mm:ss'),
                por:this.porin,
                user_id:this.authenticatedUser.id


            }
            //   console.log(ledger_entry)

            const apiToken = localStorage.getItem('token');
            let apiurl=`${URL.BASEURL}/api/add-stock`
            if(this.edit){
                apiurl=`${URL.BASEURL}/api/update-inbound`
            }
            if (apiToken) {
                // Set the Authorization header with the API token
                axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
            }

            try {
                const res = await axios.post(`${apiurl}`, ledger_entry);

                if (res) {

                    if(this.edit){
                        await this.approve_inbound(this.draft_in.id)
                        Swal.fire('Success', 'In Bound Updated and Received', 'success');
                    }
                    else {
                        Swal.fire('Success', 'Stock Received', 'success');
                    }

                    // console.log(res.data)
                    this.reset_inbound_searched_items()
                    this.addsearchitem_in()
                    this.edit=false
                    this.location_id_in='';
                    this.datein=new Date();
                    this.porin='';
                    this.note='';

                    this.$router.back();
                }
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'Failed to Add Stock.', 'info');
            }
        },

        formatDate(date){
            let date1 = new Date(date)
            let date2 =  moment(date1).format('D MMMM, YYYY');
            //  let date2 =  moment(date1).format('D MMMM, h:mm A');

            return date2;
        },
        async getlocations(){
            const {data} = await apiService.get(`/locations`);
            //  console.log('branches', data)
            this.locations=data
            this.locations_in=data
        },
        async getsuppliers(){
            const {data} = await apiService.get(`/suppliers`);
            //  console.log('branches', data)
            this.suppliers=data
        },
        addsearchitem(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.po_searched_items).length==0) {
                let mapid=Math.random()
                let item=cloneDeep(newitem)
                let datawithmapid = { ...item, mapid: mapid,qty:item.box_qty };
             //   alert('here')
                this.add_po_searched_items(datawithmapid)
            }
            else if(itemcode) {
                const   item=this.items.find((i) => i.itemCode==itemcode)
                // console.log(item,'item')
                if(item){
                    //  console.log(item[0])
                    let mapid=Math.random()
                    // alert('here')
                    let datawithmapid = { ...item, mapid: mapid, qty: Number(item.box_qty).toFixed(2) };
                    console.log(Number(item.box_qty).toFixed(2),'hereeee')
                    console.log(datawithmapid,'hereeee')
                    this.add_po_searched_items(datawithmapid)


                    const   dummyitem=(this.po_searched_items).find((i) => i.id==0)
                    console.log(dummyitem)
                    this.removepoSearchedItem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },

      async  removeitem(id,item=null){

            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');

            // this.remove_clicked=true;
          console.log('item_clicked', item)
            if(this.viewType=='view' && this.authenticatedUser.permissions.includes('super')){
                const result = await Swal.fire({
                    title: "Are you sure?",
                    text: "This action cannot be undone. Do you really want to delete this PO item?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "Cancel"
                });

                if (result.isConfirmed) {
                    this.remove_clicked=true;
                  let res= await this.remove_out_of_stock(item)

                        console.log('res',res)
                        this.removepoSearchedItem(id)
                    // this.reset_inbound_searched_items()
                        await this.getpo();
                        await this.handleenter()


                }
            } else {
                this.removepoSearchedItem(id)
            }

        },
        addsearchitem_in(dummy='', itemcode=null,mapid=null){

            // alert(this.searchitem)
            //console.log((this.searched_items).length)
            if(dummy=='yes' || (this.inbound_searched_items).length==0) {

                let mapid=Math.random()
                let item=cloneDeep(newitem)
                let datawithmapid = { ...item, mapid: mapid,qty:item.box_qty };
                this.add_inbound_searched_items(datawithmapid)
            }
            else if(itemcode) {
                const   item=this.items.find((i) => i.itemCode==itemcode)

                if(item){
                    //  console.log(item[0])
                    let mapid=Math.random()
                    let datawithmapid = { ...item, mapid: mapid,qty:item.box_qty };
                    this.add_inbound_searched_items(datawithmapid)


                    const   dummyitem=(this.inbound_searched_items).find((i) => i.id==0)
                    console.log(dummyitem)
                    this.removeinboundSearchedItem(dummyitem.mapid)


                }
                else {
                    Swal.fire('Item Not Found', 'No item available for this code', 'info');
                    //  alert('not found')
                    this.removeitem(mapid)
                }
            }
            //  console.log(this.searched_items)

        },
        removeitem_in(id){
            //  await  Swal.fire('Removing ', 'You are removing item from invoice', 'info');
            this.removeinboundSearchedItem(id)

        },

        formattedDate(date) {

            const formattedDate = moment(date).format('DD-MM-YYYY');

            return formattedDate;
        },


        async reloadcb(){
            if(this.branches.length==0){
                //   alert(this.branches.length)
                await this.getbranches()

            }
            if(this.items.length==0){
                //   alert(this.branches.length)
                if(this.viewType!='view'){
                    await this.getItems();
                }


            }
            if(this.users.length==0){
                await this.getusers()
                ///console.log(this.users)
            }
        },
        async approve_inbound(dispatch_id){

            const swalResult = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to do it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            });

            if (swalResult.isConfirmed) {
                try {
                    const { data } = await apiService.get(`/receive-inbound-stock/${dispatch_id}`);
                    console.log(data.status);
                    this.reset_po_searched_items()
                    await this.getpo()

                } catch (error) {
                    // Handle errors, you might want to show a Swal alert for errors too
                    console.error('Error in approving:', error);
                    Swal.fire('Error!', 'An error occurred while processing the request.', 'error');
                }
            }
        },


        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        // async getUsers(role = '') {
        //     const { data } = await this.$axios.get(`user?role=${role}`);
        //     this.items = data;
        // },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },




    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}

.gray{
    background-color: gray !important;
}
.input-container {
    display: inline-flex; /* Use inline-flex to place the elements in the same line */
    align-items: center; /* To vertically center the elements */
}

.hr0{
    margin-top: 5px;
    margin-bottom: 0px;

    border: 0;
    border-top: 3px solid green;
    max-width: 100%;

}



</style>
