<template>

    <div class="container-fluid fixcontent " >

        <div class="row " style="margin-top: 1px; margin-bottom: -4px !important;" >
            <div class="col-md-2 text-light " style="padding-left: 0px !important;">

                <b-link class="" :to="{ path: '/' }">
                    <img class="col-md-12   " src="@/assets/images/headerlogo.png"   >
                </b-link>
            </div>
            <div class="col-md-5 text-center col-7 mt-4 text-light offset-1 font-weight-semi-bold" style="font-size: 17px">
             <welcomenote/>

            </div>
            <div class="col-md-4 text-right col-4 text-light mt-1" style="font-size: 17px" >
                <p style="margin-bottom: 0.3rem"><ShowRealTime/></p>
                <a class="text-light" href="#" @click.prevent="signOut">Login</a> / <a class="text-light" href="#" @click.prevent="signOut">Logout</a>
            </div>
        </div>

        <div class="row mt-2">
            <sidebar></sidebar>
            <div class="col-md-11 bgsecond mt-2 text-light ">
                <div class="row mb-2 text-center">
                    <h1 class="col-md-12">Stock Management</h1>
                </div>

                <div class="row mt-5">

<!--                    <div class="col-md-4 mb-5">-->
<!--                        <b-link class="" :to="{ path: '/products' }">-->
<!--                            <b-button  type="button" class="btn stockbtn w-100 btn-lg  mt-3"  ><span class="inbound_stock">Products</span> </b-button>-->
<!--                        </b-link>-->
<!--                    </div>-->
<!--                    <div class="col-md-4 mb-5">-->
<!--                        <b-link class="" :to="{ path: '/transfer-guide' }">-->
<!--                            <b-button  type="button" class="btn stockbtn w-100 btn-lg  mt-3"  ><span class="inbound_stock">Transfer Guide</span> </b-button>-->
<!--                        </b-link>-->
<!--                    </div>-->

<!--                    <div class="col-md-4 mb-5">-->
<!--                        <b-link class="" :to="{ path: '/dispatch-planner' }">-->
<!--                            <b-button  type="button" class="btn stockbtn w-100 btn-lg  mt-3"  ><span class="inbound_stock">Dispatch Planner</span> </b-button>-->
<!--                        </b-link>-->
<!--                    </div>-->

                        <div class="col-md-4 mb-5" v-if="authenticatedUser.permissions.includes('super')">
                            <b-link class="" :to="{ path: '/inbounds' }">
                                <b-button  type="button" class="btn stockbtn w-100 btn-lg  mt-3"  ><span class="inbound_stock">InBound</span> </b-button>
                            </b-link>
                        </div>


                        <div class="col-md-4 mb-5" >
                            <b-link class="" :to="{ path: '/adjustments' }">
                                <b-button  type="button" class="btn stockbtn w-100 btn-lg  mt-3"  ><span class="inbound_stock">Adjustment</span> </b-button>
                            </b-link>
                        </div>


                        <div class="col-md-4 mb-5" >
                            <b-link class="" :to="{ path: '/intransfers' }">
                                <b-button  type="button" class="btn stockbtn w-100 btn-lg  mt-3"  ><span class="inbound_stock">Transfer</span> </b-button>
                            </b-link>
                        </div>






                </div>



            </div>

        </div>

    </div>

</template>

<script>
//import apiService from "@/service/apiService";
import { mapActions } from "vuex";
import { mapGetters } from 'vuex';
//import { mapState } from 'vuex';
import sidebar from "@/components/layout/sidebar";
import showrealtime from "@/components/showrealtime";
import Welcomenote from "@/pages/admin/mypos/welcomenote";

//import vSelect from "vue-select";


export default {
    name: "settings",

    components:{Welcomenote, 'sidebar': sidebar,'ShowRealTime':showrealtime},
    computed: {
        ...mapGetters(['AuthenticatedUser']),
        // ...mapState('invoice', ['latestinvoices','cashiers','branches']),
        // ...mapState('invoice', ['branches']),
        // totalPages() {
        //     const itemsPerPage = 10;
        //     return Math.ceil(this.filteredOrders.length / itemsPerPage);
        // },
        authenticatedUser() {
            let user = localStorage.getItem('user');
            user = JSON.parse(user)
            return user
        },

    },
    data() {
        return {
            roles: [],
            user: {},

            cashier_id:'',
            order_status:'',
            branch_id:''

        };
    },
    async created() {
        await this.getUsers();
        await this.getRoles();
        // await this.getbranches()
        // await this.getcashiers()

    },
    async mounted() {


    },
    methods: {
        ...mapActions(['login', 'processLogoutManual']),


        async signOut() {
            await this.processLogoutManual();
            //  await this.$router.push({ name: "Login" });
            window.location.href = this.$router.resolve({ name: "Login" }).href;
        },
        async getUsers(role = '') {
            const { data } = await this.$axios.get(`user?role=${role}`);
            this.items = data;
        },
        async getRoles() {
            const { data } = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsers(value) {
            this.getUsers(value)
        },
        // async getbranches(){
        //     const {data} = await apiService.get(`/branches`);
        //     console.log('branches', data)
        //     this.branches=data
        // },


    },
}
</script>

<style scoped>
@import '../../../assets/styles/pos.css';
.hide {
    visibility: hidden !important;
}



</style>
